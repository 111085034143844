import PropTypes from 'prop-types';
import ContentGeneric from './ContentGeneric';

const SsoWarmWelcome = ({contentId, userFirstName}) => {
    const coverageDetails = {
        title: {
            key: userFirstName
                ? `${contentId}.title`
                : `${contentId}.titleWithoutUserFirstName`,
            interpolation: {
                userFirstName,
            },
        },
        paragraph: {key: `${contentId}.paragraph`},
        paragraph2: {key: `${contentId}.paragraph2`},
    };

    return (
        <ContentGeneric infoDetails={coverageDetails} contentId={contentId} />
    );
};

SsoWarmWelcome.propTypes = {
    contentId: PropTypes.string.isRequired,
    userFirstName: PropTypes.string.isRequired,
};

export default SsoWarmWelcome;
