import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Accordion from '@teladoc/pulse/ui/Accordion';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import {questionConfig} from 'constants/questions';
import css from './ClientConsent.scss';

const Local99Consent = ({id, name, defaultValue, required}) => {
    const {
        helperText,
        label,
        content1,
        content2,
        content3,
        content4,
        content5,
        trigger,
        error,
    } = questionConfig[id] || {};
    const {t} = useTranslation('questions');

    return (
        <>
            <span>{t(helperText)}</span>
            <Accordion
                className={css.root}
                key={id}
                items={[
                    {
                        isOpen: false,
                        trigger: <div>{t(trigger)}</div>,
                        content: (
                            <div className={css.accordionContent}>
                                <ul>
                                    <li>{t(content1)}</li>
                                    <li>{t(content2)}</li>
                                    <li>{t(content3)}</li>
                                    <li>{t(content4)}</li>
                                    <li>{t(content5)}</li>
                                </ul>
                            </div>
                        ),
                    },
                ]}
            />
            <Checkbox
                defaultChecked={defaultValue === 'true'}
                id={id}
                name={name}
                value="YES"
                label={<Label>{t(label)}</Label>}
                i18nItemLabel={t(label)}
                error={<FormElementError>{t(error)}</FormElementError>}
                required={required}
            />
        </>
    );
};

Local99Consent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool.isRequired,
};

export default Local99Consent;
