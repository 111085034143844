/**
 * Questions gRPC calls
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/enrollment/questions
 */
import {SubmitAnswersRequest} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/questions/submit_answers_pb';
import {QuestionId} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/common/question_ids_pb';
import findKey from 'lodash/findKey';
import {
    enrollmentPromiseClient,
    getGrpcProtocolVersion,
    getGrpcAccessToken,
    getGrpcMetadata,
    answerToProtobuf,
} from './enrollment-utils';

// Get string question name like EMAIL from integer question id like 19
export const getQuestionNameById = value =>
    findKey(QuestionId.Values, id => id === value);

/**
 * submitAnswers
 * Submits answers to backend
 * @param {Array<Object>} answers containing all the expected fields from gRPC
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.questions.SubmitAnswersResponse>}
 *  Promise
 */
export const submitAnswers = answers => {
    const request = new SubmitAnswersRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());
    answers.forEach(answer => {
        request.addAnswers(answerToProtobuf(answer));
    });

    // Comment out to take mock data.
    return enrollmentPromiseClient
        .submitAnswers(request, getGrpcMetadata())
        .then(response => response.toObject());
};
