import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import css from './ContentGeneric.scss';

const DidNotAcceptClientConsent = ({sectionInfo, contentId}) => {
    const {t} = useTranslation('content');
    const dispatch = useDispatch();

    useEffect(() => {
        let obj = {backBtn: false, nextBtn: true};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};
            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <h1 className={css.h1}>{t(`${contentId}.title`)}</h1>
            <p className={css.p}>{t(`${contentId}.paragraph`)}</p>
        </>
    );
};

DidNotAcceptClientConsent.propTypes = {
    contentId: PropTypes.string,
    sectionInfo: PropTypes.object,
};

export default DidNotAcceptClientConsent;
