const mixpanelEventPrefix =
    location.pathname.split('/')[1] === 'upsell' ? 'UPSELL' : 'REG';

export const mixpanelEvent = {
    BEFORE_INITIALIZE: `${mixpanelEventPrefix} 5.0 Before Initialize`,
    SUBMITTED_REG_CODE: `${mixpanelEventPrefix} 5.0 Submitted RegCode`,
    SUBMITTED_TRACKING_ID: `${mixpanelEventPrefix} 5.0 Submitted Tracking ID`,
    APP_DOWNLOAD_ATTEMPTED: `${mixpanelEventPrefix} 5.0 App Download Attempted`,
    PHONE_NUMBER_CLICKED: `${mixpanelEventPrefix} 5.0 Phone Number Clicked`,
    SESSION_TIME_OUT: `${mixpanelEventPrefix} 5.0 Session Time Out`,
    SESSION_CONTINUED_BEFORE_TIME_OUT: `${mixpanelEventPrefix} 5.0 Session Continued Before Time Out`,
    SESSION_RESTART: `${mixpanelEventPrefix} 5.0 Session Restart`,
    PAGE_LAND: `${mixpanelEventPrefix} 5.0 Page Land`,
    PAGE_PASS: `${mixpanelEventPrefix} 5.0 Page Pass`,
    UNABLE_TO_CONTINUE: `${mixpanelEventPrefix} 5.0 Unable to Continue`,
    BUTTON_CLICKED: `${mixpanelEventPrefix} 5.0 Button Clicked`,
    NAVIGATE_NEXT_CLICK: `${mixpanelEventPrefix} 5.0 Navigate Next Click`,
    NAVIGATE_BACK_CLICK: `${mixpanelEventPrefix} 5.0 Navigate Back Click`,
    CHANGE_PROGRAM_SELECTION: `${mixpanelEventPrefix}REG 5.0 Change Program Selection`,
    GENERAL_ERROR: `${mixpanelEventPrefix} 5.0 General Error`,
    PAGE_ERROR: `${mixpanelEventPrefix} 5.0 Page Error`,
    PDF_VIEWED: `${mixpanelEventPrefix} 5.0 PDF Viewed`,
    MODAL_VIEWED: `${mixpanelEventPrefix} 5.0 Modal Viewed`,
    FORGOT_REGCODE: `${mixpanelEventPrefix} 5.0 Forgot Regcode Match Details`,
    MODAL_CLOSED: `${mixpanelEventPrefix} 5.0 Modal Closed`,
    START_OVER: `${mixpanelEventPrefix} 5.0 Start Over`,
    ACCOUNT_CREATED: `${mixpanelEventPrefix} 5.0 Account Created FE`,
    USER_LOGIN_SUCCESSFUL: `${mixpanelEventPrefix} 5.0 User Login Successful`,
    USER_LOGIN_FAILED: `${mixpanelEventPrefix} 5.0 User Login Failed`,
    USER_SSO_SUCCESSFUL: `${mixpanelEventPrefix} 5.0 User SSO Successful`,
    USER_SSO_FAILED: `${mixpanelEventPrefix} 5.0 User SSO Failed`,
    BH_SSO_SUCCESSFUL: `${mixpanelEventPrefix} 5.0 User SSO to myStrength Successful`,
    BH_SSO_FAILED: `${mixpanelEventPrefix} 5.0 User SSO to myStrength Failed`,
    BH_SSO_RETRY: `${mixpanelEventPrefix} 5.0 User SSO to myStrength Retried`,
    BH_SSO_RESEND_EMAIL: `${mixpanelEventPrefix} 5.0 User SSO to myStrength Resent Email`,
    ONEAPP_SKIP_FOR_NOW: `${mixpanelEventPrefix} 5.0 CQM Skipped`,
    ENROLLMENT_EXPERIMENT: `${mixpanelEventPrefix} 5.0 Enrollment Experiment FE`,
    PROGRAM_SELECTION_VIEW: `${mixpanelEventPrefix} 5.0 Program Selection Screen View`,
    PROGRAM_SELECTION_CLICK: `${mixpanelEventPrefix} 5.0 Program Selection Selection Activity`,
    UPSELL_PROGRAM_ENROLLED: `${mixpanelEventPrefix} 5.0 Program Enrolled`,
    CLICKED_TELADOC_LOGO: `${mixpanelEventPrefix} 5.0 Clicked Teladoc Logo`,
    PAGE_HIDE: `${mixpanelEventPrefix} 5.0 Page Hide`,
};

export const mixpanelEventProperty = {
    CONTENT_ID: 'Content ID',
    QUESTION_ID: 'Question ID',
    MODAL_ID: 'Modal ID',
    VWO_VARIANT: 'VWO Variant',
    MODAL_DESCRIPTION: 'Modal Description',
    FORGOT_REGCODE_TALLIED: 'Forgot Reg Code match type',
    FORGOT_REGCODE_SUBMISSION: 'Forgot Reg Code submission number',
    TERM_TYPE: 'Term Type',
    DATATYPE: 'Response Type',
    BUTTON_LABEL: 'Button Label',
    BUTTON_COPY: 'Button Copy',
    TITLE: 'Title',
    EXPERIMENT_NAME: 'Experiment Name FE',
    EXPERIMENT_GROUP: 'Experiment Group FE',
    TRIGGER_LOCATION: 'Trigger Location',
    PROGRAM_OFFERED: 'Programs Offered',
    NUMBER_PROGRAM_OFFERED: 'Number Programs Offered',
    PROGRAM_SELECTED: 'Programs Selected',
    NUMBER_PROGRAM_SELECTED: 'Number Programs Selected',
    PROGRAM_DESELECTED: 'Programs Deselected',
    NUMBER_PROGRAM_DESELECTED: 'Number Programs Deselected',
};

export const utmKeywords = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
];
