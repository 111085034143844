import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import Label from '@teladoc/pulse/ui/Label';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import ScreenReader from 'common/screen-reader/ScreenReader';
import {questionConfig} from 'constants/questions';
import Config from 'config';
import {mixpanelPdfViewedEvent} from 'utilities/mixpanel-utils';
import css from '../Question.scss';

const {tosUrl, privacyPracticeUrl, nlaUrl} = Config;

const TermsAndConditions = ({id, name, required}) => {
    const locale = getStoredLang().toLowerCase();
    const {error} = questionConfig[id] || {};
    const {t} = useTranslation('questions');

    const onTermsOfServiceClick = () => {
        mixpanelPdfViewedEvent('Terms of Service', 'TERMS_AND_CONDITIONS');
    };

    const onPrivacyPracticesClick = () => {
        mixpanelPdfViewedEvent('Privacy Policy', 'TERMS_AND_CONDITIONS');
    };

    const onNonDiscriminationDisclosureClick = () => {
        mixpanelPdfViewedEvent(
            'Non-Discrimination Disclosure',
            'TERMS_AND_CONDITIONS'
        );
    };

    const termsOfService = (
        <a
            href={`${tosUrl}?locale=${locale}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onTermsOfServiceClick}
        >
            <ScreenReader />
        </a>
    );
    const privacyPractices = (
        <a
            href={`${privacyPracticeUrl}?locale=${locale}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onPrivacyPracticesClick}
        >
            <ScreenReader />
        </a>
    );
    const nonDiscriminationDisclosure = (
        <a
            href={`${nlaUrl}?locale=${locale}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onNonDiscriminationDisclosureClick}
        >
            <ScreenReader />
        </a>
    );

    const allTermAndConditions = [
        termsOfService,
        privacyPractices,
        nonDiscriminationDisclosure,
    ];

    return (
        <Checkbox
            classNameRoot={css.marginAdj}
            id={id}
            name={name}
            value="true"
            label={
                <Label>
                    <Trans
                        i18nKey="questions:TERMS_AND_CONDITIONS.label"
                        components={allTermAndConditions}
                    />
                </Label>
            }
            i18nItemLabel={t('TERMS_AND_CONDITIONS.i18nItemLabel')}
            error={<FormElementError>{t(error)}</FormElementError>}
            required={required}
        />
    );
};

TermsAndConditions.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
};

export default TermsAndConditions;
