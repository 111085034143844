/*
 * Authentication gRPC calls
 * https://github.com/livongo/protos/blob/integration/src/livongo/protobuf/grpc/external/enrollment/manage_solution/manage_solution.proto
 */

import {
    InitializeChangeSolutionRequest,
    ManageSolutionRequest,
    ManageSolutionResponse,
} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/manage_solution/manage_solution_pb';
import {
    manageSolutionPromiseClient,
    getUpsellGrpcMetadata,
    getGrpcAccessToken,
} from './enrollment-utils';

export const ManageSolutionResult = ManageSolutionResponse.ManageSolutionResult;

export const initializeChangeSolutionRequest = (authToken, programs) => {
    const request = new InitializeChangeSolutionRequest();

    request.setName('user/liv/me');
    request.setProgramsList(programs); // accept array of INT

    return manageSolutionPromiseClient
        .initializeChangeSolution(request, getUpsellGrpcMetadata(authToken))
        .then(response => response.toObject());
};

export const manageSolutionRequest = authToken => {
    const request = new ManageSolutionRequest();

    request.setName('user/liv/me');
    request.setAccessToken(getGrpcAccessToken());

    return manageSolutionPromiseClient
        .manageSolution(request, getUpsellGrpcMetadata(authToken))
        .then(response => response.toObject());
};
