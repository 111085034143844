import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Label from '@teladoc/pulse/ui/Label';
import Select from '@teladoc/pulse/ui/Select';
import TextInput from '@teladoc/pulse/ui/TextInput';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import {questionConfig} from 'constants/questions';
import css from './Height.scss';

const feet = [
    {label: `1'`, value: '12'},
    {label: `2'`, value: '24'},
    {label: `3'`, value: '36'},
    {label: `4'`, value: '48'},
    {label: `5'`, value: '60'},
    {label: `6'`, value: '72'},
    {label: `7'`, value: '84'},
    {label: `8'`, value: '96'},
    {label: `9'`, value: '108'},
];
const inches = [
    {label: '0"', value: '0'},
    {label: '1"', value: '1'},
    {label: '2"', value: '2'},
    {label: '3"', value: '3'},
    {label: '4"', value: '4'},
    {label: '5"', value: '5'},
    {label: '6"', value: '6'},
    {label: '7"', value: '7'},
    {label: '8"', value: '8'},
    {label: '9"', value: '9'},
    {label: '10"', value: '10'},
    {label: '11"', value: '11'},
];

const Height = ({
    id,
    name,
    required,
    defaultValue,
    customValidationError,
    hasNodeTitle,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    const {
        label,
        labelForWithNodeTitle,
        footLabel,
        inchLabel,
        footPlaceholder,
        inchPlaceholder,
        error,
    } = questionConfig[id] || {};

    const [foot, setFoot] = useState(
        defaultValue ? `${Math.floor(defaultValue / 12) * 12}` : null
    );
    const [inch, setInch] = useState(
        defaultValue ? `${defaultValue % 12}` : null
    );
    const [height, setHeight] = useState(defaultValue || '');

    const onFootChange = ({value}) => {
        if (value !== foot) {
            setFoot(value);
        }

        if (onChange) {
            onChange(Number(value) + Number(inch));
        }
    };
    const onInchChange = ({value}) => {
        if (value !== inch) {
            setInch(value);
        }

        if (onChange) {
            onChange(Number(foot) + Number(value));
        }
    };

    useEffect(() => {
        if (foot && inch) {
            setHeight(Number(foot) + Number(inch));
        }
    }, [foot, inch]);

    const renderQuestionLabel = hasNodeTitle ? labelForWithNodeTitle : label;
    const renderClassForQuestionLabel = hasNodeTitle
        ? css.questionLabelForWithNodeTitle
        : css.questionLabel;

    return (
        <>
            <span className={renderClassForQuestionLabel}>
                {t(renderQuestionLabel)}
            </span>
            <div className={css.root}>
                <Select
                    id={`foot_${id}`}
                    defaultValue={foot}
                    classNameRoot={css.selectFoot}
                    label={
                        <Label i18nRequiredVisualLabel=" ">
                            {t(footLabel)}
                        </Label>
                    }
                    items={feet}
                    placeholder={t(footPlaceholder)}
                    error={<FormElementError>{t(error)}</FormElementError>}
                    customValidationError={customValidationError}
                    onChange={onFootChange}
                    required={required}
                />
                <Select
                    id={`inch_${id}`}
                    defaultValue={inch}
                    classNameRoot={css.selectInch}
                    label={
                        <Label i18nRequiredVisualLabel=" ">
                            {t(inchLabel)}
                        </Label>
                    }
                    items={inches}
                    placeholder={t(inchPlaceholder)}
                    error={<FormElementError>{t(error)}</FormElementError>}
                    customValidationError={customValidationError}
                    onChange={onInchChange}
                    required={required}
                />
                <TextInput
                    id={id}
                    name={name}
                    type="hidden"
                    // TODO: Update to a more proper label.
                    i18nItemLabel="hidden"
                    value={height}
                />
            </div>
        </>
    );
};

Height.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.number,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    hasNodeTitle: PropTypes.bool,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} a1cValue - Either the A1c Value or the IDK string.
     */
    onChange: PropTypes.func,
};

export default Height;
