import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import {programs as programsName} from 'constants/app';
import css from './Address.scss';

const {
    DIABETES,
    HYPERTENSION,
    WEIGHT_MANAGEMENT,
    PREDIABETES,
    CHRONIC_KIDNEY_DISEASE,
    HEART_FAILURE,
    ADV_WEIGHT_MANAGEMENT,
    ADV_PREDIABETES,
    COMPR_WEIGHT_CARE,
    COMPR_PREDIABETES_CARE,
} = programsName;

const Address = ({info, programs}) => {
    const {t} = useTranslation('questions');
    const {
        bloodGlucoseMeterAlt,
        bloodGlucoseMeter,
        bloodPressureMonitorAlt,
        bloodPressureMonitor,
        weightManagementScale,
        weightManagementScaleAlt,
    } = info;
    const isDiabetes = programs.includes(DIABETES);
    const isHypertension = programs.includes(HYPERTENSION);
    const isWeightManagement =
        programs.includes(WEIGHT_MANAGEMENT) ||
        programs.includes(ADV_WEIGHT_MANAGEMENT);
    const isPreDiabetes =
        programs.includes(PREDIABETES) || programs.includes(ADV_PREDIABETES);
    const isComprehensiveCarePrograms =
        programs.includes(COMPR_WEIGHT_CARE) ||
        programs.includes(COMPR_PREDIABETES_CARE);
    const isChronicKidneyDisease = programs.includes(CHRONIC_KIDNEY_DISEASE);
    const isHeartFailure = programs.includes(HEART_FAILURE);

    return (
        <>
            {isDiabetes && (
                <div className={css.cardContainer}>
                    <Image
                        cloudinaryImageId="registration/components/questions/address/bg1000_kit_esn66a"
                        alt={t(bloodGlucoseMeterAlt)}
                        width={1200}
                        height={675}
                        classNameImage={css.img}
                    />
                    <p className={css.p}>{t(bloodGlucoseMeter)}</p>
                </div>
            )}
            {isComprehensiveCarePrograms && (
                <>
                    <div className={css.cardContainer}>
                        <Image
                            cloudinaryImageId="registration/components/questions/address/wm1500_kit_cndvkv"
                            alt={t(weightManagementScaleAlt)}
                            width={1200}
                            height={675}
                            classNameImage={css.img}
                        />
                        <p className={css.p}>{t(weightManagementScale)}</p>
                    </div>
                    <div className={css.cardContainer}>
                        <Image
                            cloudinaryImageId="registration/components/questions/address/ht945-kit_llcj2v"
                            alt={t(bloodPressureMonitorAlt)}
                            width={1200}
                            height={675}
                            classNameImage={css.img}
                        />
                        <p className={css.p}>{t(bloodPressureMonitor)}</p>
                    </div>
                </>
            )}
            {(isHypertension || isChronicKidneyDisease || isHeartFailure) && (
                <div className={css.cardContainer}>
                    <Image
                        cloudinaryImageId="registration/components/questions/address/ht900_kit_livtwz"
                        alt={t(bloodPressureMonitorAlt)}
                        width={1200}
                        height={675}
                        classNameImage={css.img}
                    />
                    <p className={css.p}>{t(bloodPressureMonitor)}</p>
                </div>
            )}
            {(isWeightManagement ||
                isPreDiabetes ||
                isChronicKidneyDisease ||
                isHeartFailure) && (
                <div className={css.cardContainer}>
                    <Image
                        cloudinaryImageId="registration/components/questions/address/wm1500_kit_cndvkv"
                        alt={t(weightManagementScaleAlt)}
                        width={1200}
                        height={675}
                        classNameImage={css.img}
                    />
                    <p className={css.p}>{t(weightManagementScale)}</p>
                </div>
            )}
        </>
    );
};

Address.propTypes = {
    info: PropTypes.object.isRequired,
    programs: PropTypes.array.isRequired,
};

export default Address;
