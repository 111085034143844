import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import PasswordInput from '@teladoc/pulse/ui/PasswordInput';
import PasswordCriteria from '@teladoc/pulse/ui/PasswordCriteria';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import FormGroupError from '@teladoc/pulse/ui/FormGroupError';
import {questionConfig} from 'constants/questions';
import {errorTypes} from 'constants/type';
import {getFirstNonEmptyKey} from 'utilities/utils';
import {renderLabel} from '../questions-utils';
import css from './Password.scss';

const {INVALID_PASSWORD} = errorTypes;

const Password = ({
    id,
    name,
    defaultValue = '',
    customValidationError,
    hasNodeTitle,
    onChange,
    required,
    validationErrorsList,
}) => {
    const {t} = useTranslation('questions');
    const [value, setValue] = useState(defaultValue);
    const handleOnChange = evt => {
        setValue(evt.target.value);

        if (onChange) {
            onChange(evt);
        }
    };

    // Get question UI configuration.
    const {
        autocomplete,
        label,
        labelForWithNodeTitle,
        ariaLabel,
        placeholder,
        criteria1,
        criteria2,
        criteria3,
        criteria4,
        criteria5,
        error,
        formGroupErrorTitle,
    } = questionConfig[id] || {};

    const renderingLabel = hasNodeTitle ? labelForWithNodeTitle : label;
    const errorTypeKeys = validationErrorsList.map(errorTypeObj =>
        getFirstNonEmptyKey(errorTypeObj)
    );
    let allErrorMessageKeys = [];
    let errorForRender = [];

    // handle all invalid error msg in FormGroupError
    if (errorTypeKeys.includes(INVALID_PASSWORD)) {
        const passwordErrorList =
            validationErrorsList[0]?.invalidPassword?.violatedRulesList;

        allErrorMessageKeys = passwordErrorList.map(errorTypeObj =>
            getFirstNonEmptyKey(errorTypeObj)
        );
        errorForRender = allErrorMessageKeys.map(key => ({
            message: t(`PASSWORD.passwordErrorMapping.${key}`),
        }));
    }

    return (
        <div className={css.root}>
            <PasswordInput
                {...renderLabel(t(renderingLabel), t(ariaLabel), '')}
                id={id}
                name={name}
                defaultValue={defaultValue}
                placeholder={t(placeholder)}
                autoComplete={autocomplete}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={customValidationError}
                onChange={handleOnChange}
                required={required}
                showToggle
            />
            {customValidationError && allErrorMessageKeys.length > 1 && (
                <FormGroupError
                    className={css.li}
                    title={t(formGroupErrorTitle)}
                    errors={errorForRender}
                />
            )}
            <div>
                <PasswordCriteria
                    value={value}
                    classNameRoot={css.criteria}
                    classNameItem={css.criteriaLabel}
                    i18nLengthLabel={t(criteria4)}
                    i18nLowercaseLabel={t(criteria1)}
                    i18nUppercaseLabel={t(criteria2)}
                    i18nNumericLabel={t(criteria3)}
                    i18nSpecialLabel={t(criteria5)}
                />
            </div>
        </div>
    );
};

Password.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    hasNodeTitle: PropTypes.bool,
    /**
     * Function to execute when the input changes.
     *
     * @param {SyntheticEvent} evt - The React `SyntheticEvent`.
     */
    onChange: PropTypes.func,
    required: PropTypes.bool.isRequired,
    validationErrorsList: PropTypes.array,
};

export default Password;
