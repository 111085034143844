import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import IconCheckDefault from '@teladoc/pulse/icons/check-default.svg';
import IconStarDefault from '@teladoc/pulse/icons/star-default.svg';
import {
    contentConfig,
    HERE_IS_HOW,
    VALUE_POINTS,
} from 'constants/medOpt/contentIds';
import {getBrandName} from 'routes/medOpt/MedOptsUtils';
import css from './ListCard.scss';

const {HERE_IS_HOW_CONTENT, VALUE_POINTS_CONTENT} = contentConfig;

const ListCard = ({contentId, isOneApp}) => {
    const {t} = useTranslation('content');
    let listItems;
    let contentFlag = false;
    let contentTextParams = {};

    if (contentId === HERE_IS_HOW) {
        listItems = HERE_IS_HOW_CONTENT.content;
        contentFlag = true;
        contentTextParams = {
            brandName: getBrandName(isOneApp),
        };
    } else if (contentId === VALUE_POINTS) {
        listItems = VALUE_POINTS_CONTENT.content;
        contentTextParams = {
            brandName: getBrandName(isOneApp),
        };
    }

    const nodeTitle = t(`${contentId}.title`, contentTextParams);
    const nodeSubTitle = t(`${contentId}.subTitle`, contentTextParams);

    return (
        <div>
            {nodeTitle !== '' && <h1 className={css.nodeTitle}>{nodeTitle}</h1>}
            {nodeSubTitle !== '' && (
                <span className={css.nodeSubTitle}>{nodeSubTitle}</span>
            )}
            <ul className={css.questionsSubContainer}>
                {listItems &&
                    listItems.map(listItem => {
                        return (
                            <div className={css.listItem} key={listItem}>
                                <div className={css.listItemEmoji}>
                                    <div className={css.circle}>
                                        {contentFlag ? (
                                            <IconCheckDefault
                                                className={css.emoji}
                                            />
                                        ) : (
                                            <IconStarDefault
                                                className={css.emoji}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={css.listItemBody}>
                                    <li className={css.f1}>
                                        {t(listItem, contentTextParams)}{' '}
                                    </li>
                                </div>
                            </div>
                        );
                    })}
            </ul>
        </div>
    );
};

ListCard.propTypes = {
    contentId: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool,
};

export default ListCard;
