import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import PhoneInput from '@teladoc/pulse/ui/PhoneInput';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {COUNTRY_CODE} from 'constants/countryCode';
import {renderLabel} from '../questions-utils';
import css from './PhoneInputField.scss';

const PhoneInputInternational = ({
    id,
    name,
    label,
    ariaLabel,
    placeholder,
    autocomplete,
    question,
    required,
    defaultValue,
    error,
    customValidationError,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    const questions = question?.dataType?.compoundField?.questionsList || [];
    let intPhoneNumberPreviousAnswer;
    let countryCodePreviousAnswer;
    const getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    };

    questions.forEach(q => {
        if (q.questionId.id === QuestionId.Values.MOBILE_PHONE_COUNTRY) {
            countryCodePreviousAnswer = getKeyByValue(
                COUNTRY_CODE,
                q?.dataType?.singleSelectEnum?.previousAnswerCode?.value
            );
        } else if (q.questionId.id === QuestionId.Values.PHONE_MOBILE) {
            intPhoneNumberPreviousAnswer =
                q?.dataType?.textField?.previousAnswer?.value;

            // since we send country calling code prefix to BE, we will need to trim it when BE response back
            // below is the substring after the first space (+1 415 555 5555 -> 415 555 5555)
            if (intPhoneNumberPreviousAnswer) {
                intPhoneNumberPreviousAnswer =
                    intPhoneNumberPreviousAnswer.substring(
                        intPhoneNumberPreviousAnswer.indexOf(' ') + 1
                    );
            }
        }
    });

    return (
        <div>
            <PhoneInput
                {...renderLabel(t(label), t(ariaLabel), css.label)}
                id={id}
                name={name}
                defaultCountry={countryCodePreviousAnswer}
                defaultValue={intPhoneNumberPreviousAnswer}
                showCountrySelect
                showCallingCode
                placeholder={t(placeholder)}
                autoComplete={autocomplete}
                error={<FormElementError>{t(error)}</FormElementError>}
                onChange={evt => {
                    onChange(evt?.value);
                }}
                required={required}
                customValidationError={customValidationError}
            />
        </div>
    );
};

PhoneInputInternational.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    autocomplete: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    question: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    error: PropTypes.string.isRequired,
    customValidationError: PropTypes.element,
    onChange: PropTypes.func,
};

export default PhoneInputInternational;
