import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import Button from '@teladoc/pulse/ui/Button';
import {cleanSessionInfo} from 'utilities/utils';
import Config from 'config';
import css from './InformationalScreen.scss';

const {oneAppMemberSupportPhoneNumber} = Config;

const InformationalScreen = ({contentId}) => {
    const {t} = useTranslation('content');

    const handleClick = () => {
        const redirectUrl = process.env.ONEAPP_LOGIN_URL ?? Config.oneAppUrl;

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    return (
        <div className={css.root}>
            <h1 className={css.h1}>{t('InformationalScreen.title')}</h1>
            <Image
                cloudinaryImageId="registration/components/contents/oneAppIntroduction_of071f.png"
                width={440}
                height={150}
                alt="OneApp welcome kit"
                showLoader={false}
            />
            <p className={css.p}>{t('InformationalScreen.paragraph')}</p>
            <div className={css.readyToGo}>
                <Trans
                    i18nKey="content:InformationalScreen.content"
                    components={[
                        /* eslint-disable react/jsx-key */
                        <div>
                            <a
                                className={css.phoneNumber}
                                href={`tel:${oneAppMemberSupportPhoneNumber}`}
                            >
                                {oneAppMemberSupportPhoneNumber}
                            </a>
                        </div>,
                    ]}
                />
            </div>
            <Button
                className={css.oneAppSkipButton}
                variant="secondary"
                onClick={handleClick}
            >
                {t('buttonsLabel.skipForNow')}
            </Button>
        </div>
    );
};

InformationalScreen.propTypes = {
    contentId: PropTypes.string.isRequired,
};

export default InformationalScreen;
