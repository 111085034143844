import {NOTIFICATION_SHOW, NOTIFICATION_CLEAR} from './notification-actions';

export default function notification(state = null, {type, payload}) {
    switch (type) {
        case NOTIFICATION_SHOW: {
            return {
                ...state,
                ...payload,
            };
        }

        case NOTIFICATION_CLEAR: {
            return null;
        }

        default:
            return state;
    }
}
