/**
 * This file gathers all gRPC services used so that we can set the GRPCWEB_DEVTOOLS in one spot.
 * For now, we are using only the Med Opt Admin gPRC service
 */
import {FlowAdminServicePromiseClient} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/flow_admin_grpc_web_pb';
import {MedOptOnboardingServicePromiseClient} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/med_opt_onboarding_grpc_web_pb';

export const flowAdminPromiseClient = new FlowAdminServicePromiseClient(
    process.env.SERVICE_URL
);

export const medOptOnboardingPromiseClient =
    new MedOptOnboardingServicePromiseClient(process.env.SERVICE_URL);

const developmentEnvs = ['local', 'integration', 'preprod'];

if (developmentEnvs.indexOf(process.env.APP_ENV) > -1) {
    // eslint-disable-next-line
    const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

    // enable debugging grpc calls
    enableDevTools([flowAdminPromiseClient]);
    enableDevTools([medOptOnboardingPromiseClient]);
}
