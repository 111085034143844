import {fetchChronicCareToken} from '@td/websdk';
import isNil from 'lodash/isNil';
import {v4 as uuidV4} from 'uuid';
import Arg from '@livongo/arg';
import UserAPI from 'api/rest/user-api';
import {initializeEnrollment} from 'api/protobuf/medOpt/authentication';
import {MED_OPT} from 'constants/app';
import {
    mixpanelGeneralError,
    mixpanelSessionRestart,
    mixpanelUserSSOFailed,
    mixpanelUserSSOSuccessful,
} from 'utilities/mixpanel-utils';
import {
    cleanSessionInfo,
    getUserSessionExpires,
    getRegistrationContext,
    setApplication,
    setGrpcAccessToken,
    setGrpcAccessTokenTimeStamp,
    setIsAuthenticated,
    setRegistrationContext,
    setRestApiAccessToken,
    setRestApiRefreshToken,
    setUserSessionExpires,
    setUserUUID,
    getOneAppUrl,
} from 'utilities/utils';
import Config from 'config';
import {
    biometricAlertsNavNextConfig,
    biometricAlertsNavBackConfig,
} from 'config/biometricAlertsConfig';
import {
    getTdocMemberId,
    getTdocAuthToken,
    setOneAppPortalUrl,
    getOneAppLocaleCookie,
} from './medOptCookieUtils';

export const getMedOptLoginUrl = () => {
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;

    if (isOneApp) {
        // This is for local environment only so DEVs don't have to manually go to
        // the login page to set the cookies.
        const {pbc} = Arg.all();
        const pbcFlavor =
            !isNil(pbc) && pbc === Config.pbcExp.pbce
                ? 'condition-care-WM-DPP-signup'
                : 'medopt-signup';

        return `${getOneAppUrl()}${pbcFlavor}?locale=${getOneAppLocaleCookie()}`;
        // e.g. https://my.pwpi-medopt-integration.uat.teladoc.io/medopt-signup?locale=en
    } else {
        return process.env.MED_OPT_MEMBER_PORTAL_LOGIN_URL;
    }
};

export const getWebSdkBaseUrl = () => {
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;

    if (
        process.env.APP_ENV === 'production' ||
        process.env.APP_ENV === 'local' ||
        process.env.APP_ENV === 'preprod' ||
        !isOneApp
    ) {
        return process.env.WEBSDK_BASE_URL;
    } else {
        const fullSubDomain = Arg('returnSubDomain');
        let domain = '';

        if (fullSubDomain.includes('teladoc.io')) {
            domain = fullSubDomain?.replace(
                fullSubDomain.split('.')[0],
                Config.baseUrlPrefix
            );
        } else if (fullSubDomain.includes('tdoctest.com')) {
            domain = fullSubDomain?.replace(
                fullSubDomain.split('-')[0],
                Config.baseUrlPrefix
            );
        }

        return `https://${domain}${Arg('returnPath')}`;
        // const subDomainWithOutPrefix = fullSubDomain.substring(
        //     fullSubDomain.split(/[.-]/, 1)[0].length
        // ); // pwpi-medopt-integration.uat
        // const subDomain = `${Config.baseUrlPrefix}${subDomainWithOutPrefix}`; // mobile-api.pwpi-medopt-integration.uat

        // return `https://${subDomain}.teladoc.io${Arg('returnPath')}`; // e.g. https://mobile-api.pwpi-medopt-integration.uat.teladoc.io/
    }
};

const redirectToLoginIfNeed = exception => {
    // This is when tdoc auth cookie is invalid
    if (exception?.toString().toLowerCase().includes('authentication')) {
        window.location = getMedOptLoginUrl();
    }
};

const generateWebSDKParams = () => {
    return {
        baseUrl: getWebSdkBaseUrl(),
        apiToken: process.env.WEBSDK_API_TOKEN,
        authToken: getTdocAuthToken(),
        bundleIdentifier: Config.oneAppBundleIdentifier,
        authorizedMemberId: getTdocMemberId(),
    };
};

export const getChronicCareToken = async () => {
    const response = await fetchChronicCareToken(generateWebSDKParams()).catch(
        exception => {
            redirectToLoginIfNeed(exception);
        }
    );

    return response;
};

const updateAuthorization = accessCodeResponse => {
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;
    const accessToken = isOneApp
        ? accessCodeResponse?.access_token
        : accessCodeResponse?.data?.access_token;
    const refreshToken = isOneApp
        ? accessCodeResponse?.refresh_token
        : accessCodeResponse?.data?.refresh_token;
    const expiration = isOneApp
        ? accessCodeResponse?.expires_in
        : accessCodeResponse?.data?.expires_in;

    setGrpcAccessToken(accessToken);
    setGrpcAccessTokenTimeStamp(new Date().getTime());
    setUserSessionExpires(Date.now() + expiration * 1000);
    setRestApiAccessToken(accessToken);
    setRestApiRefreshToken(refreshToken);
    setIsAuthenticated(true);

    if (refreshToken) {
        // set up the authorization token refresh to happen 5 minutes before its set to expire
        // the "expires" time comes in seconds from the server so we manipulate it to units we need for setTimeout which is ms
        window.setTimeout(() => {
            if (isOneApp && getTdocMemberId() && getTdocAuthToken()) {
                fetchChronicCareToken(generateWebSDKParams())
                    .then(response => {
                        if (response?.access_token) {
                            updateAuthorization(response);
                        }
                    })
                    .catch(ex => {
                        // do nothing
                    });
            } else {
                UserAPI.refreshToken().then(response => {
                    updateAuthorization(response);
                });
            }
        }, expiration * 1000 - 300000); // 300,000ms = 5 minutes
    }
};

const initUpdateAuthorization = response => {
    cleanSessionInfo();

    updateAuthorization(response);

    const userUUID = uuidV4();

    setUserUUID(userUUID);
};

const startInitializeEnrollment = async medOptFlavor => {
    try {
        const initializeResponse = await initializeEnrollment(medOptFlavor);
        const {response: initializeStatus} = initializeResponse;

        // catch INVALID status
        if (initializeStatus === 0) {
            mixpanelGeneralError({
                name: 'initializeMedOpt',
                location: 'initializeMedOpt.initializeEnrollment',
                additionalInfo: 'grpc returned INVALID',
            });
        }
    } catch (e) {
        mixpanelGeneralError({
            name: 'initializeMedOpt',
            location: 'initializeMedOpt.initializeEnrollment',
            additionalInfo: e,
        });
    }
};

export const initializeMedOpt = async () => {
    const args = Arg.all();

    /*
     * This function set the application name so that different application can use the same localstorage utility functions
     * without collisions between applcations by using different keys.  For instance with key for stepLoader, registration would be
     * registration_stepLoader and medopt would be medopt_stepLoader.
     */
    setApplication(MED_OPT);

    // check oneapp URL param. Set registration context client as OneApp if oneapp flag is true
    const oneAppFlag = Config.client.oneapp;

    if (args[oneAppFlag]) {
        setRegistrationContext({
            client: Config.client.oneapp,
        });
    } else {
        setRegistrationContext({
            client: '',
        });
    }

    if (args[Config.oneAppPortal]) {
        setOneAppPortalUrl(args[Config.oneAppPortal]);
    }

    /*
     * Get user session expiration time from localstorage or cookie.
     * If time is after that,
     * clean all the session information than start a new session.
     */
    // const isVisited = getIsVisited() || false;
    const userSessionExpires = getUserSessionExpires() || 0;
    const isUserSessionExpired = Date.now() > userSessionExpires;
    // Need to get one time access code from Member Portal login from url search parameter
    // one time access code name to be determined
    // If no access code, go back to member portal login
    // else Pass access code to /v1/users/me/auth
    // then get back access token and store in localstorage
    // if no access code or invalid access code, send back to member portal login
    const {registrationAccessCode} = args;

    if (
        args[oneAppFlag] &&
        !isNil(getTdocMemberId()) &&
        !isNil(getTdocAuthToken())
    ) {
        try {
            const ssoResponse = await getChronicCareToken();

            initUpdateAuthorization(ssoResponse);
        } catch (e) {
            mixpanelUserSSOFailed();
            mixpanelGeneralError({
                name: 'initializeMedOpt',
                location: 'initializeMedOpt.getChronicCareToken',
                additionalInfo: e,
            });
        }

        // pass in medopt pbc expansion flavor from url e.g. PBC for WM / DPP
        const paramPBC = args[Config.pbcExp.pbc]; // get querystring value e.g. pbc = WM_DPP
        const pbcType = paramPBC && Config.MedoptFlavor[paramPBC]; // get the type value; e.g. 2

        startInitializeEnrollment(pbcType);
    } else if (registrationAccessCode) {
        try {
            const accessCodeResponse = await UserAPI.loginWithAuthCode(
                registrationAccessCode
            );

            initUpdateAuthorization(accessCodeResponse);

            mixpanelUserSSOSuccessful();
        } catch (e) {
            mixpanelUserSSOFailed();
            mixpanelGeneralError({
                name: 'initializeMedOpt',
                location: 'initializeMedOpt.loginWithAuthCode',
                additionalInfo: e,
            });
        }
        startInitializeEnrollment();
    } else if (isUserSessionExpired) {
        // Send back to Member Portal to login and get a new registration token or
        // Send back to OneAppLogin to mint a new tdoc token
        window.location = getMedOptLoginUrl();
    } else {
        mixpanelSessionRestart();
    }
};

export const getBrandName = isOneApp => {
    return isOneApp ? 'Teladoc Health' : 'Livongo';
};

export const getShortBrandName = isOneApp => {
    return isOneApp ? 'Teladoc' : 'Livongo';
};

export const convertBiometricAlertsStep = (
    BiometricAlertsResponse,
    direction
) => {
    const flowStageItem = BiometricAlertsResponse?.flowStagesList[0];
    //  INELIGIBLE is in another alertState and not in question so if no questionCode found default to INELIGIBLE
    const questionCode = flowStageItem?.question?.questionCode || 'INELIGIBLE';

    switch (questionCode) {
        case 'HERE_IS_HOW': {
            return direction === 'next'
                ? biometricAlertsNavNextConfig.HERE_IS_HOW
                : biometricAlertsNavBackConfig.HERE_IS_HOW;
        }
        case 'SMART_PHONE': {
            return direction === 'next'
                ? biometricAlertsNavNextConfig.SMART_PHONE
                : biometricAlertsNavBackConfig.SMART_PHONE;
        }
        case 'QUALIFY': {
            return direction === 'next'
                ? biometricAlertsNavNextConfig.QUALIFY
                : biometricAlertsNavBackConfig.QUALIFY;
        }
        case 'GOOD_MATCH': {
            return direction === 'next'
                ? biometricAlertsNavNextConfig.GOOD_MATCH
                : biometricAlertsNavBackConfig.GOOD_MATCH;
        }
        case 'RAV_PLACEHOLDER': {
            return direction === 'next'
                ? biometricAlertsNavNextConfig.RAV_PLACEHOLDER
                : biometricAlertsNavBackConfig.RAV_PLACEHOLDER;
        }
        case 'GET_THE_APP': {
            return direction === 'next'
                ? biometricAlertsNavNextConfig.GET_THE_APP
                : biometricAlertsNavBackConfig.GET_THE_APP;
        }
        case 'INELIGIBLE': {
            return direction === 'next'
                ? biometricAlertsNavNextConfig.INELIGIBLE
                : biometricAlertsNavBackConfig.INELIGIBLE;
        }
        default:
            throw new Error();
    }
};
