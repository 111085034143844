import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import PhoneInput from '@teladoc/pulse/ui/PhoneInput';
import {renderLabel} from '../questions-utils';
import css from './PhoneInputField.scss';

const PhoneInputField = ({
    id,
    name,
    label,
    ariaLabel,
    placeholder,
    autocomplete,
    required,
    defaultValue,
    error,
    customValidationError,
    onChange,
}) => {
    const {t} = useTranslation('questions');

    return (
        <div>
            <PhoneInput
                {...renderLabel(t(label), t(ariaLabel), css.label)}
                id={id}
                name={name}
                defaultValue={defaultValue}
                showCountrySelect={false}
                showCallingCode={false}
                disableCountrySelect
                placeholder={t(placeholder)}
                autoComplete={autocomplete}
                error={<FormElementError>{t(error)}</FormElementError>}
                onChange={evt => {
                    onChange(evt?.value);
                }}
                required={required}
                customValidationError={customValidationError}
            />
        </div>
    );
};

PhoneInputField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    autocomplete: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
    error: PropTypes.string.isRequired,
    customValidationError: PropTypes.element,
    onChange: PropTypes.func,
};

export default PhoneInputField;
