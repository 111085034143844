import {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import TextInput from '@teladoc/pulse/ui/TextInput';
import notify from '@teladoc/pulse/ui/Notification';
import Form from '@teladoc/pulse/ui/Form';
import Loader from '@teladoc/pulse/ui/Loader';
import Label from '@teladoc/pulse/ui/Label';
import ValidationUtils from '@livongo/utils/validation';
import Config from 'config';
import messageConfig from 'constants/messages';
import {devConsoleLog} from 'utilities/utils';
import {
    mixpanelButtonClickedEvent,
    mixpanelRegcodeDetailsEvent,
} from 'utilities/mixpanel-utils';
import useMessage from 'hook/useMessage';
import {findRegCodesByEmail} from 'api/protobuf/enrollment/services';
import css from './modalStyle.scss';

const {memberSupportPhoneNumber, oneAppMemberSupportPhoneNumber} = Config;

const NotFoundSponsor = ({
    id,
    isOneApp,
    onHidden,
    regCodeTally,
    regCodeRef,
}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const messageValue = messageConfig[id] || {};
    const [isPending, setIsPending] = useState(false);
    const [customValidationError, setCustomValidationError] = useState(null);
    const formRef = useRef();
    const {
        title,
        title2,
        content,
        subTitle1,
        subTitle2,
        subTitle3,
        oneAppContent,
        imageLetter,
        imageEmail,
        email: emailTitle,
    } = messageValue;

    const {showFoundRegcodesFromEmail} = useMessage();

    const onApiError = error => {
        setCustomValidationError(
            <FormElementError>
                {t('MODAL_NOT_FOUND_SPONSOR.emailNotFound')}
            </FormElementError>
        );
        setIsPending(false);
    };
    const onFormChange = () => {
        setCustomValidationError(false);
    };
    const onGetRegCodeClick = async evt => {
        regCodeTally();
        const form = formRef.current;

        if (form.isValid()) {
            setIsPending(true);

            try {
                const {email} = form.submit();
                const response = await findRegCodesByEmail(email);
                let regCodeDetailsForMixpanel = {
                    regcodeSubmission: 0, // tally when user input new email
                    regcodeTallied: 0, // list of regcode found
                };

                mixpanelButtonClickedEvent(
                    'REG_CODE',
                    '',
                    t('messages:MODAL_NOT_FOUND_SPONSOR.btnText1'),
                    'CTA'
                );

                if (!response?.badRequest) {
                    const detailsList = response?.regCodeMatch?.detailsList;
                    let regCodeData;

                    regCodeDetailsForMixpanel = {
                        regcodeSubmission: regCodeRef.current,
                        regcodeTallied: detailsList.length,
                    };
                    mixpanelRegcodeDetailsEvent(regCodeDetailsForMixpanel);

                    /* If only one matching regCode is found, close the modal and autofill the regCode textbox and notify.
                       If multiple regcode found, show <FoundRegcodesFromEmail>
                    */
                    if (detailsList.length === 1) {
                        regCodeData = {
                            email,
                            isOpen: false,
                            detailsList,
                            regCode: detailsList[0].regCode,
                            clientName: detailsList[0].clientName,
                        };
                        showFoundRegcodesFromEmail(regCodeData);
                        notify({
                            type: 'success',
                            title: '',
                            variant: 'toast',
                            classNameBody: css.notify,
                            message: t(
                                'messages:MODAL_NOT_FOUND_SPONSOR.successMsg',
                                {
                                    email,
                                }
                            ),
                        });
                    } else {
                        regCodeData = {
                            email,
                            isOpen: true,
                            detailsList,
                        };
                        showFoundRegcodesFromEmail(regCodeData);
                    }
                } else {
                    regCodeDetailsForMixpanel = {
                        regcodeSubmission: regCodeRef.current,
                        regcodeTallied: 0,
                    };
                    mixpanelRegcodeDetailsEvent(regCodeDetailsForMixpanel);
                    onApiError(true);
                }
            } catch (error) {
                devConsoleLog(
                    'components/registration.NotFoundSponsor.onGetRegCodeClick.catch',
                    error
                );
            }
        }
    };

    return (
        <div className={css.modalContainer}>
            <h1>{t(title)}</h1>
            <p>{isOneApp ? t(oneAppContent) : t(content)}</p>
            <div className={css.notFoundSponsorContentContainer}>
                {!isOneApp && (
                    <>
                        <div className={css.paragraphContainer}>
                            <h2>{t(subTitle1)}</h2>
                            <Image
                                cloudinaryImageId="registration/components/questions/regcode/regcode_via_letter_vfn2gl"
                                width={480}
                                height={140}
                                aria-label={t(imageLetter)}
                                showLoader={false}
                            />
                        </div>
                        <div className={css.paragraphContainer}>
                            <h2>{t(subTitle2)}</h2>
                            <Image
                                cloudinaryImageId="registration/components/questions/regcode/regcode_via_email_pt2qxa"
                                width={480}
                                height={140}
                                aria-label={t(imageEmail)}
                                showLoader={false}
                            />
                        </div>
                    </>
                )}
                {isOneApp && (
                    <>
                        <div className={css.paragraphContainer}>
                            <h2>{t(subTitle1)}</h2>
                            <Image
                                cloudinaryImageId="/registration/components/questions/regcode/From_the_letter_x3_t7hx9f.png"
                                width={504}
                                height={139}
                                alt=""
                                showLoader={false}
                                className={css.oneAppImgBackground}
                            />
                        </div>
                        <div className={css.paragraphContainer}>
                            <h2>{t(subTitle2)}</h2>
                            <Image
                                cloudinaryImageId="/registration/components/questions/regcode/From_the_Email_x3_qkmkxq.png"
                                width={504}
                                height={139}
                                alt=""
                                showLoader={false}
                                className={css.oneAppImgBackground}
                            />
                        </div>
                        <div className={css.oneAppLegalTextBlock}>
                            <Trans
                                i18nKey="messages:MODAL_NOT_FOUND_SPONSOR.oneAppCallUs"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={`tel:${oneAppMemberSupportPhoneNumber}`}
                                    >
                                        {oneAppMemberSupportPhoneNumber}
                                    </a>,
                                ]}
                            />
                        </div>
                    </>
                )}

                <Form ref={formRef} id="emailSearch">
                    <div>
                        <h1>{t(title2)}</h1>
                        <p className="emailSearch">{t(subTitle3)}</p>
                        <TextInput
                            id="email"
                            name="email"
                            type="email"
                            label={
                                <Label i18nRequiredVisualLabel=" ">
                                    {t(emailTitle)}
                                </Label>
                            }
                            pattern={ValidationUtils.EMAIL}
                            error={
                                <FormElementError>
                                    {t('common:forms.emailError')}
                                </FormElementError>
                            }
                            customValidationError={customValidationError}
                            required
                            autoComplete="email"
                            onChange={onFormChange}
                        />
                        {isPending && <Loader className="Loader-module-root" />}
                        <Button
                            className={css.secondaryAction}
                            variant="primary"
                            type="submit"
                            disabled={isPending}
                            onClick={onGetRegCodeClick}
                        >
                            {t('messages:MODAL_NOT_FOUND_SPONSOR.btnText1')}
                        </Button>
                    </div>
                </Form>

                <div className={css.paragraphContainer}>
                    <Trans
                        i18nKey="messages:MODAL_NOT_FOUND_SPONSOR.callUs"
                        components={[
                            /* eslint-disable react/jsx-key */
                            <a
                                href={`tel:${
                                    !isOneApp
                                        ? memberSupportPhoneNumber
                                        : oneAppMemberSupportPhoneNumber
                                }`}
                            >
                                {!isOneApp
                                    ? memberSupportPhoneNumber
                                    : oneAppMemberSupportPhoneNumber}
                            </a>,
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

NotFoundSponsor.propTypes = {
    id: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool.isRequired,
    onHidden: PropTypes.func,
    regCodeTally: PropTypes.func,
    regCodeRef: PropTypes.object,
};

export default NotFoundSponsor;
