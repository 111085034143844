import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import Radio from '@teladoc/pulse/ui/Radio';
import {questionConfig} from 'constants/questions';
import useMessage from 'hook/useMessage';
import css from '../Question.scss';

const BloodTest = ({
    id,
    name,
    required,
    items,
    defaultValue,
    customValidationError,
    hasNodeTitle,
}) => {
    const {t} = useTranslation('questions');
    const {labelForWithNodeTitle, error} = questionConfig[id] || {};
    const {showBloodTestExampleMessage} = useMessage();

    const onExampleClick = evt => {
        showBloodTestExampleMessage();
    };

    return (
        <div>
            {hasNodeTitle && (
                <span className={css.questionLabelForWithNodeTitle}>
                    {t(labelForWithNodeTitle)}
                </span>
            )}
            <RadioGroup
                id={id}
                name={name}
                label={
                    <Label
                        className={css.questionLabel}
                        i18nRequiredVisualLabel=" "
                    >
                        <Trans
                            i18nKey="questions:BLOOD_TEST_PREDIABETES.label"
                            components={[
                                /* eslint-disable react/jsx-key */
                                <Button
                                    className={css.btnAlign}
                                    onClick={onExampleClick}
                                    children=""
                                />,
                                /* eslint-enable react/jsx-key */
                            ]}
                        />
                    </Label>
                }
                required={required}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={customValidationError}
            >
                {items.map(({value, label}) => {
                    return (
                        <Radio
                            key={value}
                            id={`item-${value}`}
                            value={value}
                            label={<Label>{label}</Label>}
                            defaultChecked={value === defaultValue}
                        />
                    );
                })}
            </RadioGroup>
        </div>
    );
};

BloodTest.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    hasNodeTitle: PropTypes.bool,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} a1cValue - Either the A1c Value or the IDK string.
     */
};

export default BloodTest;
