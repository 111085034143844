import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Modal from '@teladoc/pulse/ui/Modal';
import css from './PopupModal.scss';

const PopupModal = ({
    contentId,
    isOpen,
    onRequestClose,
    primaryAction,
    secondaryAction,
}) => {
    const {t} = useTranslation('content');

    return (
        <Modal
            isOpen={isOpen}
            title={t(`${contentId}.title`)}
            onRequestClose={onRequestClose}
            primaryAction={{
                variant: 'primary',
                onClick: primaryAction,
                children: t(`${contentId}.primaryAction`),
            }}
            secondaryAction={{
                onClick: secondaryAction,
                children: t(`${contentId}.secondaryAction`),
            }}
        >
            <p className={css.description}>{t(`${contentId}.paragraph`)}</p>
        </Modal>
    );
};

PopupModal.propTypes = {
    contentId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    primaryAction: PropTypes.func.isRequired,
    secondaryAction: PropTypes.func.isRequired,
};

export default PopupModal;
