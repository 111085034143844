import {WebSDK} from '@td/websdk';
import PropTypes from 'prop-types';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import {useEffect, useRef} from 'react';
import {getTeladocSdkToken} from 'utilities/utils';
import css from './MedOptWebSdkContainer.scss';

const MedOptWebSdkContainer = ({
    path,
    appConfiguration,
    exitWebSdk,
    isOneApp,
}) => {
    const formRef = useRef();
    // temporarily disable i18n for RAV in LVGO standalone
    const locale = isOneApp
        ? getStoredLang()?.slice(0, 2)?.toLowerCase()
        : 'en'; // webSDK only take 2 chars for locale; e.g. 'es' or 'en'

    if (isOneApp) {
        appConfiguration.isOneApp = true;
        appConfiguration.isEnhancedRedesign = true;
        appConfiguration.brandCountryCode = 'USA';
    }

    useEffect(() => {
        const exitWithExistAppt = intervalId => {
            const errorMessage = formRef?.current?.querySelector(
                `.toast-container-web-sdk .error`
            );
            const websdkLoader =
                formRef?.current?.querySelector(`.loader-web-sdk`);

            if (
                errorMessage &&
                errorMessage.innerText ===
                    'A Chronic Care has already been requested.'
            ) {
                clearInterval(intervalId);
                exitWebSdk();
            }
            // If websdk screen is loaded and error message is not detected, then member can proceed RAV
            else if (!websdkLoader) {
                clearInterval(intervalId);
            }
        };

        const checkExistingApptErrorMsg = () => {
            const intervalId = setInterval(() => {
                exitWithExistAppt(intervalId);
            }, 500);
        };

        // Check for existing appointment error on state selection screen
        // If member already scheduled appointment, exit RAV
        window.addEventListener('popstate', () => {
            if (window.location.href.endsWith('#ConsultationLocation')) {
                checkExistingApptErrorMsg();
            }
        });
    }, [exitWebSdk]);

    return (
        <div ref={formRef} className={css.websdkContainer}>
            <WebSDK
                path={path}
                authorization={{ssoToken: getTeladocSdkToken()}}
                appConfiguration={{
                    ...appConfiguration,
                    locale,
                }}
                onExit={exitWebSdk}
            />
        </div>
    );
};

MedOptWebSdkContainer.propTypes = {
    path: PropTypes.string,
    appConfiguration: PropTypes.object,
    exitWebSdk: PropTypes.func,
    isOneApp: PropTypes.bool,
};

export default MedOptWebSdkContainer;
