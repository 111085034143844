import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Arg from '@livongo/arg';
import Banner from '@teladoc/pulse/ui/Banner';
import css from './TeamsBanner.scss';

const TeamsBanner = () => {
    const {t} = useTranslation();
    const isRedirectFromTeams = Arg('fromTeams');
    const [showBanner, setShowBanner] = useState(isRedirectFromTeams);
    const dismissBanner = () => {
        setShowBanner(false);
    };

    return showBanner ? (
        <Banner
            onDismissClick={dismissBanner}
            className={css.banner}
            showDismiss
        >
            <p>{t('banner.msTeamsBanner')}</p>
        </Banner>
    ) : (
        ''
    );
};

export default TeamsBanner;
