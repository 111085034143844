import {Trans} from 'react-i18next';
import Config from 'config';
import css from './NCQAInfo.scss';

const {memberSupportPhoneNumber, memberHelpEmail} = Config;

const NCQAInfo = () => {
    return (
        <div className={css.root}>
            <Trans
                i18nKey="app:NCQAInfo.context"
                components={[
                    /* eslint-disable react/jsx-key */
                    <a href={`tel:${memberSupportPhoneNumber}`}>
                        {memberSupportPhoneNumber}
                    </a>,
                    <a href={`mailto:${memberHelpEmail}`}>{memberHelpEmail}</a>,
                ]}
            />
        </div>
    );
};

export default NCQAInfo;
