import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import messageConfig from 'constants/messages';
import css from './modalStyle.scss';

const BloodTestExample = ({id}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const messageValue = messageConfig[id] || {};

    const {title, heading1, content1, heading2, content2, heading3, content3} =
        messageValue;

    return (
        <div className={css.modalContainer}>
            <h1>{t(title)}</h1>
            <div className={css.contentContainer}>
                <div className={css.paragraphContainer}>
                    <h2>{t(heading1)}</h2>
                    <p>{t(content1)}</p>
                </div>
                <div className={css.paragraphContainer}>
                    <h2>{t(heading2)}</h2>
                    <p>{t(content2)}</p>
                </div>
                <div className={css.paragraphContainer}>
                    <h2>{t(heading3)}</h2>
                    <p>{t(content3)}</p>
                </div>
            </div>
        </div>
    );
};

BloodTestExample.propTypes = {
    id: PropTypes.string.isRequired,
};

export default BloodTestExample;
