import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import Label from '@teladoc/pulse/ui/Label';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import {questionConfig} from 'constants/questions';
import {getFirstNonEmptyKey} from 'utilities/utils';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
import css from '../Question.scss';

const HealthIssues = ({id, name, question, customValidationError}) => {
    const {title, label, error} = questionConfig[id] || {};
    const {t} = useTranslation('questions');
    const subQuestions = question?.dataType?.compoundField?.questionsList || [];
    const questionIDValueHash = {};
    const questionIDNameHash = {};
    const questionIDDefaultValueHash = {};

    subQuestions.forEach(que => {
        const questionName = que.questionId.id;
        const questionTypedField = getFirstNonEmptyKey(que.dataType);
        const dataType = que.dataType[questionTypedField];

        questionIDValueHash[questionName] = que;

        questionIDNameHash[questionName] = getQuestionNameById(
            que.questionId.id
        );

        if (dataType.userInput) {
            questionIDDefaultValueHash[questionName] = dataType.userInput;
        } else if (dataType.previousAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswer.value;
        } else if (dataType.defaultAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.defaultAnswer.value;
        }
    });

    return (
        <>
            <span className={css.questionLabel}>{t(label)}</span>
            {subQuestions.map((subQuestion, index) => (
                <div key={index}>
                    <Checkbox
                        id={subQuestion?.questionId.id}
                        name={`${name}_-_${subQuestion?.questionId.id}`}
                        value="true"
                        defaultChecked={
                            questionIDDefaultValueHash[
                                subQuestion?.questionId.id
                            ]
                        }
                        label={
                            <Label className={css.questionLabel}>
                                {t(
                                    questionConfig[
                                        questionIDNameHash[
                                            subQuestion?.questionId.id
                                        ]
                                    ].label
                                )}
                            </Label>
                        }
                        i18nItemLabel={t(title)}
                        error={<FormElementError>{t(error)}</FormElementError>}
                        customValidationError={customValidationError}
                    />
                </div>
            ))}
        </>
    );
};

HealthIssues.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    customValidationError: PropTypes.element,
};

export default HealthIssues;
