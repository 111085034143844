import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import IconLogsDefault from '@teladoc/pulse/icons/logs-default.svg';
import IconSuppliesDefault from '@teladoc/pulse/icons/supplies-default.svg';
import IconGroupDefault from '@teladoc/pulse/icons/group-default.svg';
import css from './OneAppIntroduction.scss';

const BHBundleEnrollmentInfo = ({contentId}) => {
    const {t} = useTranslation('content');

    return (
        <div className={css.root}>
            <h1 className={css.h1}>{t('BHBundleEnrollmentInfo.title')}</h1>
            <Image
                cloudinaryImageId="registration/components/contents/oneAppIntroduction_of071f.png"
                width={440}
                height={150}
                alt="Teladoc kit"
                showLoader={false}
            />
            <p className={css.p}>{t('BHBundleEnrollmentInfo.paragraph')}</p>
            <div className={css.listItemWithIcon}>
                <div className={css.circle}>
                    <IconLogsDefault className={css.icon} />
                </div>
                <div className={css.listItemBody}>
                    {t('BHBundleEnrollmentInfo.content1')}
                </div>
            </div>
            <div className={css.listItemWithIcon}>
                <div className={css.circle}>
                    <IconSuppliesDefault className={css.icon} />
                </div>
                <div className={css.listItemBody}>
                    {t('BHBundleEnrollmentInfo.content2')}
                </div>
            </div>
            <div className={css.listItemWithIcon}>
                <div className={css.circle}>
                    <IconGroupDefault className={css.icon} />
                </div>
                <div className={css.listItemBody}>
                    {t('BHBundleEnrollmentInfo.content3')}
                </div>
            </div>
        </div>
    );
};

BHBundleEnrollmentInfo.propTypes = {
    contentId: PropTypes.string.isRequired,
};

export default BHBundleEnrollmentInfo;
