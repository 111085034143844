/**
 * Questions gRPC calls
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/med_opt/questions
 */
import {SubmitAnswersRequest} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/med_opt_onboarding_pb';
import {QuestionId} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/common/question_ids_pb';
import findKey from 'lodash/findKey';
// Comment back when testing with modk data
// import {getSubmitAnswersMockPromise} from 'constants/medOptMockData';
import {
    medOptOnboardingPromiseClient,
    getGrpcProtocolVersion,
    getGrpcMetadata,
    answerToProtobuf,
} from './utils';

// Get string question name like EMAIL from integer question id like 19
export const getQuestionNameById = value =>
    findKey(QuestionId.Values, id => id === value);

/**
 * submitAnswers
 * Submits answers to backend
 * @param {Array<Object>} answers containing all the expected fields from gRPC
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.questions.SubmitAnswersResponse>}
 *  Promise
 */
export const submitAnswers = answers => {
    const request = new SubmitAnswersRequest();

    request.setParent(`user/liv/me/medopt`);
    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAnswer(answerToProtobuf(answers));

    // Comment out to take mock data.
    return medOptOnboardingPromiseClient
        .submitAnswers(request, getGrpcMetadata())
        .then(response => response.toObject());

    // Comment in for mock data
    // return getSubmitAnswersMockPromise();
};
