import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
import {questionConfig} from 'constants/questions';
import {contentIds, nodeTitleIds} from 'constants/app';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import useFocus from 'hook/useFocus';
import Content from 'components/content/Content';
import Question from 'components/question';
import css from './Section.scss';

const {askGuardianInfo, askU18GuardianInfo} = nodeTitleIds;
const {SsoWarmWelcome} = contentIds;

const Section = ({
    sectionInfo,
    validationErrorsList = [],
    setNavigationLabel,
    formRef,
    isOneApp,
}) => {
    const {t} = useTranslation('questions');
    const contentId = sectionInfo.contentId
        ? sectionInfo.contentId
        : sectionInfo.errorsList
        ? sectionInfo.errorsList[0].contentId
        : '';
    const questions = sectionInfo.questionsToAsk?.questionsList || [];
    let isMobileQuestionWithAddress = false;

    // check if the sms question is asked with address question
    questions.forEach(question => {
        if (
            question.questionId.id === QuestionId.Values.ADDRESS_USA_STANDARD ||
            question.questionId.id === QuestionId.Values.ADDRESS_USA_COMBINED
        ) {
            isMobileQuestionWithAddress = true;
        }
    });

    const context = sectionInfo.context?.answersList || [];
    const nodeTitleId = sectionInfo.questionsToAsk?.nodeTitleId;
    const qIdToError = validationErrorsList.reduce((hash, validationError) => {
        if (!hash[validationError.questionId?.id]) {
            hash[validationError.questionId?.id] = [];
        }
        validationError.errorTypesList.forEach(errorType => {
            hash[validationError.questionId?.id].push(errorType);
        });

        return hash;
    }, {});

    const [u13Checked, setU13Checked] = useState(
        nodeTitleId === askGuardianInfo || nodeTitleId === askU18GuardianInfo
    );

    const birthdateAnswer = context.find(
        q =>
            getQuestionNameById(q.questionId?.id) ===
            QuestionId.Values.BIRTH_DATE
    );

    const {year, month, day} = birthdateAnswer?.value?.date || {};
    const CURRENT_Y = new Date().getFullYear();
    const CURRENT_M = new Date().getMonth();
    const CURRENT_D = new Date().getDate();

    useEffect(() => {
        if (
            year + 13 > CURRENT_Y ||
            (year + 13 === CURRENT_Y && month > CURRENT_M + 1) ||
            (year + 13 === CURRENT_Y &&
                month === CURRENT_M + 1 &&
                day > CURRENT_D)
        ) {
            setU13Checked(true);
        }
        // eslint-disable-next-line
    }, []);

    const renderGroupTitleAndDescription = () => {
        if (nodeTitleId) {
            const nodeTitle = t(
                u13Checked
                    ? `$${nodeTitleId}.childTitle`
                    : `$${nodeTitleId}.title`
            );
            const nodeSubTitle = t(
                u13Checked
                    ? `$${nodeTitleId}.childSubTitle`
                    : `$${nodeTitleId}.subTitle`
            );

            return (
                <>
                    {nodeTitle !== '' && (
                        <h1 className={css.nodeTitle}>{nodeTitle}</h1>
                    )}
                    {nodeSubTitle !== '' && (
                        <span className={css.nodeSubTitle}>{nodeSubTitle}</span>
                    )}
                </>
            );
        }

        if (questions.length > 1) {
            const {questionId} = questions[0] || {};
            const {id = -1} = questionId || {};
            // Get question name in string format by question id.
            const questionName = getQuestionNameById(id);
            // Get question UI configuration.
            const {groupTitle, groupLabel} = questionConfig[questionName] || {};

            return (
                <>
                    {groupTitle !== '' && (
                        <h1 className={css.nodeTitle}>{t(groupTitle)}</h1>
                    )}
                    {groupLabel !== '' && (
                        <span className={css.nodeSubTitle}>
                            {t(groupLabel)}
                        </span>
                    )}
                </>
            );
        }

        return null;
    };

    const handleChange = (id, value) => {
        if (id === QuestionId.Values.REGISTERING_UNDER_13) {
            setU13Checked(value);
        }
    };

    useFocus('interview', sectionInfo);

    return (
        <div className={css.root}>
            {contentId && (
                <Content
                    contentId={contentId}
                    sectionInfo={sectionInfo}
                    setNavigationLabel={setNavigationLabel}
                    isOneApp={isOneApp}
                />
            )}
            {(!contentId || contentId === SsoWarmWelcome) && (
                <div className={css.questionsContainer}>
                    {renderGroupTitleAndDescription()}
                    {questions.map(question => {
                        if (
                            u13Checked &&
                            (question.questionId.id === QuestionId.Values.ZIP ||
                                question.questionId.id ===
                                    QuestionId.Values.SUPPORTED_REGION ||
                                question.questionId.id ===
                                    QuestionId.Values.TERMS_AND_CONDITIONS)
                        ) {
                            return null;
                        }

                        return (
                            <Question
                                key={question.questionId.id}
                                question={question}
                                answersList={sectionInfo.context?.answersList}
                                validationErrorsList={
                                    qIdToError[question.questionId.id]
                                }
                                hasNodeTitle={nodeTitleId !== ''}
                                isMobileQuestionWithAddress={
                                    isMobileQuestionWithAddress
                                }
                                setNavigationLabel={setNavigationLabel}
                                onChange={handleChange}
                                u13Checked={u13Checked}
                                sectionInfo={sectionInfo}
                                formRef={formRef}
                                isOneApp={isOneApp}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

Section.propTypes = {
    sectionInfo: PropTypes.object.isRequired,
    validationErrorsList: PropTypes.arrayOf(PropTypes.object),
    setNavigationLabel: PropTypes.func,
    formRef: PropTypes.object,
    isOneApp: PropTypes.bool,
};

export default Section;
