import {USER_UPDATE} from './user-actions';

export const initialState = {
    firstName: null,
};

export default function user(state = initialState, {type, payload}) {
    switch (type) {
        case USER_UPDATE: {
            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
}
