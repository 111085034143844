import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Config from 'config';
import {getCallBackUrl, cleanSessionInfo} from 'utilities/utils';
import messageConfig from 'constants/messages';
import useMessage from 'hook/useMessage';
import css from './modalStyle.scss';

const {memberSupportPhoneNumber, oneAppMemberSupportPhoneNumber} = Config;

const NotFoundRegcode = ({id, isOneApp}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const {showNotFoundSponsorMessage} = useMessage();
    const messageValue = messageConfig[id] || {};
    const {title, subTitle, oneAppButtonLabel} = messageValue;

    const onHelpClick = evt => {
        showNotFoundSponsorMessage();
    };

    const redirectToOneApp = () => {
        const redirectUrl = getCallBackUrl();

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    return (
        <div className={css.modalContainer}>
            <h1>{t(title)}</h1>
            <div className={css.contentContainer}>
                <div className={css.paragraphContainer}>
                    <p>{t(subTitle)}</p>
                    <Trans
                        i18nKey="messages:MODAL_NOT_FOUND_REGCODE.content"
                        components={[
                            /* eslint-disable react/jsx-key */
                            <Button
                                className={css.notFoundRegcodeButtonFont}
                                onClick={onHelpClick}
                                children=""
                            />,
                        ]}
                    />
                </div>
                {!isOneApp && (
                    <div className={css.paragraphContainer}>
                        <Trans
                            i18nKey="messages:MODAL_NOT_FOUND_REGCODE.callUs"
                            components={[
                                /* eslint-disable react/jsx-key */
                                <a href={`tel:${memberSupportPhoneNumber}`}>
                                    {memberSupportPhoneNumber}
                                </a>,
                            ]}
                        />
                    </div>
                )}
                {isOneApp && (
                    <>
                        <div className={css.paragraphContainer}>
                            <Trans
                                i18nKey="messages:MODAL_NOT_FOUND_REGCODE.oneAppCallUs"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={`tel:${oneAppMemberSupportPhoneNumber}`}
                                    >
                                        {oneAppMemberSupportPhoneNumber}
                                    </a>,
                                ]}
                            />
                        </div>
                        {oneAppButtonLabel && (
                            <Button
                                className={css.modalButton}
                                variant="primary"
                                onClick={redirectToOneApp}
                            >
                                {t(oneAppButtonLabel)}
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

NotFoundRegcode.propTypes = {
    id: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool.isRequired,
};

export default NotFoundRegcode;
