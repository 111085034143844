import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {questionConfig, eligibilityStatus} from 'constants/questions';
import {nodeTitleIds, contentIds, programs} from 'constants/app';
import {contentConfig} from 'constants/contentIds';
import Address from './Address';
import GeneralInfoByQuestionId from './GeneralInfoByQuestionId';
import css from './Info.scss';

const {askU13SafeQuestions, askGuardianInfo, askU18GuardianInfo} = nodeTitleIds;
const {SsoWarmWelcome} = contentIds;
const {DIABETES_DEVICEFLEX} = programs;

const Info = ({data}) => {
    const {t} = useTranslation('questions');
    const questionsList = data?.questionsToAsk?.questionsList || [];
    const nodeTitleId =
        data.questionsToAsk?.nodeTitleId === 'askNonMemberA1cShippingAddr'
            ? ''
            : data.questionsToAsk?.nodeTitleId || '';

    const hasNodeTitleId =
        nodeTitleId !== '' &&
        nodeTitleId !== askU13SafeQuestions &&
        nodeTitleId !== askGuardianInfo &&
        nodeTitleId !== askU18GuardianInfo;

    const isAddressQuestion =
        questionsList[0]?.questionId?.id ===
            QuestionId?.Values?.ADDRESS_USA_STANDARD ||
        questionsList[0]?.questionId?.id ===
            QuestionId?.Values?.ADDRESS_USA_COMBINED;

    const answersWithinContext = data.context?.answersList || [];

    const programAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.PROGRAM
    );
    const selectedPrograms =
        programAnswer?.value?.multiSelectEnum?.enumCodeList || [];

    // only display DF content when DiabetesDeviceFlex only
    const isDiabetesDeviceFlexOnly =
        selectedPrograms.includes(DIABETES_DEVICEFLEX) &&
        selectedPrograms.length === 1;

    const eligibilityAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.ELIGIBILITY_STATUS
    );

    const experimentContext = answersWithinContext.find(
        answer =>
            answer.questionId?.id ===
            QuestionId.Values.SHOW_EXPERIMENTAL_WORDING
    );

    const isExperimentContext = experimentContext?.value.pb_boolean === true;
    const hideShippingInfoForPendingProgram =
        isAddressQuestion &&
        eligibilityAnswer?.value?.singleSelectEnumCode ===
            eligibilityStatus.INELIGIBLE;

    const isSsoWarmWelcome = data?.contentId === SsoWarmWelcome;

    const hasInfo = questionsList.some(question => {
        const {questionId} = question;
        const {id = -1} = questionId || {};
        // Get question id in string format by value.
        const questionName = getQuestionNameById(id);
        // Get question UI configuration.
        let info = questionConfig[questionName]?.info;

        if (isSsoWarmWelcome) {
            info = contentConfig?.SsoWarmWelcome?.info;
        }

        return info;
    });

    return (
        <aside
            className={cn(css.infoContainer, {
                [css.hideMobile]: !hasInfo,
            })}
        >
            {!hasNodeTitleId &&
                questionsList.map(question => {
                    const {questionId} = question;
                    const {id = -1} = questionId || {};
                    // Get question id in string format by value.
                    const questionName = getQuestionNameById(id);
                    // Get question UI configuration.
                    let info;

                    if (isSsoWarmWelcome) {
                        info = contentConfig?.SsoWarmWelcome?.info;
                    } else if (nodeTitleId === askU18GuardianInfo) {
                        info = questionConfig[questionName]?.U18info;
                    } else {
                        info = questionConfig[questionName]?.info;
                    }

                    const {
                        title,
                        titleForFEPBLUE,
                        content,
                        pendingLabel,
                        pendingLabelForDBTDF,
                        deviceFlexContent,
                    } = info || {};

                    switch (id) {
                        case QuestionId.Values.EVER_DIAGNOSED_WITH_DIABETES:
                            return (
                                <div key={questionName} className={css.p}>
                                    <GeneralInfoByQuestionId
                                        info={info}
                                        id={id}
                                    />
                                </div>
                            );

                        case QuestionId.Values.BLOOD_TEST_PREDIABETES:
                            return (
                                <div key={questionName} className={css.p}>
                                    <GeneralInfoByQuestionId
                                        info={info}
                                        id={id}
                                    />
                                </div>
                            );

                        case QuestionId.Values.ARE_YOU_ACTIVE:
                            return (
                                <div key={questionName} className={css.p}>
                                    <GeneralInfoByQuestionId
                                        info={info}
                                        id={id}
                                    />
                                </div>
                            );
                        // default info will only have simple title and content
                        default:
                            return (
                                <div key={questionName}>
                                    {!hideShippingInfoForPendingProgram && (
                                        <>
                                            {title &&
                                                t(title) &&
                                                !isDiabetesDeviceFlexOnly && (
                                                    <h2 className={css.title}>
                                                        {t(
                                                            data?.questionsToAsk
                                                                ?.nodeTitleId ===
                                                                'askNonMemberA1cShippingAddr'
                                                                ? titleForFEPBLUE
                                                                : title
                                                        )}
                                                    </h2>
                                                )}
                                            {content &&
                                                t(content) &&
                                                !isDiabetesDeviceFlexOnly &&
                                                !isExperimentContext && (
                                                    <p className={css.p}>
                                                        {t(content)}
                                                    </p>
                                                )}
                                            {isExperimentContext &&
                                                t(`${content}Experimental`) && (
                                                    <p className={css.p}>
                                                        {t(
                                                            `${content}Experimental`
                                                        )}
                                                    </p>
                                                )}
                                            {deviceFlexContent &&
                                                t(deviceFlexContent) &&
                                                isDiabetesDeviceFlexOnly && (
                                                    <p className={css.p}>
                                                        {t(deviceFlexContent)}
                                                    </p>
                                                )}
                                            {(id ===
                                                QuestionId.Values
                                                    .ADDRESS_USA_STANDARD ||
                                                id ===
                                                    QuestionId.Values
                                                        .ADDRESS_USA_COMBINED) && (
                                                <Address
                                                    info={info}
                                                    programs={selectedPrograms}
                                                />
                                            )}
                                        </>
                                    )}
                                    {hideShippingInfoForPendingProgram &&
                                        (pendingLabel ||
                                            pendingLabelForDBTDF) &&
                                        (t(pendingLabel) ||
                                            t(pendingLabelForDBTDF)) && (
                                            <p className={css.p}>
                                                {isDiabetesDeviceFlexOnly
                                                    ? t(pendingLabelForDBTDF)
                                                    : t(pendingLabel)}
                                            </p>
                                        )}
                                </div>
                            );
                    }
                })}
            {hasNodeTitleId && <div>&nbsp;</div>}
        </aside>
    );
};

Info.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Info;
