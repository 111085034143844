import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import Radio from '@teladoc/pulse/ui/Radio';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import ScreenReader from 'common/screen-reader/ScreenReader';
import Config from 'config';
import {questionConfig} from 'constants/questions';
import {mixpanelPdfViewedEvent} from 'utilities/mixpanel-utils';
import css from './LabConsent.scss';

const {privacyPracticeUrl} = Config;

const LabConsent = ({
    id,
    name,
    required,
    items,
    defaultValue,
    customValidationError,
    hasNodeTitle,
    isOneApp,
}) => {
    const {t} = useTranslation('questions');
    const locale = getStoredLang().toLowerCase();
    const {
        label: questionLabel,
        labelForWithNodeTitle,
        error,
        legalTextNo,
        legalTextYes,
        oneAppLegalTextYes,
        oneAppLegalTextNo,
    } = questionConfig[id] || {};
    const onPrivacyPracticesClick = () => {
        mixpanelPdfViewedEvent('Privacy Policy', 'CONSENT_LABS_DATA_ACCESS');
    };
    const [labConsentRadioLabel, setLabConsentRadioLabel] = useState(
        defaultValue ? defaultValue : null
    );

    const onRadioChange = evt => {
        setLabConsentRadioLabel(evt.target.value);
    };

    const renderQuestionLabel = hasNodeTitle
        ? labelForWithNodeTitle
        : questionLabel;

    return (
        <div>
            <RadioGroup
                id={id}
                name={name}
                label={
                    <Label i18nRequiredVisualLabel=" ">
                        {t(renderQuestionLabel)}
                    </Label>
                }
                required={required}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={customValidationError}
                onChange={onRadioChange}
            >
                {items.map(({value, label}) => {
                    return (
                        <Radio
                            key={value}
                            id={`item-${value}`}
                            value={value}
                            label={<Label>{label}</Label>}
                            defaultChecked={value === defaultValue}
                        />
                    );
                })}
            </RadioGroup>
            {labConsentRadioLabel === 'YES' && (
                <div className={css.legalTextBlock}>
                    <Trans
                        i18nKey={`questions:${
                            isOneApp ? oneAppLegalTextYes : legalTextYes
                        }`}
                        components={[
                            /* eslint-disable react/jsx-key */
                            <a
                                href={`${privacyPracticeUrl}?locale=${locale}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={onPrivacyPracticesClick}
                            >
                                <ScreenReader />
                            </a>,
                        ]}
                    />
                </div>
            )}
            {labConsentRadioLabel === 'NO' && (
                <div className={css.legalTextBlock}>
                    {t(isOneApp ? oneAppLegalTextNo : legalTextNo)}
                </div>
            )}
        </div>
    );
};

LabConsent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
    customValidationError: PropTypes.element,
    hasNodeTitle: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    isOneApp: PropTypes.bool,
};

export default LabConsent;
