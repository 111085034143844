/* eslint-disable camelcase, no-use-before-define */
import isNil from 'lodash/isNil';
import APIUtils, {APIRegistry} from '@livongo/utils/api';
import {
    getRestApiAccessToken,
    setRestApiAccessToken,
    getRestApiRefreshToken,
    setRestApiRefreshToken,
    getSplicedDomainName,
} from 'utilities/utils';

// TODO: Declare all the constants here.
// TODO: Team discussion about where should store the auth token.
/*
 *  Currently stored in localstorage.
 *  Should we store it in cookie to keep most browser compatible?
 *  Should we store it in session cookie to clear it between sessions?
 */

const COACH_COMMUNICATION_APP_ID = 5;
const CUSTOM_ALERTS_APP_ID = 11;

const basicAuthConfig = {
    headers: {
        Authorization: `Basic ${process.env.BASIC_AUTH}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    },
};

const UserAPI = {
    /**
     * Set the auth token.
     * It will be called during the initial and sub-sequence gRPC API call.
     * @param {object} response get from gRPC API call.
     * @returns {*} none
     */
    updateAuthorization(response) {
        const KEY_REFRESH_COOKIE = 'refresh_token';
        const KEY_ACCESS_COOKIE = 'access_token';
        const storedAccessToken = getRestApiAccessToken() || '';
        const storedRefreshToken = getRestApiRefreshToken() || '';

        const {
            data: {access_token: accessToken, refresh_token: refreshToken},
        } = response || {
            data: {
                // eslint-disable-next-line
                access_token: storedAccessToken,
                // eslint-disable-next-line
                refresh_token: storedRefreshToken,
            },
        };

        const apiDefaults = APIRegistry.get('default').instance.defaults;

        // Set access token for the sub-sequence API calls.
        apiDefaults.headers = {
            ...apiDefaults.headers,
            Authorization: `Bearer ${accessToken}`,
        };

        // Set access token and store it to localstorage or cookie.
        setRestApiAccessToken(accessToken);
        setRestApiRefreshToken(refreshToken);
        // Set the cookie directly without StorageUtils since it doesn't currently support setting extra settings on cookies.
        document.cookie = `${KEY_REFRESH_COOKIE}=${refreshToken}; Path=/; Domain=${getSplicedDomainName()}; SameSite=None; Secure`;
        document.cookie = `${KEY_ACCESS_COOKIE}=${accessToken}; Path=/; Domain=${getSplicedDomainName()}; SameSite=None; Secure`;

        return response;
    },

    login(userName, password) {
        const data = {
            username: userName,
            password,
            // eslint-disable-next-line
            grant_type: 'password',
        };

        return APIUtils.post('v1/users/me/auth', data, basicAuthConfig).then(
            this.updateAuthorization
        );
    },

    loginWithAuthCode(authCode) {
        const data = {
            code: authCode,
            // eslint-disable-next-line
            grant_type: 'authorization_code',
        };

        return APIUtils.post('v1/users/me/auth', data, basicAuthConfig).then(
            this.updateAuthorization
        );
    },

    getCoachingPrefs() {
        return APIUtils.get(
            `v1/users/me/prefs/apps?prefs=${COACH_COMMUNICATION_APP_ID}/1/*,${CUSTOM_ALERTS_APP_ID}/1/*`
        ).then(({data}) => {
            const formattedData = {};
            const paramToExtract = ['prefMeth', 'BGLowerLimit', 'BGUpperLimit'];

            if (data) {
                data.forEach(item => {
                    if (paramToExtract.includes(item.prefName)) {
                        formattedData[item.prefName] = item.prefVal;
                    }
                });
            }

            return formattedData;
        });
    },

    updateCoachingPrefs(data) {
        return APIUtils.post('v1/users/me/prefs/apps', data);
    },

    getUserInfo(bearer = {}) {
        const buildUserInfo = response => {
            const {address, firstName, lastName} = response.data;
            const userInfo = {};

            userInfo.recipient = `${firstName} ${lastName}`;
            userInfo.addressLine1 = address.addressLine1;
            userInfo.lastLine = `${address.city}, ${address.state} ${address.zipcode}`;
            userInfo.firstName = firstName;

            return userInfo;
        };

        if (!isNil(bearer)) {
            return APIUtils.get('v1/users/me/info/personal', {}, bearer).then(
                response => {
                    return buildUserInfo(response);
                }
            );
        } else {
            return APIUtils.get('v1/users/me/info/personal').then(response => {
                return buildUserInfo(response);
            });
        }
    },

    getAccountInfo() {
        return APIUtils.get('v1/users/me/account').then(response => {
            const {pid, uuid} = response.data;

            return {pid, uuid};
        });
    },

    getAccessCode() {
        const storedAccessToken = getRestApiAccessToken() || '';

        if (storedAccessToken) {
            return APIUtils.post('v1/users/me/auth/oAuth2CodeFromJWT', {
                jwt: storedAccessToken,
            });
        } else {
            return Promise.resolve({data: {code: ''}});
        }
    },

    refreshToken() {
        const storedRefreshToken = getRestApiRefreshToken() || '';

        if (storedRefreshToken) {
            return APIUtils.post(
                'v1/users/me/auth/refresher',
                {
                    grant_type: 'refresh_token',
                    refresh_token: storedRefreshToken,
                },
                basicAuthConfig
            );
        } else {
            return Promise.resolve({data: {code: ''}});
        }
    },
};

export default UserAPI;
