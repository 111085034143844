import {QuestionAnswer} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/common/user/biometricAlerts_pb';
import {getGrpcAccessToken} from 'utilities/utils';
import {errorMessage} from 'utilities/errors-utils';

export {AlertFlowServicePromiseClient} from 'api/protobuf/biometricAlerts/client';

export const getGrpcMetadata = () => {
    const accessToken = getGrpcAccessToken();

    return {
        Authorization: `Bearer ${accessToken}`,
    };
};

export const answerToProtobuf = ans => {
    if (!ans) {
        throw new Error(errorMessage('Missing answer', ans));
    }

    const answer = new QuestionAnswer();

    if (!ans.questionId) {
        throw new Error(errorMessage('Missing questionId,  for answer', ans));
    }

    if (!ans.questionId.id) {
        throw new Error(errorMessage('Missing questionId.id for answer', ans));
    }

    answer.setQuestionCode(ans.questionId.id);
    answer.setAnswerCodesList(ans.value);

    return answer;
};
