import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Tooltip from '@teladoc/pulse/ui/Tooltip';
import ArrowLeftDefault from '@teladoc/pulse/icons/arrow-left-default.svg';
import notify from '@teladoc/pulse/ui/Notification';
import findKey from 'lodash/findKey';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import messageConfig from 'constants/messages';
import useMessage from 'hook/useMessage';
import {devConsoleLog} from 'utilities/utils';
import {mixpanelButtonClickedEvent} from 'utilities/mixpanel-utils';
import {Program as ProgramNames} from 'api/protobuf/enrollment/protoTypes';
import css from './modalStyle.scss';

const FoundRegcodesFromEmail = ({id, data}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const messageValue = messageConfig[id] || {};
    const {
        title,
        content,
        content2,
        buttonLabel,
        useCodeButtonText,
        backButtonText,
    } = messageValue;
    const {showNotFoundSponsorMessage} = useMessage();
    const detailsList = data.detailsList;
    const {showFoundRegcodesFromEmail} = useMessage();
    const email = data?.email;

    const onBackClick = evt => {
        try {
            showNotFoundSponsorMessage();
        } catch (error) {
            devConsoleLog(
                'components/registration.FoundRegcodesFromEmail.onBackClick.catch',
                error
            );
        }
    };

    const getProgramNameFromValue = value =>
        findKey(ProgramNames.Values, program => program === value);

    const onUseCodeClick = (regCode, clientName) => {
        try {
            const regCodeData = {...data, isOpen: false, regCode, clientName};

            showFoundRegcodesFromEmail(regCodeData);
            mixpanelButtonClickedEvent(
                'REG_CODE',
                '',
                t(useCodeButtonText),
                'CTA'
            );
            notify({
                type: 'success',
                title: '',
                variant: 'toast',
                classNameBody: css.notify,
                message: t('messages:MODAL_NOT_FOUND_SPONSOR.successMsg', {
                    email,
                }),
            });
        } catch (error) {
            devConsoleLog(
                'components/registration.FoundRegcodesFromEmail.onUseCodeClick.catch',
                error
            );
        }
    };

    return (
        <div className={css.foundRegCodeModalContainer}>
            <section>
                <Button
                    aria-label={t(backButtonText)}
                    onClick={onBackClick}
                    icon={<ArrowLeftDefault />}
                    iconPosition="left"
                    label={t(backButtonText)}
                >
                    {t(backButtonText)}
                </Button>
            </section>
            <h1>
                {t(title)}
                <span>
                    <Tooltip content={email} layerOption="bottom-left">
                        {email}
                    </Tooltip>
                </span>
            </h1>
            <p>{t(content)}</p>
            <div>
                {detailsList.map((item, index) => (
                    <div key={index} className={css.rowContainer}>
                        <h2>{item.clientName}</h2>
                        <div className={css.regCodeContainer}>
                            <h1>{item.regCode}</h1>
                            <Button
                                variant="secondary"
                                aria-label={t(useCodeButtonText)}
                                onClick={() =>
                                    onUseCodeClick(
                                        item.regCode,
                                        item.clientName
                                    )
                                }
                            >
                                {t(useCodeButtonText)}
                            </Button>
                        </div>
                        <div className={css.supportedPrograms}>
                            <ul>
                                {item?.supportedProgramsList?.map(
                                    (program, i) => (
                                        <li key={i}>
                                            {t(
                                                `MODAL_FOUND_REGCODES_FROM_EMAIL.programOptions.${getProgramNameFromValue(
                                                    program
                                                )}`
                                            )}
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        <HorizontalRule spaceTop={24} spaceBottom={24} />
                    </div>
                ))}
                <div className={css.bottomContainer}>
                    <p>{t(content2)}</p>
                    <Button
                        aria-label={t(buttonLabel)}
                        onClick={onBackClick}
                        children=""
                    >
                        {t(buttonLabel)}
                    </Button>
                </div>
            </div>
        </div>
    );
};

FoundRegcodesFromEmail.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.object,
};

export default FoundRegcodesFromEmail;
