import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@teladoc/pulse/ui/Button';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import {redirectToMemberPortal} from 'utilities/utils';
import css from './AccountAlreadyExists.scss';

const AccountAlreadyExists = ({contentId, isOneApp}) => {
    const {t} = useTranslation('content');
    const dispatch = useDispatch();

    /* NavigationButton must be disabled in this component */
    useEffect(() => {
        let obj = {backBtn: true, nextBtn: true};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <h1 className={css.h1}>
                {isOneApp
                    ? t('AccountAlreadyExists.oneAppTitle')
                    : t('AccountAlreadyExists.title')}
            </h1>

            <p className={css.p}>
                {isOneApp
                    ? t('AccountAlreadyExists.oneAppParagraph')
                    : t('AccountAlreadyExists.paragraph')}
            </p>
            {!isOneApp && (
                <Button
                    className={css.button}
                    variant="primary"
                    onClick={redirectToMemberPortal}
                >
                    {t('AccountAlreadyExists.login')}
                </Button>
            )}
        </div>
    );
};

AccountAlreadyExists.propTypes = {
    contentId: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool,
};

export default AccountAlreadyExists;
