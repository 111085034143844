import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Countdown from 'react-countdown';
import Button from '@teladoc/pulse/ui/Button';
import {
    cleanSessionInfo,
    getApplication,
    getRegistrationContext,
    getCallBackUrl,
} from 'utilities/utils';
import {MED_OPT} from 'constants/app';
import messageConfig from 'constants/messages';
import Config from 'config';
import css from './modalStyle.scss';

const TIME_OUT_ALERT_PERIOD = 15000;

const Timeout = ({id, onHidden}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const messageValue = messageConfig[id] || {};
    const {title, content, oneappContent, primaryButtonText} = messageValue;
    const {client} = getRegistrationContext() ?? {};
    const isSalesforce = client === Config.client.salesforce;
    const isOneApp = client === Config.client.oneapp;
    const isMedOpt = getApplication() === MED_OPT;

    const handleComplete = () => {
        if (isSalesforce) {
            // set salesforce=true so at login page, we can show the closing msg
            // clean all the session info and localstorage for salesforce expiration
            cleanSessionInfo(true);
            window.open('/login?salesforce=true', '_self');
        } else if (isMedOpt) {
            window.open(process.env.MED_OPT_MEMBER_PORTAL_LOGIN_URL, '_self');
        } else if (isOneApp) {
            const redirectUrl = getCallBackUrl();

            cleanSessionInfo(true);
            window.location.href = redirectUrl;
        } else {
            cleanSessionInfo();
            window.open('/login', '_self');
        }
    };

    return (
        <div className={css.modalContainer}>
            <h1>{t(title)}</h1>
            <div className={css.contentContainer}>
                <p>
                    <Trans
                        i18nKey={`messages:${
                            isOneApp ? oneappContent : content
                        }`}
                        components={[
                            /* eslint-disable-next-line */
                            <Countdown
                                date={Date.now() + TIME_OUT_ALERT_PERIOD}
                                renderer={({seconds}) => (
                                    <strong>{seconds}</strong>
                                )}
                                onComplete={handleComplete}
                            />,
                        ]}
                    />
                </p>
            </div>
            <Button
                className={css.modalButton}
                variant="primary"
                onClick={onHidden}
            >
                {t(primaryButtonText)}
            </Button>
        </div>
    );
};

Timeout.propTypes = {
    id: PropTypes.string.isRequired,
    onHidden: PropTypes.func,
};

export default Timeout;
