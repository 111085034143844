import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import css from './Login.scss';
import LoginForm from './LoginForm';

const Login = () => {
    const {t} = useTransLoader('app');
    const arg = Arg.all();
    const isSalesforce = arg.salesforce;

    return isSalesforce ? (
        <div className={css.root} role="main">
            <h1 className="heading-sm">{t('login.titleForSalesforce')}</h1>
            <p className={css.p}>{t('login.descriptionForSalesforce')}</p>
        </div>
    ) : (
        <div className={css.root} role="main">
            <h1 className={css.h1}>{t('login.title')}</h1>
            <p className={css.p}>{t('login.description')}</p>
            <LoginForm />
        </div>
    );
};

export default Login;
