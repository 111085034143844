import InformationalScreen from 'components/content/subComponents/InformationalScreen';
import css from './CoverageConflict.scss';

const CoverageConflict = () => {
    return (
        <div className={css.container}>
            <InformationalScreen />
        </div>
    );
};

export default CoverageConflict;
