import {combineReducers} from 'redux';
import notification from './notification/notification-reducer';
import message from './message/message-reducer';
import navigationBtnDisable from './navigationButton/navigationBtnDisable-reducer';
import user from './user/user-reducer';

export default combineReducers({
    navigationBtnDisable,
    notification,
    message,
    user,
});
