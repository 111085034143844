import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import Loader from '@teladoc/pulse/ui/Loader';
import cn from 'classnames';
import useTransLoader from 'i18n/use-trans-loader';
import Header from 'components/header';
import Footer from 'components/footer';
import TeamsBanner from 'components/teamsBanner';
import useTitle from 'hook/useTitle';
import {mixpanelGeneralError} from 'utilities/mixpanel-utils';
import {getRegistrationContext} from 'utilities/utils';
import Config from 'config';

const RouteWithHeaderAndFooter = ({path, component: Component, beforeLoad}) => {
    const {t} = useTransLoader('app');
    const [initializeData, setInitializeData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showNewContent, setShowNewContent] = useState(false);
    const {client} = getRegistrationContext() ?? {};
    const isSalesforce = client === Config.client.salesforce;
    const isOneApp = client === Config.client.oneapp;

    useTitle({
        path,
        isOneApp,
    });

    useEffect(() => {
        if (beforeLoad) {
            const getter = async () => {
                try {
                    setInitializeData(await beforeLoad());
                    setLoading(false);
                } catch (e) {
                    mixpanelGeneralError({
                        name: `${path} beforeLoad`,
                        location: 'RouteWithHeaderAndFooter.useEffect.getter',
                        additionalInfo: e,
                    });
                    setLoading(false);
                }
            };

            getter();
        } else {
            setLoading(false);
        }

        setTimeout(() => {
            setShowNewContent(true);
        }, 10000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                className={cn('viewPortHeight', {
                    oneAppBody: isOneApp,
                })}
            >
                <TeamsBanner />
                <Header loading={loading} isOneApp={isOneApp} />
                <Route
                    path={path}
                    render={routeProps =>
                        loading ? (
                            <div className="loader-content">
                                <Loader className="Loader-module-root" />
                                {isOneApp &&
                                    (showNewContent ? (
                                        <p>
                                            {t('loader.oneappLoadingContent1')}
                                        </p>
                                    ) : (
                                        <p>
                                            {t('loader.oneappLoadingContent')}
                                        </p>
                                    ))}
                            </div>
                        ) : (
                            <Component
                                {...routeProps}
                                initializeData={initializeData}
                            />
                        )
                    }
                />
            </div>
            {!isSalesforce && <Footer />}
        </>
    );
};

RouteWithHeaderAndFooter.propTypes = {
    path: PropTypes.string,
    component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.object,
    ]).isRequired,
    /** An array of promises to resolve before rendering the Route. */
    beforeLoad: PropTypes.func,
};

export default RouteWithHeaderAndFooter;
