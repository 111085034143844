import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import {questionConfig} from 'constants/questions';

const UmassConsent = ({id, name, defaultValue}) => {
    const [userInput, setUserInput] = useState(defaultValue ?? 'NO');
    const {helperText, label, error} = questionConfig[id] || {};
    const {t} = useTranslation('questions');

    const handleChange = event => {
        if (event.target.checked) {
            setUserInput('YES');
        } else {
            setUserInput('NO');
        }
    };

    return (
        <>
            <Checkbox
                defaultChecked={defaultValue === 'YES'}
                id={id}
                name={name}
                value={userInput}
                label={<Label>{t(label)}</Label>}
                onChange={handleChange}
                i18nItemLabel={t(label)}
                error={<FormElementError>{t(error)}</FormElementError>}
            />
            <span>{t(helperText)}</span>
        </>
    );
};

UmassConsent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
};

export default UmassConsent;
