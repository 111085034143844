const HERE_IS_HOW = 'HERE_IS_HOW';
const INELIGIBLE = 'INELIGIBLE';
const VALUE_POINTS = 'VALUE_POINTS';
const GET_THE_APP = 'GET_THE_APP';
const EXIT_NOT_ENROLLED = 'EXIT_NOT_ENROLLED';
const EXIT_NOT_SCHEDULED = 'EXIT_NOT_SCHEDULED';
const EXIT_ALL_SET = 'EXIT_ALL_SET';

export {
    HERE_IS_HOW,
    EXIT_NOT_ENROLLED,
    EXIT_NOT_SCHEDULED,
    EXIT_ALL_SET,
    INELIGIBLE,
    VALUE_POINTS,
    GET_THE_APP,
};

export const contentConfig = {
    HERE_IS_HOW_CONTENT: {
        content: [
            'HERE_IS_HOW.HERE_IS_HOW_CONTENT.content1',
            'HERE_IS_HOW.HERE_IS_HOW_CONTENT.content2',
            'HERE_IS_HOW.HERE_IS_HOW_CONTENT.content3',
            'HERE_IS_HOW.HERE_IS_HOW_CONTENT.content4',
        ],
    },
    VALUE_POINTS_CONTENT: {
        content: [
            'VALUE_POINTS.VALUE_POINTS_CONTENT.content1',
            'VALUE_POINTS.VALUE_POINTS_CONTENT.content2',
            'VALUE_POINTS.VALUE_POINTS_CONTENT.content3',
            'VALUE_POINTS.VALUE_POINTS_CONTENT.content4',
            'VALUE_POINTS.VALUE_POINTS_CONTENT.content5',
        ],
    },
};
