import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import Label from '@teladoc/pulse/ui/Label';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import ScreenReader from 'common/screen-reader/ScreenReader';
import {questionConfig} from 'constants/questions';
import Config from 'config';
import {mixpanelPdfViewedEvent} from 'utilities/mixpanel-utils';

const {tosUrl, privacyPracticeUrl, nlaUrl, childrenPrivacyUrl} = Config;

const AcknowledgedChildPrivacyPolicy = ({id, name, required}) => {
    const {title, error} = questionConfig[id] || {};
    const {t} = useTranslation('questions');
    const locale = getStoredLang().toLowerCase();

    const onTermsOfServiceClick = () => {
        mixpanelPdfViewedEvent('Terms of Service', 'TERMS_AND_CONDITIONS');
    };

    const onPrivacyPracticesClick = () => {
        mixpanelPdfViewedEvent('Privacy Policy', 'TERMS_AND_CONDITIONS');
    };

    const onNonDiscriminationDisclosureClick = () => {
        mixpanelPdfViewedEvent(
            'Non-Discrimination Disclosure',
            'TERMS_AND_CONDITIONS'
        );
    };

    const onChildrenPrivacyPolicyClick = () => {
        mixpanelPdfViewedEvent(
            "Children's Online Privacy Policy",
            'ACKNOWLEDGED_CHILD_PRIVACY_POLICY'
        );
    };

    const termsOfService = (
        <a
            href={`${tosUrl}?locale=${locale}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onTermsOfServiceClick}
        >
            <ScreenReader />
        </a>
    );

    const privacyPractices = (
        <a
            href={`${privacyPracticeUrl}?locale=${locale}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onPrivacyPracticesClick}
        >
            <ScreenReader />
        </a>
    );

    const nonDiscriminationDisclosure = (
        <a
            href={`${nlaUrl}?locale=${locale}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onNonDiscriminationDisclosureClick}
        >
            <ScreenReader />
        </a>
    );

    const childrenPrivacyPolicy = (
        <a
            href={`${childrenPrivacyUrl}?locale=${locale}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onChildrenPrivacyPolicyClick}
        >
            <ScreenReader />
        </a>
    );

    const termsConditionsChildPrivacyPolicy = [
        privacyPractices,
        termsOfService,
        childrenPrivacyPolicy,
        nonDiscriminationDisclosure,
    ];

    return (
        <Checkbox
            id={id}
            name={name}
            value="true"
            label={
                <Label>
                    <Trans
                        i18nKey="questions:ACKNOWLEDGED_CHILD_PRIVACY_POLICY.label"
                        components={termsConditionsChildPrivacyPolicy}
                    />
                </Label>
            }
            i18nItemLabel={t(title)}
            error={<FormElementError>{t(error)}</FormElementError>}
            required={required}
        />
    );
};

AcknowledgedChildPrivacyPolicy.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
};

export default AcknowledgedChildPrivacyPolicy;
