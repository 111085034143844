/**
 * Authentication gRPC calls
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/med_opt/authentication
 */
import {
    InitializeEnrollmentRequest,
    MedoptFlavor,
} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/med_opt_onboarding_pb';
import {InitializeTeladocUserRequest} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/flow_admin_pb';
// import {getTeladocSdkTokenMockPromise} from 'constants/medOptMockData';
import {
    flowAdminPromiseClient,
    medOptOnboardingPromiseClient,
    getGrpcProtocolVersion,
    getGrpcMetadata,
} from './utils';

/**
 * initializeEnrollment
 * Used to start the enrollment process.
 * @returns {!Promise<!proto.com.livongo.protobuf.grpc.external.med_opt.InitializeEnrollmentResponse>}
 *  The response will be the same as a successful attempt to authenticate (modulo wrappers), with the difference that
 *     the returned token is the _only_ way to access this pending account until an email and password have been
 *     submitted using the returned token.
 */
export const initializeEnrollment = flavorValue => {
    const request = new InitializeEnrollmentRequest();
    const flavor = new MedoptFlavor();

    // set medOpt flavor
    if (flavorValue) {
        flavor.setValues(`${flavorValue}`);
        request.setMedoptFlavor(flavor);
    }

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setParent('user/liv/me/medOpt/enrollment');

    return medOptOnboardingPromiseClient
        .initializeEnrollment(request, getGrpcMetadata())
        .then(response => response.toObject());
};

/**
 * initializeTeladocUser
 * Create Teladoc User if not exists, and get token for the Teladoc WebSDK
 * @returns {!Promise<!proto.com.livongo.protobuf.grpc.external.med_opt.InitializeTeladocUserResponse>}
 *  If the credentials supplied match, a token will be returned that can be used to continue the enrollment process.
 */
// user's resource name, i.e., user/liv/<UUID>/medOpt or user/liv/me/medOpt
export const createTeladocSdkToken = () => {
    const request = new InitializeTeladocUserRequest();

    request.setName('user/liv/me/medOpt');

    return flowAdminPromiseClient
        .initializeTeladocUser(request, getGrpcMetadata())
        .then(response => response.toObject());

    // return getTeladocSdkTokenMockPromise();
};
