import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useTranslation, Trans} from 'react-i18next';
import cn from 'classnames';
import AdvancedSelect from '@teladoc/pulse/ui/AdvancedSelect';
import Loader from '@teladoc/pulse/ui/Loader';
import Button from '@teladoc/pulse/ui/Button';
import IconSearchDefault from '@teladoc/pulse/icons/search-default.svg';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import {
    mixpanelModalViewedEvent,
    mixpanelButtonClickedEvent,
    mixpanelEnrollmentExperimentEvent,
} from 'utilities/mixpanel-utils';
import {
    getClientFriendlyName,
    setClientFriendlyName,
    setRegCode,
    getVWOVariant,
} from 'utilities/utils';
import useRegCode from 'hook/useRegCode';
import useMessage from 'hook/useMessage';
import css from './RegCode.scss';

const Item = ({displayName, code, className}) => (
    <div className={className}>
        <p className={css.textBold}>{displayName}</p>
        <p>{code}</p>
    </div>
);

Item.propTypes = {
    code: PropTypes.string,
    className: PropTypes.string,
    displayName: PropTypes.string,
};

const RegCode = ({
    id,
    name,
    version,
    onChange,
    required,
    defaultValue,
    customValidationError,
    isExperimentContext,
    isOneApp,
}) => {
    const {t} = useTranslation('questions');
    const {showNotFoundSponsorMessage} = useMessage();
    const [selectedRegCode, setSelectedRegCode] = useState(null);
    const {message} = useSelector(state => state);
    const {
        fetching: isLoading,
        suggestions,
        error,
        setValue,
        clearSuggestions,
    } = useRegCode(id, version);

    useEffect(() => {
        // call mixpanel Enrollment ExperimentEvent when land on regcode question.
        // Todo: will remove it once a/b test done
        const vwoExperiments = getVWOVariant() ?? {};

        // only EXPERIMENTAL-WORDING-FOR-REG-CODE experiment should trigger this mixpanel event
        if (
            vwoExperiments.experimentName ===
            'EXPERIMENTAL-WORDING-FOR-REG-CODE'
        ) {
            mixpanelEnrollmentExperimentEvent(
                vwoExperiments.experimentName,
                vwoExperiments.experimentGroup,
                'REG_CODE'
            );
        }
    }, []);

    const [initialSelectedItems, setInitialSelectedItems] = useState(() =>
        defaultValue
            ? [
                  {
                      label: getClientFriendlyName(),
                      value: defaultValue,
                  },
              ]
            : []
    );

    useEffect(() => {
        const clientFriendlyName = getClientFriendlyName();

        if (defaultValue && clientFriendlyName) {
            setSelectedRegCode({
                code: defaultValue,
                displayName: clientFriendlyName,
            });
        }
    }, [defaultValue]);

    useEffect(() => {
        if (message?.data?.regCode) {
            const regCode = message?.data?.regCode;
            const clientName = message?.data?.clientName;

            setInitialSelectedItems([
                {
                    label: clientName,
                    value: regCode,
                },
            ]);
            setClientFriendlyName(clientName);
            setRegCode(regCode);
            setSelectedRegCode({
                code: regCode,
                displayName: clientName,
            });
        }
    }, [message]);

    useEffect(() => {
        if (error) {
            // TODO: Display more granular message after BE support it.
        }
    }, [error]);

    const handleInput = value => {
        setValue(value);
        setSelectedRegCode(null);

        if (onChange) {
            onChange(value);
        }
        setInitialSelectedItems([]);
        setClientFriendlyName(null);

        if (value) {
            setRegCode(value.toUpperCase());
        }
    };

    const handleSelect = value => {
        const suggestion = suggestions.find(
            ({code}) => code.toUpperCase() === value
        );

        setSelectedRegCode(suggestion);
        setClientFriendlyName(suggestion.displayName);
        setRegCode(suggestion.code);

        if (onChange) {
            onChange(suggestion.code);
        }
    };

    const handleClear = () => {
        setValue(null);
        setSelectedRegCode(null);
        clearSuggestions();
        setInitialSelectedItems([]);

        if (onChange) {
            onChange('');
        }
    };

    const onShowNotFoundMessage = evt => {
        showNotFoundSponsorMessage();
        mixpanelModalViewedEvent('Need Registration Code Help', '', 'REG_CODE');
        mixpanelButtonClickedEvent(
            'REG_CODE',
            '',
            t('REG_CODE.notFoundSponsorLinkText'),
            'CTA'
        );
    };

    const formattedSuggestions = suggestions.map(suggestion => {
        const {code, displayName} = suggestion;

        return {
            label: `${displayName} ${code}`,
            value: code.toUpperCase(),
        };
    });

    const rowRenderer = ({value, chunks, highlighted, item}) => {
        const {code, displayName} = suggestions.find(
            suggestion => suggestion.code.toUpperCase() === item.value
        );

        return Item({code, displayName, className: css.listItem});
    };

    return (
        <div>
            <span className={css.label}>
                {isExperimentContext
                    ? t('REG_CODE.inputHintExperimental')
                    : t('REG_CODE.inputHint')}
            </span>

            <AdvancedSelect
                input={{
                    name,
                    placeholder: t('REG_CODE.placeholder'),
                    i18nItemLabel: t('REG_CODE.ariaLabel'),
                    error: (
                        <FormElementError>
                            {t('common:error.required')}
                        </FormElementError>
                    ),
                    required: {required},
                    icon: <IconSearchDefault />,
                }}
                initialSelectedItems={initialSelectedItems}
                heightMenuItem={102}
                onClear={handleClear}
                rowRenderer={rowRenderer}
                totalItemsBeforeScroll={6}
                onItemSelect={handleSelect}
                onValueChange={handleInput}
                items={formattedSuggestions}
                customValidationError={customValidationError}
                showToggleIcon={false}
                withValueAsDisplay
                classNameRoot={css.root}
                classNameMenu={css.menu}
                classNameHighlight={css.highlight}
                isRelaxedSearchEnabled
            />
            {isLoading && <Loader className="Loader-module-root" />}
            {selectedRegCode?.displayName && (
                <div className={css.selectedRegCode}>
                    <Item {...selectedRegCode} />
                </div>
            )}
            <div
                className={cn(css.regcodeHintContainer, {
                    [css.regcodeHintContainerOneApp]: isOneApp,
                })}
            >
                <Trans
                    i18nKey="questions:REG_CODE.notFoundSponsorLinkText"
                    components={[
                        /* eslint-disable react/jsx-key */
                        <Button
                            className={css.buttonFont}
                            onClick={onShowNotFoundMessage}
                            children=""
                        />,
                    ]}
                />
            </div>
        </div>
    );
};

RegCode.propTypes = {
    id: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} regCode - Current string in TextInput.
     */
    onChange: PropTypes.func,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
    isExperimentContext: PropTypes.bool,
    isOneApp: PropTypes.bool,
};

export default RegCode;
