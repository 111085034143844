export const SECTION_TYPES = {
    QUESTION: 'QUESTION',
    INFORMATION: 'INFORMATION',
};

export const questionUiType = {
    TEXT_INPUT: 'textInput',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    MULTI_SELECT: 'multiSelect',
    MULTI_SELECT_REQUIRED: 'MultiSelectRequired',
    COMPOUND: 'compoundField',
    // it's not value of the password it's the id of the password, it's a fake alert
    PASSWORD: 'password',
    ADDRESS: 'address',
    A1C_DATE: 'a1cDate',
    A1C_VALUE: 'a1cValue',
    REG_CODE: 'regCode',
    BIRTH_DATE: 'birthDate',
    SMS_OPT_IN: 'sms',
    TERMS_AND_CONDITIONS: 'termsAndConditions',
    PROGRAM: 'program',
    PHONE_INPUT: 'phoneInput',
    BLOOD_TEST: 'bloodTest',
    HEIGHT: 'height',
    WEIGHT: 'weight',
    SUPPORTED_REGION: 'supportedRegion',
    LAB_CONSENT: 'labConsent',
    ADDRESS_COMBINED: 'addressCombined',
    PHONE_INPUT_INTERNATIONAL: 'phoneInputInternational',
    UNION: 'unionField',
    ZIP: 'zip',
    HEALTH_ISSUES: 'healthIssues',
    ACKNOWLEDGED_CHILD_PRIVACY_POLICY: 'acknowledgedChildPrivacyPolicy',
    CLIENT_CONSENT: 'clientConsent',
    MOUNTSINAI_DIABETES_ALLIANCE: 'mountsinaiDiabetesAlliance',
    DIGNITY_HEALTH_TOU: 'dignityHealthTou',
    UMASS_BG_DATA: 'umassBgData',
    LOCAL99_TOU: 'local99Tou',
    LOCATION: 'location',
    CONFIRM_ELIGIBILITY_FILE_REG_CODE: 'confirmEligibilityFileRegCode',
    TARGET_PROGRAMS: 'targetPrograms',
    RADIO_CARD: 'radioCard',
    CHECKBOX_CARD: 'checkboxCard',
    RACE_V2: 'raceV2',
    GENDER_IDENTITY_V2: 'genderIdentityV2',
};

export const errorTypes = {
    WRONG_TYPE: 'wrongType',
    MISSING: 'missing',
    UNDERFLOW: 'underflow',
    OVERFLOW: 'overflow',
    ILLEGAL_ENUM_VALUE: 'illegalEnumValue',
    ILLEGAL_SCALAR_VALUE: 'illegalScalarValue',
    INVALID_PHONE_NUMBER: 'invalidPhoneNumber',
    PASSWORD_MISSING_CHAR_SETS: 'passwordMissingCharSets',
    DATE_DOES_NOT_EXIST: 'dateDoesNotExist',
    INVALID_PROGRAM_SET: 'invalidProgramsSet',
    EMAIL_IN_USE: 'emailInUse',
    INVALID_REG_CODE: 'invalidRegCode',
    INVALID_PASSWORD: 'invalidPassword',
    ANSWERED_TWICE: 'answeredTwice',
    FLOW_ERROR: 'flowError',
    DUPLICATES: 'duplicates',
    NONE_OPTION_ONLY: 'noneOptionOnly',
    ALREADY_ENROLLED: 'AlreadyEnrolled',
    INCORRECT_BIRTH_DATE: 'incorrectBirthDate',
    CONFIRM_DOB_ATTEMPTS_EXHAUSTED: 'confirmDobAttemptsExhausted',
    UNDEFINED: 'undefined',
    UNKNOWN_QUESTION_ID: 'unknownQuestionId',
    UNKNOWN_ENUM_VALUE: 'unknownEnumValue',
    UNKNOWN_NODE: 'unknownNode',
    UNKNOWN_BRANCH_POINT: 'unknownBranchPoint',
    UNKNOWN_REFERENCE: 'unknownReference',
    INVALID_ZIP_CODE: 'invalidZipCode',
};

export const controlGroupTypes = {
    INVALID: 'INVALID',
    CONTROL: 'CONTROL',
    VARIATION_1: 'VARIATION_1',
};

export const IDK = 'I_DONT_KNOW_skdl';
