import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Radio from '@teladoc/pulse/ui/Radio';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import TextInput from '@teladoc/pulse/ui/TextInput';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
import {questionConfig} from 'constants/questions';
import {getFirstNonEmptyKey} from 'utilities/utils';
import css from './RaceAndGenderV2.scss';

const GenderIdentityV2 = ({id, name, question, error}) => {
    const {t} = useTranslation('questions');
    const questions = question?.dataType?.compoundField?.questionsList || [];
    const questionIDValueHash = {};
    const questionIDDefaultValueHash = {};

    questions.forEach(que => {
        const questionName = getQuestionNameById(que.questionId.id);
        const questionTypedField = getFirstNonEmptyKey(que.dataType);
        const dataType = que.dataType[questionTypedField];

        questionIDValueHash[questionName] = que;

        // can handle TEXT_FIELD, PROBABLY BOOLEAN_FIELD, INTEGER_FIELD, FLOAT_FIELD, and SINGLE_SELECT_ENUM
        if (dataType.userInput) {
            questionIDDefaultValueHash[questionName] = dataType.userInput;
        } else if (dataType.previousAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswer.value;
        } else if (dataType.defaultAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.defaultAnswer.value;
        } else if (dataType.previousAnswerCode) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswerCode.value;
        } else if (dataType.defaultAnswerCode) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswerCode.value;
        } else if (dataType.previousAnswerCodesList) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswerCodesList;
        } else if (dataType.defaultAnswerCodesList) {
            questionIDDefaultValueHash[questionName] =
                dataType.defaultAnswerCodesList;
        }
    });

    // Create a hash of all the default values set to true

    const defaultGenderAnswer =
        questionIDDefaultValueHash?.GENDER_IDENTITY_SELECTION;
    const [currentAnswer, setCurrentAnswer] = useState(defaultGenderAnswer);
    const [customValidationError, setCustomValidationError] = useState(null);

    useEffect(() => {
        if (error) {
            setCustomValidationError(error);
        }
    }, [error]);

    // this is hardcoded answerCodesList, since BE have difficulty to change it
    // ideally we should use the answerCodesList from Backend just like what we did here: src/components/question/Question.js:263
    const answerCodes = [
        'MALE',
        'FEMALE',
        'FEMALE_TO_MALE',
        'MALE_TO_FEMALE',
        'NON_BINARY',
        'PREFER_NOT_TO_SAY',
        'PREFER_SELF_IDENTIFY',
    ];
    const options = answerCodes.map(code => {
        const label = t(`GENDER_IDENTITY_SELECTION.options.${code}`);

        return {
            value: code,
            label,
        };
    });

    const handleOnChange = value => {
        setCurrentAnswer(value);
    };

    const onTextInputChange = () => {
        setCustomValidationError(false);
    };

    return (
        <div>
            <div className={css.helperText}>
                {t(questionConfig.GENDER_IDENTITY_V2.helperText)}
            </div>
            {questionIDValueHash.GENDER_IDENTITY_SELECTION && (
                <RadioGroup
                    id={questionConfig.GENDER_IDENTITY_SELECTION.id}
                    name={`${name}_-_${questionIDValueHash.GENDER_IDENTITY_SELECTION.questionId.id}`}
                    label={
                        <Label i18nRequiredVisualLabel=" ">
                            {t(questionConfig.GENDER_IDENTITY_SELECTION.label)}
                        </Label>
                    }
                    error={
                        <FormElementError>
                            {t(questionConfig.GENDER_IDENTITY_SELECTION.error)}
                        </FormElementError>
                    }
                    required={
                        questionIDValueHash.GENDER_IDENTITY_SELECTION.required
                    }
                    onChange={evt => {
                        handleOnChange(evt.target.value);
                    }}
                >
                    {options.map(({value, label: itemLabel}) => {
                        return (
                            <Radio
                                key={value}
                                id={`${id}_${value}`}
                                name={`${name}_-_${questionIDValueHash.GENDER_IDENTITY_SELECTION.questionId.id}`}
                                value={value}
                                label={<Label>{itemLabel}</Label>}
                                defaultChecked={value === defaultGenderAnswer}
                            />
                        );
                    })}
                </RadioGroup>
            )}
            {currentAnswer === 'PREFER_SELF_IDENTIFY' && (
                <TextInput
                    type={questionConfig.GENDER_IDENTITY_OTHER.type}
                    id={questionConfig.GENDER_IDENTITY_OTHER.id}
                    name={`${name}_-_${questionIDValueHash.GENDER_IDENTITY_OTHER.questionId.id}`}
                    defaultValue={
                        questionIDDefaultValueHash?.GENDER_IDENTITY_OTHER
                    }
                    pattern={questionConfig.GENDER_IDENTITY_OTHER.pattern}
                    label={
                        <Label i18nRequiredVisualLabel="*">
                            {t(questionConfig.GENDER_IDENTITY_OTHER.label)}
                        </Label>
                    }
                    placeholder={t(
                        questionConfig.GENDER_IDENTITY_OTHER.placeholder
                    )}
                    onChange={onTextInputChange}
                    customValidationError={customValidationError}
                />
            )}
        </div>
    );
};

GenderIdentityV2.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    error: PropTypes.element,
};

export default GenderIdentityV2;
