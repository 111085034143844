import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Select from '@teladoc/pulse/ui/Select';
import IconSearchDefault from '@teladoc/pulse/icons/search-default.svg';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import {questionConfig} from 'constants/questions';

const Location = ({id, name, items, defaultValue, required}) => {
    const {label, placeholder, error} = questionConfig[id] || {};
    const {t} = useTranslation('questions');

    return (
        <Select
            id={id}
            name={name}
            defaultValue={defaultValue}
            icon={<IconSearchDefault />}
            label={<Label i18nRequiredVisualLabel=" ">{t(label)}</Label>}
            placeholder={t(placeholder)}
            items={items}
            error={<FormElementError>{t(error)}</FormElementError>}
            required={required}
        />
    );
};

Location.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool.isRequired,
};

export default Location;
