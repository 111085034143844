import {useTranslation, Trans} from 'react-i18next';
import PropTypes from 'prop-types';
import Config from 'config';
import {getClientFriendlyName} from 'utilities/utils';
import css from './AccountAlreadyExists.scss';

const {memberSupportPhoneNumber, memberSupportFormattedPhoneNumber} = Config;

const U13U18Disqualification = ({contentId, clientName}) => {
    const {t} = useTranslation('content');
    const clientFriendlyName = clientName
        ? clientName
        : getClientFriendlyName();

    return (
        <div>
            {clientFriendlyName ? (
                <h1 className={css.h1}>
                    {t(`${contentId}.title`, {
                        clientFriendlyName,
                    })}
                </h1>
            ) : (
                <h1 className={css.h1}>
                    {t(`${contentId}.titleWithoutClientFriendlyName`, {
                        clientFriendlyName,
                    })}
                </h1>
            )}
            {clientFriendlyName ? (
                <p className={css.p}>
                    {t(`${contentId}.paragraph`, {
                        clientFriendlyName,
                    })}
                </p>
            ) : (
                <p className={css.p}>
                    {t(`${contentId}.paragraphWithoutClientFriendlyName`, {
                        clientFriendlyName,
                    })}
                </p>
            )}
            <p className={css.p}>
                <Trans
                    i18nKey={
                        clientFriendlyName
                            ? `content:${contentId}.paragraph2`
                            : `content:${contentId}.paragraph2WithoutClientFriendlyName`
                    }
                    values={{
                        clientFriendlyName,
                        memberSupportFormattedPhoneNumber,
                    }}
                    components={[
                        /* eslint-disable react/jsx-key */
                        <a href={`tel:${memberSupportPhoneNumber}`}>
                            {memberSupportPhoneNumber}
                        </a>,
                        /* eslint-enable react/jsx-key */
                    ]}
                />
            </p>
        </div>
    );
};

U13U18Disqualification.propTypes = {
    contentId: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
};

export default U13U18Disqualification;
