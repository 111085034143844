import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useState} from 'react';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import CheckableGroup from '@teladoc/pulse/ui/CheckableGroup';
import SelectionCard from '@teladoc/pulse/ui/SelectionCard';
import {questionConfig} from 'constants/questions';
import {renderLabel} from 'components/question/questions-utils';
import css from './CheckboxCard.scss';

const CheckboxCard = ({
    id,
    name,
    required,
    customValidationError,
    hasNodeTitle,
    items,
    errorMessage,
    defaultValues,
    isOneApp,
}) => {
    const {t} = useTranslation('questions');
    const {
        itemListContents,
        ariaLabel,
        labelForWithNodeTitle,
        label: qLabel,
    } = questionConfig[id] || {};
    const renderQuestionLabel = hasNodeTitle ? labelForWithNodeTitle : qLabel;
    const renderClassForQuestionLabel = hasNodeTitle
        ? css.questionLabelForWithNodeTitle
        : '';
    const defaultCheckedHash =
        id !== 'DIABETES_MEDS'
            ? defaultValues.reduce(
                  (hash, defaultValue) => ({...hash, [defaultValue]: true}),
                  {}
              )
            : {};

    const [key, setKey] = useState(new Date().getTime());
    const [checkedHash, setCheckedHash] = useState(defaultCheckedHash);

    const handleOnChange = (value, checked) => {
        const newCheckedHash = {
            ...checkedHash,
            [value]: checked,
        };

        setCheckedHash(newCheckedHash);
        setKey(new Date().getTime());
    };

    return (
        <CheckableGroup
            id={id}
            name={name}
            key={key}
            allowMultiSelect
            {...renderLabel(
                t(renderQuestionLabel),
                t(ariaLabel),
                renderClassForQuestionLabel
            )}
            error={<FormElementError>{errorMessage}</FormElementError>}
            required={required}
            onChange={evt => {
                handleOnChange(evt.target.value, evt.target.checked);
            }}
            customValidationError={customValidationError}
        >
            {items.map(({value, label}) => {
                const content = isOneApp
                    ? itemListContents[value].contentOneApp
                    : itemListContents[value].content;

                return (
                    <SelectionCard
                        key={value}
                        id={`${id}_${value}`}
                        name={name}
                        value={value}
                        hasCheckbox
                        title={<h2 className={css.title}>{label}</h2>}
                        content={t(content) && <p>{t(content)}</p>}
                        defaultChecked={checkedHash[value]}
                    />
                );
            })}
        </CheckableGroup>
    );
};

CheckboxCard.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValues: PropTypes.arrayOf(PropTypes.string),
    customValidationError: PropTypes.element,
    hasNodeTitle: PropTypes.bool,
    items: PropTypes.array.isRequired,
    errorMessage: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool,
};

export default CheckboxCard;
