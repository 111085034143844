/**
 * Navigation gRPC calls
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/enrollment/navigation
 */
import {
    NavigateNextRequest,
    NavigateBackRequest,
    NavigateHereRequest,
    ProgressCheckRequest,
} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/navigation/navigation_pb';
// import StorageUtils from '@livongo/utils/storage';
// import {
//     simulationGrpcResponse,
//     mockData,
//     convertToNavigateHereStep,
//     convertToNavigateBackStep,
// } from 'constants/mockData';
import {
    enrollmentPromiseClient,
    getGrpcProtocolVersion,
    getGrpcAccessToken,
    getGrpcMetadata,
} from './enrollment-utils';

// const mockDataSteps = 25;

/**
 * navigateNext
 * Next questions to be asked
 * @param {int} sectionId the sectionId of the section we want question(s) for
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.navigation.NavigateNextResponse>}
 *  The next group of questions which should be answered, or an indicator that there aren't any more questions in this
 *     section which have not been answered.
 */
export const navigateNext = sectionId => {
    const request = new NavigateNextRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());
    request.setSectionId(sectionId);

    // Comment out to take mock data.
    // TODO: Uncomment back when BE is ready.
    return enrollmentPromiseClient
        .navigateNext(request, getGrpcMetadata())
        .then(response => response.toObject());
    // return new Promise((resolve, reject) => {
    //     const time = 1;
    //     const direction = StorageUtils.get({key: 'beDirection'}) || 'forward';
    //     const storageIndex = StorageUtils.get({key: 'beStepIndex'});
    //     let stepIndex =
    //         storageIndex === null
    //             ? 1
    //             : direction === 'forward'
    //             ? storageIndex
    //             : storageIndex + 2;

    //     setTimeout(() => {
    //         if (stepIndex > 0 && stepIndex < mockDataSteps) {
    //             if ([0, 8, 15, 23].includes(stepIndex)) {
    //                 stepIndex++;
    //             }

    //             if (
    //                 (mockData[`step${stepIndex}`].nextStep &&
    //                     mockData[`step${stepIndex}`].nextStep.context
    //                         .sectionId === sectionId) ||
    //                 (mockData[`step${stepIndex}`].endOfSectionReached &&
    //                     mockData[`step${stepIndex}`].endOfSectionReached
    //                         .sectionId === sectionId)
    //             ) {
    //                 resolve(mockData[`step${stepIndex}`]);
    //             } else {
    //                 reject(
    //                     new Error(
    //                         '-----Wrong section id passed to get section question from navigateNext.'
    //                     )
    //                 );
    //             }

    //             StorageUtils.set({key: 'beStepIndex', value: stepIndex + 1});
    //             StorageUtils.set({key: 'beDirection', value: 'forward'});
    //         } else {
    //             reject(
    //                 new Error(
    //                     '-----Run into error to get section question from navigateNext.'
    //                 )
    //             );
    //         }
    //     }, time * 500);
    // });
};

/**
 * navigateBack
 * Previous questions which were asked
 * @param {int} sectionId the sectionId of the section we want question(s) for
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.navigation.NavigateBackResponse>}
 *  In the case where it is possible to go back, a `RegistrationStep` is returned which should be equivalent to the
 *     previous step - with the addition of previously provided answers.
 */
export const navigateBack = sectionId => {
    const request = new NavigateBackRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());
    request.setSectionId(sectionId);

    // Comment out to take mock data.
    // TODO: Uncomment back when BE is ready.
    return enrollmentPromiseClient
        .navigateBack(request, getGrpcMetadata())
        .then(response => response.toObject());
    // return new Promise((resolve, reject) => {
    //     const time = 1;
    //     const direction = StorageUtils.get({key: 'beDirection'}) || 'forward';
    //     const storageIndex = StorageUtils.get({key: 'beStepIndex'});
    //     let stepIndex =
    //         storageIndex === null
    //             ? 1
    //             : direction === 'backward'
    //             ? storageIndex
    //             : storageIndex - 2;

    //     setTimeout(() => {
    //         if (stepIndex > 0 && stepIndex < mockDataSteps) {
    //             if ([7, 14, 22, 25].includes(stepIndex)) {
    //                 stepIndex--;
    //             }
    //             if (
    //                 (mockData[`step${stepIndex}`].nextStep &&
    //                     mockData[`step${stepIndex}`].nextStep.context
    //                         .sectionId === sectionId) ||
    //                 (mockData[`step${stepIndex}`].beginningOfSectionReached &&
    //                     (mockData[`step${stepIndex}`].beginningOfSectionReached
    //                         .sectionId === sectionId ||
    //                         mockData[`step${stepIndex}`]
    //                             .beginningOfSectionReached.sectionId ===
    //                             sectionId + 1))
    //             ) {
    //                 resolve(
    //                     convertToNavigateBackStep(mockData[`step${stepIndex}`])
    //                 );
    //             } else {
    //                 reject(
    //                     new Error(
    //                         '-----Wrong section id passed to get section question from navigateBack.'
    //                     )
    //                 );
    //             }

    //             StorageUtils.set({key: 'beStepIndex', value: stepIndex - 1});
    //             StorageUtils.set({key: 'beDirection', value: 'backward'});
    //         } else {
    //             reject(
    //                 new Error(
    //                     '-----Run into error to get section question from navigateBack.'
    //                 )
    //             );
    //         }
    //     }, time * 1000);
    // });
};

/**
 * navigateHere
 * Requests the current questions to be asked
 * @param {int} sectionId the sectionId of the section we want question(s) for
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.navigation.NavigateHereResponse>}
 *  Promise
 */
export const navigateHere = sectionId => {
    const request = new NavigateHereRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());
    request.setSectionId(sectionId);

    // Comment out to take mock data.
    // TODO: Uncomment back when BE is ready.
    return enrollmentPromiseClient
        .navigateHere(request, getGrpcMetadata())
        .then(response => response.toObject());
    // .then(response => {
    //     const inclusiveQuestionIds = [];
    //     const exclusiveQuestionIds = [];
    //
    //     simulationGrpcResponse.currentStep.questionsToAsk.questionsList.filter(
    //         (q, i) => {
    //             const qId = q.questionId.id;
    //
    //             if (inclusiveQuestionIds.indexOf(qId) < 0) {
    //                 inclusiveQuestionIds.push(qId);
    //
    //                 return true;
    //             } else {
    //                 exclusiveQuestionIds.push(qId);
    //
    //                 return false;
    //             }
    //         }
    //     );
    //
    //     return response;
    // })
    // .then(() => simulationGrpcResponse)
    // return new Promise((resolve, reject) => {
    //     const time = 1;
    //     const direction = StorageUtils.get({key: 'beDirection'}) || 'forward';
    //     const storageIndex = StorageUtils.get({key: 'beStepIndex'});
    //     const stepIndex =
    //         storageIndex === null
    //             ? 1
    //             : direction === 'backward'
    //             ? storageIndex + 1
    //             : storageIndex - 1;

    //     setTimeout(() => {
    //         if (stepIndex > 0 && stepIndex < mockDataSteps) {
    //             resolve(
    //                 convertToNavigateHereStep(mockData[`step${stepIndex}`])
    //             );
    //         } else {
    //             reject(
    //                 new Error(
    //                     '-----Run into error to get section question from navigateHere.'
    //                 )
    //             );
    //         }
    //     }, time * 1000);
    // });
};

/**
 * progressCheck
 * Overall Progress Check
 * This is useful for when the FrontEnd thinks they should be done, but the
 * `NavigateNextResponse.end_of_section_reached.account_creation_possible` flag is unexpectedly `false`
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.navigation.ProgressCheckResponse>}
 *  Promise
 */
export const progressCheck = () => {
    const request = new ProgressCheckRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());

    return enrollmentPromiseClient.progressCheck(request, getGrpcMetadata());
};
