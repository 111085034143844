import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import CheckableGroup from '@teladoc/pulse/ui/CheckableGroup';
import {questionConfig} from 'constants/questions';
import css from '../Question.scss';

const MultiSelect = ({
    id,
    name,
    required,
    items,
    defaultValues,
    customValidationError,
    label,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    // Get question UI configuration.
    const {error} = questionConfig[id] || {};
    // Create a hash of all the default values set to true
    const defaultCheckedHash = defaultValues.reduce(
        (hash, defaultValue) => ({...hash, [defaultValue]: true}),
        {}
    );
    const [checkedHash, setCheckedHash] = useState(defaultCheckedHash);
    const handleOnChange = (value, checked) => {
        const newCheckedHash = {
            ...checkedHash,
            [value]: checked,
        };

        setCheckedHash(newCheckedHash);

        if (onChange) {
            const selectedValues = Object.keys(newCheckedHash).reduce(
                (arr, key) => {
                    return newCheckedHash[key] ? arr.concat(key) : arr;
                },
                []
            );

            onChange(selectedValues);
        }
    };

    return (
        <div>
            <CheckableGroup
                allowMultiSelect
                id={id}
                name={name}
                label={label}
                error={<FormElementError>{t(error)}</FormElementError>}
                required={required}
                customValidationError={customValidationError}
                onChange={evt => {
                    handleOnChange(evt.target.value, evt.target.checked);
                }}
            >
                {items.map(({value, label: itemLabel}) => {
                    return (
                        <Checkbox
                            classNameRoot={css.checkboxRoot}
                            key={value}
                            id={`${id}_${value}`}
                            name={name}
                            value={value}
                            label={<Label>{itemLabel}</Label>}
                            i18nItemLabel={itemLabel}
                            defaultChecked={checkedHash[value]}
                        />
                    );
                })}
            </CheckableGroup>
        </div>
    );
};

MultiSelect.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    defaultValues: PropTypes.arrayOf(PropTypes.string),
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {String[]} selectedValues - Array of selected values.
     */
    /** A custom label (typically a Label component) to display above the input. */
    label: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {SyntheticEvent} evt - The React `SyntheticEvent`.
     */
    onChange: PropTypes.func,
};

export default MultiSelect;
