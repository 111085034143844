import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import IconExternalLinkDefault from '@teladoc/pulse/icons/external-link-default.svg';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import ScreenReader from 'common/screen-reader/ScreenReader';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import Config from 'config';
import css from './GeneralInfoByQuestionId.scss';

const {LivongoDiabetesGuide} = Config;

const GeneralInfoByQuestionId = ({info, id}) => {
    const {t} = useTranslation('questions');
    const locale = getStoredLang().toLowerCase();

    switch (id) {
        case QuestionId.Values.EVER_DIAGNOSED_WITH_DIABETES:
            return (
                <>
                    <Trans
                        i18nKey={`questions:${info.content}`}
                        components={[
                            // eslint-disable-next-line react/jsx-key
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${LivongoDiabetesGuide}?locale=${locale}`}
                            >
                                <ScreenReader />
                            </a>,
                        ]}
                    />
                    <IconExternalLinkDefault className={css.icon} />
                </>
            );

        case QuestionId.Values.BLOOD_TEST_PREDIABETES:
            return (
                <>
                    <h3>{t(info.subtitle)}</h3>
                    <ul className={css.ulContent}>
                        <li>{t(info.content1)}</li>
                        <li>{t(info.content2)}</li>
                        <li>{t(info.content3)}</li>
                    </ul>
                </>
            );

        case QuestionId.Values.ARE_YOU_ACTIVE:
            return (
                <>
                    <h3>{t(info.subtitle)}</h3>
                    <ul className={css.ulContent}>
                        <li>{t(info.content1)}</li>
                        <li>{t(info.content2)}</li>
                        <li>{t(info.content3)}</li>
                        <li>{t(info.content4)}</li>
                        <li>{t(info.content5)}</li>
                    </ul>
                </>
            );
    }
};

GeneralInfoByQuestionId.propTypes = {
    info: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
};

export default GeneralInfoByQuestionId;
