import {useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '@teladoc/pulse/ui/Button';
import Config from 'config';
import {getRegistrationContext} from 'utilities/utils';
import {checkIsGivenQuestion} from '../question/questions-utils';
import css from './NavigationButton.scss';

const TYPE_PREVIOUS = 'PREVIOUS';
const TYPE_NEXT = 'NEXT';
const DEFAULT_LABEL_PREVIOUS = 'Back';
const DEFAULT_LABEL_NEXT = 'Next';

const NavigationButton = ({
    type,
    label,
    active = false,
    className,
    onClick,
    setNavigationLabel,
    sectionInfo,
    icon,
    iconPosition,
    hideLabel,
    id,
}) => {
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;
    const isPrevious = type === TYPE_PREVIOUS;
    const isNext = type === TYPE_NEXT;
    const buttonType = type === TYPE_NEXT ? 'primary' : null;

    const classes = cn(css.root, className, {
        [css.backButton]: isPrevious,
        [css.nextButton]: isNext,
        [css.oneAppNextButton]: isOneApp && isNext,
        [css.oneAppBackButton]: isOneApp && isPrevious,
    });

    let labelToDisplay = '';

    if (label) {
        labelToDisplay = label;
    } else if (isPrevious) {
        labelToDisplay = DEFAULT_LABEL_PREVIOUS;
    } else if (isNext) {
        labelToDisplay = DEFAULT_LABEL_NEXT;
    }

    useEffect(() => {
        if (setNavigationLabel) {
            if (
                !checkIsGivenQuestion(sectionInfo, 'PROGRAMS') &&
                !checkIsGivenQuestion(sectionInfo, 'TARGET_PROGRAMS')
            ) {
                if (sectionInfo?.contentId === 'OneAppIntroduction') {
                    setNavigationLabel({nextLabel: 'buttonsLabel.enrollNow'});
                } else {
                    setNavigationLabel({nextLabel: 'buttonsLabel.next'});
                }
            }
        }
    }, [sectionInfo, setNavigationLabel]);

    return (
        <Button
            type={type === TYPE_NEXT ? 'submit' : 'button'}
            variant={buttonType}
            className={classes}
            icon={icon}
            iconPosition={iconPosition}
            onClick={onClick}
            id={id}
        >
            {!hideLabel && labelToDisplay}
        </Button>
    );
};

NavigationButton.propTypes = {
    type: PropTypes.oneOf([TYPE_PREVIOUS, TYPE_NEXT]).isRequired,
    label: PropTypes.string,
    active: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    sectionInfo: PropTypes.object.isRequired,
    setNavigationLabel: PropTypes.func,
    /** A custom icon (typically one of the Icon components) to show in the button. */
    icon: PropTypes.element,
    /** If an icon is provided, whether it should appear on the left or right side of the button children. */
    iconPosition: PropTypes.oneOf(['left', 'right']),
    hideLabel: PropTypes.bool,
    id: PropTypes.string,
};

export default NavigationButton;
