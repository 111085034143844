import {useEffect, useState} from 'react';
import Arg from '@livongo/arg';
import MixpanelUtils from '@livongo/utils/mixpanel';
import Loader from '@teladoc/pulse/ui/Loader';
import useTransLoader from 'i18n/use-trans-loader';
import {authenticate} from 'api/protobuf/enrollment/services';
import {
    setIsAuthenticated,
    setGrpcAccessToken,
    setGrpcAccessTokenTimeStamp,
    setSsoContext,
    cleanSessionInfo,
    setUserProgress,
    setUserUUID,
} from 'utilities/utils';
import {
    mixpanelUserSSOSuccessful,
    mixpanelUserSSOFailed,
} from 'utilities/mixpanel-utils';
import {amplitudeFlag, AmplitudeUtils} from 'utilities/amplitude-utils';

const SSO = () => {
    const {t} = useTransLoader('app');
    const arg = Arg.all();
    const mixpanelId = 'mid';
    const isOneApp = arg.oneapp === true;
    const [showNewContent, setShowNewContent] = useState(false);

    // for all sso flow, there will be a mid sent from BE redirectURL
    if (arg[mixpanelId]) {
        MixpanelUtils.identify(arg[mixpanelId]);
    }

    useEffect(() => {
        // when sso failed, no need the params (such as oneapp=true) in url, should just redirect user to plain reg url
        const handleSSOFailed = () => {
            cleanSessionInfo(true);
            mixpanelUserSSOFailed();

            if (amplitudeFlag) {
                AmplitudeUtils.trackUserSSOFailed();
            }

            if (arg.oneapp) {
                window.location.href = `${process.env.ONEAPP_REG_FLOW_URL}${window.location.search}&fromSso=false`;
            } else {
                window.location.href = `${process.env.REG_FLOW_URL}${window.location.search}&fromSso=false`;
            }
        };

        const handleSSOSuccess = () => {
            mixpanelUserSSOSuccessful();

            if (amplitudeFlag) {
                AmplitudeUtils.trackUserSSOSuccessful();
            }

            if (arg.oneapp) {
                window.location.href = `${process.env.ONEAPP_REG_FLOW_URL}${window.location.search}&fromSso=true`;
            } else {
                window.location.href = `${process.env.REG_FLOW_URL}${window.location.search}&fromSso=true`;
            }
        };

        if (arg.registrationAccessCode) {
            setTimeout(() => {
                setShowNewContent(true);
            }, 10000);

            cleanSessionInfo(true);
            setUserUUID(arg[mixpanelId]);

            if (arg.oneapp) {
                //  oneApp SSO flow
                authenticate({
                    accessCode: arg.registrationAccessCode,
                    isAuthAndNav: true,
                })
                    .then(response => {
                        const navigateResponse = response.navigateHereResponse;
                        const accessToken =
                            response.authenticateResponse?.authenticated
                                ?.accessToken?.token;

                        const ssoContextList =
                            response.authenticateResponse?.registrationContext
                                ?.sso?.cqmTriggerInfo;

                        if (ssoContextList) {
                            setSsoContext({
                                isImpersonation:
                                    ssoContextList.location?.value?.includes(
                                        'Impersonation'
                                    ),
                                agentNumber:
                                    ssoContextList.agent?.value?.match(/\d+/) ??
                                    '0',
                            });
                        }

                        if (accessToken) {
                            setUserProgress(navigateResponse);
                            setGrpcAccessToken(accessToken);
                            setGrpcAccessTokenTimeStamp(new Date().getTime());
                            setIsAuthenticated(true);
                            handleSSOSuccess();
                        } else {
                            handleSSOFailed();
                        }
                    })
                    .catch(exception => {
                        handleSSOFailed();
                    });
            } else {
                //  legacy SSO flow
                authenticate({
                    accessCode: arg.registrationAccessCode,
                    isAuthAndNav: false,
                })
                    .then(response => {
                        const accessToken =
                            response.authenticated?.accessToken?.token;

                        const ssoContextList =
                            response.registrationContext?.sso?.cqmTriggerInfo;

                        if (ssoContextList) {
                            setSsoContext({
                                isImpersonation:
                                    ssoContextList.location?.value?.includes(
                                        'Impersonation'
                                    ),
                                agentNumber:
                                    ssoContextList.agent?.value?.match(/\d+/) ??
                                    '0',
                            });
                        }

                        if (accessToken) {
                            setGrpcAccessToken(accessToken);
                            setGrpcAccessTokenTimeStamp(new Date().getTime());
                            setIsAuthenticated(true);
                            handleSSOSuccess();
                        } else {
                            handleSSOFailed();
                        }
                    })
                    .catch(exception => {
                        handleSSOFailed();
                    });
            }
        } else {
            handleSSOFailed();
        }
    });

    return isOneApp ? (
        <div className="loader-content">
            <Loader className="Loader-module-root" />
            {isOneApp &&
                (showNewContent ? (
                    <p>{t('loader.oneappLoadingContent1')}</p>
                ) : (
                    <p>{t('loader.oneappLoadingContent')}</p>
                ))}
        </div>
    ) : null;
};

export default SSO;
