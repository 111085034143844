import PropTypes from 'prop-types';
import ContentGeneric from './ContentGeneric';

const Coverage = ({contentId, clientName, isOneApp}) => {
    const coverageDetails = {
        title: {
            key: isOneApp
                ? clientName
                    ? `${contentId}.oneAppTitle`
                    : `${contentId}.oneAppTitleWithoutClientFriendlyName`
                : clientName
                ? `${contentId}.title`
                : `${contentId}.titleWithoutClientFriendlyName`,
            interpolation: {
                clientFriendlyName: clientName,
            },
        },
        paragraph: {key: `${contentId}.paragraph`},
    };

    return (
        <ContentGeneric
            infoDetails={coverageDetails}
            contentId={contentId}
            isOneApp={isOneApp}
        />
    );
};

Coverage.propTypes = {
    contentId: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool,
};

export default Coverage;
