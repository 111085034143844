import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import EnvironmentUtils from '@livongo/utils/environment';
import {paths} from '@td/websdk';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import Form from '@teladoc/pulse/ui/Form';
import Loader from '@teladoc/pulse/ui/Loader';
import Button from '@teladoc/pulse/ui/Button';
import IconArrowLeftActive from '@teladoc/pulse/icons/arrow-left-active.svg';
import IconCloseDefault from '@teladoc/pulse/icons/close-default.svg';
import Arg from '@livongo/arg';
import {
    QuestionId,
    SectionId as MedOptSectionId,
} from 'api/protobuf/medOpt/prototypes';
import {AlertState} from 'api/protobuf/biometricAlerts/protoTypes';
import {
    createTeladocSdkToken,
    getQuestionNameById,
    navigateBack,
    navigateNext,
    submitAnswers,
} from 'api/protobuf/medOpt/services';
import {
    updateAlertStage,
    listFlowStages,
} from 'api/protobuf/biometricAlerts/alertFlow';
import Config from 'config';
import {IDK} from 'constants/type';
import {questionConfig, questionFieldTypes} from 'constants/questions';
import {COUNTRY_CODE} from 'constants/countryCode';
import {
    EXIT_ALL_SET,
    EXIT_NOT_ENROLLED,
    EXIT_NOT_SCHEDULED,
    GET_THE_APP,
    HERE_IS_HOW,
    VALUE_POINTS,
    INELIGIBLE,
} from 'constants/medOpt/contentIds';
import {WEB_SDK} from 'constants/medOpt/triggerIds';
import useNavigation from 'hook/useNavigation';
import useMessage from 'hook/useMessage';
import {
    mixpanelButtonClicked,
    mixpanelGeneralError,
    mixpanelPageLand,
    mixpanelPagePass,
    mixpanelSessionTimeOut,
} from 'utilities/mixpanel-utils';
import PIWIKUtils from 'utilities/piwik-utils';
import {
    devConsoleLog,
    getFirstNonEmptyKey,
    getRegistrationStepIndex,
    getRegistrationStepLoader,
    getRegistrationStepUrls,
    getWebSdkApplicationConfiguration,
    redirectToDownloadApps,
    redirectToMemberPortalOrOneAppHomePage,
    setRegCode,
    setRegistrationStepLoader,
    setRegistrationStepUrls,
    setTeladocSdkToken,
    setWebSdkInitialState,
    getWebSdkInitialState,
    getIsAuthenticated,
    getRegistrationContext,
} from 'utilities/utils';
import ProgressBar from 'components/progressBar';
import NavigationButton from 'components/navigationButton';
import Section from 'components/section';
import PopupModal from 'components/popupModal';
import MedOptWebSdkContainer from './MedOptWebSdkContainer';
import css from './MedOpt.scss';
import {
    getMedOptLoginUrl,
    convertBiometricAlertsStep,
    getWebSdkBaseUrl,
} from './MedOptsUtils';
import {getTdocAuthToken} from './medOptCookieUtils';

const {MULTI_SELECT_ENUM} = questionFieldTypes;
const {medOptSectionsDefault: sections, medOptUrl} = Config;

// TODO: Move out for better organized.
/**
 * Get data for previous step.
 * This function is for previous button.
 * Pace is one step but need to continuously check until not beginning of section.
 * @param {number} sectionIndex - Index of section.
 * @param {string} sectionPathname - Pathname of section.
 * @returns {Promise<unknown>} - Promise.
 */
const getDataForPreviousStep = async (sectionIndex, sectionPathname) => {
    let navigateResponse;
    const sectionIndexForNavigation = sectionIndex;
    const sectionsForNavigation = [...sections];

    /*
     * Loop under the configured sections size
     *  to cover some corner case which run into
     *  beginning of section right after another beginning of section.
     */
    for (
        let i = sectionIndexForNavigation, j = sectionIndexForNavigation;
        i > -1;
        i--
    ) {
        // eslint-disable-next-line
        navigateResponse = await navigateBack(sectionsForNavigation[j].id)
            .then(response => response)
            .catch(
                error =>
                    // TODO: Test error.
                    error
            );

        if (navigateResponse.beginningOfSectionReached) {
            // Move to previous section.
            j--;
        } else {
            break;
        }
    }

    // Return the final response get from gRPC.
    return navigateResponse;
};

// TODO: Move out for better organized.
/**
 * Get data for next step.
 * This function is for next button.
 * Pace is one step but need to continuously check until not end of section.
 * @param {number} sectionIndex - Index of section.
 * @param {string} sectionPathname - Pathname of section.
 * @returns {Promise<unknown>} - Promise.
 */
const getDataForNextStep = async (sectionIndex, sectionPathname) => {
    let navigateResponse;
    const sectionIndexForNavigation = sectionIndex;
    const sectionsForNavigation = [...sections];

    /*
     * Loop under the configured sections size
     *  to cover some corner case which run into
     *  end of section right after another end of section.
     */
    for (
        let i = sectionIndexForNavigation, j = sectionIndexForNavigation;
        i < sectionsForNavigation.length;
        i++
    ) {
        // eslint-disable-next-line
        navigateResponse = await navigateNext(sectionsForNavigation[j].id)
            .then(response => response)
            .catch(
                error =>
                    // TODO: Test error.
                    error
            );

        if (navigateResponse.endOfSectionReached) {
            // Move to next section.
            j++;
        } else {
            break;
        }
    }

    // Return the final response get from gRPC.
    return navigateResponse;
};

/**
 * Convert formdata into a hashmap with the question id as key and answer as value
 * For compound questions, the key is a hash of sub question ids and sub question answers
 * @param {Object} formData - Hashmap of question ids to answers.
 * @returns {Object} - hashmap with the question id as key and answer as value
 */
const parseAnswersFromForm = (formData = {}) => {
    return Object.keys(formData).reduce((hash, key) => {
        const [, questionId, subQuestionId, grandQuestionId] = key.split('_-_'); // ${QUESTION_NAME}_-_${QUESTION_ID}_-_${SUB_QUESTION_ID_INT}
        const value = formData[key];
        const countryCode = COUNTRY_CODE[formData?.countryCode];

        // incorrect format; questionId start at one and can't be zero
        // also to protect again incorrect data from formData: { countryCode: US }
        if (!questionId) {
            devConsoleLog(
                `Skipping form data because of no question ID: ${key}`
            );

            return hash;
        }

        if (!grandQuestionId) {
            if (
                !subQuestionId &&
                questionId !== `${QuestionId.Values.MOBILE_PHONE_INTERNATIONAL}`
            ) {
                hash[questionId] = value;
            } else {
                // compound question
                if (!hash[questionId]) {
                    hash[questionId] = {};
                }

                // Special handle for internation phone since it return as compound question.
                if (
                    questionId ===
                    `${QuestionId.Values.MOBILE_PHONE_INTERNATIONAL}`
                ) {
                    hash[questionId][QuestionId.Values.MOBILE_PHONE_COUNTRY] =
                        countryCode;
                    hash[questionId][QuestionId.Values.PHONE_MOBILE] = value;
                } else {
                    hash[questionId][subQuestionId] = value;
                }
            }
        } else {
            // union question
            if (!hash[questionId]) {
                hash[questionId] = {};
            }

            if (!hash[questionId][subQuestionId]) {
                hash[questionId][subQuestionId] = {};
            }

            hash[questionId][subQuestionId][grandQuestionId] = value;
        }

        return hash;
    }, {});
};

/**
 * convert answer hash and question array into Answer format for BE
 * @param {Object} answerHash - Hashmap of question ids to answers.
 * @param {Object[]} questions - Index of section.
 * @returns {Object[]} - Array of Answers.
 */
const parseAnswers = (answerHash = {}, questions = []) => {
    const answers = [];

    questions.forEach(question => {
        const questionId = question.questionId.id;
        const value = answerHash[questionId];
        const dataType = getFirstNonEmptyKey(question.dataType);

        // missing typedValueType, badly formatted data
        if (!question.dataType || Object.keys(question.dataType).length === 0) {
            throw new Error(
                `dataType not found in Question object for: ${questionId}`
            );
        }

        // optional question and not returning this answer to the backend
        if (!question.required && (value === IDK || !value)) {
            devConsoleLog(
                `Skipping answer for ${questionId} because it is optional and the user provided no answer`
            );

            return;
        }

        let answerValue = value;

        if (dataType === MULTI_SELECT_ENUM) {
            answerValue = value ? [].concat(value) : [];
        }

        answers.push({
            questionId: question.questionId,
            type: dataType,
            value: answerValue,
        });
    });

    return answers;
};

const parseContentAnswer = currentRegistrationStep => {
    if (currentRegistrationStep.contentId) {
        const newObject = {};

        newObject.contentAck = currentRegistrationStep.contentId;

        return newObject;
    } else if (currentRegistrationStep.triggerId) {
        const newObject = {};

        newObject.triggerAck = currentRegistrationStep.triggerId;

        return newObject;
    }

    return;
};

const generateAnswerForBiometricAlerts = (steps, answer) => {
    const ansObject = {};
    const flowStageItem = steps?.flowStagesList[0];
    const questionCode = flowStageItem?.question?.questionCode;
    const alertId = flowStageItem?.alertId?.value;

    ansObject.questionId = {id: questionCode};
    ansObject.alertId = alertId;

    if (answer.contentAck || answer.triggerAck) {
        ansObject.value = ['OK'];
    } else if (answer.type === 'boolField') {
        answer.value === 'true'
            ? (ansObject.value = ['YES'])
            : (ansObject.value = ['NO']);
    } else {
        ansObject.value = answer.value;
    }

    return ansObject;
};

/**
 * Method converts service-med-opt PROTO returns to enrollment PROTO responses
 * @param {*} medoptStep
 * @returns enrollment formated steps
 */
const convertMedoptStep = medoptStep => {
    const newStep = {};
    const previousAnswer = {};
    let previousAnswerCodesList = [];

    if (
        medoptStep.context.answersList &&
        medoptStep.context.answersList.length > 0 &&
        (medoptStep.context.answersList[0].contentAck ||
            medoptStep.context.answersList[0].submittedAnswer)
    ) {
        const context = {};
        const answersList = [];

        context.sectionId = medoptStep.context.sectionId;
        context.stepNumber = medoptStep.context.stepNumber;
        context.numberOfSteps = medoptStep.context.numberOfSteps;

        // Map medopt submittedAnswer to enrollment answer
        if (medoptStep.context.answersList[0].submittedAnswer) {
            const inAns = medoptStep.context.answersList[0].submittedAnswer;

            if (
                inAns.boolField &&
                inAns.boolField.answer &&
                inAns.boolField.answer.value !== null
            ) {
                previousAnswer.value = inAns.boolField.answer.value;
            } else if (
                inAns.multiSelectEnum &&
                inAns.multiSelectEnum.answerCodesList &&
                inAns.multiSelectEnum.answerCodesList.length > 0
            ) {
                previousAnswerCodesList = inAns.multiSelectEnum.answerCodesList;
            }
        }

        context.answersList = answersList;
        newStep.context = context;
    } else {
        newStep.context = medoptStep.context;
    }

    if (medoptStep.questionsToAsk) {
        const questionsToAsk = {};
        const questions = [];

        if (
            medoptStep.questionsToAsk.questionsList &&
            medoptStep.questionsToAsk.questionsList.length > 0
        ) {
            const question = {};
            const inQ = medoptStep.questionsToAsk.questionsList[0];

            question.questionId = inQ.questionId;
            question.required = inQ.required;
            question.validationErrorsList = [];

            if (inQ.dataType.multiSelectEnum) {
                const dataType = {};
                const multiSelectEnum = {};

                multiSelectEnum.answerCodesList =
                    inQ.dataType.multiSelectEnum.answerCodesList;

                multiSelectEnum.defaultAnswerCodesList = [];

                if (previousAnswerCodesList.length > 0) {
                    multiSelectEnum.previousAnswerCodesList =
                        previousAnswerCodesList;
                }
                dataType.multiSelectEnum = multiSelectEnum;
                question.dataType = dataType;
            } else if (
                inQ.dataType.boolField &&
                previousAnswer.value !== null
            ) {
                const dataType = {};
                const boolField = {};

                boolField.previousAnswer = previousAnswer;
                dataType.boolField = boolField;
                question.dataType = dataType;
            } else {
                question.dataType = inQ.dataType;
            }
            questions.push(question);
        }
        questionsToAsk.questionsList = questions;
        questionsToAsk.nodeTitleId = '';
        newStep.questionsToAsk = questionsToAsk;
    }

    if (medoptStep.contentId) {
        newStep.contentId = medoptStep.contentId;
    }

    if (medoptStep.triggerId) {
        newStep.triggerId = medoptStep.triggerId;
    }

    return newStep;
};

export const convertMedoptNavigationStep = registrationStep => {
    const newObject = {};

    if (registrationStep.beginningOfSectionReached) {
        newObject.beginningOfSectionReached =
            registrationStep.beginningOfSectionReached;
    }

    if (registrationStep.endOfSectionReached) {
        newObject.endOfSectionReached = registrationStep.endOfSectionReached;
    }

    return newObject;
};

/* Convert a MedOpt NavigateNext RegistrationStep Question to Enrollment Question Def */
export const convertNavigateNextStep = registrationStep => {
    const newObject = convertMedoptNavigationStep(registrationStep);

    if (registrationStep.onboardingComplete) {
        newObject.onboardingComplete = registrationStep.onboardingComplete;
    }

    if (registrationStep.nextStep) {
        newObject.nextStep = convertMedoptStep(registrationStep.nextStep);
    }

    return newObject;
};

/* Convert a MedOpt NavigateBack RegistrationStep Question to Enrollment Question Def */
export const convertNavigateBackStep = registrationStep => {
    const newObject = convertMedoptNavigationStep(registrationStep);

    if (registrationStep.onboardingComplete) {
        newObject.onboardingComplete = registrationStep.onboardingComplete;
    }

    if (registrationStep.previousStep) {
        newObject.previousStep = convertMedoptStep(
            registrationStep.previousStep
        );
    }

    return newObject;
};

/* Convert a NavigateNext RegistrationStep to a NavigateHere RegistrationStep */
export const convertToNavigateHereStep = registrationStep => {
    const newObject = convertMedoptNavigationStep(registrationStep);

    if (registrationStep.nextStep) {
        newObject.currentStep = convertMedoptStep(registrationStep.nextStep);
    }

    return newObject;
};

const MedOpt = ({history, location}) => {
    const {t} = useTranslation(['questions', 'common']);
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;
    // set it always to false for now and wait release signal to turn it on
    const isBiometricAlertsTurnedOn = false;

    const {
        showBadRequestErrorMessage,
        showTimeoutMessage,
        showMobileAppSmsMessage,
    } = useMessage();
    // get provider base care value
    const {pbc} = Arg.all();
    const formRef = useRef();
    const formRefWeb = useRef();
    const progressBarRef = useRef();
    // State to flag loading or not.
    const [isLoading, setIsLoading] = useState(false);
    // State to show WebSDK container
    const [showWebSdk, setShowWebSdk] = useState(false);
    // State to show MedOpt next button on progress bar
    const [showMedOptNextButton, setShowMedOptNextButton] = useState(true);
    // State to hold the whole return from gRPC.
    const [currentRegistrationStep, setCurrentRegistrationStep] = useState({});
    // State to hold the whole return for BiometricAlerts.
    const [currentBiometricAlertsInfo, setCurrentBiometricAlertsInfo] =
        useState({});
    // Saved biometricAlertsAns only use for browser Navigate
    const [savedBiometricAlertsAns, setSavedBiometricAlertsAns] =
        useState(null);
    // flag to disable biometricAlertsAns back button
    const [isBiometricBackButtonDisabled, setIsBiometricBackButtonDisabled] =
        useState(false);
    // State to show errors for each questions from submit answers and navigate gRPC responses
    const [questionErrors, setQuestionErrors] = useState([]);
    // State to show exit modal
    const [showExitModal, setShowExitModal] = useState(false);
    // Get step URLs from registration history.
    const stepUrls = getRegistrationStepUrls();
    // Get previous step index from registration history.
    const previousStepIndex = getRegistrationStepIndex();
    // Get current step index from URL.
    const currentStepIndex = stepUrls.findIndex(
        url => url.indexOf(location.pathname) === 0
    );
    // Determine if the parent directory is /med-opts
    const parentPathname = location.pathname.startsWith(medOptUrl)
        ? medOptUrl
        : '/';
    // Get the child pathname and make sure it starts with a forward slash
    let childPathname = location.pathname.substring(parentPathname.length);

    childPathname = `${childPathname[0] === '/' ? '' : '/'}${childPathname}`;

    // Get current section pathname from URL.
    const [, currentSectionPathname] = childPathname.split('/');
    // Get current section index based on the pathname.

    let currentSectionIndex = sections.findIndex(
        s => s.pathname === currentSectionPathname
    );

    // Set navigationNextLabel based on contentId or question
    const getNavigateBackText = () => {
        const {contentId} = currentRegistrationStep;

        if (contentId === HERE_IS_HOW) {
            return t('medOptButtonsLabel.hereIsHow');
        } else if (contentId === VALUE_POINTS) {
            return t('medOptButtonsLabel.valuePoints');
        }

        return t('medOptButtonsLabel.continue');
    };
    const navigationNextLabel = getNavigateBackText();

    // check to see if currentSectionPathname in sections array is found and in the stepUrls
    // otherwise set it to 0
    const pathNameFoundInStepUrls = stepUrls.some(
        url => url.indexOf(currentSectionPathname) !== -1
    );

    if (currentSectionIndex === -1 || !pathNameFoundInStepUrls) {
        currentSectionIndex = 0;
    }

    const disableNavBtn = useSelector(state => state.navigationBtnDisable);

    const handleIdle = () => {
        mixpanelSessionTimeOut(currentRegistrationStep);

        showTimeoutMessage();
    };

    const loadWebSdk = async triggerId => {
        let sdkTokenInfo = {};

        if (WEB_SDK === triggerId) {
            let createTeladocSdkTokenResponse;

            if (isBiometricAlertsTurnedOn) {
                setTeladocSdkToken(getTdocAuthToken());
                setShowWebSdk(true);
                setShowMedOptNextButton(false);
                formRefWeb.current
                    ?.querySelector('#medOptNextButton')
                    ?.classList.add(css.hideMedOptNextButton);
            } else {
                try {
                    createTeladocSdkTokenResponse =
                        await createTeladocSdkToken();

                    const {authToken, teladocSdkInfo, failureReason} =
                        createTeladocSdkTokenResponse;

                    // fallback scenario for the old createTeladocSdkTokenResponse
                    if (authToken && !teladocSdkInfo) {
                        sdkTokenInfo = {
                            authToken,
                            healthCategory: 'Primary Care',
                            specialtyFlavor: 'medopt',
                        };
                    } else {
                        sdkTokenInfo = {...teladocSdkInfo};
                    }

                    setShowMedOptNextButton(false);
                    formRefWeb.current
                        ?.querySelector('#medOptNextButton')
                        ?.classList.add(css.hideMedOptNextButton);

                    if (failureReason) {
                        throw failureReason;
                    } else {
                        setTeladocSdkToken(sdkTokenInfo?.authToken);
                        setWebSdkInitialState({
                            // eslint-disable-next-line
                            health_category: sdkTokenInfo?.healthCategory,
                            // eslint-disable-next-line
                            specialty_flavor: sdkTokenInfo?.specialtyFlavor,
                        });
                        setShowWebSdk(true);
                    }
                } catch (error) {
                    setIsLoading(false);
                    setRegistrationStepLoader(false);
                    mixpanelGeneralError({
                        name: 'initWebSdk.createTeladocToken',
                        location: 'MedOpt.initWebSdk.createTeladocToken',
                        additionalInfo: error,
                    });

                    // set BE back a step
                    try {
                        await getDataForPreviousStep(
                            currentSectionIndex,
                            currentSectionPathname
                        );
                    } catch (navBackError) {
                        mixpanelGeneralError({
                            name: 'getDataForNextStep.createTeladocToken',
                            location: 'MedOpt.gotoNextStep.rollbackNav',
                            additionalInfo: `error code: ${navBackError}`,
                        });
                    }

                    showBadRequestErrorMessage();
                }
            }
        } else {
            setTeladocSdkToken(null);
            setWebSdkInitialState(null);
            setShowWebSdk(false);
            setShowMedOptNextButton(true);
            formRefWeb.current
                ?.querySelector('#medOptNextButton')
                ?.classList.add(css.hideMedOptNextButton);
        }
    };

    /**
     * Call BE API to submit answers.
     * Construct URL and redirect to previous page if good to go back.
     * Page URL will include dynamically generated step id.
     * @returns {void}
     */
    const gotoPreviousStep = async () => {
        // Mixpanel navigation back click event track.
        // mixpanelNavigateBackClick(currentRegistrationStep);
        mixpanelButtonClicked(
            currentRegistrationStep,
            t('buttonsLabel.back'),
            'navigationBack'
        );

        // TODO: Display message if value of forms changed.
        // Show spinning to give visual effect.
        setIsLoading(true);
        // Set flag to signal the step is loaded by app action, not browser action.
        setRegistrationStepLoader(true);

        if (isBiometricAlertsTurnedOn) {
            const flowStageItem = currentBiometricAlertsInfo?.flowStagesList[0];
            const questionCode = flowStageItem?.question?.questionCode;

            // when go back the answer value will always be 'BACK'
            try {
                const updateAlertStageResponse = await updateAlertStage({
                    questionId: {id: questionCode},
                    value: ['BACK'],
                    alertId: flowStageItem?.alertId?.value,
                });
                const {badRequest} = updateAlertStageResponse;

                if (badRequest) {
                    setIsLoading(false);
                    setRegistrationStepLoader(false);
                    mixpanelGeneralError({
                        name: 'updateAlertStage gRPC Error',
                        location: 'BiometricAlerts.updateAlertStage',
                        additionalInfo: badRequest,
                    });
                    showBadRequestErrorMessage();

                    return;
                }
            } catch (e) {
                mixpanelGeneralError({
                    name: 'updateAlertStageResponse',
                    location: 'updateAlertStageResponse',
                    additionalInfo: e,
                });
            }
        }

        let navigateBackResponse;

        try {
            if (isBiometricAlertsTurnedOn) {
                const listFlowStagesResponse = await listFlowStages([3]);

                setCurrentBiometricAlertsInfo(listFlowStagesResponse);

                const flowStageItem = listFlowStagesResponse?.flowStagesList[0];
                const answerCodesList =
                    flowStageItem?.question?.answerCodesList || [];

                if (
                    flowStageItem.alertState ===
                    AlertState.Values.MEDOPT_ELIGIBILITY_CHECK_FAILED
                ) {
                    setIsBiometricBackButtonDisabled(true);
                } else {
                    if (!answerCodesList.includes('BACK')) {
                        setIsBiometricBackButtonDisabled(true);
                    } else {
                        setIsBiometricBackButtonDisabled(false);
                    }
                }

                navigateBackResponse = convertNavigateBackStep(
                    convertBiometricAlertsStep(listFlowStagesResponse, 'back')
                );
            } else {
                const medoptNavigateBackResponse = await getDataForPreviousStep(
                    currentSectionIndex,
                    currentSectionPathname
                );

                navigateBackResponse = convertNavigateBackStep(
                    medoptNavigateBackResponse
                );
            }
        } catch (error) {
            setIsLoading(false);
            setRegistrationStepLoader(false);
            mixpanelGeneralError({
                name: 'getDataForPreviousStep',
                location: 'MedOpt.gotoPreviousStep.getDataForPreviousStep',
                additionalInfo: error,
            });
            showBadRequestErrorMessage();

            return;
        }

        const {beginningOfSectionReached, previousStep, badRequest} =
            navigateBackResponse;

        if (beginningOfSectionReached) {
            // beginning of section reached
            setIsLoading(false);
            setRegistrationStepLoader(false);

            return;
        } else if (previousStep) {
            const {sectionId, stepNumber, numberOfSteps} =
                previousStep.context || {};
            let previousSection = {};
            const {triggerId} = previousStep;

            try {
                await loadWebSdk(triggerId);
            } catch (error) {
                return;
            }

            previousSection = sections.find(
                section => section.id === sectionId
            );

            if (previousSection) {
                const medOptFlavor = pbc ? `&pbc=${pbc}` : '';
                const urlParams = isOneApp ? `?oneapp=true${medOptFlavor}` : '';
                // It is a valid section and construct the URL for previous step.
                const previousStepUrl = `${parentPathname}/${previousSection.pathname}/${numberOfSteps.value}/${stepNumber.value}${urlParams}`;

                if (currentStepIndex === 0) {
                    /*
                     * Handle login back in user case.
                     * Add the new step to the beginning.
                     */
                    stepUrls.unshift(previousStepUrl);
                } else if (previousStepUrl !== stepUrls[currentStepIndex - 1]) {
                    /*
                     * Step URL is different than the one in the registration history.
                     * Replace old one since it is not valid anymore.
                     */
                    stepUrls.splice(currentStepIndex - 1, 1, previousStepUrl);
                }

                // Update registration history to local storage or cookie.
                setRegistrationStepUrls(stepUrls);
                // Do the redirection.
                history.push(previousStepUrl);
                // Update current Registration Step
                setCurrentRegistrationStep(previousStep);
            } else if (badRequest) {
                setIsLoading(false);
                setRegistrationStepLoader(false);
                mixpanelGeneralError({
                    name: 'getDataForPreviousStep',
                    location: 'MedOpt.gotoPreviousStep.getDataForPreviousStep',
                    additionalInfo: badRequest,
                });
                showBadRequestErrorMessage();

                return;
            }
        } else {
            setIsLoading(false);
            setRegistrationStepLoader(false);
            mixpanelGeneralError({
                name: 'getDataForPreviousStep',
                location: 'MedOpt.gotoPreviousStep.getDataForPreviousStep',
                additionalInfo:
                    'Should not happen, previousStep missing from grpc call',
            });
            showBadRequestErrorMessage();

            return;
        }
        setIsLoading(false);
        setRegistrationStepLoader(false);

        // Mixpanel page pass event track.
        mixpanelPagePass(currentRegistrationStep);
    };

    /**
     * Call BE API to submit answers.
     * Construct URL and redirect to next page if good to go next.
     * Page URL will include dynamically generated step id.
     * @returns {void}
     */

    const gotoNextStep = async () => {
        // Mixpanel navigation next click event track.
        // mixpanelNavigateNextClick(currentRegistrationStep);
        mixpanelButtonClicked(
            currentRegistrationStep,
            navigationNextLabel,
            'navigationNext'
        );

        const form = formRef.current;

        if (!form.isValid()) {
            form.animateToInvalid();

            return;
        }

        // Show spinning to give visual effect.
        setIsLoading(true);
        // Set flag to signal the step is loaded by app action, not browser action.
        setRegistrationStepLoader(true);

        let answer;

        // If there are any questions, then get the formData and send to API
        if (currentRegistrationStep.questionsToAsk?.questionsList) {
            let answerHash;
            let answers;
            const questions =
                currentRegistrationStep.questionsToAsk.questionsList;

            try {
                const {...formData} = form.submit();

                answerHash = parseAnswersFromForm(formData);
                answers = parseAnswers(answerHash, questions);
                answer = answers.at(0);
            } catch (err) {
                setIsLoading(false);
                setRegistrationStepLoader(false);
                mixpanelGeneralError({
                    name: 'parseAnswers',
                    location: 'MedOpt.goToNextStep.parseAnswers',
                    additionalInfo: err,
                });
                showBadRequestErrorMessage();

                return;
            }

            const questionIdArray = [];

            // For adding the original user input into the question object
            questions.forEach(question => {
                const qId = question.questionId.id;

                // Save question ID for later validation.
                questionIdArray.push(qId);
            });
        } else {
            answer = {};
            answer = parseContentAnswer(currentRegistrationStep);
        }

        /*
            Validate "None of these apply to me" option
            This option cannot be selected with other options.
        */

        if (
            answer?.questionId?.id === QuestionId.Values.QUALIFY &&
            answer?.type === 'multiSelectEnum' &&
            answer?.value?.length >= 2 &&
            answer?.value.includes('NONE_OF_THESE_APPLY')
        ) {
            setIsLoading(false);
            setRegistrationStepLoader(false);

            const validationErrorsList = [
                {
                    questionId: answer?.questionId,
                    errorTypesList: [{noneOptionOnly: {}}],
                },
            ];

            setQuestionErrors(validationErrorsList);

            return;
        }

        if (answer !== null) {
            if (isBiometricAlertsTurnedOn) {
                try {
                    // when move forward, generate biometricAlerts answer based on previous parsed answer
                    const biometricAlertsAns = generateAnswerForBiometricAlerts(
                        currentBiometricAlertsInfo,
                        answer
                    );

                    // save biometricAlertsAns only for browser Navigation
                    setSavedBiometricAlertsAns(biometricAlertsAns);

                    const updateAlertStageResponse = await updateAlertStage(
                        biometricAlertsAns
                    );
                    const {badRequest} = updateAlertStageResponse;

                    if (badRequest) {
                        setIsLoading(false);
                        setRegistrationStepLoader(false);
                        mixpanelGeneralError({
                            name: 'updateAlertStage gRPC Error',
                            location: 'BiometricAlerts.updateAlertStage',
                            additionalInfo: badRequest,
                        });
                        showBadRequestErrorMessage();

                        return;
                    }
                } catch (e) {
                    mixpanelGeneralError({
                        name: 'updateAlertStageResponse',
                        location: 'updateAlertStageResponse',
                        additionalInfo: e,
                    });
                }
            } else {
                try {
                    const submitAnswersResponse = await submitAnswers(answer);
                    const {badRequest} = submitAnswersResponse;

                    if (badRequest) {
                        setIsLoading(false);
                        setRegistrationStepLoader(false);
                        mixpanelGeneralError({
                            name: 'submitAnswers gRPC Error',
                            location: 'MedOpt.goToNextStep.submitAnswer',
                            additionalInfo: badRequest,
                        });
                        showBadRequestErrorMessage();

                        return;
                    }
                } catch (error) {
                    setIsLoading(false);
                    setRegistrationStepLoader(false);
                    mixpanelGeneralError({
                        name: 'submitAnswers gRPC Error',
                        location: 'MedOpt.goToNextStep.submitAnswer',
                        additionalInfo: error,
                    });

                    showBadRequestErrorMessage();

                    return;
                }
            }

            // Mixpanel page pass event track if no error
            mixpanelPagePass(currentRegistrationStep);
        }

        let navigateNextResponse;

        try {
            if (isBiometricAlertsTurnedOn) {
                const listFlowStagesResponse = await listFlowStages([3]);

                setCurrentBiometricAlertsInfo(listFlowStagesResponse);

                const flowStageItem = listFlowStagesResponse?.flowStagesList[0];
                const answerCodesList =
                    flowStageItem?.question?.answerCodesList || [];

                if (
                    flowStageItem.alertState ===
                    AlertState.Values.MEDOPT_ELIGIBILITY_CHECK_FAILED
                ) {
                    setIsBiometricBackButtonDisabled(true);
                } else {
                    if (!answerCodesList.includes('BACK')) {
                        setIsBiometricBackButtonDisabled(true);
                    } else {
                        setIsBiometricBackButtonDisabled(false);
                    }
                }

                navigateNextResponse = convertNavigateNextStep(
                    convertBiometricAlertsStep(listFlowStagesResponse, 'next')
                );
            } else {
                const medOptNavigateNextResponse = await getDataForNextStep(
                    currentSectionIndex,
                    currentSectionPathname
                );

                navigateNextResponse = convertNavigateNextStep(
                    medOptNavigateNextResponse
                );
            }
        } catch (error) {
            setIsLoading(false);
            setRegistrationStepLoader(false);
            mixpanelGeneralError({
                name: 'getDataForNextStep',
                location: 'MedOpt.gotoNextStep.getDataForNextStep',
                additionalInfo: error,
            });
            showBadRequestErrorMessage();

            return;
        }

        const {nextStep, badRequest} = navigateNextResponse;

        if (nextStep) {
            // mixpanel log page land when there is next step
            mixpanelPageLand(nextStep);
            const {triggerId} = nextStep;
            const {sectionId, stepNumber, numberOfSteps} =
                nextStep.context || {};
            const answersList = [];
            let nextSection = {};

            try {
                await loadWebSdk(triggerId);
            } catch (error) {
                return;
            }

            nextSection = sections.find(section => section.id === sectionId);

            // set regcode if we find it in BE return
            if (answersList) {
                const regCodeQuestion = answersList.find(
                    answerItem =>
                        answerItem.questionId?.id === QuestionId.Values.REG_CODE
                );

                const regCodeAnswer = regCodeQuestion?.value?.text;

                if (regCodeAnswer) {
                    setRegCode(regCodeAnswer);
                }
            }

            const questionsList = nextStep.questionsToAsk?.questionsList || [];
            const validationErrorsList = questionsList.reduce(
                (list, question) => {
                    return list.concat({
                        questionId: question.questionId,
                        errorTypesList: question.validationErrorsList,
                    });
                },
                []
            );

            setQuestionErrors(validationErrorsList);

            if (nextSection) {
                const medOptFlavor = pbc ? `&pbc=${pbc}` : '';
                const urlParams = isOneApp ? `?oneapp=true${medOptFlavor}` : '';
                // It is a valid section and construct the URL for next step.
                const nextStepUrl = `${parentPathname}/${nextSection.pathname}/${numberOfSteps.value}/${stepNumber.value}${urlParams}`;

                stepUrls.splice(currentStepIndex + 1);
                stepUrls.push(nextStepUrl);

                // Update registration history to local storage or cookie.
                setRegistrationStepUrls(stepUrls);
                // Do the redirection.
                history.push(nextStepUrl);
                // Update data.
                setCurrentRegistrationStep(nextStep);
            } else {
                // TODO: Display proper message.
            }
        } else if (badRequest) {
            // TODO: Display proper message.
            setIsLoading(false);
            setRegistrationStepLoader(false);
            mixpanelGeneralError({
                name: 'MedOpt.gotoNextStep',
                location: 'MedOpt.gotoNextStep.getDataForNextStep',
                additionalInfo: badRequest,
            });
            showBadRequestErrorMessage();

            return;
        } else {
            // TODO: should not reach here
            setIsLoading(false);
            setRegistrationStepLoader(false);
            mixpanelGeneralError({
                name: 'MedOpt.gotoNextStep',
                location: 'MedOpt.gotoNextStep.getDataForNextStep',
                additionalInfo: 'grpc return empty response',
            });
            showBadRequestErrorMessage();
        }
        setIsLoading(false);
        setRegistrationStepLoader(false);
    };

    const gotoWebSdkPreviousStep = () => {
        const webSdkBackButton = formRefWeb?.current?.querySelector(
            `.button-left-arrow-web-sdk`
        );

        if (webSdkBackButton) {
            webSdkBackButton.click();
        } else {
            gotoPreviousStep();
        }
    };

    // Navigate among registration steps.
    // Used to keep track of current
    useNavigation(location.pathname, history);

    // For page, use navigateHere to get the correct question and section
    useEffect(() => {
        const loadCurrentStep = async () => {
            setIsLoading(true);
            setRegistrationStepLoader(true);

            try {
                let sectionIndex = currentSectionIndex;
                let found = false;
                let callCount = 0;

                while (!found && callCount < sections.length) {
                    const currentSection = sections[sectionIndex];
                    /* eslint-disable no-await-in-loop */
                    let navigateHereResponse;

                    if (isBiometricAlertsTurnedOn) {
                        const listFlowStagesResponse = await listFlowStages([
                            3,
                        ]);

                        setCurrentBiometricAlertsInfo(listFlowStagesResponse);

                        const flowStageItem =
                            listFlowStagesResponse?.flowStagesList[0];
                        const answerCodesList =
                            flowStageItem?.question?.answerCodesList || [];

                        if (
                            flowStageItem.alertState ===
                            AlertState.Values.MEDOPT_ELIGIBILITY_CHECK_FAILED
                        ) {
                            setIsBiometricBackButtonDisabled(true);
                        } else {
                            if (!answerCodesList.includes('BACK')) {
                                setIsBiometricBackButtonDisabled(true);
                            } else {
                                setIsBiometricBackButtonDisabled(false);
                            }
                        }

                        navigateHereResponse = convertToNavigateHereStep(
                            convertBiometricAlertsStep(
                                listFlowStagesResponse,
                                'next'
                            )
                        );
                    } else {
                        const navigateNextResponse = await navigateNext(
                            sections[sectionIndex].id
                        );

                        navigateHereResponse =
                            convertToNavigateHereStep(navigateNextResponse);
                    }

                    const {currentStep, badRequest, endOfSectionReached} =
                        navigateHereResponse;

                    if (badRequest) {
                        // turn off the loop condition
                        found = true;

                        throw new Error(JSON.stringify(badRequest));
                    }

                    if (currentStep) {
                        found = true;
                        setCurrentRegistrationStep(currentStep);

                        // Mixpanel page land event track.
                        mixpanelPageLand(currentStep);
                        PIWIKUtils.trackPageLand();

                        // Construct the URL
                        const {stepNumber, numberOfSteps} = currentStep.context;
                        const medOptFlavor = pbc ? `&pbc=${pbc}` : '';
                        const urlParams = isOneApp
                            ? `?oneapp=true${medOptFlavor}`
                            : '';
                        const stepUrl = `${parentPathname}/${currentSection.pathname}/${numberOfSteps.value}/${stepNumber.value}${urlParams}`;

                        // if the url is different, set
                        if (location.pathname !== stepUrl) {
                            history.replace(stepUrl);
                        }

                        // if the url was not found, add it to Registration Step Urls
                        if (stepUrls.indexOf(stepUrl) === -1) {
                            stepUrls.push(stepUrl);

                            // Update registration history to local storage or cookie.
                            setRegistrationStepUrls(stepUrls);
                        }

                        const {triggerId} = currentStep;

                        try {
                            await loadWebSdk(triggerId);
                        } catch (errors) {
                            return;
                        }
                    } else if (endOfSectionReached) {
                        // move onto next section
                        sectionIndex++;
                        sectionIndex %= sections.length;
                        callCount++;
                    }
                }
            } catch (error) {
                mixpanelGeneralError({
                    name: 'navigateHere',
                    location: 'MedOpt.getCurrentStepOnLoad',
                    additionalInfo: error,
                });
                showBadRequestErrorMessage();
            } finally {
                setIsLoading(false);
                setRegistrationStepLoader(false);
            }
        };

        loadCurrentStep();

        // TODO: Clean other dependencies.
        // eslint-disable-next-line
    }, []);

    // Got data for page load by forward or backward browser action.
    // Can handle case when use skips
    useEffect(() => {
        // Get flag whether the step load by app or browser action.
        // Also used to skip when navigateHere changes the url onload
        const isLoadFromApp = getRegistrationStepLoader();

        /*
         * For below situations do not need to load data.
         * If step load by app action of next or back button.
         * If current step is not valid.
         * If current section is not valid.
         */
        if (isLoadFromApp) {
            return;
        }

        /**
         * Get section index of the step based on the step index within registration history.
         * @param {number} stepIndex - Index of step within registration history.
         * @returns {number} - Index of section.
         */
        const getSectionIndexByStepIndex = stepIndex => {
            const stepUrl = stepUrls[stepIndex] || '';
            const [, , stepPathname] = stepUrl.split('/');

            return sections.findIndex(s => s.pathname === stepPathname);
        };

        /**
         * Get data for previous or next step or steps.
         * This function is for handling browser actions.
         * @returns {Promise<*>} - Promise.
         */
        const getDataForBackAndForth = async () => {
            let navigateResponse;
            let sectionIndex = getSectionIndexByStepIndex(previousStepIndex);

            setIsLoading(true);
            let triggerId;

            if (previousStepIndex < currentStepIndex) {
                try {
                    for (
                        let i = previousStepIndex,
                            stepIndex = previousStepIndex;
                        i < currentStepIndex;
                        i++, stepIndex++
                    ) {
                        if (isBiometricAlertsTurnedOn) {
                            try {
                                const updateAlertStageResponse =
                                    await updateAlertStage(
                                        savedBiometricAlertsAns
                                    );
                                const {badRequest} = updateAlertStageResponse;

                                if (badRequest) {
                                    setIsLoading(false);
                                    setRegistrationStepLoader(false);
                                    mixpanelGeneralError({
                                        name: 'updateAlertStage gRPC Error',
                                        location:
                                            'BiometricAlerts.updateAlertStage',
                                        additionalInfo: badRequest,
                                    });
                                    showBadRequestErrorMessage();

                                    return;
                                }
                            } catch (e) {
                                mixpanelGeneralError({
                                    name: 'updateAlertStageResponse',
                                    location: 'updateAlertStageResponse',
                                    additionalInfo: e,
                                });
                            }

                            const listFlowStagesResponse = await listFlowStages(
                                [3]
                            );

                            setCurrentBiometricAlertsInfo(
                                listFlowStagesResponse
                            );

                            const flowStageItem =
                                listFlowStagesResponse?.flowStagesList[0];
                            const answerCodesList =
                                flowStageItem?.question?.answerCodesList || [];

                            if (
                                flowStageItem.alertState ===
                                AlertState.Values
                                    .MEDOPT_ELIGIBILITY_CHECK_FAILED
                            ) {
                                setIsBiometricBackButtonDisabled(true);
                            } else {
                                if (!answerCodesList.includes('BACK')) {
                                    setIsBiometricBackButtonDisabled(true);
                                } else {
                                    setIsBiometricBackButtonDisabled(false);
                                }
                            }

                            navigateResponse = convertNavigateNextStep(
                                convertBiometricAlertsStep(
                                    listFlowStagesResponse,
                                    'next'
                                )
                            );
                        } else {
                            navigateResponse = await navigateNext(
                                sections[sectionIndex].id
                            );
                        }

                        const {
                            // unableToGoBack,
                            badRequest,
                            endOfSectionReached,
                            nextStep,
                            // unableToContinue,
                        } = navigateResponse;

                        if (endOfSectionReached) {
                            if (sectionIndex === sections.length) {
                                break;
                            }

                            // Move to next section.
                            sectionIndex++;

                            // Adjust step index.
                            i--;
                        } else if (nextStep) {
                            setCurrentRegistrationStep(nextStep);
                            triggerId = nextStep.triggerId;
                        } else if (badRequest) {
                            throw new Error(JSON.stringify(badRequest));
                        } else {
                            // unableToGoBack || unableToContinue
                            // TODO: error message for user for these cases
                        }
                    }
                } catch (error) {
                    mixpanelGeneralError({
                        name: 'navigateNext (Browser Action)',
                        location:
                            'MedOpt.getDataForBackAndForth inside useEffect([location.pathname])',
                        additionalInfo: error,
                    });
                    showBadRequestErrorMessage();
                } finally {
                    setIsLoading(false);
                    setRegistrationStepLoader(false);
                }
            } else {
                try {
                    for (
                        let i = previousStepIndex,
                            stepIndex = previousStepIndex;
                        i > currentStepIndex;
                        i--, stepIndex--
                    ) {
                        if (isBiometricAlertsTurnedOn) {
                            // when go back the answer value will always be 'BACK'
                            try {
                                const flowStageItem =
                                    currentBiometricAlertsInfo
                                        ?.flowStagesList[0];
                                const questionCode =
                                    flowStageItem?.question?.questionCode;

                                const updateAlertStageResponse =
                                    await updateAlertStage({
                                        questionId: {id: questionCode},
                                        value: ['BACK'],
                                        alertId: flowStageItem?.alertId?.value,
                                    });
                                const {badRequest} = updateAlertStageResponse;

                                if (badRequest) {
                                    setIsLoading(false);
                                    setRegistrationStepLoader(false);
                                    mixpanelGeneralError({
                                        name: 'updateAlertStage gRPC Error',
                                        location:
                                            'BiometricAlerts.updateAlertStage',
                                        additionalInfo: badRequest,
                                    });
                                    showBadRequestErrorMessage();

                                    return;
                                }
                            } catch (e) {
                                mixpanelGeneralError({
                                    name: 'updateAlertStageResponse',
                                    location: 'updateAlertStageResponse',
                                    additionalInfo: e,
                                });
                            }

                            const listFlowStagesResponse = await listFlowStages(
                                [3]
                            );

                            setCurrentBiometricAlertsInfo(
                                listFlowStagesResponse
                            );

                            const flowStageItem =
                                listFlowStagesResponse?.flowStagesList[0];
                            const answerCodesList =
                                flowStageItem?.question?.answerCodesList || [];

                            if (
                                flowStageItem.alertState ===
                                AlertState.Values
                                    .MEDOPT_ELIGIBILITY_CHECK_FAILED
                            ) {
                                setIsBiometricBackButtonDisabled(true);
                            } else {
                                if (!answerCodesList.includes('BACK')) {
                                    setIsBiometricBackButtonDisabled(true);
                                } else {
                                    setIsBiometricBackButtonDisabled(false);
                                }
                            }

                            navigateResponse = convertNavigateBackStep(
                                convertBiometricAlertsStep(
                                    listFlowStagesResponse,
                                    'back'
                                )
                            );
                        } else {
                            navigateResponse = await navigateBack(
                                sections[sectionIndex].id
                            );
                        }

                        const {
                            // unableToGoBack,
                            beginningOfSectionReached,
                            previousStep,
                            badRequest,
                            // unableToContinue,
                        } = navigateResponse;

                        if (beginningOfSectionReached) {
                            if (sectionIndex === 0) {
                                break;
                            }

                            // Move to previous section.
                            sectionIndex--;

                            // Adjust step index.
                            i++;
                        } else if (previousStep) {
                            setCurrentRegistrationStep(previousStep);
                            triggerId = previousStep.triggerId;
                        } else if (badRequest) {
                            throw new Error(JSON.stringify(badRequest));
                        } else {
                            // unableToGoBack || unableToContinue
                            // TODO: error message for user for these cases
                        }
                    }
                } catch (error) {
                    mixpanelGeneralError({
                        name: 'navigateBack (Browser Action)',
                        location:
                            'MedOpt.getDataForBackAndForth inside useEffect([location.pathname])',
                        additionalInfo: error,
                    });
                    showBadRequestErrorMessage();
                } finally {
                    setIsLoading(false);
                    setRegistrationStepLoader(false);
                }
            }
            try {
                await loadWebSdk(triggerId);
                // eslint-disable-next-line
            } catch (error) {}
        };

        if (previousStepIndex !== currentStepIndex) {
            // Load data for previous or next step or steps.
            // TODO: Display message for forward action if value of forms changed.
            getDataForBackAndForth();
        }

        // TODO: Clean other dependencies.
        // eslint-disable-next-line
    }, [location.pathname]);

    // WebSDK css contains global styles
    // Import WebSDK styles dynamically to avoid overriding registration styles
    useEffect(() => {
        import('./MedOptWebSdkContainer.css');
        // import('@td/websdk/dist/index.modern.css');
    }, [showWebSdk]);

    const sectionInfo = currentRegistrationStep;

    // clear section answerList for display, to support browser back buttone.
    if (sectionInfo.context && sectionInfo.context.answersList) {
        sectionInfo.context.answersList = [];
    }

    // Add default and error list for multiSelectEnum to support browser back button.
    if (
        sectionInfo.questionsToAsk &&
        sectionInfo.questionsToAsk.questionsList &&
        sectionInfo.questionsToAsk.questionsList[0] &&
        sectionInfo.questionsToAsk.questionsList[0].dataType &&
        sectionInfo.questionsToAsk.questionsList[0].dataType.multiSelectEnum
    ) {
        sectionInfo.questionsToAsk.questionsList[0].dataType.multiSelectEnum.defaultAnswerCodesList =
            [];
        sectionInfo.questionsToAsk.questionsList[0].validationErrorsList = [];
        sectionInfo.questionsToAsk.nodeTitleId = '';
    }

    const isGetTheApp = sectionInfo.contentId === GET_THE_APP;
    const isIneligible = sectionInfo.contentId === INELIGIBLE;
    const showNextButton = () =>
        !disableNavBtn.nextBtn && !isGetTheApp && !isIneligible;

    const showPrevButton = () =>
        sectionInfo.context &&
        sectionInfo.context.sectionId &&
        /* GET APP SECTION */
        sectionInfo.context.sectionId !== MedOptSectionId.Values.ALL_DONE &&
        /* STARTING POINT - SIGNUP */
        !(
            sectionInfo.context.sectionId === MedOptSectionId.Values.SIGNUP &&
            sectionInfo.context.stepNumber.value === 1
        ) &&
        !showWebSdk &&
        !isBiometricBackButtonDisabled;
    const questionsList = sectionInfo?.questionsToAsk?.questionsList || [];
    const hasInfo = questionsList.some(question => {
        const {questionId} = question;
        const {id = -1} = questionId || {};
        // Get question id in string format by value.
        const questionName = getQuestionNameById(id);
        // Get question UI configuration.

        return questionConfig[questionName]?.info;
    });

    const webSdkSection = WEB_SDK === sectionInfo.triggerId;

    const exitModalId = (() => {
        if (isGetTheApp) {
            return EXIT_ALL_SET;
        }

        return webSdkSection ? EXIT_NOT_SCHEDULED : EXIT_NOT_ENROLLED;
    })();
    const onExitModalClose = () => setShowExitModal(false);
    const downloadTheApp = () => {
        onExitModalClose();
        const isMobileDevice =
            EnvironmentUtils.isMobileDevice() || EnvironmentUtils.isTablet();

        if (isMobileDevice || isOneApp) {
            redirectToDownloadApps(isOneApp);
        } else {
            showMobileAppSmsMessage(sectionInfo.contentId);
        }
    };
    const onExitModalPrimaryAction =
        exitModalId === EXIT_ALL_SET ? downloadTheApp : onExitModalClose;

    const exitWebSdk = () => {
        setShowWebSdk(false);
        gotoNextStep();
    };

    // Hide section title when back button is hidden in OneApp version
    const showSectionTitle = () => {
        const backButtonDisplayed = showPrevButton() || showWebSdk;

        return !(isOneApp && !backButtonDisplayed);
    };

    // redirect to login page, if not authenticated.
    const isAuthenticated = getIsAuthenticated();

    if (!isAuthenticated) {
        window.location.href = getMedOptLoginUrl();

        return;
    }

    return (
        <>
            <IdleTimer
                element={document}
                onIdle={handleIdle}
                debounce={Config.idleTimerDebounce}
                timeout={Config.idleTimerTimeout}
            />
            <div className={cn(css.card)}>
                <main
                    className={cn(
                        css.questionsContainer,
                        {
                            [css.radius]: hasInfo,
                            [css.mobileRadius]: !hasInfo,
                        },
                        {
                            [css.oneAppQuestionsContainer]: isOneApp,
                        }
                    )}
                    role="main"
                >
                    <div className={css.questionsSubContainer}>
                        <div
                            className={css.progressBarRow}
                            ref={progressBarRef}
                        >
                            {showPrevButton() && (
                                <NavigationButton
                                    type="PREVIOUS"
                                    className={css.navigateBackButton}
                                    onClick={gotoPreviousStep}
                                    sectionInfo={sectionInfo}
                                    icon={<IconArrowLeftActive />}
                                    iconPosition="left"
                                    hideLabel
                                    id="medOptBackButton"
                                />
                            )}
                            {showWebSdk && (
                                <NavigationButton
                                    type="PREVIOUS"
                                    className={css.navigateBackButton}
                                    sectionInfo={sectionInfo}
                                    onClick={gotoWebSdkPreviousStep}
                                    icon={<IconArrowLeftActive />}
                                    iconPosition="left"
                                    hideLabel
                                />
                            )}
                            {showSectionTitle() && (
                                <ProgressBar
                                    location={location}
                                    sections={sections}
                                    className={cn(css.progressBarNoBar)}
                                />
                            )}
                            {!isOneApp && (
                                <Button
                                    className={css.closeIcon}
                                    onClick={() => {
                                        setShowExitModal(true);
                                    }}
                                >
                                    <IconCloseDefault />
                                </Button>
                            )}
                        </div>

                        {!isLoading && showWebSdk && (
                            <div ref={formRefWeb}>
                                <MedOptWebSdkContainer
                                    exitWebSdk={exitWebSdk}
                                    path={paths.RAV}
                                    appConfiguration={{
                                        ...getWebSdkApplicationConfiguration(
                                            isOneApp,
                                            getWebSdkBaseUrl()
                                        ),
                                        initialState: getWebSdkInitialState(),
                                    }}
                                    isOneApp={isOneApp}
                                />
                            </div>
                        )}
                        <Form ref={formRef} id="interview" className={css.form}>
                            {isLoading ? (
                                <Loader className="Loader-module-root" />
                            ) : (
                                !showWebSdk &&
                                sectionInfo && (
                                    <Section
                                        sectionInfo={sectionInfo}
                                        validationErrorsList={questionErrors}
                                        isOneApp={isOneApp}
                                    />
                                )
                            )}

                            <div className={css.navigationButtonsContainer}>
                                {showMedOptNextButton &&
                                    showNextButton() &&
                                    !isLoading && (
                                        <NavigationButton
                                            type="NEXT"
                                            className={css.navigateNextButton}
                                            onClick={gotoNextStep}
                                            label={navigationNextLabel}
                                            sectionInfo={sectionInfo}
                                            active
                                            id="medOptNextButton"
                                        />
                                    )}
                            </div>
                        </Form>
                    </div>
                    <PopupModal
                        contentId={exitModalId}
                        isOpen={showExitModal}
                        onRequestClose={onExitModalClose}
                        primaryAction={onExitModalPrimaryAction}
                        secondaryAction={() =>
                            redirectToMemberPortalOrOneAppHomePage(isOneApp)
                        }
                    />
                </main>
            </div>
        </>
    );
};

MedOpt.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default MedOpt;
