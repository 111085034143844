import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import ScreenReader from 'common/screen-reader/ScreenReader';
import {questionConfig} from 'constants/questions';
import Config from 'config';
import css from './ClientConsent.scss';

const {dignityHealthTermsUrl} = Config;

const DignityHealthConsent = ({id, name, defaultValue, required}) => {
    const [userInput, setUserInput] = useState(defaultValue ?? 'NO');
    const {helperText, error, checkboxLabel} = questionConfig[id] || {};
    const {t} = useTranslation('questions');

    const handleChange = event => {
        if (event.target.checked) {
            setUserInput('YES');
        } else {
            setUserInput('NO');
        }
    };

    return (
        <>
            <div className={css.helperTextContainer}>{t(helperText)}</div>
            <Checkbox
                defaultChecked={defaultValue === 'YES'}
                id={id}
                name={name}
                value={userInput}
                label={
                    <Label>
                        <Trans
                            i18nKey="questions:DIGNITY_HEALTH_TOU.label"
                            components={[
                                /* eslint-disable react/jsx-key */
                                <a
                                    href={dignityHealthTermsUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ScreenReader />
                                </a>,
                                /* eslint-enable react/jsx-key */
                            ]}
                        />
                    </Label>
                }
                onChange={handleChange}
                i18nItemLabel={t(checkboxLabel)}
                error={<FormElementError>{t(error)}</FormElementError>}
                required={required}
            />
        </>
    );
};

DignityHealthConsent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool.isRequired,
};

export default DignityHealthConsent;
