export const questionFieldTypes = {
    TEXT_FIELD: 'textField',
    SINGLE_SELECT_ENUM: 'singleSelectEnum',
    MULTI_SELECT_ENUM: 'multiSelectEnum',
    BOOLEAN_FIELD: 'boolField',
    INTEGER_FIELD: 'intField',
    FLOAT_FIELD: 'floatField',
    DATE_FIELD: 'dateField',
    COMPOUND_FIELD: 'compoundField',
    UNION_FIELD: 'unionField',
    PASSWORD_TEXT_FIELD: 'passwordTextField',
};

// TODO: Add autocomplete for all other questions?
export const questionConfig = {
    UNKNOWN: {
        id: 'unknown',
        uiType: 'unknown',
    },
    EVER_HAD_A1C: {
        id: 'everHadA1c',
        title: 'EVER_HAD_A1C.title',
        label: 'EVER_HAD_A1C.label',
        labelForWithNodeTitle: 'EVER_HAD_A1C.labelForWithNodeTitle',
        ariaLabel: 'EVER_HAD_A1C.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'EVER_HAD_A1C.info.title',
            content: 'EVER_HAD_A1C.info.content',
        },
    },
    A1C_LAST_CHECKED_VALUE: {
        id: 'a1cLastCheckedValue',
        uiType: 'a1cValue',
        type: 'number',
        pattern: '[-0-9A-zÀ-ž ]+',
        title: 'A1C_LAST_CHECKED_VALUE.title',
        label: 'A1C_LAST_CHECKED_VALUE.label',
        labelForWithNodeTitle: 'A1C_LAST_CHECKED_VALUE.labelForWithNodeTitle',
        helpNode: 'A1C_LAST_CHECKED_VALUE.helpNode',
        ariaLabel: 'A1C_LAST_CHECKED_VALUE.ariaLabel',
        checkboxLabel: 'A1C_LAST_CHECKED_VALUE.checkboxLabel',
        placeholder: 'A1C_LAST_CHECKED_VALUE.placeholder',
        error: 'common:error.required',
    },
    A1C_LAST_CHECKED_DATE: {
        id: 'a1cLastCheckedDate',
        uiType: 'a1cDate',
        type: 'number',
        pattern: '[-0-9A-zÀ-ž ]+',
        title: 'A1C_LAST_CHECKED_DATE.title',
        label: 'A1C_LAST_CHECKED_DATE.label',
        labelForWithNodeTitle: 'A1C_LAST_CHECKED_DATE.labelForWithNodeTitle',
        ariaLabel: 'A1C_LAST_CHECKED_DATE.ariaLabel',
        groupTitle: 'A1C_LAST_CHECKED_DATE.groupTitle',
        groupLabel: 'A1C_LAST_CHECKED_DATE.groupLabel',
        checkboxLabel: 'A1C_LAST_CHECKED_DATE.checkboxLabel',
        placeholder: 'A1C_LAST_CHECKED_DATE.placeholder',
        error: 'common:error.required',
    },
    A1C_HOME_LAB_KIT_REQUEST: {
        id: 'a1cHomeLabKitRequest',
        title: 'A1C_HOME_LAB_KIT_REQUEST.title',
        label: 'A1C_HOME_LAB_KIT_REQUEST.label',
        labelForWithNodeTitle: 'A1C_HOME_LAB_KIT_REQUEST.labelForWithNodeTitle',
        ariaLabel: 'A1C_HOME_LAB_KIT_REQUEST.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'A1C_HOME_LAB_KIT_REQUEST.info.title',
            content: 'A1C_HOME_LAB_KIT_REQUEST.info.content',
        },
    },
    ADDRESS_LINE1: {
        id: 'addressLine1',
        type: 'text',
        pattern: '[-.0-9A-zÀ-ž #]+',
        autocomplete: 'address-line1',
        title: 'ADDRESS_LINE1.title',
        label: 'ADDRESS_LINE1.label',
        labelForWithNodeTitle: 'ADDRESS_LINE1.labelForWithNodeTitle',
        placeholder: 'ADDRESS_LINE1.placeholder',
        error: 'common:error.required',
        maxError: 'ADDRESS_LINE1.maxError',
    },
    ADDRESS_LINE2: {
        id: 'addressLine2',
        type: 'text',
        pattern: '[-.0-9A-zÀ-ž #]+',
        autocomplete: 'address-line2',
        title: 'ADDRESS_LINE2.title',
        label: 'ADDRESS_LINE2.label',
        labelForWithNodeTitle: 'ADDRESS_LINE2.labelForWithNodeTitle',
        placeholder: 'ADDRESS_LINE2.placeholder',
        optionalText: 'ADDRESS_LINE2.optionalText',
        error: 'common:error.required',
    },
    BIRTH_DATE: {
        id: 'birthDate',
        uiType: 'birthDate',
        autocompleteDay: 'bday-day',
        autocompleteMonth: 'bday-month',
        autocompleteYear: 'bday-year',
        title: 'BIRTH_DATE.title',
        label: 'BIRTH_DATE.label',
        labelForWithNodeTitle: 'BIRTH_DATE.labelForWithNodeTitle',
        childLabelForWithNodeTitle: 'BIRTH_DATE.childLabelForWithNodeTitle',
        ariaLabel: 'BIRTH_DATE.ariaLabel',
        yearAriaLabel: 'BIRTH_DATE.yearAriaLabel',
        monthAriaLabel: 'BIRTH_DATE.monthAriaLabel',
        dayAriaLabel: 'BIRTH_DATE.dayAriaLabel',
        yearPlaceholder: 'BIRTH_DATE.yearPlaceholder',
        monthPlaceholder: 'BIRTH_DATE.monthPlaceholder',
        dayPlaceholder: 'BIRTH_DATE.dayPlaceholder',
        error: 'common:error.required',
    },
    BLOOD_PRESSURE_RANGE: {
        id: 'bloodPressureRange',
        title: 'BLOOD_PRESSURE_RANGE.title',
        label: 'BLOOD_PRESSURE_RANGE.label',
        labelForWithNodeTitle: 'BLOOD_PRESSURE_RANGE.labelForWithNodeTitle',
        placeholder: 'BLOOD_PRESSURE_RANGE.placeholder',
        error: 'common:error.required',
    },
    BP_CHECKS_SELF_REPORTED: {
        id: 'bpChecksSelfReported',
        title: 'BP_CHECKS_SELF_REPORTED.title',
        label: 'BP_CHECKS_SELF_REPORTED.label',
        labelForWithNodeTitle: 'BP_CHECKS_SELF_REPORTED.labelForWithNodeTitle',
        placeholder: 'BP_CHECKS_SELF_REPORTED.placeholder',
        error: 'common:error.required',
    },
    CGM: {
        id: 'cgm',
        title: 'CGM.title',
        label: 'CGM.label',
        labelForWithNodeTitle: 'CGM.labelForWithNodeTitle',
        placeholder: 'CGM.placeholder',
        error: 'common:error.required',
    },
    CITY: {
        id: 'city',
        type: 'text',
        pattern: '[-.0-9A-zÀ-ž #]+',
        autocomplete: 'address-level2',
        title: 'CITY.title',
        label: 'CITY.label',
        labelForWithNodeTitle: 'CITY.labelForWithNodeTitle',
        placeholder: 'CITY.placeholder',
        error: 'common:error.required',
    },
    COUNTRY: {
        id: 'country',
        autocomplete: 'country',
        title: 'COUNTRY.title',
        label: 'COUNTRY.label',
        labelForWithNodeTitle: 'COUNTRY.labelForWithNodeTitle',
        placeholder: 'COUNTRY.placeholder',
        error: 'common:error.required',
    },
    CURRENT_ACTIVITY_LEVEL: {
        id: 'currentActivityLevel',
        title: 'CURRENT_ACTIVITY_LEVEL.title',
        label: 'CURRENT_ACTIVITY_LEVEL.label',
        labelForWithNodeTitle: 'CURRENT_ACTIVITY_LEVEL.labelForWithNodeTitle',
        placeholder: 'CURRENT_ACTIVITY_LEVEL.placeholder',
        error: 'common:error.required',
    },
    DAILY_BGCHECKS: {
        id: 'dailyBgchecks',
        type: 'text',
        title: 'DAILY_BGCHECKS.title',
        label: 'DAILY_BGCHECKS.label',
        labelForWithNodeTitle: 'DAILY_BGCHECKS.labelForWithNodeTitle',
        placeholder: 'DAILY_BGCHECKS.placeholder',
        error: 'common:error.required',
    },
    DAILY_BGCHECKS_SELF_REPORTED: {
        id: 'dailyBgchecksSelfReported',
        type: 'text',
        title: 'DAILY_BGCHECKS_SELF_REPORTED.title',
        label: 'DAILY_BGCHECKS_SELF_REPORTED.label',
        labelForWithNodeTitle:
            'DAILY_BGCHECKS_SELF_REPORTED.labelForWithNodeTitle',
        placeholder: 'DAILY_BGCHECKS_SELF_REPORTED.placeholder',
        error: 'common:error.required',
    },
    DIABETES_DIAGNOSIS_DATE: {
        id: 'diabetesDiagnosisDate',
        uiType: 'birthDate',
        title: 'DIABETES_DIAGNOSIS_DATE.title',
        label: 'DIABETES_DIAGNOSIS_DATE.label',
        labelForWithNodeTitle: 'DIABETES_DIAGNOSIS_DATE.labelForWithNodeTitle',
        placeholder: 'DIABETES_DIAGNOSIS_DATE.placeholder',
        ariaLabel: 'DIABETES_DIAGNOSIS_DATE.ariaLabel',
        yearAriaLabel: 'DIABETES_DIAGNOSIS_DATE.yearAriaLabel',
        monthAriaLabel: 'DIABETES_DIAGNOSIS_DATE.monthAriaLabel',
        dayAriaLabel: 'DIABETES_DIAGNOSIS_DATE.dayAriaLabel',
        yearPlaceholder: 'DIABETES_DIAGNOSIS_DATE.yearPlaceholder',
        monthPlaceholder: 'DIABETES_DIAGNOSIS_DATE.monthPlaceholder',
        dayPlaceholder: 'DIABETES_DIAGNOSIS_DATE.dayPlaceholder',
        error: 'common:error.required',
    },
    DIABETES_MANAGEMENT: {
        id: 'diabetesManagement',
        title: 'DIABETES_MANAGEMENT.title',
        label: 'DIABETES_MANAGEMENT.label',
        labelForWithNodeTitle: 'DIABETES_MANAGEMENT.labelForWithNodeTitle',
        placeholder: 'DIABETES_MANAGEMENT.placeholder',
        error: 'common:error.required',
    },
    DIABETES_TYPE: {
        uiType: 'radioCard',
        id: 'diabetesType',
        title: 'DIABETES_TYPE.title',
        labelForWithNodeTitle: 'DIABETES_TYPE.labelForWithNodeTitle',
        error: 'common:error.required',
        itemListContents: {
            Type1: {
                content1: 'DIABETES_TYPE.itemListContents.Type1.content1',
                content2: 'DIABETES_TYPE.itemListContents.Type1.content2',
                content3: 'DIABETES_TYPE.itemListContents.Type1.content3',
            },
            Type2: {
                content1: 'DIABETES_TYPE.itemListContents.Type2.content1',
                content2: 'DIABETES_TYPE.itemListContents.Type2.content2',
                content3: 'DIABETES_TYPE.itemListContents.Type2.content3',
            },
        },
    },
    EATING_HABITS_PAST_WEEK: {
        id: 'eatingHabitsPastWeek',
        type: 'text',
        title: 'EATING_HABITS_PAST_WEEK.title',
        label: 'EATING_HABITS_PAST_WEEK.label',
        labelForWithNodeTitle: 'EATING_HABITS_PAST_WEEK.labelForWithNodeTitle',
        placeholder: 'EATING_HABITS_PAST_WEEK.placeholder',
        error: 'common:error.required',
    },
    EMAIL: {
        id: 'email',
        type: 'text',
        pattern:
            '^(([a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+(.[a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+)*)|(".+"))@(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,})$',
        autocomplete: 'email',
        title: 'EMAIL.title',
        titleOneApp: 'EMAIL.titleOneApp',
        label: 'EMAIL.label',
        groupTitle: 'EMAIL.groupTitle',
        groupLabel: 'EMAIL.groupLabel',
        labelForWithNodeTitle: 'EMAIL.labelForWithNodeTitle',
        placeholder: 'EMAIL.placeholder',
        error: 'EMAIL.error-email-format',
    },
    ENROLLMENT_MOTIVATION: {
        id: 'enrollmentSource',
        title: 'ENROLLMENT_MOTIVATION.title',
        label: 'ENROLLMENT_MOTIVATION.label',
        labelForWithNodeTitle: 'ENROLLMENT_MOTIVATION.labelForWithNodeTitle',
        ariaLabel: 'ENROLLMENT_MOTIVATION.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'ENROLLMENT_MOTIVATION.info.title',
            content: 'ENROLLMENT_MOTIVATION.info.content',
        },
    },
    ENROLLMENT_SOURCE: {
        id: 'enrollmentSource',
        title: 'ENROLLMENT_SOURCE.title',
        label: 'ENROLLMENT_SOURCE.label',
        labelForWithNodeTitle: 'ENROLLMENT_SOURCE.labelForWithNodeTitle',
        ariaLabel: 'ENROLLMENT_SOURCE.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'ENROLLMENT_SOURCE.info.title',
            content: 'ENROLLMENT_SOURCE.info.content',
        },
    },
    FIRST_NAME: {
        id: 'firstName',
        type: 'text',
        pattern: "[-0-9A-zÀ-ž '’.]+",
        autocomplete: 'given-name',
        title: 'FIRST_NAME.title',
        label: 'FIRST_NAME.label',
        labelForWithNodeTitle: 'FIRST_NAME.labelForWithNodeTitle',
        childLabelForWithNodeTitle: 'FIRST_NAME.childLabelForWithNodeTitle',
        placeholder: 'FIRST_NAME.placeholder',
        placeholderForWithNodeTitle: 'FIRST_NAME.placeholderForWithNodeTitle',
        error: 'FIRST_NAME.error-firstname-format',
        info: {
            title: 'FIRST_NAME.info.title',
            content: 'FIRST_NAME.info.content',
        },
    },
    FLU_VACCINE: {
        id: 'fluVaccine',
        title: 'FLU_VACCINE.title',
        label: 'FLU_VACCINE.label',
        labelForWithNodeTitle: 'FLU_VACCINE.labelForWithNodeTitle',
        placeholder: 'FLU_VACCINE.placeholder',
        error: 'common:error.required',
    },
    GENDER: {
        id: 'gender',
        title: 'GENDER.title',
        label: 'GENDER.label',
        labelForWithNodeTitle: 'GENDER.labelForWithNodeTitle',
        ariaLabel: 'GENDER.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'GENDER.info.title',
            content: 'GENDER.info.content',
        },
    },
    GENDER_IDENTITY: {
        id: 'genderIdentity',
        title: 'GENDER_IDENTITY.title',
        label: 'GENDER_IDENTITY.label',
        labelForWithNodeTitle: 'GENDER_IDENTITY.labelForWithNodeTitle',
        ariaLabel: 'GENDER_IDENTITY.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'EDUCATION.info.title',
            content: 'EDUCATION.info.content',
        },
    },
    GENDER_IDENTITY_V2: {
        id: 'genderIdentity',
        uiType: 'genderIdentityV2',
        title: 'GENDER_IDENTITY_V2.title',
        helperText: 'GENDER_IDENTITY_V2.helperText',
        label: 'GENDER_IDENTITY_V2.label',
        labelForWithNodeTitle: 'GENDER_IDENTITY_V2.labelForWithNodeTitle',
        error: 'common:error.required',
    },
    GENDER_IDENTITY_SELECTION: {
        id: 'genderIdentitySelection',
        title: 'GENDER_IDENTITY_SELECTION.title',
        label: 'GENDER_IDENTITY_SELECTION.label',
        labelForWithNodeTitle:
            'GENDER_IDENTITY_SELECTION.labelForWithNodeTitle',
        helpNode: 'GENDER_IDENTITY_SELECTION.helpNode',
        error: 'common:error.required',
    },
    GENDER_IDENTITY_OTHER: {
        id: 'genderIdentityOther',
        title: 'GENDER_IDENTITY_OTHER.title',
        label: 'GENDER_IDENTITY_OTHER.label',
        labelForWithNodeTitle: 'GENDER_IDENTITY_OTHER.labelForWithNodeTitle',
        ariaLabel: 'GENDER_IDENTITY_OTHER.ariaLabel',
        error: 'common:error.required',
    },
    HEIGHT: {
        uiType: 'height',
        id: 'height',
        type: 'text',
        title: 'HEIGHT.title',
        label: 'HEIGHT.label',
        labelForWithNodeTitle: 'HEIGHT.labelForWithNodeTitle',
        error: 'common:error.required',
        ariaLabel: 'HEIGHT.ariaLabel',
        footLabel: 'HEIGHT.footLabel',
        inchLabel: 'HEIGHT.inchLabel',
        footAriaLabel: 'HEIGHT.footAriaLabel',
        inchAriaLabel: 'HEIGHT.inchAriaLabel',
        footPlaceholder: 'HEIGHT.footPlaceholder',
        inchPlaceholder: 'HEIGHT.inchPlaceholder',
        info: {
            title: 'HEIGHT.info.title',
            content: 'HEIGHT.info.content',
        },
    },
    HYPERTENSION_DIAGNOSIS_DATE: {
        id: 'hypertensionDiagnosisDate',
        type: 'text',
        title: 'HYPERTENSION_DIAGNOSIS_DATE.title',
        label: 'HYPERTENSION_DIAGNOSIS_DATE.label',
        labelForWithNodeTitle:
            'HYPERTENSION_DIAGNOSIS_DATE.labelForWithNodeTitle',
        placeholder: 'HYPERTENSION_DIAGNOSIS_DATE.placeholder',
        error: 'common:error.required',
    },
    HYPERTENSION_MEDS: {
        id: 'hypertensionMeds',
        title: 'HYPERTENSION_MEDS.title',
        label: 'HYPERTENSION_MEDS.label',
        labelForWithNodeTitle: 'HYPERTENSION_MEDS.labelForWithNodeTitle',
        error: 'common:error.required',
    },
    DIABETES_MEDS: {
        uiType: 'checkboxCard',
        id: 'diabetesMeds',
        title: 'DIABETES_MEDS.title',
        labelForWithNodeTitle: 'DIABETES_MEDS.labelForWithNodeTitle',
        error: 'common:error.required',
        itemListContents: {
            ORAL_MEDICATIONS: {
                content:
                    'DIABETES_MEDS.itemListContents.ORAL_MEDICATIONS.content',
                contentOneApp:
                    'DIABETES_MEDS.itemListContents.ORAL_MEDICATIONS.contentOneApp',
            },
            ORAL_MEDICATIONS_1: {
                content:
                    'DIABETES_MEDS.itemListContents.ORAL_MEDICATIONS_1.content',
                contentOneApp:
                    'DIABETES_MEDS.itemListContents.ORAL_MEDICATIONS_1.contentOneApp',
            },
            INSULIN: {
                content: 'DIABETES_MEDS.itemListContents.INSULIN.content',
            },
        },
    },
    INSULIN_PUMP: {
        id: 'insulinPump',
        title: 'INSULIN_PUMP.title',
        label: 'INSULIN_PUMP.label',
        labelForWithNodeTitle: 'INSULIN_PUMP.labelForWithNodeTitle',
        placeholder: 'INSULIN_PUMP.placeholder',
        error: 'common:error.required',
    },
    INSURER_CODE: {
        id: 'insurerCode',
        type: 'text',
        title: 'INSURER_CODE.title',
        label: 'INSURER_CODE.label',
        labelForWithNodeTitle: 'INSURER_CODE.labelForWithNodeTitle',
        ariaLabel: 'INSURER_CODE.ariaLabel',
        placeholder: 'INSURER_CODE.placeholder',
        error: 'common:error.required',
        info: {
            title: 'INSURER_CODE.info.title',
            content: 'INSURER_CODE.info.content',
        },
    },
    INSURER_NAME: {
        id: 'insurerName',
        type: 'text',
        title: 'INSURER_NAME.title',
        label: 'INSURER_NAME.label',
        labelForWithNodeTitle: 'INSURER_NAME.labelForWithNodeTitle',
        ariaLabel: 'INSURER_NAME.ariaLabel',
        placeholder: 'INSURER_NAME.placeholder',
        error: 'common:error.required',
        info: {
            title: 'INSURER_NAME.info.title',
            content: 'INSURER_NAME.info.content',
        },
    },
    LAST_NAME: {
        id: 'lastName',
        type: 'text',
        pattern: "[-0-9A-zÀ-ž '’.]+",
        autocomplete: 'family-name',
        title: 'LAST_NAME.title',
        label: 'LAST_NAME.label',
        labelForWithNodeTitle: 'LAST_NAME.labelForWithNodeTitle',
        childLabelForWithNodeTitle: 'LAST_NAME.childLabelForWithNodeTitle',
        placeholder: 'LAST_NAME.placeholder',
        placeholderForWithNodeTitle: 'LAST_NAME.placeholderForWithNodeTitle',
        error: 'LAST_NAME.error-lastname-format',
    },
    LDL_CHOLESTEROL_RANGE: {
        id: 'ldlCholesterolRange',
        title: 'LDL_CHOLESTEROL_RANGE.title',
        label: 'LDL_CHOLESTEROL_RANGE.label',
        labelForWithNodeTitle: 'LDL_CHOLESTEROL_RANGE.labelForWithNodeTitle',
        placeholder: 'LDL_CHOLESTEROL_RANGE.placeholder',
        error: 'common:error.required',
    },
    LEARN_ABOUT_DIET_PRIORITY: {
        id: 'learnAboutDietPriority',
        title: 'LEARN_ABOUT_DIET_PRIORITY.title',
        label: 'LEARN_ABOUT_DIET_PRIORITY.label',
        labelForWithNodeTitle:
            'LEARN_ABOUT_DIET_PRIORITY.labelForWithNodeTitle',
        placeholder: 'LEARN_ABOUT_DIET_PRIORITY.placeholder',
        error: 'common:error.required',
    },
    MEDICAL_RECORD_NUMBER: {
        id: 'medicalRecordNumber',
        type: 'text',
        title: 'MEDICAL_RECORD_NUMBER.title',
        label: 'MEDICAL_RECORD_NUMBER.label',
        labelForWithNodeTitle: 'MEDICAL_RECORD_NUMBER.labelForWithNodeTitle',
        placeholder: 'MEDICAL_RECORD_NUMBER.placeholder',
        error: 'common:error.required',
    },
    PASSWORD: {
        id: 'password',
        uiType: 'password',
        autocomplete: 'new-password',
        title: 'PASSWORD.title',
        label: 'PASSWORD.label',
        labelForWithNodeTitle: 'PASSWORD.labelForWithNodeTitle',
        ariaLabel: 'PASSWORD.ariaLabel',
        placeholder: 'PASSWORD.placeholder',
        criteria1: 'PASSWORD.criteria1',
        criteria2: 'PASSWORD.criteria2',
        criteria3: 'PASSWORD.criteria3',
        criteria4: 'PASSWORD.criteria4',
        criteria5: 'PASSWORD.criteria5',
        error: 'common:error.required',
        formGroupErrorTitle: 'PASSWORD.formGroupErrorTitle',
        info: {
            title: 'PASSWORD.info.title',
            content: 'PASSWORD.info.content',
        },
    },
    PHARMACY_MEMBER_ID: {
        id: 'pharmacyMemberId',
        type: 'text',
        title: 'PHARMACY_MEMBER_ID.title',
        label: 'PHARMACY_MEMBER_ID.label',
        labelForWithNodeTitle: 'PHARMACY_MEMBER_ID.labelForWithNodeTitle',
        placeholder: 'PHARMACY_MEMBER_ID.placeholder',
        ariaLabel: 'PHARMACY_MEMBER_ID.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'PHARMACY_MEMBER_ID.info.title',
            content: 'PHARMACY_MEMBER_ID.info.content',
        },
    },
    PHARMACY_GROUP_ID: {
        id: 'pharmacyGroupId',
        type: 'text',
        title: 'PHARMACY_GROUP_ID.title',
        label: 'PHARMACY_GROUP_ID.label',
        labelForWithNodeTitle: 'PHARMACY_GROUP_ID.labelForWithNodeTitle',
        placeholder: 'PHARMACY_GROUP_ID.placeholder',
        error: 'common:error.required',
    },
    PHONE_HOME: {
        id: 'phoneHome',
        uiType: 'phoneInput',
        type: 'text',
        title: 'PHONE_HOME.title',
        label: 'PHONE_HOME.label',
        labelForWithNodeTitle: 'PHONE_HOME.labelForWithNodeTitle',
        placeholder: 'PHONE_HOME.placeholder',
        error: 'common:error.required',
    },
    PHONE_MOBILE: {
        id: 'phoneMobile',
        uiType: 'phoneInput',
        type: 'number',
        autocomplete: 'tel-national',
        title: 'PHONE_MOBILE.title',
        titleForMultipleQuestion: 'PHONE_MOBILE.titleForMultipleQuestion',
        label: 'PHONE_MOBILE.label',
        labelForWithNodeTitle: 'PHONE_MOBILE.labelForWithNodeTitle',
        labelForMultipleQuestion: 'PHONE_MOBILE.labelForMultipleQuestion',
        ariaLabel: 'PHONE_MOBILE.ariaLabel',
        placeholder: 'PHONE_MOBILE.placeholder',
        placeholderForWithNodeTitle: 'PHONE_MOBILE.placeholderForWithNodeTitle',
        placeholderForMultipleQuestion:
            'PHONE_MOBILE.placeholderForMultipleQuestion',
        error: 'PHONE_MOBILE.error-phone-valid',
    },
    PNEUMONIA_VACCINE: {
        id: 'pneumoniaVaccine',
        title: 'PNEUMONIA_VACCINE.title',
        label: 'PNEUMONIA_VACCINE.label',
        labelForWithNodeTitle: 'PNEUMONIA_VACCINE.labelForWithNodeTitle',
        error: 'common:error.required',
    },
    POLICY_HOLDER_BIRTH_DATE: {
        id: 'policyHolderBirthDate',
        uiType: 'birthDate',
        autocompleteDay: 'ph-bday-day',
        autocompleteMonth: 'ph-bday-month',
        autocompleteYear: 'ph-bday-year',
        title: 'POLICY_HOLDER_BIRTH_DATE.title',
        label: 'POLICY_HOLDER_BIRTH_DATE.label',
        labelForWithNodeTitle: 'POLICY_HOLDER_BIRTH_DATE.labelForWithNodeTitle',
        ariaLabel: 'POLICY_HOLDER_BIRTH_DATE.ariaLabel',
        yearAriaLabel: 'POLICY_HOLDER_BIRTH_DATE.yearAriaLabel',
        monthAriaLabel: 'POLICY_HOLDER_BIRTH_DATE.monthAriaLabel',
        dayAriaLabel: 'POLICY_HOLDER_BIRTH_DATE.dayAriaLabel',
        yearPlaceholder: 'POLICY_HOLDER_BIRTH_DATE.yearPlaceholder',
        monthPlaceholder: 'POLICY_HOLDER_BIRTH_DATE.monthPlaceholder',
        dayPlaceholder: 'POLICY_HOLDER_BIRTH_DATE.dayPlaceholder',
        error: 'common:error.required',
    },
    POLICY_HOLDER_FIRST_NAME: {
        id: 'policyHolderFirstName',
        type: 'text',
        pattern: "[-0-9A-zÀ-ž '’.]+",
        groupTitle: 'POLICY_HOLDER_FIRST_NAME.groupTitle',
        groupLabel: 'POLICY_HOLDER_FIRST_NAME.groupLabel',
        title: 'POLICY_HOLDER_FIRST_NAME.title',
        label: 'POLICY_HOLDER_FIRST_NAME.label',
        labelForWithNodeTitle: 'POLICY_HOLDER_FIRST_NAME.labelForWithNodeTitle',
        placeholder: 'POLICY_HOLDER_FIRST_NAME.placeholder',
        placeholderForWithNodeTitle:
            'POLICY_HOLDER_FIRST_NAME.placeholderForWithNodeTitle',
        error: 'common:error.required',
        info: {
            title: 'POLICY_HOLDER_INFO.info.title',
            content: 'POLICY_HOLDER_INFO.info.content',
        },
    },
    POLICY_HOLDER_GROUP_ID: {
        id: 'policyHolderGroupId',
        type: 'text',
        title: 'POLICY_HOLDER_GROUP_ID.title',
        label: 'POLICY_HOLDER_GROUP_ID.label',
        labelForWithNodeTitle: 'POLICY_HOLDER_GROUP_ID.labelForWithNodeTitle',
        ariaLabel: 'POLICY_HOLDER_GROUP_ID.ariaLabel',
        placeholder: 'POLICY_HOLDER_GROUP_ID.placeholder',
        error: 'common:error.required',
    },
    POLICY_HOLDER_LAST_NAME: {
        id: 'policyHolderLastName',
        type: 'text',
        title: 'POLICY_HOLDER_LAST_NAME.title',
        pattern: "[-0-9A-zÀ-ž '’.]+",
        label: 'POLICY_HOLDER_LAST_NAME.label',
        labelForWithNodeTitle: 'POLICY_HOLDER_LAST_NAME.labelForWithNodeTitle',
        placeholder: 'POLICY_HOLDER_LAST_NAME.placeholder',
        placeholderForWithNodeTitle:
            'POLICY_HOLDER_LAST_NAME.placeholderForWithNodeTitle',
        error: 'common:error.required',
    },
    POLICY_HOLDER_MEMBER_ID: {
        id: 'policyHolderMemberId',
        type: 'text',
        pattern: '[-.#_a-zA-Z0-9]+',
        title: 'POLICY_HOLDER_MEMBER_ID.title',
        label: 'POLICY_HOLDER_MEMBER_ID.label',
        chunk1Prefix: 'POLICY_HOLDER_MEMBER_ID.chunk1Prefix',
        chunk2Prefix: 'POLICY_HOLDER_MEMBER_ID.chunk2Prefix',
        chunkLast: 'POLICY_HOLDER_MEMBER_ID.chunkLast',
        labelForWithNodeTitle: 'POLICY_HOLDER_MEMBER_ID.labelForWithNodeTitle',
        ariaLabel: 'POLICY_HOLDER_MEMBER_ID.ariaLabel',
        placeholder: 'POLICY_HOLDER_MEMBER_ID.placeholder',
        error: 'common:error.format',
        info: {
            title: 'POLICY_HOLDER_MEMBER_ID.info.title',
            content: 'POLICY_HOLDER_MEMBER_ID.info.content',
        },
    },
    POLICY_HOLDER_MEMBER_ID_FEPBLUE: {
        id: 'policyHolderMemberId',
        type: 'text',
        pattern: '^(R|r){1}[0-9]{8}$',
        title: 'POLICY_HOLDER_MEMBER_ID.title-fepblue',
        label: 'POLICY_HOLDER_MEMBER_ID.label',
        labelForWithNodeTitle:
            'POLICY_HOLDER_MEMBER_ID.labelForWithNodeTitle-fepblue',
        ariaLabel: 'POLICY_HOLDER_MEMBER_ID.ariaLabel',
        placeholder: 'POLICY_HOLDER_MEMBER_ID.placeholder',
        error: 'common:error.format',
        info: {
            title: 'POLICY_HOLDER_MEMBER_ID.info.title',
            content: 'POLICY_HOLDER_MEMBER_ID.info.content',
        },
    },
    POLICY_HOLDER_NAME: {
        id: 'policyHolderName',
        type: 'text',
        title: 'POLICY_HOLDER_NAME.title',
        label: 'POLICY_HOLDER_NAME.label',
        labelForWithNodeTitle: 'POLICY_HOLDER_NAME.labelForWithNodeTitle',
        ariaLabel: 'POLICY_HOLDER_NAME.ariaLabel',
        placeholder: 'POLICY_HOLDER_NAME.placeholder',
        error: 'common:error.required',
        info: {
            title: 'POLICY_HOLDER_NAME.info.title',
            content: 'POLICY_HOLDER_NAME.info.content',
        },
    },
    POLICY_HOLDER_RELATIONSHIP: {
        id: 'policyHolderRelationship',
        title: 'POLICY_HOLDER_RELATIONSHIP.title',
        label: 'POLICY_HOLDER_RELATIONSHIP.label',
        labelForWithNodeTitle:
            'POLICY_HOLDER_RELATIONSHIP.labelForWithNodeTitle',
        error: 'common:error.required',
    },
    RACE: {
        id: 'race',
        groupTitle: 'RACE.groupTitle',
        groupLabel: 'RACE.groupLabel',
        title: 'RACE.title',
        label: 'RACE.label',
        labelForWithNodeTitle: 'RACE.labelForWithNodeTitle',
        placeholder: 'RACE.placeholder',
        error: 'common:error.required',
    },
    RACE_V2: {
        id: 'race',
        uiType: 'raceV2',
        title: 'RACE_V2.title',
        label: 'RACE_V2.label',
        labelForWithNodeTitle: 'RACE_V2.labelForWithNodeTitle',
        error: 'common:error.required',
    },
    RACE_SELECTION: {
        id: 'raceSelection',
        title: 'RACE_SELECTION.title',
        label: 'RACE_SELECTION.label',
        labelForWithNodeTitle: 'RACE_SELECTION.labelForWithNodeTitle',
        helpNode: 'RACE_SELECTION.helpNode',
        error: 'common:error.required',
    },
    RACE_OTHER: {
        id: 'raceOther',
        title: 'RACE_OTHER.title',
        label: 'RACE_OTHER.label',
        labelForWithNodeTitle: 'RACE_OTHER.labelForWithNodeTitle',
        ariaLabel: 'RACE_OTHER.ariaLabel',
        error: 'common:error.required',
    },
    ETHNICITY: {
        id: 'ethnicity',
        type: 'text',
        title: 'ETHNICITY.title',
        label: 'ETHNICITY.label',
        labelForWithNodeTitle: 'ETHNICITY.labelForWithNodeTitle',
        groupTitle: 'ETHNICITY.groupTitle',
        groupLabel: 'ETHNICITY.groupLabel',
        placeholder: 'ETHNICITY.placeholder',
        error: 'common:error.required',
    },
    SMOKER: {
        id: 'smoker',
        title: 'SMOKER.title',
        label: 'SMOKER.label',
        labelForWithNodeTitle: 'SMOKER.labelForWithNodeTitle',
        placeholder: 'SMOKER.placeholder',
        error: 'common:error.required',
    },
    SSN4: {
        id: 'ssn4',
        type: 'text',
        title: 'SSN4.title',
        label: 'SSN4.label',
        labelForWithNodeTitle: 'SSN4.labelForWithNodeTitle',
        ariaLabel: 'SSN4.ariaLabel',
        placeholder: 'SSN4.placeholder',
        error: 'SSN4.error-format',
        info: {
            title: 'SSN4.info.title',
            content: 'SSN4.info.content',
        },
    },
    STATE: {
        id: 'state',
        uiType: 'select',
        title: 'STATE.title',
        label: 'STATE.label',
        labelForWithNodeTitle: 'STATE.labelForWithNodeTitle',
        placeholder: 'STATE.placeholder',
        error: 'common:error.required',
        translateOptions: false,
        autocomplete: 'address-level1',
    },
    USER_ADDRESS_TYPE: {
        id: 'userAddressType',
        title: 'USER_ADDRESS_TYPE.title',
        label: 'USER_ADDRESS_TYPE.label',
        labelForWithNodeTitle: 'USER_ADDRESS_TYPE.labelForWithNodeTitle',
        placeholder: 'USER_ADDRESS_TYPE.placeholder',
        error: 'common:error.required',
    },
    LIFESTYLE_CHANGE_DESIRED: {
        id: 'lifestyleChangeDesired',
        title: 'LIFESTYLE_CHANGE_DESIRED.title',
        label: 'LIFESTYLE_CHANGE_DESIRED.label',
        labelForWithNodeTitle: 'LIFESTYLE_CHANGE_DESIRED.labelForWithNodeTitle',
        placeholder: 'LIFESTYLE_CHANGE_DESIRED.placeholder',
        error: 'common:error.required',
    },
    WEIGHT: {
        id: 'weight',
        uiType: 'weight',
        type: 'number',
        title: 'WEIGHT.title',
        label: 'WEIGHT.label',
        labelForWithNodeTitle: 'WEIGHT.labelForWithNodeTitle',
        ariaLabel: 'WEIGHT.ariaLabel',
        placeholder: 'WEIGHT.placeholder',
        error: 'common:error.required',
    },
    ZIP: {
        id: 'zip',
        uiType: 'zip',
        type: 'text',
        autocomplete: 'postal-code',
        title: 'ZIP.title',
        label: 'ZIP.label',
        label1: 'ZIP.label1',
        labelForWithNodeTitle: 'ZIP.labelForWithNodeTitle',
        childLabelForWithNodeTitle: 'ZIP.childLabelForWithNodeTitle',
        ariaLabel: 'ZIP.ariaLabel',
        placeholder: 'ZIP.placeholder',
        error: 'ZIP.error-zip-empty',
    },
    MOUNTSINAI_DIABETES_ALLIANCE: {
        uiType: 'mountsinaiDiabetesAlliance',
        id: 'mountsinaiDiabetesAlliance',
        title: 'MOUNTSINAI_DIABETES_ALLIANCE.title',
        labelForWithNodeTitle:
            'MOUNTSINAI_DIABETES_ALLIANCE.labelForWithNodeTitle',
        helperText: 'MOUNTSINAI_DIABETES_ALLIANCE.helperText',
        trigger: 'MOUNTSINAI_DIABETES_ALLIANCE.trigger',
        content: 'MOUNTSINAI_DIABETES_ALLIANCE.content',
        label: 'MOUNTSINAI_DIABETES_ALLIANCE.label',
        error: 'common:error.required',
    },
    MOUNTSINAI_ER_DATA: {
        id: 'mountsinaiErData',
        title: 'MOUNTSINAI_ER_DATA.title',
        label: 'MOUNTSINAI_ER_DATA.label',
        labelForWithNodeTitle: 'MOUNTSINAI_ER_DATA.labelForWithNodeTitle',
        placeholder: 'MOUNTSINAI_ER_DATA.placeholder',
        error: 'common:error.required',
    },
    DIGNITY_HEALTH_TOU: {
        uiType: 'dignityHealthTou',
        id: 'dignityHealthTou',
        title: 'DIGNITY_HEALTH_TOU.title',
        labelForWithNodeTitle: 'DIGNITY_HEALTH_TOU.labelForWithNodeTitle',
        helperText: 'DIGNITY_HEALTH_TOU.helperText',
        label: 'DIGNITY_HEALTH_TOU.label',
        error: 'common:error.required',
    },
    UMASS_BG_DATA: {
        uiType: 'umassBgData',
        id: 'umassBgData',
        title: 'UMASS_BG_DATA.title',
        labelForWithNodeTitle: 'UMASS_BG_DATA.labelForWithNodeTitle',
        label: 'UMASS_BG_DATA.label',
        helperText: 'UMASS_BG_DATA.helperText',
        error: 'common:error.required',
    },
    LOCAL99_TOU: {
        uiType: 'local99Tou',
        id: 'local99Tou',
        title: 'LOCAL99_TOU.title',
        labelForWithNodeTitle: 'LOCAL99_TOU.labelForWithNodeTitle',
        helperText: 'LOCAL99_TOU.helperText',
        trigger: 'LOCAL99_TOU.trigger',
        content1: 'LOCAL99_TOU.content1',
        content2: 'LOCAL99_TOU.content2',
        content3: 'LOCAL99_TOU.content3',
        content4: 'LOCAL99_TOU.content4',
        content5: 'LOCAL99_TOU.content5',
        label: 'LOCAL99_TOU.label',
        error: 'common:error.required',
    },
    LOCATION: {
        id: 'location',
        uiType: 'location',
        title: 'LOCATION.title',
        labelForWithNodeTitle: 'LOCATION.labelForWithNodeTitle',
        label: 'LOCATION.label',
        placeholder: 'LOCATION.placeholder',
        error: 'common:error.required',
    },
    LIVONGO_POINT_OF_CONTACT: {
        id: 'livongoPointOfContact',
        type: 'text',
        title: 'LIVONGO_POINT_OF_CONTACT.title',
        label: 'LIVONGO_POINT_OF_CONTACT.label',
        labelForWithNodeTitle: 'LIVONGO_POINT_OF_CONTACT.labelForWithNodeTitle',
        ariaLabel: 'LIVONGO_POINT_OF_CONTACT.ariaLabel',
        placeholder: 'LIVONGO_POINT_OF_CONTACT.placeholder',
        error: 'common:error.required',
    },
    EMPLOYEE_ID: {
        id: 'employeeId',
        type: 'text',
        title: 'EMPLOYEE_ID.title',
        label: 'EMPLOYEE_ID.label',
        labelForWithNodeTitle: 'EMPLOYEE_ID.labelForWithNodeTitle',
        ariaLabel: 'EMPLOYEE_ID.ariaLabel',
        placeholder: 'EMPLOYEE_ID.placeholder',
        error: 'common:error.required',
        info: {
            title: 'EMPLOYEE_ID.info.title',
            content: 'EMPLOYEE_ID.info.content',
        },
    },
    ARE_YOU_PREGNANT: {
        id: 'areYouPregnant',
        title: 'ARE_YOU_PREGNANT.title',
        label: 'ARE_YOU_PREGNANT.label',
        labelForWithNodeTitle: 'ARE_YOU_PREGNANT.labelForWithNodeTitle',
        error: 'common:error.required',
        info: {
            title: 'ARE_YOU_PREGNANT.info.title',
            content: 'ARE_YOU_PREGNANT.info.content',
        },
    },
    EVER_DIAGNOSED_WITH_DIABETES: {
        id: 'everDiagnosedWithDiabetes',
        title: 'EVER_DIAGNOSED_WITH_DIABETES.title',
        label: 'EVER_DIAGNOSED_WITH_DIABETES.label',
        labelForWithNodeTitle:
            'EVER_DIAGNOSED_WITH_DIABETES.labelForWithNodeTitle',
        ariaLabel: 'EVER_DIAGNOSED_WITH_DIABETES.ariaLabel',
        error: 'common:error.required',
        info: {
            content: 'EVER_DIAGNOSED_WITH_DIABETES.info.content',
        },
    },
    EVER_DIAGNOSED_WITH_PRE_DIABETES: {
        id: 'everDiagnosedWithPreDiabetes',
        title: 'EVER_DIAGNOSED_WITH_PRE_DIABETES.title',
        label: 'EVER_DIAGNOSED_WITH_PRE_DIABETES.label',
        labelForWithNodeTitle:
            'EVER_DIAGNOSED_WITH_PRE_DIABETES.labelForWithNodeTitle',
        ariaLabel: 'EVER_DIAGNOSED_WITH_PRE_DIABETES.ariaLabel',
        error: 'common:error.required',
    },
    BLOOD_TEST_PREDIABETES: {
        id: 'bloodTestPrediabetes',
        uiType: 'bloodTest',
        title: 'BLOOD_TEST_PREDIABETES.title',
        label: 'BLOOD_TEST_PREDIABETES.label',
        labelForWithNodeTitle: 'BLOOD_TEST_PREDIABETES.labelForWithNodeTitle',
        error: 'common:error.required',
        info: {
            subtitle: 'BLOOD_TEST_PREDIABETES.info.subtitle',
            content1: 'BLOOD_TEST_PREDIABETES.info.content1',
            content2: 'BLOOD_TEST_PREDIABETES.info.content2',
            content3: 'BLOOD_TEST_PREDIABETES.info.content3',
        },
    },
    RELATIVE_HAS_DIABETES: {
        id: 'relativeHasDiabetes',
        title: 'RELATIVE_HAS_DIABETES.title',
        label: 'RELATIVE_HAS_DIABETES.label',
        labelForWithNodeTitle: 'RELATIVE_HAS_DIABETES.labelForWithNodeTitle',
        error: 'common:error.required',
        info: {
            title: 'RELATIVE_HAS_DIABETES.info.title',
            content: 'RELATIVE_HAS_DIABETES.info.content',
        },
    },
    EVER_DIAGNOSED_WITH_GESTATIONAL_DB: {
        id: 'everDiagnosedWithGestationalDb',
        title: 'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.title',
        label: 'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.label',
        labelForWithNodeTitle:
            'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.labelForWithNodeTitle',
        ariaLabel: 'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.info.title',
            content: 'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.info.content',
        },
    },
    EVER_DIAGNOSED_WITH_HYPERTENSION: {
        id: 'everDiagnosedWithHypertension',
        title: 'EVER_DIAGNOSED_WITH_HYPERTENSION.title',
        label: 'EVER_DIAGNOSED_WITH_HYPERTENSION.label',
        labelForWithNodeTitle:
            'EVER_DIAGNOSED_WITH_HYPERTENSION.labelForWithNodeTitle',
        ariaLabel: 'EVER_DIAGNOSED_WITH_HYPERTENSION.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'EVER_DIAGNOSED_WITH_HYPERTENSION.info.title',
            content: 'EVER_DIAGNOSED_WITH_HYPERTENSION.info.content',
        },
    },
    ARE_YOU_ACTIVE: {
        id: 'areYouActive',
        title: 'ARE_YOU_ACTIVE.title',
        label: 'ARE_YOU_ACTIVE.label',
        labelForWithNodeTitle: 'ARE_YOU_ACTIVE.labelForWithNodeTitle',
        error: 'common:error.required',
        info: {
            title: 'ARE_YOU_ACTIVE.info.title',
            content: 'ARE_YOU_ACTIVE.info.content',
            subtitle: 'ARE_YOU_ACTIVE.info.subtitle',
            content1: 'ARE_YOU_ACTIVE.info.content1',
            content2: 'ARE_YOU_ACTIVE.info.content2',
            content3: 'ARE_YOU_ACTIVE.info.content3',
            content4: 'ARE_YOU_ACTIVE.info.content4',
            content5: 'ARE_YOU_ACTIVE.info.content5',
        },
    },
    GOT_SMART_PHONE: {
        id: 'gotSmartPhone',
        title: 'GOT_SMART_PHONE.title',
        label: 'GOT_SMART_PHONE.label',
        labelForWithNodeTitle: 'GOT_SMART_PHONE.labelForWithNodeTitle',
        ariaLabel: 'GOT_SMART_PHONE.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'GOT_SMART_PHONE.info.title',
            content: 'GOT_SMART_PHONE.info.content',
        },
    },
    CONSENT_LABS_DATA_ACCESS: {
        id: 'consentLabsDataAccess',
        uiType: 'labConsent',
        title: 'CONSENT_LABS_DATA_ACCESS.title',
        titleOneApp: 'CONSENT_LABS_DATA_ACCESS.titleOneApp',
        label: 'CONSENT_LABS_DATA_ACCESS.label',
        labelForWithNodeTitle: 'CONSENT_LABS_DATA_ACCESS.labelForWithNodeTitle',
        ariaLabel: 'CONSENT_LABS_DATA_ACCESS.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'CONSENT_LABS_DATA_ACCESS.info.title',
            content: 'CONSENT_LABS_DATA_ACCESS.info.content',
            contentOneApp: 'CONSENT_LABS_DATA_ACCESS.info.contentOneApp',
        },
        legalTextYes: 'CONSENT_LABS_DATA_ACCESS.legalTextYes',
        legalTextNo: 'CONSENT_LABS_DATA_ACCESS.legalTextNo',
        oneAppLegalTextYes: 'CONSENT_LABS_DATA_ACCESS.oneAppLegalTextYes',
        oneAppLegalTextNo: 'CONSENT_LABS_DATA_ACCESS.oneAppLegalTextNo',
    },
    EDUCATION: {
        id: 'education',
        title: 'EDUCATION.title',
        label: 'EDUCATION.label',
        labelForWithNodeTitle: 'EDUCATION.labelForWithNodeTitle',
        ariaLabel: 'EDUCATION.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'EDUCATION.info.title',
            content: 'EDUCATION.info.content',
        },
    },
    SMS_OPT_IN: {
        id: 'smsOptIn',
        uiType: 'sms',
        title: 'SMS_OPT_IN.title',
        label: 'SMS_OPT_IN.label',
        labelForWithNodeTitle: 'SMS_OPT_IN.labelForWithNodeTitle',
        ariaLabel: 'SMS_OPT_IN.ariaLabel',
        error: 'common:error.required',
    },
    LANGUAGE: {
        id: 'language',
        title: 'LANGUAGE.title',
        label: 'LANGUAGE.label',
        labelForWithNodeTitle: 'LANGUAGE.labelForWithNodeTitle',
        placeholder: 'LANGUAGE.placeholder',
        error: 'common:error.required',
    },
    EVER_HAD_VISUAL_IMPAIRMENT: {
        id: 'everHadVisualImpairment',
        title: 'EVER_HAD_VISUAL_IMPAIRMENT.title',
        label: 'EVER_HAD_VISUAL_IMPAIRMENT.label',
        labelForWithNodeTitle:
            'EVER_HAD_VISUAL_IMPAIRMENT.labelForWithNodeTitle',
        placeholder: 'EVER_HAD_VISUAL_IMPAIRMENT.placeholder',
        error: 'common:error.required',
    },
    VISUAL_IMPAIRMENT_TYPE: {
        id: 'visualImpairmentType',
        title: 'VISUAL_IMPAIRMENT_TYPE.title',
        label: 'VISUAL_IMPAIRMENT_TYPE.label',
        labelForWithNodeTitle: 'VISUAL_IMPAIRMENT_TYPE.labelForWithNodeTitle',
        placeholder: 'VISUAL_IMPAIRMENT_TYPE.placeholder',
        error: 'common:error.required',
    },
    PREFER_TO_USE_VOICE_ASSISTANT: {
        id: 'preferToUseVoiceAssistant',
        type: 'text',
        title: 'PREFER_TO_USE_VOICE_ASSISTANT.title',
        label: 'PREFER_TO_USE_VOICE_ASSISTANT.label',
        labelForWithNodeTitle:
            'PREFER_TO_USE_VOICE_ASSISTANT.labelForWithNodeTitle',
        placeholder: 'PREFER_TO_USE_VOICE_ASSISTANT.placeholder',
        error: 'common:error.required',
    },
    LIVONGO_BETA_OPT_IN: {
        id: 'livongoBetaOptIn',
        type: 'text',
        title: 'LIVONGO_BETA_OPT_IN.title',
        label: 'LIVONGO_BETA_OPT_IN.label',
        labelForWithNodeTitle: 'LIVONGO_BETA_OPT_IN.labelForWithNodeTitle',
        placeholder: 'LIVONGO_BETA_OPT_IN.placeholder',
        error: 'common:error.required',
    },
    REG_CODE: {
        id: 'regCode',
        uiType: 'regCode',
        title: 'REG_CODE.title',
        labelForWithNodeTitle: 'REG_CODE.labelForWithNodeTitle',
        ariaLabel: 'REG_CODE.ariaLabel',
        placeholder: 'REG_CODE.placeholder',
        inputHint: 'REG_CODE.inputHint',
        notFoundSponsorLinkText: 'REG_CODE.notFoundSponsor',
        error: 'REG_CODE.error.invalidRegCode',
        info: {
            title: 'REG_CODE.info.title',
            content: 'REG_CODE.info.content',
        },
    },
    TERMS_AND_CONDITIONS: {
        id: 'termsAndConditions',
        uiType: 'termsAndConditions',
        title: 'TERMS_AND_CONDITIONS.title',
        label: 'TERMS_AND_CONDITIONS.label',
        labelForWithNodeTitle: 'TERMS_AND_CONDITIONS.labelForWithNodeTitle',
        error: 'TERMS_AND_CONDITIONS.error-terms-empty',
    },
    ADDRESS_USA_STANDARD: {
        id: 'addressUsaStandard',
        uiType: 'address',
        title: 'ADDRESS_USA_STANDARD.title',
        labelForWithNodeTitle: 'ADDRESS_USA_STANDARD.labelForWithNodeTitle',
        oneApp: {
            helperText: 'ADDRESS_USA_STANDARD.oneApp.helperText',
            BG300Label: 'ADDRESS_USA_STANDARD.oneApp.BG300Label',
            HT900Label: 'ADDRESS_USA_STANDARD.oneApp.HT900Label',
            WM1500Label: 'ADDRESS_USA_STANDARD.oneApp.WM1500Label',
            shippingCardTitle: 'ADDRESS_USA_STANDARD.oneApp.shippingCardTitle',
            shippingFormTitle: 'ADDRESS_USA_STANDARD.oneApp.shippingFormTitle',
            shippingFormHelperText:
                'ADDRESS_USA_STANDARD.oneApp.shippingFormHelperText',
            requiredLabel: 'ADDRESS_USA_STANDARD.oneApp.requiredLabel',
            shippingCardEditButton:
                'ADDRESS_USA_STANDARD.oneApp.shippingCardEditButton',
            shippingCardSaveButton:
                'ADDRESS_USA_STANDARD.oneApp.shippingCardSaveButton',
            shippingCardCancelButton:
                'ADDRESS_USA_STANDARD.oneApp.shippingCardCancelButton',
        },
        info: {
            title: 'ADDRESS_USA_STANDARD.info.title',
            titleForFEPBLUE: 'ADDRESS_USA_STANDARD.info.titleForFEPBLUE',
            pendingLabel: 'ADDRESS_USA_STANDARD.info.pendingLabel',
            pendingLabelForDBTDF:
                'ADDRESS_USA_STANDARD.info.pendingLabelForDBTDF',
            content: 'ADDRESS_USA_STANDARD.info.content',
            bloodGlucoseMeter: 'ADDRESS_USA_STANDARD.info.bloodGlucoseMeter',
            bloodGlucoseMeterAlt:
                'ADDRESS_USA_STANDARD.info.bloodGlucoseMeterAlt',
            bloodPressureMonitor:
                'ADDRESS_USA_STANDARD.info.bloodPressureMonitor',
            bloodPressureMonitorAlt:
                'ADDRESS_USA_STANDARD.info.bloodPressureMonitorAlt',
            weightManagementScale:
                'ADDRESS_USA_STANDARD.info.weightManagementScale',
            weightManagementScaleAlt:
                'ADDRESS_USA_STANDARD.info.weightManagementScaleAlt',
            deviceFlexContent: 'ADDRESS_USA_STANDARD.info.deviceFlexContent',
        },
    },
    PROGRAM: {
        id: 'program',
        uiType: 'program',
        title: 'PROGRAM.title',
        label: 'PROGRAM.label',
        labelForWithNodeTitle: 'PROGRAM.labelForWithNodeTitle',
        additionalInfo: 'PROGRAM.additionalInfo',
        placeholder: 'PROGRAM.placeholder',
        error: 'common:error.required',
    },
    TRACKING_UUID: {
        id: 'trackingUUID',
        title: 'TRACKING_UUID.title',
        label: 'TRACKING_UUID.label',
        labelForWithNodeTitle: 'TRACKING_UUID.labelForWithNodeTitle',
        placeholder: 'TRACKING_UUID.placeholder',
        error: 'common:error.required',
    },
    CLIENT_NAME: {
        id: 'clientName',
        title: 'CLIENT_NAME.title',
        label: 'CLIENT_NAME.label',
        labelForWithNodeTitle: 'CLIENT_NAME.labelForWithNodeTitle',
        placeholder: 'CLIENT_NAME.placeholder',
        error: 'common:error.required',
    },
    SUPPORTED_REGION: {
        id: 'supportedRegion',
        uiType: 'supportedRegion',
        title: 'SUPPORTED_REGION.title',
        label: 'SUPPORTED_REGION.label',
        labelForWithNodeTitle: 'SUPPORTED_REGION.labelForWithNodeTitle',
        error: 'common:error.required',
    },
    REGISTERING_UNDER_13: {
        id: 'REGISTERING_UNDER_13',
        uiType: 'checkbox',
        label: 'REGISTERING_UNDER_13.label',
        labelForWithNodeTitle: 'REGISTERING_UNDER_13.labelForWithNodeTitle',
        u13ActionLink: 'REGISTERING_UNDER_13.u13ActionLink',
        notU13ActionLink: 'REGISTERING_UNDER_13.notU13ActionLink',
        error: 'common:error.required',
    },
    GUARDIAN_FIRST_NAME: {
        id: 'guardianFirstName',
        type: 'text',
        pattern: "[-0-9A-zÀ-ž '’.]+",
        title: 'GUARDIAN_FIRST_NAME.title',
        label: 'GUARDIAN_FIRST_NAME.label',
        labelForWithNodeTitle: 'GUARDIAN_FIRST_NAME.labelForWithNodeTitle',
        placeholder: 'GUARDIAN_FIRST_NAME.placeholder',
        placeholderForWithNodeTitle:
            'GUARDIAN_FIRST_NAME.placeholderForWithNodeTitle',
        error: 'common:error.required',
        info: {
            title: 'GUARDIAN_FIRST_NAME.info.title',
            content: 'GUARDIAN_FIRST_NAME.info.content',
        },
        U18info: {
            title: 'GUARDIAN_FIRST_NAME.U18info.title',
            content: 'GUARDIAN_FIRST_NAME.U18info.content',
        },
    },
    GUARDIAN_EMAIL: {
        id: 'guardianEmail',
        type: 'text',
        pattern:
            '^(([a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+(.[a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+)*)|(".+"))@(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,})$',
        title: 'GUARDIAN_EMAIL.title',
        label: 'GUARDIAN_EMAIL.label',
        labelForWithNodeTitle: 'GUARDIAN_EMAIL.labelForWithNodeTitle',
        placeholder: 'GUARDIAN_EMAIL.placeholder',
        error: 'common:error.required',
    },
    CALCULATED_AGE: {
        id: 'CALCULATED_AGE',
        title: 'CALCULATED_AGE.title',
        label: 'CALCULATED_AGE.label',
        labelForWithNodeTitle: 'CALCULATED_AGE.labelForWithNodeTitle',
        placeholder: 'CALCULATED_AGE.placeholder',
        error: 'common:error.required',
    },
    CALCULATED_IS_U13: {
        id: 'CALCULATED_IS_U13',
        title: 'CALCULATED_IS_U13.title',
        label: 'CALCULATED_IS_U13.label',
        labelForWithNodeTitle: 'CALCULATED_IS_U13.labelForWithNodeTitle',
        placeholder: 'CALCULATED_IS_U13.placeholder',
        error: 'common:error.required',
    },
    CALCULATED_IS_U18: {
        id: 'CALCULATED_IS_U18',
        title: 'CALCULATED_IS_U18.title',
        label: 'CALCULATED_IS_U18.label',
        labelForWithNodeTitle: 'CALCULATED_IS_U18.labelForWithNodeTitle',
        placeholder: 'CALCULATED_IS_U18.placeholder',
        error: 'common:error.required',
    },
    MOBILE_PHONE_INTERNATIONAL: {
        id: 'phoneMobile',
        uiType: 'phoneInputInternational',
        type: 'number',
        autocomplete: 'tel-national',
        title: 'MOBILE_PHONE_INTERNATIONAL.title',
        titleForMultipleQuestion:
            'MOBILE_PHONE_INTERNATIONAL.titleForMultipleQuestion',
        label: 'PHONE_MOBILE.label',
        labelForWithNodeTitle: 'PHONE_MOBILE.labelForWithNodeTitle',
        labelForMultipleQuestion:
            'MOBILE_PHONE_INTERNATIONAL.labelForMultipleQuestion',
        ariaLabel: 'MOBILE_PHONE_INTERNATIONAL.ariaLabel',
        placeholder: 'MOBILE_PHONE_INTERNATIONAL.placeholder',
        placeholderForMultipleQuestion:
            'MOBILE_PHONE_INTERNATIONAL.placeholderForMultipleQuestion',
        error: 'MOBILE_PHONE_INTERNATIONAL.error-phone-valid',
    },
    MOBILE_PHONE_COUNTRY: {
        id: 'mobilePhoneCountry',
        uiType: 'select',
        title: 'MOBILE_PHONE_COUNTRY.title',
        label: 'MOBILE_PHONE_COUNTRY.label',
        labelForWithNodeTitle: 'MOBILE_PHONE_COUNTRY.labelForWithNodeTitle',
        ariaLabel: 'MOBILE_PHONE_COUNTRY.ariaLabel',
        error: 'common:error.required',
    },
    ADDRESS_USA_COMBINED: {
        id: 'addressUsaCombined',
        uiType: 'addressCombined',
        title: 'ADDRESS_USA_COMBINED.title',
        label: 'ADDRESS_USA_COMBINED.label',
        labelForWithNodeTitle: 'ADDRESS_USA_COMBINED.labelForWithNodeTitle',
        oneApp: {
            helperText: 'ADDRESS_USA_COMBINED.oneApp.helperText',
            BG300Label: 'ADDRESS_USA_COMBINED.oneApp.BG300Label',
            HT900Label: 'ADDRESS_USA_COMBINED.oneApp.HT900Label',
            WM1500Label: 'ADDRESS_USA_COMBINED.oneApp.WM1500Label',
            shippingCardTitle: 'ADDRESS_USA_COMBINED.oneApp.shippingCardTitle',
            shippingFormTitle: 'ADDRESS_USA_COMBINED.oneApp.shippingFormTitle',
            shippingFormHelperText:
                'ADDRESS_USA_COMBINED.oneApp.shippingFormHelperText',
            requiredLabel: 'ADDRESS_USA_COMBINED.oneApp.requiredLabel',
            shippingCardEditButton:
                'ADDRESS_USA_COMBINED.oneApp.shippingCardEditButton',
            shippingCardSaveButton:
                'ADDRESS_USA_COMBINED.oneApp.shippingCardSaveButton',
            shippingCardCancelButton:
                'ADDRESS_USA_COMBINED.oneApp.shippingCardCancelButton',
        },
        info: {
            title: 'ADDRESS_USA_COMBINED.info.title',
            pendingLabel: 'ADDRESS_USA_COMBINED.info.pendingLabel',
            pendingLabelForDBTDF:
                'ADDRESS_USA_COMBINED.info.pendingLabelForDBTDF',
            content: 'ADDRESS_USA_COMBINED.info.content',
            bloodGlucoseMeter: 'ADDRESS_USA_COMBINED.info.bloodGlucoseMeter',
            bloodGlucoseMeterAlt:
                'ADDRESS_USA_COMBINED.info.bloodGlucoseMeterAlt',
            bloodPressureMonitor:
                'ADDRESS_USA_COMBINED.info.bloodPressureMonitor',
            bloodPressureMonitorAlt:
                'ADDRESS_USA_COMBINED.info.bloodPressureMonitorAlt',
            weightManagementScale:
                'ADDRESS_USA_COMBINED.info.weightManagementScale',
            weightManagementScaleAlt:
                'ADDRESS_USA_COMBINED.info.weightManagementScaleAlt',
            deviceFlexContent: 'ADDRESS_USA_COMBINED.info.deviceFlexContent',
        },
    },
    ADDRESS_USA_MILITARY_UNIT: {
        id: 'addressUsaMilitaryUnit',
        type: 'text',
        pattern: '[-.0-9A-zÀ-ž #]+',
        title: 'ADDRESS_USA_MILITARY_UNIT.title',
        label: 'ADDRESS_USA_MILITARY_UNIT.label',
        labelForWithNodeTitle:
            'ADDRESS_USA_MILITARY_UNIT.labelForWithNodeTitle',
        placeholder: 'ADDRESS_USA_MILITARY_UNIT.placeholder',
        error: 'common:error.required',
    },
    ADDRESS_USA_MILITARY_ADDRESS_TYPE: {
        id: 'addressUsaMilitaryAddressType',
        uiType: 'select',
        title: 'ADDRESS_USA_MILITARY_ADDRESS_TYPE.title',
        label: 'ADDRESS_USA_MILITARY_ADDRESS_TYPE.label',
        labelForWithNodeTitle:
            'ADDRESS_USA_MILITARY_ADDRESS_TYPE.labelForWithNodeTitle',
        placeholder: 'ADDRESS_USA_MILITARY_ADDRESS_TYPE.placeholder',
        error: 'common:error.required',
    },
    ADDRESS_USA_MILITARY_STATE: {
        id: 'militaryState',
        uiType: 'select',
        title: 'ADDRESS_USA_MILITARY_STATE.title',
        label: 'ADDRESS_USA_MILITARY_STATE.label',
        labelForWithNodeTitle:
            'ADDRESS_USA_MILITARY_STATE.labelForWithNodeTitle',
        placeholder: 'ADDRESS_USA_MILITARY_STATE.placeholder',
        error: 'common:error.required',
    },
    ADDRESS_USA_TERRITORIES_STATE: {
        id: 'territoriesState',
        uiType: 'select',
        title: 'ADDRESS_USA_TERRITORIES_STATE.title',
        label: 'ADDRESS_USA_TERRITORIES_STATE.label',
        labelForWithNodeTitle:
            'ADDRESS_USA_TERRITORIES_STATE.labelForWithNodeTitle',
        placeholder: 'ADDRESS_USA_TERRITORIES_STATE.placeholder',
        error: 'common:error.required',
    },
    MARKETING_ATTRIBUTION: {
        id: 'marketingAttribution',
        title: 'MARKETING_ATTRIBUTION.title',
        label: 'MARKETING_ATTRIBUTION.label',
        labelForWithNodeTitle: 'MARKETING_ATTRIBUTION.labelForWithNodeTitle',
        ariaLabel: 'MARKETING_ATTRIBUTION.ariaLabel',
        error: 'common:error.required',
    },
    EVER_DIAGNOSED_WITH_CHRONIC_KIDNEY_DISEASE: {
        id: 'everDiagnosedWithChronicKidneyDisease',
        title: 'EVER_DIAGNOSED_WITH_CHRONIC_KIDNEY_DISEASE.title',
        label: 'EVER_DIAGNOSED_WITH_CHRONIC_KIDNEY_DISEASE.label',
        labelForWithNodeTitle:
            'EVER_DIAGNOSED_WITH_CHRONIC_KIDNEY_DISEASE.labelForWithNodeTitle',
        ariaLabel: 'EVER_DIAGNOSED_WITH_CHRONIC_KIDNEY_DISEASE.ariaLabel',
        error: 'common:error.required',
    },
    POLICY_HOLDER_INFO: {
        id: 'policyHolderInfo',
        uiType: questionFieldTypes.COMPOUND_FIELD,
        title: 'POLICY_HOLDER_INFO.title',
        subTitle: 'POLICY_HOLDER_INFO.subTitle',
        label: 'POLICY_HOLDER_INFO.label',
        labelForWithNodeTitle: 'POLICY_HOLDER_INFO.labelForWithNodeTitle',
        ariaLabel: 'POLICY_HOLDER_INFO.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'POLICY_HOLDER_INFO.info.title',
            content: 'POLICY_HOLDER_INFO.info.content',
        },
    },
    ELIGIBILITY_STATUS: {
        id: 'eligibilityStatus',
    },
    MIDDLE_NAME: {
        id: 'middleName',
    },
    TARGET_PROGRAMS: {
        id: 'targetPrograms',
        uiType: 'targetPrograms',
        titleForSingleProgram: 'TARGET_PROGRAMS.titleForSingleProgram',
        titleForMultipleProgram: 'TARGET_PROGRAMS.titleForMultipleProgram',
        labelForSingleProgram: 'TARGET_PROGRAMS.labelForSingleProgram',
        labelForMultipleProgram: 'TARGET_PROGRAMS.labelForMultipleProgram',
        titleForDisqualifiedProgram:
            'TARGET_PROGRAMS.titleForDisqualifiedProgram',
        subTitleForDisqualifiedProgram:
            'TARGET_PROGRAMS.subTitleForDisqualifiedProgram',
        labelForDisqualifiedProgram:
            'TARGET_PROGRAMS.labelForDisqualifiedProgram',
        learnMoreButtonText: 'TARGET_PROGRAMS.learnMoreButtonText',
        optionsForProgramSelection:
            'TARGET_PROGRAMS.optionsForProgramSelection',
        error: 'common:error.required',
    },
    EVER_DIAGNOSED_WITH_HEART_FAILURE: {
        id: 'everDiagnosedWithHeartFailure',
        title: 'EVER_DIAGNOSED_WITH_HEART_FAILURE.title',
        label: 'EVER_DIAGNOSED_WITH_HEART_FAILURE.label',
        labelForWithNodeTitle:
            'EVER_DIAGNOSED_WITH_HEART_FAILURE.labelForWithNodeTitle',
        ariaLabel: 'EVER_DIAGNOSED_WITH_HEART_FAILURE.ariaLabel',
        error: 'common:error.required',
    },
    EVER_HOSPITALIZED_FOR_HEART_FAILURE: {
        id: 'everHospitalizedForHeartFailure',
        title: 'EVER_HOSPITALIZED_FOR_HEART_FAILURE.title',
        label: 'EVER_HOSPITALIZED_FOR_HEART_FAILURE.label',
        labelForWithNodeTitle:
            'EVER_HOSPITALIZED_FOR_HEART_FAILURE.labelForWithNodeTitle',
        ariaLabel: 'EVER_HOSPITALIZED_FOR_HEART_FAILURE.ariaLabel',
        error: 'common:error.required',
    },
    HEART_FAILURE_HOSPITALIZATIONS_PAST_YEAR: {
        id: 'heartFailureHospitalizationsPastYear',
        title: 'HEART_FAILURE_HOSPITALIZATIONS_PAST_YEAR.title',
        label: 'HEART_FAILURE_HOSPITALIZATIONS_PAST_YEAR.label',
        labelForWithNodeTitle:
            'HEART_FAILURE_HOSPITALIZATIONS_PAST_YEAR.labelForWithNodeTitle',
        ariaLabel: 'HEART_FAILURE_HOSPITALIZATIONS_PAST_YEAR.ariaLabel',
        error: 'common:error.required',
    },
    CARDIAC_EJECTION_FRACTION: {
        id: 'cardiacEjectionFraction',
        title: 'CARDIAC_EJECTION_FRACTION.title',
        label: 'CARDIAC_EJECTION_FRACTION.label',
        labelForWithNodeTitle:
            'CARDIAC_EJECTION_FRACTION.labelForWithNodeTitle',
        ariaLabel: 'CARDIAC_EJECTION_FRACTION.ariaLabel',
        error: 'common:error.required',
    },
    HAS_REPORTED_COPD: {
        id: 'hasReportedCopd',
        label: 'HAS_REPORTED_COPD.label',
    },
    HAS_REPORTED_KIDNEY_ISSUES: {
        id: 'hasReportedKidneyIssues',
        label: 'HAS_REPORTED_KIDNEY_ISSUES.label',
    },
    HAS_REPORTED_DEPRESSION: {
        id: 'hasReportedDepression',
        label: 'HAS_REPORTED_DEPRESSION.label',
    },
    HAS_REPORTED_HEART_ISSUES: {
        id: 'hasReportedHeartIssues',
        label: 'HAS_REPORTED_HEART_ISSUES.label',
    },
    HAS_REPORTED_ANEMIA: {
        id: 'hasReportedAnemia',
        label: 'HAS_REPORTED_ANEMIA.label',
    },
    HAS_REPORTED_HYPERTENSION: {
        id: 'hasReportedHypertension',
        label: 'HAS_REPORTED_HYPERTENSION.label',
    },
    HAS_REPORTED_DIABETES: {
        id: 'hasReportedDiabetes',
        label: 'HAS_REPORTED_DIABETES.label',
    },
    HAS_REPORTED_HIGH_CHOLESTEROL: {
        id: 'hasReportedDyslipidemia',
        label: 'HAS_REPORTED_HIGH_CHOLESTEROL.label',
    },
    HAS_REPORTED_ARRHYTHMIA: {
        id: 'hasReportedArrhythmia',
        label: 'HAS_REPORTED_ARRHYTHMIA.label',
    },
    HAS_REPORTED_HEALTH_ISSUES: {
        id: 'hasReportedHealthIssues',
        uiType: 'healthIssues',
        title: 'HAS_REPORTED_HEALTH_ISSUES.title',
        label: 'HAS_REPORTED_HEALTH_ISSUES.label',
        labelForWithNodeTitle:
            'HAS_REPORTED_HEALTH_ISSUES.labelForWithNodeTitle',
        error: 'common:error.required',
    },
    DIAGNOSIS_RECENCY: {
        id: 'diagnosisRecency',
        title: 'DIAGNOSIS_RECENCY.title',
        label: 'DIAGNOSIS_RECENCY.label',
        labelForWithNodeTitle: 'DIAGNOSIS_RECENCY.labelForWithNodeTitle',
        ariaLabel: 'DIAGNOSIS_RECENCY.ariaLabel',
        error: 'common:error.required',
    },
    ACKNOWLEDGED_CHILD_PRIVACY_POLICY: {
        id: 'acknowledgedChildPrivacyPolicy',
        uiType: 'acknowledgedChildPrivacyPolicy',
        title: 'ACKNOWLEDGED_CHILD_PRIVACY_POLICY.title',
        label: 'ACKNOWLEDGED_CHILD_PRIVACY_POLICY.label',
        labelForWithNodeTitle:
            'ACKNOWLEDGED_CHILD_PRIVACY_POLICY.labelForWithNodeTitle',
        error: 'ACKNOWLEDGED_CHILD_PRIVACY_POLICY.error-terms-empty',
    },
    TOP_ASPECTS_OF_MANAGING_HEALTH: {
        id: 'topAspectsOfManagingHealth',
        title: 'TOP_ASPECTS_OF_MANAGING_HEALTH.title',
        label: 'TOP_ASPECTS_OF_MANAGING_HEALTH.label',
        labelForWithNodeTitle:
            'TOP_ASPECTS_OF_MANAGING_HEALTH.labelForWithNodeTitle',
        error: 'TOP_ASPECTS_OF_MANAGING_HEALTH.error-terms-empty',
    },
    QUALIFY: {
        id: 'quality',
        uiType: 'MultiSelectRequired',
        title: 'QUALIFY.title',
        label: 'QUALIFY.label',
        labelForWithNodeTitle: 'QUALIFY.labelForWithNodeTitle',
        error: 'common:error.required',
    },
    SMART_PHONE: {
        id: 'smartPhone',
        title: 'SMART_PHONE.title',
        label: 'SMART_PHONE.label',
        labelForWithNodeTitle: 'SMART_PHONE.labelForWithNodeTitle',
        ariaLabel: 'SMART_PHONE.ariaLabel',
        error: 'common:error.required',
        info: {
            title: 'SMART_PHONE.info.title',
            content: 'SMART_PHONE.info.content',
        },
    },
    CALCULATED_ENROLLED_DPP: {
        id: 'calculatedEnrolledDpp',
        title: 'CALCULATED_ENROLLED_DPP.title',
        label: 'CALCULATED_ENROLLED_DPP.label',
        labelForWithNodeTitle: 'CALCULATED_ENROLLED_DPP.labelForWithNodeTitle',
        error: 'common:error.required',
    },
    HF_HEALTH_ALERT_CONSENT: {
        id: 'hfHealthAlertSmsOptIn',
        title: 'HF_HEALTH_ALERT_CONSENT.title',
        label: 'HF_HEALTH_ALERT_CONSENT.label',
        labelForWithNodeTitle: 'HF_HEALTH_ALERT_CONSENT.labelForWithNodeTitle',
        error: 'common:error.required',
        info: {
            title: 'HF_HEALTH_ALERT_CONSENT.info.title',
            content: 'HF_HEALTH_ALERT_CONSENT.info.content',
        },
    },
    CLIENT_CONSENT: {
        uiType: 'clientConsent',
        id: 'clientConsent',
        title: 'CLIENT_CONSENT.title',
        label: 'CLIENT_CONSENT.label',
        labelForWithNodeTitle: 'CLIENT_CONSENT.labelForWithNodeTitle',
        content: {
            content1: 'CLIENT_CONSENT.content.content1',
            content2: 'CLIENT_CONSENT.content.content2',
            content3: 'CLIENT_CONSENT.content.content3',
            content4: 'CLIENT_CONSENT.content.content4',
        },
        checkboxLabel: 'CLIENT_CONSENT.checkboxLabel',
        error: 'CLIENT_CONSENT.error',
    },
    CONFIRM_ELIGIBILITY_FILE_REG_CODE: {
        uiType: 'confirmEligibilityFileRegCode',
        id: 'confirmEligibilityFileRegCode',
        title: 'CONFIRM_ELIGIBILITY_FILE_REG_CODE.title',
        label: 'CONFIRM_ELIGIBILITY_FILE_REG_CODE.label',
        labelForWithNodeTitle:
            'CONFIRM_ELIGIBILITY_FILE_REG_CODE.labelForWithNodeTitle',
    },
    CONFIRM_BIRTH_DATE: {
        id: 'birthDate',
        uiType: 'birthDate',
        autocompleteDay: 'bday-day',
        autocompleteMonth: 'bday-month',
        autocompleteYear: 'bday-year',
        title: 'CONFIRM_BIRTH_DATE.title',
        helperText: 'CONFIRM_BIRTH_DATE.helperText',
        helperTextForTrackingID: 'CONFIRM_BIRTH_DATE.helperTextForTrackingID',
        label: 'CONFIRM_BIRTH_DATE.label',
        labelForWithNodeTitle: 'CONFIRM_BIRTH_DATE.labelForWithNodeTitle',
        ariaLabel: 'CONFIRM_BIRTH_DATE.ariaLabel',
        requiredLabel: 'CONFIRM_BIRTH_DATE.requiredLabel',
        error: 'CONFIRM_BIRTH_DATE.error',
        errorNotMatch: {
            p1: 'CONFIRM_BIRTH_DATE.errorNotMatch.p1',
            p2: 'CONFIRM_BIRTH_DATE.errorNotMatch.p2',
        },
        errorAlreadyExist: {
            p1: 'CONFIRM_BIRTH_DATE.errorAlreadyExist.p1',
            p2: 'CONFIRM_BIRTH_DATE.errorAlreadyExist.p2',
        },
    },
};

export const eligibilityStatus = {
    INELIGIBLE: 'INELIGIBLE',
};
