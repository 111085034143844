import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import TextInput from '@teladoc/pulse/ui/TextInput';
import {IDK} from 'constants/type';
import {questionConfig} from 'constants/questions';
import {renderLabel} from 'components/question/questions-utils';
import css from './A1cValue.scss';

const currentYear = new Date().getUTCFullYear();
const currentMonth = new Date().getMonth() + 1;

const A1cDate = ({
    id,
    name,
    required,
    defaultValue,
    defaultDisabled = false,
    hasNodeTitle,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    // Get question UI configuration.
    const {
        label,
        labelForWithNodeTitle,
        helpNode,
        ariaLabel,
        checkboxLabel,
        autocomplete,
        placeholder,
        error,
    } = questionConfig[id] || {};
    const textInputId = `txt_${id}`;
    // This is the display value with the percent sign shown
    const [a1cDate, setA1cDate] = useState('');
    const [disableA1CInput, setDisableA1CInput] = useState(defaultDisabled);
    // This is the float value that will be passed when a form submit happens
    const [a1cDateHiddenValue, setA1cDateHiddenValue] = useState('');
    const [placeHolder, setPlaceHolder] = useState(placeholder);
    const [customValidationError, setCustomValidationError] = useState(null);

    const onA1cChange = evt => {
        setDisableA1CInput(evt.target.checked);
        setA1cDateHiddenValue(evt.target.checked ? IDK : a1cDate);

        if (onChange) {
            onChange(evt.target.checked ? IDK : a1cDate);
        }
    };
    const handleTextOnChange = evt => {
        const {value} = evt.target;
        const newValue = value.replace(/[^0-9.]/g, '');

        setA1cDateHiddenValue(newValue);

        // date validation
        if (
            newValue.length === 6 &&
            ((parseInt(newValue.slice(0, 2), 10) > currentMonth &&
                parseInt(newValue.slice(2, 6), 10) === currentYear) ||
                parseInt(newValue.slice(2, 6), 10) > currentYear ||
                parseInt(newValue.slice(0, 2), 10) > 12 ||
                parseInt(newValue.slice(0, 2), 10) <= 0)
        ) {
            setCustomValidationError(
                <FormElementError>{t('error.dataNotValid')}</FormElementError>
            );
        } else {
            setCustomValidationError(false);
        }

        if (onChange) {
            onChange(newValue);
        }

        setA1cDate(newValue);
    };
    const onTextInputFocus = evt => {
        setPlaceHolder('MM/YYYY');
    };
    const onTextInputBlur = evt => {
        const {value} = evt.target;
        const newValue = value.replace(/[^0-9.]/g, '');

        if (newValue.length > 0 && newValue.length !== 6) {
            setCustomValidationError(
                <FormElementError>{t('error.dataNotValid')}</FormElementError>
            );
        }

        setPlaceHolder(placeholder);
    };

    useEffect(() => {
        // below regex is to check whether the default value is single digit or not
        // if single digit, will need to insert 0 before it
        const formattedDefaultValue = defaultValue
            ? `${
                  /^\d$/.test(defaultValue.month)
                      ? `0${defaultValue.month}`
                      : defaultValue.month
              }${defaultValue.year}`
            : '';

        setA1cDate(formattedDefaultValue);
        setA1cDateHiddenValue(formattedDefaultValue);
    }, [defaultValue]);

    const renderQuestionLabel = hasNodeTitle ? labelForWithNodeTitle : label;
    const renderClassForQuestionLabel = hasNodeTitle
        ? css.questionLabelForWithNodeTitle
        : '';

    return (
        <div>
            <TextInput
                {...renderLabel(
                    t(renderQuestionLabel),
                    t(ariaLabel),
                    renderClassForQuestionLabel
                )}
                id={textInputId}
                placeholder={t(placeHolder)}
                autoComplete={autocomplete}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={customValidationError}
                onChange={handleTextOnChange}
                onFocus={onTextInputFocus}
                onBlur={onTextInputBlur}
                disabled={disableA1CInput}
                required={required || !disableA1CInput}
                value={a1cDate}
                helpNode={t(helpNode)}
                mask="99/9999"
            />
            <Checkbox
                id={`chb_${id}`}
                value={IDK}
                i18nItemLabel={t(checkboxLabel)}
                label={<Label>{t(checkboxLabel)}</Label>}
                error={<FormElementError>{t(error)}</FormElementError>}
                onChange={onA1cChange}
                defaultChecked={defaultDisabled}
            />
            <TextInput
                // this hidden text input is only for submit form
                id={id}
                name={name}
                type="hidden"
                i18nItemLabel="hidden"
                value={a1cDateHiddenValue}
            />
        </div>
    );
};

A1cDate.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.object,
    defaultDisabled: PropTypes.bool,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    hasNodeTitle: PropTypes.bool,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} a1cValue - Either the A1c Value or the IDK string.
     */
    onChange: PropTypes.func,
};

export default A1cDate;
