export const nodeTitleIds = {
    askPolicyHolderInfo: 'askPolicyHolderInfo',
    askU13SafeQuestions: 'askU13SafeQuestions',
    askGuardianInfo: 'askGuardianInfo',
    askU18GuardianInfo: 'askU18GuardianInfo',
    reAskNodeCoverageConfirmation: 'reAskNodeCoverageConfirmation',
    askAdultZipCodeAndSupportedRegion: 'askAdultZipCodeAndSupportedRegion',
    reAskPolicyHolderRelation: 'reAskPolicyHolderRelation',
    reAskPolicyHolderInfo: 'reAskPolicyHolderInfo',
};
