import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import CheckableGroup from '@teladoc/pulse/ui/CheckableGroup';
import Button from '@teladoc/pulse/ui/Button';
import Label from '@teladoc/pulse/ui/Label';
import SelectionCard from '@teladoc/pulse/ui/SelectionCard';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import FormGroupError from '@teladoc/pulse/ui/FormGroupError';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import TextInput from '@teladoc/pulse/ui/TextInput';
import Config from 'config';
import {
    setStepNumber,
    getStepNumber,
    setAllOfferedPrograms,
    getAllOfferedPrograms,
    getVWOVariant,
} from 'utilities/utils';
import {
    mixpanelEnrollmentExperimentEvent,
    mixpanelProgramSelectionView,
} from 'utilities/mixpanel-utils';
import {questionConfig} from 'constants/questions';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import useMessage from 'hook/useMessage';
import css from './TargetPrograms.scss';

const {memberSupportPhoneNumber, oneAppMemberSupportPhoneNumber} = Config;

const TargetPrograms = ({
    id,
    name,
    items,
    question,
    dataType,
    defaultValue: defaultValues,
    isOneApp,
    context,
}) => {
    const {t} = useTranslation('questions');
    const qualifiedProgram = [];
    const messageValue = questionConfig[id] || {};
    const {
        titleForSingleProgram,
        titleForMultipleProgram,
        labelForSingleProgram,
        labelForMultipleProgram,
        titleForDisqualifiedProgram,
        subTitleForDisqualifiedProgram,
        learnMoreButtonText,
        optionsForProgramSelection,
        error,
    } = messageValue;
    const {showProgramEligibility} = useMessage();
    const options = question.dataType[dataType].defaultAnswerCodesList;
    const stepNumber = context.stepNumber;
    const storedStepNumber = getStepNumber();
    const allOfferedPrograms = getAllOfferedPrograms();
    const defaultCheckedHash = defaultValues.reduce(
        (hash, defaultValue) => ({...hash, [defaultValue]: true}),
        {}
    );

    const isDisqualified = storedStepNumber && storedStepNumber !== stepNumber;

    if (!storedStepNumber) {
        setStepNumber(stepNumber);
    }

    const isSingleProgram = items.length === 1;
    const title = isDisqualified
        ? titleForDisqualifiedProgram
        : isSingleProgram
        ? titleForSingleProgram
        : titleForMultipleProgram;
    const label = isSingleProgram
        ? labelForSingleProgram
        : labelForMultipleProgram;
    const [customValidationError, setCustomValidationError] = useState(null);

    const clientNameAnswer = context?.answersList.find(
        answer => answer.questionId?.id === QuestionId.Values.CLIENT_NAME
    );
    const clientFriendlyName = clientNameAnswer?.value?.text || '';

    items.map(program => qualifiedProgram.push(program.value));

    // if there is default value (previous answer), set to default value otherwise set to qualifiedProgram
    const [programSelections, setProgramSelections] = useState(
        qualifiedProgram
            ? Object.assign(
                  ...qualifiedProgram.map(program => ({[program]: true}))
              )
            : {}
    );
    // handle onchange for all checkbox
    const onProgramChange = ({target: {value, checked}}) => {
        setProgramSelections({...programSelections, [value]: checked});
    };

    useEffect(() => {
        // only save all offered programs when there is no offered program value
        if (options && !allOfferedPrograms) {
            setAllOfferedPrograms(options);
        }
    }, [allOfferedPrograms, options]);

    useEffect(() => {
        // if all values in programSelections all false, call onShowMustSelectMessage function
        if (!Object.values(programSelections).includes(true)) {
            setCustomValidationError(
                <FormGroupError
                    errors={{
                        details: (
                            <div>
                                {isOneApp
                                    ? t('PROGRAM.oneAppError.p1')
                                    : t('PROGRAM.error.p1')}
                                <br />
                                <Trans
                                    i18nKey={
                                        isOneApp
                                            ? 'questions:PROGRAM.oneAppError.p2'
                                            : 'questions:PROGRAM.error.p2'
                                    }
                                    components={[
                                        // eslint-disable-next-line
                                        <a
                                            href={
                                                isOneApp
                                                    ? `tel:${oneAppMemberSupportPhoneNumber}`
                                                    : `tel:${memberSupportPhoneNumber}`
                                            }
                                        />,
                                    ]}
                                />
                            </div>
                        ),
                    }}
                />
            );
        } else {
            setCustomValidationError(false);
        }
    }, [programSelections, t, isOneApp]);

    useEffect(() => {
        // call mixpanel Enrollment ExperimentEvent when land on program selection question.
        // Todo: will remove it once a/b test done
        const vwoExperiments = getVWOVariant() ?? {};

        // only EXPERIMENTAL-WORDING-FOR-REG-CODE experiment should trigger this mixpanel event
        if (vwoExperiments.experimentName === 'PROGRAM-SELECTION') {
            mixpanelEnrollmentExperimentEvent(
                vwoExperiments.experimentName,
                vwoExperiments.experimentGroup,
                'TARGET_PROGRAMS',
                {
                    'Program Selection Page Visits Number': isDisqualified
                        ? 2
                        : 1,
                }
            );

            mixpanelProgramSelectionView(options, isDisqualified ? 2 : 1);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className={css.programSelectionContainer}>
            <h1>{t(title, {clientFriendlyName})}</h1>

            {isDisqualified && (
                <div>
                    <HorizontalRule spaceTop={24} spaceBottom={24} />
                    <h2>{t(subTitleForDisqualifiedProgram)}</h2>
                </div>
            )}

            {!isDisqualified && <p className={css.subtitle}>{t(label)}</p>}
            {(isSingleProgram || isDisqualified) && (
                <div>
                    {options.map(value => {
                        return (
                            <>
                                <Label
                                    key={value}
                                    className={css.singleProgram}
                                >
                                    <div>
                                        <h3>
                                            {t(
                                                `${optionsForProgramSelection}.${value}.title`
                                            )}
                                        </h3>
                                        <p>
                                            {t(
                                                `${optionsForProgramSelection}.${value}.content`
                                            )}
                                        </p>
                                    </div>
                                </Label>
                                <TextInput
                                    id={id}
                                    name={name}
                                    type="hidden"
                                    i18nItemLabel="hidden"
                                    value={value}
                                />
                            </>
                        );
                    })}
                    {isDisqualified && (
                        <Button
                            onClick={() => {
                                showProgramEligibility();
                            }}
                        >
                            {t(learnMoreButtonText)}
                        </Button>
                    )}
                </div>
            )}
            {!isSingleProgram && !isDisqualified && (
                <CheckableGroup
                    id={id}
                    name={name}
                    className={css.checkableGroup}
                    label={<Label i18nRequiredVisualLabel=" " />}
                    customValidationError={customValidationError}
                    onChange={onProgramChange}
                    allowMultiSelect
                    error={<FormElementError>{t(error)}</FormElementError>}
                >
                    {options.map(value => {
                        return (
                            <SelectionCard
                                key={value}
                                id={`${id}_${value}`}
                                name={name}
                                value={value}
                                hasCheckbox
                                title={
                                    <h2 className={css.title}>
                                        {t(
                                            `${optionsForProgramSelection}.${value}.title`
                                        )}
                                    </h2>
                                }
                                content={
                                    <p>
                                        {t(
                                            `${optionsForProgramSelection}.${value}.content`
                                        )}
                                    </p>
                                }
                                i18nItemLabel={value}
                                defaultChecked={defaultCheckedHash[value]}
                            />
                        );
                    })}
                </CheckableGroup>
            )}
        </div>
    );
};

TargetPrograms.propTypes = {
    id: PropTypes.string.isRequired,
    dataType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    defaultValue: PropTypes.array,
    question: PropTypes.object.isRequired,
    isOneApp: PropTypes.bool,
    context: PropTypes.object,
};

export default TargetPrograms;
