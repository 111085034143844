import Arg from '@livongo/arg';
import isNil from 'lodash/isNil';
import {Program} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/user/program_pb';
import MixpanelUtils from '@livongo/utils/mixpanel';
import {initializeChangeSolutionRequest} from 'api/protobuf/enrollment/manageSolution';
import {mixpanelEvent} from 'constants/mixpanel';
import {UPSELL} from 'constants/app';
import {
    setApplication,
    setGrpcAccessToken,
    setGrpcAccessTokenTimeStamp,
    setRegistrationContext,
    setUpsellProgram,
    setRestApiAccessToken,
    setCallBackUrl,
    setIsAuthenticated,
} from 'utilities/utils';
import Config from 'config';

const arg = Arg.all();

export const initializeUpsell = async () => {
    setApplication(UPSELL);
    const {authToken, program} = Arg.all(); // parse params from URL

    if (isNil(authToken) || isNil(program)) {
        // TODO: add open modal
        return;
    }

    if (program) {
        setUpsellProgram({
            program,
            upsellFinished: false,
        });
    }

    if (authToken) {
        setRestApiAccessToken(authToken);
    }

    const programs = [Program.Values[program]]; // setProgramsList only accept an array
    const oneAppFlag = Config.client.oneapp;

    if (arg[oneAppFlag]) {
        setRegistrationContext({
            client: Config.client.oneapp,
        });
    }

    const returnSubDomain = 'returnSubDomain';
    const returnPath = 'returnPath';

    // for new upsell BE wont send call back url in grpc response, need to grab from url param
    if (arg[returnSubDomain] && arg[returnPath]) {
        if (process.env.APP_ENV === 'production') {
            setCallBackUrl(
                `https://${arg[returnSubDomain]}.teladoc.com${arg[returnPath]}`
            );
        } else {
            setCallBackUrl(`https://${arg[returnSubDomain]}${arg[returnPath]}`);
        }
    }

    MixpanelUtils.track({
        event: mixpanelEvent.BEFORE_INITIALIZE,
    });

    try {
        const res = await initializeChangeSolutionRequest(authToken, programs);

        window.grpcExpires = res;
        const accessToken = res?.authenticated?.accessToken?.token;

        if (accessToken) {
            setIsAuthenticated(true);
        }

        setGrpcAccessToken(accessToken); // Store gRPC access token to localstorage or cookie for sub-sequence calls.
        setGrpcAccessTokenTimeStamp(new Date().getTime());
    } catch (e) {
        // TODO: display proper error message.
    }
};
