export const amplitudeEvent = {
    BEFORE_INITIALIZE: 'reg5_before_initialize',
    USER_SSO_SUCCESSFUL: 'reg5_user_sso_successful',
    USER_SSO_FAILED: 'reg5_user_sso_failed',
    UNABLE_TO_CONTINUE: 'reg5_unable_to_continue',
    SESSION_TIME_OUT: 'reg5_session_time_out',
    PAGE_LAND: 'reg5_page_land',
    PAGE_PASS: 'reg5_page_pass',
    CLICKED_TELADOC_LOGO: 'reg5_clicked_teladoc_logo',
    PAGE_HIDE: 'reg5_page_hide',
};
