/**
 * This file configures the gRPC enrollment service
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/enrollment
 */
// TODO, see if we can merge some of this stuff

// The below imports are installed as a dependency of @livongo protobuf packages and don't need to be managed in our project directly
/* eslint-disable node/no-extraneous-import */
import {BoolValue} from 'google-protobuf/google/protobuf/wrappers_pb';
/* eslint-enable node/no-extraneous-import */
import {SemanticVersion} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/common/types/semanticVersion_pb';
import {ProtocolVersion} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/common/types/protocolVersion_pb';
import {TypedField} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/common/typed_field_pb';
import {QuestionId} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/common/question_ids_pb';
import {Answer} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/med_opt_onboarding_pb';
import {questionFieldTypes} from 'constants/questions';
import {errorMessage} from 'utilities/errors-utils';
import {getGrpcAccessToken} from 'utilities/utils';

export {
    flowAdminPromiseClient,
    medOptOnboardingPromiseClient,
} from 'api/protobuf/medOpt/client';

export const getGrpcProtocolVersion = () => {
    const protocolVersion = new ProtocolVersion();
    const semanticVersion = new SemanticVersion();
    const [major, minor, patch] = process.env.MED_OPT_GRPC_VERSION.split('.');

    semanticVersion.setMajor(major);
    semanticVersion.setMinor(minor);
    // for patch number below, since the dev package might have trailing char and it will causing assertion error
    // so only get first few digit here
    semanticVersion.setPatch(patch.replace(/(^\d+)(.+$)/i, '$1'));

    protocolVersion.setProject(process.env.MED_OPT_GRPC_ID);
    protocolVersion.setVersion(semanticVersion);

    return protocolVersion;
};

const {MULTI_SELECT_ENUM, BOOLEAN_FIELD} = questionFieldTypes;

export const getGrpcMetadata = () => {
    const accessToken = getGrpcAccessToken();

    return {
        // 'x-initial-request-uuid': getUserUUID(),
        Authorization: `Bearer ${accessToken}`,
    };
};

/**
 * answerToProtobuf
 * Converts an answer from a javascript object to gRPC
 * @param {Object} ans answers to be sent to the server
 *  example:
 *       {
 *           questionId: {
 *               id: 21, // FIRST_NAME
 *               version: 1,
 *           },
 *           type: TypedField.FieldTypeCase.TEXT,
 *           value: 'Michael',
 *       },
 * @return {proto.com.livongo.protobuf.grpc.external.med_opt.Answer} answer in gRPC format
 */
export const answerToProtobuf = ans => {
    if (!ans) {
        throw new Error(errorMessage('Missing answer', ans));
    }

    const answer = new Answer();

    if (ans.contentAck) {
        answer.setContentAck(ans.contentAck);
    } else if (ans.triggerAck) {
        answer.setTriggerAck(ans.triggerAck);
    } else {
        if (!ans.questionId) {
            throw new Error(
                errorMessage('Missing questionId,  for answer', ans)
            );
        }

        if (!ans.questionId.id) {
            throw new Error(
                errorMessage('Missing questionId.id for answer', ans)
            );
        }

        const questionId = new QuestionId();
        const typedValue = new TypedField();

        questionId.setId(ans.questionId.id);

        switch (ans.type) {
            case BOOLEAN_FIELD: {
                const boolField = new TypedField.BooleanField();
                const boolVal = new BoolValue();

                boolVal.setValue(ans.value === true || ans.value === 'true');
                boolField.setAnswer(boolVal);
                typedValue.setBoolField(boolField);
                break;
            }
            case MULTI_SELECT_ENUM: {
                const repeatedEnum = new TypedField.MultiSelectEnum();

                repeatedEnum.setAnswerCodesList(ans.value);
                typedValue.setMultiSelectEnum(repeatedEnum);
                break;
            }
            default:
                throw new Error(
                    errorMessage('Unsupported data type for answer', ans)
                );
        }

        answer.setQuestionId(questionId);
        answer.setSubmittedAnswer(typedValue);
    }

    return answer;
};
