/**
 * Activate_experiment gRPC calls
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/enrollment/transformations/activate_experiment.proto
 */
import {ActivateExperimentRequest} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/transformations/activate_experiment_pb';
import {
    enrollmentPromiseClient,
    getGrpcProtocolVersion,
    getGrpcAccessToken,
    getGrpcMetadata,
    setControlGroupName,
} from './enrollment-utils';
/**
 * activateExperiment
 * Ask the BackEnd to attempt to activate vwo experiment
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.transformations.ActivateExperimentRequest>}
 *  Promise
 */
export const activateExperiment = (experimentCode, controlGroup) => {
    const request = new ActivateExperimentRequest();

    request.setControlGroup(setControlGroupName(controlGroup));
    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());
    request.setExperimentCode(experimentCode);

    return enrollmentPromiseClient
        .activateExperiment(request, getGrpcMetadata(true))
        .then(response => response.toObject());
};
