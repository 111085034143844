import {useState} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import useMessage from 'hook/useMessage';
import UserAPI from 'api/rest/user-api';
import NCQAInfo from 'components/ncqaInfo';
import ScreenReader from 'common/screen-reader/ScreenReader';
import {getRegistrationContext} from 'utilities/utils';
import css from './CustomConfirmationNoRecruitableMatch.scss';

const CustomConfirmationNoRecruitableMatch = () => {
    const {t} = useTranslation('content');
    const {showQuestionsFetchErrorMessage} = useMessage();
    const {
        client: registrationClient = '',
        focusedTabId: clientFocusedTabId,
        sessionId: clientSessionId,
    } = getRegistrationContext() ?? {};
    const [
        isFinishRegistrationButtonClicked,
        setIsFinishRegistrationButtonClicked,
    ] = useState(false);

    const finishRegistration = () => {
        // Disable button to avoid being clicked multiple times.
        setIsFinishRegistrationButtonClicked(true);

        UserAPI.getAccountInfo()
            .then(response => {
                const clientInfo = {
                    ...response,
                    focusedTabId: clientFocusedTabId,
                    sessionId: clientSessionId,
                };

                parent.postMessage(JSON.stringify(clientInfo), '*');
            })
            .catch(response => showQuestionsFetchErrorMessage());
    };

    return (
        <>
            <h1 className={css.h1}>
                {t('CustomConfirmationNoRecruitableMatch.title')}
            </h1>
            <p>{t('CustomConfirmationNoRecruitableMatch.paragraph')}</p>
            <br />
            <h2 className={css.subTitle}>
                {t('CustomConfirmationNoRecruitableMatch.subTitle')}
            </h2>
            <br />
            <p>{t('CustomConfirmationNoRecruitableMatch.paragraph2')}</p>
            <br />
            <p>{t('CustomConfirmationNoRecruitableMatch.paragraph3')}</p>
            <p>{t('CustomConfirmationNoRecruitableMatch.paragraph4')}</p>
            <p>{t('CustomConfirmationNoRecruitableMatch.paragraph5')}</p>
            <br />
            <p>{t('CustomConfirmationNoRecruitableMatch.paragraph6')}</p>
            <br />
            <p>{t('CustomConfirmationNoRecruitableMatch.paragraph7')}</p>
            <br />
            <p>
                <Trans
                    i18nKey="content:CustomConfirmationNoRecruitableMatch.paragraph8"
                    components={[
                        <a
                            key="diabetes"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://fepblue.org/diabetes"
                        >
                            <ScreenReader />
                        </a>,
                    ]}
                />
            </p>
            <br />
            <h2 className={css.subTitle}>
                {t('CustomConfirmationNoRecruitableMatch.subTitle2')}
            </h2>
            <br />
            <p>{t('CustomConfirmationNoRecruitableMatch.paragraph9')}</p>
            <br />
            <p>
                <Trans
                    i18nKey="content:CustomConfirmationNoRecruitableMatch.paragraph10"
                    components={[
                        <a
                            key="mystrength"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://fepblue.org/mystrength"
                        >
                            <ScreenReader />
                        </a>,
                    ]}
                />
            </p>
            <br />
            <h2 className={css.subTitle}>
                {t('CustomConfirmationNoRecruitableMatch.subTitle3')}
            </h2>
            <br />
            <p>
                <Trans
                    i18nKey="content:CustomConfirmationNoRecruitableMatch.paragraph11"
                    components={[
                        <a
                            key="contact"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://fepblue.org/contact"
                        >
                            <ScreenReader />
                        </a>,
                    ]}
                />
            </p>
            <NCQAInfo />
            {registrationClient && (
                <Button
                    className={css.finishRegistrationButton}
                    variant="secondary"
                    onClick={finishRegistration}
                    disabled={isFinishRegistrationButtonClicked}
                >
                    {t(
                        'CustomConfirmationNoRecruitableMatch.buttonForSalesforce'
                    )}
                </Button>
            )}
        </>
    );
};

export default CustomConfirmationNoRecruitableMatch;
