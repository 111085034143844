import {useState, useRef} from 'react';
import {v4 as uuidV4} from 'uuid';
import Button from '@teladoc/pulse/ui/Button';
import Form from '@teladoc/pulse/ui/Form';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import PasswordInput from '@teladoc/pulse/ui/PasswordInput';
import TextInput from '@teladoc/pulse/ui/TextInput';
import FormGroupError from '@teladoc/pulse/ui/FormGroupError';
import ValidationUtils from '@livongo/utils/validation';
import useTransLoader from 'i18n/use-trans-loader';
import {authenticate} from 'api/protobuf/enrollment/services';
import {
    setUserEmail,
    setIsAuthenticated,
    setGrpcAccessToken,
    setGrpcAccessTokenTimeStamp,
    cleanSessionInfo,
    setUserSessionExpires,
    getUserUUID,
    setUserUUID,
} from 'utilities/utils';
import {
    mixpanelUserStartOver,
    mixpanelUserLoginSuccessful,
    mixpanelUserLoginFailed,
} from 'utilities/mixpanel-utils';
import Config from 'config';
import useFocus from 'hook/useFocus';
import css from './Login.scss';

const {idleTimerTimeout} = Config;

const LoginForm = () => {
    const {t} = useTransLoader('app');
    const formRef = useRef();
    const [isPending, setIsPending] = useState(false);
    const [hasError, setHasError] = useState(null);
    const userUUID = getUserUUID() || uuidV4();
    const onStartOverClick = evt => {
        mixpanelUserStartOver();

        cleanSessionInfo(true);

        window.location.href = process.env.REG_FLOW_URL;
    };
    const onApiError = error => {
        mixpanelUserLoginFailed();

        setHasError(error);
        setIsPending(false);
    };
    const onFormChange = () => {
        setHasError(false);
    };
    const onSubmitClick = async evt => {
        const form = formRef.current;

        if (form.isValid()) {
            setIsPending(true);

            if (userUUID) {
                setUserUUID(userUUID);
            }

            try {
                const {username, password} = form.submit();
                const response = await authenticate({
                    email: username,
                    password,
                });
                const accessToken = response?.authenticated?.accessToken?.token;

                if (accessToken) {
                    mixpanelUserLoginSuccessful();

                    setUserEmail(username);
                    setIsAuthenticated(true);
                    setGrpcAccessToken(accessToken);
                    setGrpcAccessTokenTimeStamp(new Date().getTime());
                    setUserSessionExpires(Date.now() + idleTimerTimeout);

                    window.location.href = process.env.REG_FLOW_URL;
                } else {
                    onApiError(true);
                }
            } catch (error) {
                onApiError(true);
            }
        }
    };

    useFocus('loginForm');

    return (
        <Form ref={formRef} id="loginForm">
            <TextInput
                id="username"
                name="username"
                type="email"
                label={
                    <Label i18nRequiredVisualLabel=" ">
                        {t('login.email.label')}
                    </Label>
                }
                placeholder={t('login.email.placeholder')}
                pattern={ValidationUtils.EMAIL}
                error={
                    <FormElementError>
                        {t('common:forms.emailError')}
                    </FormElementError>
                }
                required
                autoComplete="email"
                onChange={onFormChange}
            />
            <PasswordInput
                id="password"
                name="password"
                label={
                    <Label i18nRequiredVisualLabel=" ">
                        {t('login.password.label')}
                    </Label>
                }
                placeholder={t('login.password.placeholder')}
                error={
                    <FormElementError>
                        {t('login.password.error')}
                    </FormElementError>
                }
                autoComplete="current-password"
                required
                showToggle
                onChange={onFormChange}
            />
            {hasError && (
                <FormGroupError
                    errors={{
                        details: t('login.error'),
                    }}
                />
            )}
            <div className={css.actions}>
                <Button onClick={onStartOverClick}>
                    {t('login.startOver')}
                </Button>
                <Button
                    className={css.secondaryAction}
                    variant="primary"
                    type="submit"
                    disabled={isPending}
                    onClick={onSubmitClick}
                >
                    {t('login.submit')}
                </Button>
            </div>
        </Form>
    );
};

export default LoginForm;
