/**
 * Create_User gRPC calls
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/enrollment/create_user
 */
import {CreateAccountRequest} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/create_user/create_account_pb';
import {
    enrollmentPromiseClient,
    getGrpcProtocolVersion,
    getGrpcAccessToken,
    getGrpcMetadata,
} from './enrollment-utils';

/**
 * createAccount
 * Ask the BackEnd to attempt to create the account
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.create-user.CreateAccountResponse>}
 *  Promise
 */
export const createAccount = () => {
    const request = new CreateAccountRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());

    return enrollmentPromiseClient.createAccount(request, getGrpcMetadata());
};
