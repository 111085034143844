import {useState, useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';
import Loader from '@teladoc/pulse/ui/Loader';
import Button from '@teladoc/pulse/ui/Button';
import IconCloseDefault from '@teladoc/pulse/icons/close-default.svg';
import Flyout from '@teladoc/pulse/ui/Flyout';
import useMessage from 'hook/useMessage';
import UserAPI from 'api/rest/user-api';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import {
    redirectToMemberPortal,
    getRegistrationContext,
    getIsRedirectFromTeams,
    redirectToTeams,
} from 'utilities/utils';
import {
    mixpanelModalClosedEvent,
    mixpanelModalViewedEvent,
} from 'utilities/mixpanel-utils';
import {programs} from 'constants/app';
import {eligibilityStatus} from 'constants/questions';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import Config from 'config';
import NCQAInfo from 'components/ncqaInfo';
import CoachingPreferences from './CoachingPreferences';
import css from './Confirmation.scss';

const {BEHAVIORAL_HEALTH, DIABETES, DIABETES_DEVICEFLEX} = programs;
const {bhSSOUrl, memberPortalURL, smsIframe} = Config;

const Confirmation = ({contentId, sectionInfo}) => {
    const dispatch = useDispatch();
    const redirectFromTeams = getIsRedirectFromTeams();
    const {t} = useTranslation('content');
    const {showQuestionsFetchErrorMessage} = useMessage();
    const [
        isFinishRegistrationButtonClicked,
        setIsFinishRegistrationButtonClicked,
    ] = useState(false);
    const [showCoachFlyout, setCoachFlyout] = useState(false);
    const [showCoachFlyoutFirstTime, setCoachFlyoutFirstTime] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const [coachingButtonClicked, setCoachingButtonClicked] = useState(false);
    const [prefMeth, setPrefMeth] = useState();
    const [lowerGlucoseLevel, setLowerGlucoseLevel] = useState();
    const [upperGlucoseLevel, setUpperGlucoseLevel] = useState();
    const [isBHSelected, setIsBHSelected] = useState(false);
    const {
        client: registrationClient = '',
        focusedTabId: clientFocusedTabId,
        sessionId: clientSessionId,
    } = getRegistrationContext() ?? {};
    const salesforce = Config.client.salesforce;
    const answersWithinContext = sectionInfo.context?.answersList || [];
    const eligibilityAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.ELIGIBILITY_STATUS
    );
    const isProgramPending =
        eligibilityAnswer?.value?.singleSelectEnumCode ===
        eligibilityStatus.INELIGIBLE;
    const programAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.PROGRAM
    );
    const selectedPrograms = useMemo(
        () => programAnswer?.value?.multiSelectEnum?.enumCodeList || [],
        [programAnswer]
    );

    const readyToLoadForBHOnly =
        selectedPrograms.includes(BEHAVIORAL_HEALTH) &&
        selectedPrograms.length === 1 &&
        !isProgramPending &&
        registrationClient !== salesforce;

    const hasDiabetesProgram = selectedPrograms.includes(DIABETES);
    const isDiabetesDeviceFlex = selectedPrograms.includes(DIABETES_DEVICEFLEX);

    const isDiabetesDeviceFlexOnly =
        selectedPrograms.includes(DIABETES_DEVICEFLEX) &&
        selectedPrograms.length === 1;

    const [readyToLoadForNormal, setReadyToLoadForNormal] = useState(false);

    const onMobileDownloadClick = () => {
        window.open(smsIframe, '_blank');
    };
    const onToggleCoachFlyout = evt => {
        setCoachFlyout(show => !show);
        setCoachingButtonClicked(true);

        if (document.getElementsByClassName('Flyout-open').length > 0) {
            const element = document.getElementsByClassName('Flyout-open')[0];

            element.classList.remove('Flyout-open');
        }

        if (!showCoachFlyout) {
            mixpanelModalViewedEvent('Coaching Preference', contentId, '');
        } else {
            mixpanelModalClosedEvent('Coaching Preference', contentId, '');
        }
    };

    // TODO: Show a more proper error message.
    const getUserInfo = evt => {
        UserAPI.getUserInfo()
            .then(response => {
                setUserInfo(response);
            })
            .catch(response => showQuestionsFetchErrorMessage());
    };

    const redirectToBH = () => {
        window.open(`${bhSSOUrl}?n=t`, 'bhWnd');
    };

    // TODO: Show a more proper error message.
    const finishRegistration = () => {
        // Disable button to avoid being clicked multiple times.
        setIsFinishRegistrationButtonClicked(true);

        UserAPI.getAccountInfo()
            .then(response => {
                const clientInfo = {
                    ...response,
                    focusedTabId: clientFocusedTabId,
                    sessionId: clientSessionId,
                };

                parent.postMessage(JSON.stringify(clientInfo), '*');
            })
            .catch(response => showQuestionsFetchErrorMessage());
    };

    useEffect(() => {
        if (selectedPrograms.includes(BEHAVIORAL_HEALTH)) {
            if (
                selectedPrograms.length !== 1 ||
                (selectedPrograms.length === 1 && registrationClient !== '')
            ) {
                setIsBHSelected(true);
            }
        }
        setReadyToLoadForNormal(true);
    }, [selectedPrograms, registrationClient]);

    /* NavigationButton must be disabled in this component */
    useEffect(() => {
        if (!readyToLoadForNormal) {
            if (!readyToLoadForBHOnly) {
                return;
            }
        }

        let obj = {backBtn: true, nextBtn: true};

        if (!readyToLoadForBHOnly) {
            getUserInfo();
        }
        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, [readyToLoadForNormal]);

    useEffect(() => {
        if (!readyToLoadForNormal) {
            return;
        }

        if (!showCoachFlyout) {
            UserAPI.getCoachingPrefs()
                .then(data => {
                    setPrefMeth(showCoachFlyoutFirstTime ? '' : data.prefMeth);
                    setLowerGlucoseLevel(parseInt(data.BGLowerLimit, 10));
                    setUpperGlucoseLevel(parseInt(data.BGUpperLimit, 10));
                })
                .catch(response => showQuestionsFetchErrorMessage());

            setCoachFlyoutFirstTime(false);
        }
    }, [
        readyToLoadForNormal,
        showCoachFlyout,
        showQuestionsFetchErrorMessage,
        showCoachFlyoutFirstTime,
    ]);

    return (
        <>
            {!readyToLoadForBHOnly && !readyToLoadForNormal && (
                <Loader className="Loader-module-root" />
            )}
            {readyToLoadForBHOnly && <Redirect to={bhSSOUrl} />}
            {readyToLoadForNormal && !isProgramPending && (
                <div>
                    <section className={css.membershipItem}>
                        {registrationClient !== salesforce && (
                            <div className={css.greetingDetails}>
                                <h2>
                                    {t(
                                        `Confirmation.membershipItem.${
                                            redirectFromTeams
                                                ? 'msTeamsHeader'
                                                : 'header'
                                        }`
                                    )}
                                </h2>
                                {!isDiabetesDeviceFlexOnly ? (
                                    <p>
                                        {t(
                                            'Confirmation.membershipItem.paragraph'
                                        )}
                                    </p>
                                ) : (
                                    <p>
                                        {t(
                                            'Confirmation.membershipItem.deviceFlexParagraph'
                                        )}
                                    </p>
                                )}
                            </div>
                        )}
                        {!isDiabetesDeviceFlexOnly && (
                            <>
                                <h3>
                                    {t('Confirmation.membershipItem.subHeader')}
                                </h3>
                                <div className={css.address}>
                                    <p>{userInfo?.recipient}</p>
                                    <p>{userInfo?.addressLine1}</p>
                                    <p>{userInfo?.lastLine}</p>
                                </div>
                            </>
                        )}
                    </section>

                    {!redirectFromTeams && (
                        <>
                            <section className={css.mobileItem}>
                                <div>
                                    <span>
                                        {t('Confirmation.mobileItem.step1')}
                                    </span>
                                    <h3>
                                        {t('Confirmation.mobileItem.subHeader')}
                                    </h3>
                                    {registrationClient !== salesforce && (
                                        <p>
                                            {t(
                                                'Confirmation.mobileItem.description'
                                            )}
                                        </p>
                                    )}
                                    {registrationClient === salesforce && (
                                        <p>
                                            {t(
                                                'Confirmation.mobileItem.descriptionForSalesforce'
                                            )}
                                        </p>
                                    )}
                                    <Button
                                        className={css.confirmBtn}
                                        variant="secondary"
                                        onClick={onMobileDownloadClick}
                                    >
                                        {t('Confirmation.mobileItem.btnText')}
                                    </Button>
                                </div>
                            </section>

                            {registrationClient !== salesforce && (
                                <section className={css.coachItem}>
                                    <div>
                                        <span>
                                            {t('Confirmation.coachItem.step2')}
                                        </span>
                                        <h3>
                                            {t(
                                                'Confirmation.coachItem.subHeader'
                                            )}
                                        </h3>
                                        <p>
                                            {t(
                                                'Confirmation.coachItem.description'
                                            )}
                                        </p>
                                        <Button
                                            className={css.confirmBtn}
                                            variant="secondary"
                                            onClick={onToggleCoachFlyout}
                                        >
                                            {t(
                                                'Confirmation.coachItem.btnText'
                                            )}
                                        </Button>
                                    </div>
                                </section>
                            )}

                            {registrationClient !== salesforce && (
                                <section className={css.homeItem}>
                                    <div>
                                        <span>
                                            {t('Confirmation.homeItem.step3')}
                                        </span>
                                        <h3>
                                            {t(
                                                'Confirmation.homeItem.subHeader'
                                            )}
                                        </h3>
                                        <p>
                                            <Trans
                                                i18nKey={
                                                    isDiabetesDeviceFlex
                                                        ? 'content:Confirmation.homeItem.deviceFlexDescription'
                                                        : 'content:Confirmation.homeItem.description'
                                                }
                                                values={{
                                                    memberPortalURL,
                                                }}
                                                components={[
                                                    /* eslint-disable-next-line*/
                                                    <a
                                                        href="#"
                                                        onClick={
                                                            redirectToMemberPortal
                                                        }
                                                    >
                                                        {memberPortalURL}
                                                    </a>,
                                                ]}
                                            />
                                        </p>
                                        {!isDiabetesDeviceFlex && (
                                            <ul className={css.ul}>
                                                {hasDiabetesProgram && (
                                                    <li>
                                                        {t(
                                                            'Confirmation.homeItem.item1'
                                                        )}
                                                    </li>
                                                )}
                                                <li>
                                                    {t(
                                                        'Confirmation.homeItem.item2'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'Confirmation.homeItem.item3'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'Confirmation.homeItem.item4'
                                                    )}
                                                </li>
                                            </ul>
                                        )}

                                        <Button
                                            onClick={redirectToMemberPortal}
                                            className={css.confirmBtn}
                                            variant="secondary"
                                        >
                                            {t('Confirmation.homeItem.btnText')}
                                        </Button>
                                    </div>
                                </section>
                            )}

                            {registrationClient !== salesforce &&
                                isBHSelected && (
                                    <section>
                                        <div className={css.bhItem}>
                                            <Button
                                                onClick={redirectToBH}
                                                className={css.confirmBtn}
                                                variant="primary"
                                            >
                                                {t(
                                                    'Confirmation.bhItem.btnText'
                                                )}
                                            </Button>
                                        </div>
                                    </section>
                                )}
                        </>
                    )}

                    {redirectFromTeams && (
                        <section className={css.teamsItem}>
                            <div>
                                <h3>
                                    {t('Confirmation.msTeamsItem.subHeader')}
                                </h3>
                                <p>
                                    {t('Confirmation.msTeamsItem.description')}
                                </p>
                                <Button
                                    className={css.confirmBtn}
                                    onClick={redirectToTeams}
                                    variant="primary"
                                >
                                    {t('Confirmation.msTeamsItem.btnText')}
                                </Button>
                            </div>
                        </section>
                    )}

                    <Flyout
                        isOpen={showCoachFlyout}
                        classNameRoot={css.modal}
                        classNameContainer={css.container}
                        renderClose={onCloseClick => {
                            return (
                                <Button
                                    className={css.closeBtn}
                                    onClick={onCloseClick}
                                    aria-label={t(
                                        'Confirmation.prefs.closeAriaLabel'
                                    )}
                                >
                                    <IconCloseDefault />
                                </Button>
                            );
                        }}
                        onRequestClose={onToggleCoachFlyout}
                    >
                        <CoachingPreferences
                            prefMethDefault={
                                coachingButtonClicked ? prefMeth : ''
                            }
                            lowerGlucoseLevelDefault={lowerGlucoseLevel}
                            upperGlucoseLevelDefault={upperGlucoseLevel}
                            close={onToggleCoachFlyout}
                            selectedPrograms={selectedPrograms}
                        />
                    </Flyout>
                </div>
            )}
            {readyToLoadForNormal && isProgramPending && (
                <div>
                    <section className={css.membershipItem}>
                        <div className={css.greetingDetails}>
                            <h2>
                                {t('Confirmation.membershipPending.header')}
                            </h2>
                            <p>
                                {isDiabetesDeviceFlex
                                    ? t(
                                          'Confirmation.membershipPending.paragraphForDBTDF'
                                      )
                                    : t(
                                          'Confirmation.membershipPending.paragraph'
                                      )}
                            </p>
                        </div>
                    </section>
                </div>
            )}
            <NCQAInfo />
            {registrationClient === salesforce && (
                <Button
                    className={css.finishRegistrationBtn}
                    variant="secondary"
                    disabled={isFinishRegistrationButtonClicked}
                    onClick={finishRegistration}
                >
                    {t('Confirmation.adminItem.btnText')}
                </Button>
            )}
        </>
    );
};

Confirmation.propTypes = {
    contentId: PropTypes.string.isRequired,
    sectionInfo: PropTypes.object.isRequired,
};

export default Confirmation;
