import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import CheckableGroup from '@teladoc/pulse/ui/CheckableGroup';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import TextInput from '@teladoc/pulse/ui/TextInput';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
import {questionConfig} from 'constants/questions';
import {getFirstNonEmptyKey} from 'utilities/utils';
import css from './RaceAndGenderV2.scss';

const RaceV2 = ({id, name, question, error}) => {
    const {t} = useTranslation('questions');
    // Create a hash of all the default values set to true
    const questions = question?.dataType?.compoundField?.questionsList || [];
    const questionIDValueHash = {};
    const questionIDDefaultValueHash = {};

    questions.forEach(que => {
        const questionName = getQuestionNameById(que.questionId.id);
        const questionTypedField = getFirstNonEmptyKey(que.dataType);
        const dataType = que.dataType[questionTypedField];

        questionIDValueHash[questionName] = que;

        // can handle TEXT_FIELD, PROBABLY BOOLEAN_FIELD, INTEGER_FIELD, FLOAT_FIELD, and SINGLE_SELECT_ENUM
        if (dataType.userInput) {
            questionIDDefaultValueHash[questionName] = dataType.userInput;
        } else if (dataType.previousAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswer.value;
        } else if (dataType.defaultAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.defaultAnswer.value;
        } else if (dataType.previousAnswerCode) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswerCode.value;
        } else if (dataType.defaultAnswerCode) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswerCode.value;
        } else if (dataType.previousAnswerCodesList) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswerCodesList;
        } else if (dataType.defaultAnswerCodesList) {
            questionIDDefaultValueHash[questionName] =
                dataType.defaultAnswerCodesList;
        }
    });

    // Create a hash of all the default values set to true
    const defaultRaceCheckedHash =
        questionIDDefaultValueHash?.RACE_SELECTION.reduce(
            (hash, defaultValue) => ({...hash, [defaultValue]: true}),
            {}
        );

    const [checkedHash, setCheckedHash] = useState(defaultRaceCheckedHash);
    const [customValidationError, setCustomValidationError] = useState(null);
    const [key, setKey] = useState(new Date().getTime());

    useEffect(() => {
        if (error) {
            setCustomValidationError(error);
        }
    }, [error]);

    // this is hardcoded answerCodesList, since BE have difficulty to change it
    // ideally we should use the answerCodesList from Backend just like what we did here: src/components/question/Question.js:263
    const answerCodes = ['AIAN', 'ACJK', 'BAA', 'NHOPI', 'WC', 'DK', 'O'];
    const options = answerCodes.map(code => {
        const label = t(`RACE_SELECTION.options.${code}`);

        return {
            value: code,
            label,
        };
    });

    const handleOnChange = (value, checked) => {
        if (value === 'DK') {
            const newCheckedHash = {
                [value]: checked,
            };

            setCheckedHash(newCheckedHash);
            setKey(new Date().getTime());
        } else {
            const newCheckedHash = {
                ...checkedHash,
                [value]: checked,
            };

            setCheckedHash(newCheckedHash);
        }
    };

    const onTextInputChange = () => {
        setCustomValidationError(false);
    };

    return (
        <div>
            {questionIDValueHash.RACE_SELECTION && (
                <CheckableGroup
                    key={key}
                    allowMultiSelect
                    id={questionConfig.RACE_SELECTION.id}
                    name={`${name}_-_${questionIDValueHash.RACE_SELECTION.questionId.id}`}
                    label={
                        <Label i18nRequiredVisualLabel=" ">
                            {t(questionConfig.RACE_SELECTION.label)}
                        </Label>
                    }
                    error={
                        <FormElementError>
                            {t(questionConfig.RACE_SELECTION.error)}
                        </FormElementError>
                    }
                    required={questionIDValueHash.RACE_SELECTION.required}
                    onChange={evt => {
                        handleOnChange(evt.target.value, evt.target.checked);
                    }}
                    helpNode={t(questionConfig.RACE_SELECTION.helpNode)}
                >
                    {options.map(({value, label: itemLabel}) => {
                        return (
                            <Checkbox
                                key={value}
                                classNameRoot={css.checkboxRoot}
                                id={`${id}_${value}`}
                                name={`${name}_-_${questionIDValueHash.RACE_SELECTION.questionId.id}`}
                                value={value}
                                label={<Label>{itemLabel}</Label>}
                                i18nItemLabel={itemLabel}
                                onChange={evt => {
                                    handleOnChange(
                                        evt.target.value,
                                        evt.target.checked
                                    );
                                }}
                                defaultChecked={checkedHash[value] === true}
                                disabled={
                                    value !== 'DK' && checkedHash.DK === true
                                }
                            />
                        );
                    })}
                </CheckableGroup>
            )}
            {checkedHash.O === true && checkedHash.DK !== true && (
                <TextInput
                    type={questionConfig.RACE_OTHER.type}
                    id={questionConfig.RACE_OTHER.id}
                    name={`${name}_-_${questionIDValueHash.RACE_OTHER.questionId.id}`}
                    defaultValue={questionIDDefaultValueHash?.RACE_OTHER}
                    pattern={questionConfig.RACE_OTHER.pattern}
                    label={
                        <Label i18nRequiredVisualLabel="*">
                            {t(questionConfig.RACE_OTHER.label)}
                        </Label>
                    }
                    placeholder={t(questionConfig.RACE_OTHER.placeholder)}
                    onChange={onTextInputChange}
                    customValidationError={customValidationError}
                />
            )}
        </div>
    );
};

RaceV2.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    error: PropTypes.element,
};

export default RaceV2;
