/**
 * This file gathers all gRPC services used so that we can set the GRPCWEB_DEVTOOLS in one spot.
 * For now, we are using only the biometric_alerts Admin gPRC service
 */
import {AlertFlowPromiseClient} from '@livongo/protobuf-grpc-external-biometric_alerts/lib/livongo/protobuf/grpc/external/biometric_alerts/alertFlow_grpc_web_pb';

export const AlertFlowServicePromiseClient = new AlertFlowPromiseClient(
    process.env.SERVICE_URL
);

const developmentEnvs = ['local', 'integration', 'preprod'];

if (developmentEnvs.indexOf(process.env.APP_ENV) > -1) {
    // eslint-disable-next-line
    const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

    // enable debugging grpc calls
    enableDevTools([AlertFlowServicePromiseClient]);
}
