import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useTranslation, Trans} from 'react-i18next';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import Button from '@teladoc/pulse/ui/Button';
import messageConfig from 'constants/messages';
import {clearMessage} from 'store/message/message-actions';
import {mixpanelPdfViewedEvent} from 'utilities/mixpanel-utils';
import Config from 'config';
import {getCallBackUrl, cleanSessionInfo} from 'utilities/utils';
import css from './modalStyle.scss';

const {
    memberSupportPhoneNumber,
    regURL,
    teladocUrl,
    oneAppMemberSupportPhoneNumber,
} = Config;

const GeneralMessage = ({id, isOneApp}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const locale = getStoredLang().toLowerCase();
    const dispatch = useDispatch();
    const messageValue = messageConfig[id] || {};

    const {
        title,
        content,
        regFlowContent,
        oneAppTitle,
        oneAppContent,
        oneAppFlowContent,
        oneAppButtonLabel,
        primaryButtonText,
        primaryButtonLink,
        secondaryButtonText,
        secondaryButtonLink,
        cancelText,
    } = messageValue;

    let modalId = '';

    switch (id) {
        case 'MODAL_CLIENT_CAPPED':
            modalId = 'Client Capped';
            break;
        case 'MODAL_REGISTRATION_CLOSED':
            modalId = 'Registration Closed';
            break;
        case 'MODAL_UNDER_AGE':
            modalId = 'Under Age';
            break;
    }

    const onPrimaryClick = evt => {
        if (primaryButtonLink[0] === '/') {
            window.open(`${primaryButtonLink}?locale=${locale}`, '_self');
        } else {
            mixpanelPdfViewedEvent('Healthy Eating Guide', '', '', modalId);
            window.open(
                `${primaryButtonLink}?locale=${locale}`,
                '_blank',
                'noopener noreferrer'
            );
        }
    };
    const onSecondaryClick = evt => {
        if (secondaryButtonLink[0] === '/') {
            window.open(`${secondaryButtonLink}?locale=${locale}`, '_self');
        } else {
            mixpanelPdfViewedEvent('Healthy Eating Guide', '', '', modalId);
            window.open(
                `${secondaryButtonLink}?locale=${locale}`,
                '_blank',
                'noopener noreferrer'
            );
        }
    };

    const onCancelClick = evt => {
        dispatch(clearMessage());
    };

    const redirectToOneApp = () => {
        const redirectUrl = getCallBackUrl();

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    return (
        <>
            {!isOneApp && (
                <div className={css.modalContainer}>
                    {title && <h1>{t(title, {regURL})}</h1>}

                    <div className={css.contentContainer}>
                        {regFlowContent && Array.isArray(regFlowContent) ? (
                            regFlowContent.map((contentLine, i) => (
                                <p key={i}>
                                    <Trans
                                        i18nKey={`messages:${contentLine}`}
                                        values={regURL}
                                        components={[
                                            // eslint-disable-next-line
                                            <a
                                                href={`tel:${memberSupportPhoneNumber}`}
                                            />,
                                        ]}
                                    />
                                </p>
                            ))
                        ) : (
                            <p>
                                <Trans
                                    i18nKey={`messages:${content}`}
                                    values={{regURL}}
                                    components={[
                                        // eslint-disable-next-line
                                        <a
                                            href={`tel:${memberSupportPhoneNumber}`}
                                        />,
                                    ]}
                                />
                            </p>
                        )}
                    </div>

                    {primaryButtonText && (
                        <Button
                            className={css.modalButton}
                            variant="primary"
                            onClick={onPrimaryClick}
                        >
                            {t(primaryButtonText)}
                        </Button>
                    )}
                    {secondaryButtonText && (
                        <Button
                            className={css.modalButton}
                            onClick={onSecondaryClick}
                        >
                            {t(secondaryButtonText)}
                        </Button>
                    )}
                    {cancelText && (
                        <Button
                            className={css.modalButton}
                            onClick={onCancelClick}
                        >
                            {t(cancelText)}
                        </Button>
                    )}
                    {id === 'MODAL_ZIPCODE_NOT_SUPPORTED' && (
                        <div className={css.oneAppLegalTextBlock}>
                            <Trans
                                i18nKey="messages:MODAL_ZIPCODE_NOT_SUPPORTED.callUs"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a href={`tel:${memberSupportPhoneNumber}`}>
                                        {memberSupportPhoneNumber}
                                    </a>,
                                ]}
                            />
                        </div>
                    )}
                </div>
            )}

            {isOneApp && (
                <div className={css.modalContainer}>
                    {oneAppTitle ? (
                        <h1>{t(oneAppTitle, {teladocUrl})}</h1>
                    ) : (
                        <h1>{t(title, {regURL})}</h1>
                    )}

                    <div className={css.contentContainer}>
                        {oneAppFlowContent &&
                        Array.isArray(oneAppFlowContent) ? (
                            oneAppFlowContent.map((oneAppContentLine, i) => (
                                <p key={i}>
                                    <Trans
                                        i18nKey={`messages:${oneAppContentLine}`}
                                        values={teladocUrl}
                                        components={[
                                            // eslint-disable-next-line
                                            <a
                                                href={`tel:${oneAppMemberSupportPhoneNumber}`}
                                            />,
                                        ]}
                                    />
                                </p>
                            ))
                        ) : (
                            <p>
                                <Trans
                                    i18nKey={`messages:${oneAppContent}`}
                                    values={{teladocUrl}}
                                    components={[
                                        // eslint-disable-next-line
                                        <a
                                            href={`tel:${oneAppMemberSupportPhoneNumber}`}
                                        />,
                                    ]}
                                />
                            </p>
                        )}
                    </div>
                    {oneAppButtonLabel && (
                        <Button
                            className={css.modalButton}
                            variant="primary"
                            onClick={redirectToOneApp}
                        >
                            {t(oneAppButtonLabel)}
                        </Button>
                    )}
                    {id === 'MODAL_ZIPCODE_NOT_SUPPORTED' && (
                        <div className={css.oneAppLegalTextBlock}>
                            <Trans
                                i18nKey="messages:MODAL_ZIPCODE_NOT_SUPPORTED.oneAppCallUs"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={`tel:${oneAppMemberSupportPhoneNumber}`}
                                    >
                                        {oneAppMemberSupportPhoneNumber}
                                    </a>,
                                ]}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

GeneralMessage.propTypes = {
    id: PropTypes.string,
    isOneApp: PropTypes.bool.isRequired,
};

export default GeneralMessage;
