export const REGISTRATION = 'registration';
export const MED_OPT = 'medOpt';
export const UPSELL = 'upsell';

export const programs = {
    DIABETES: 'DIABETES',
    PREDIABETES: 'PREDIABETES',
    HYPERTENSION: 'HYPERTENSION',
    WEIGHT_MANAGEMENT: 'WEIGHT_MANAGEMENT',
    BEHAVIORAL_HEALTH: 'BEHAVIORAL_HEALTH',
    CHRONIC_KIDNEY_DISEASE: 'CHRONIC_KIDNEY_DISEASE',
    HEART_FAILURE: 'HEART_FAILURE',
    DIABETES_DEVICEFLEX: 'DIABETES_DEVICEFLEX',
    ADV_WEIGHT_MANAGEMENT: 'ADV_WEIGHT_MANAGEMENT',
    COMPR_WEIGHT_CARE: 'COMPR_WEIGHT_CARE',
    ADV_PREDIABETES: 'ADV_PREDIABETES',
    COMPR_PREDIABETES_CARE: 'COMPR_PREDIABETES_CARE',
};

export const supportedLanguages = {
    'en-US': 'English (United States)',
    'es-US': 'Español (United States)',
};

export {contentIds} from './contentIds';
export {nodeTitleIds} from './nodeTitleIds';
