import {useEffect} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import PropTypes from 'prop-types';
import FormGroupError from '@teladoc/pulse/ui/FormGroupError';
import ScreenReader from 'common/screen-reader/ScreenReader';
import Config from 'config';
import css from './AwaitingPrivoConsent.scss';

const AwaitingPrivoConsent = ({contentId, setNavigationLabel}) => {
    const {t} = useTranslation('content');
    const {memberSupportPhoneNumber} = Config;

    const phoneLink = (
        <a href={`tel:${memberSupportPhoneNumber}`}>
            <ScreenReader />
        </a>
    );

    useEffect(() => {
        if (setNavigationLabel) {
            setNavigationLabel({nextLabel: 'buttonsLabel.confirmPrivoConsent'});
        }
    }, [setNavigationLabel]);

    return (
        <>
            <div>
                <h1 className={css.h1}>{t('AwaitingPrivoConsent.title')}</h1>

                <p className={css.p}>
                    <Trans>{t('AwaitingPrivoConsent.paragraph')}</Trans>
                </p>
                <p className={css.p}>{t('AwaitingPrivoConsent.paragraph2')}</p>
            </div>
            {(contentId === 'AwaitingPrivoConsent' ||
                contentId === 'PrivoConsentNotGiven') && (
                <FormGroupError
                    className={css.li}
                    title={t('AwaitingPrivoConsent.waitingMessageTitle')}
                    errors={[
                        {
                            message: t(
                                'AwaitingPrivoConsent.waitingParagraph1'
                            ),
                        },
                        {
                            message: (
                                <Trans
                                    i18nKey="content:AwaitingPrivoConsent.waitingParagraph2"
                                    components={[phoneLink]}
                                />
                            ),
                        },
                    ]}
                />
            )}
        </>
    );
};

AwaitingPrivoConsent.propTypes = {
    contentId: PropTypes.string,
    setNavigationLabel: PropTypes.func,
};

export default AwaitingPrivoConsent;
