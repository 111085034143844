import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {getCountryCallingCode} from 'react-phone-number-input';
import ArrowLeftDefault from '@teladoc/pulse/icons/arrow-left-default.svg';
import Form from '@teladoc/pulse/ui/Form';
import Loader from '@teladoc/pulse/ui/Loader';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {
    navigateBack,
    navigateHere,
    navigateNext,
    submitAnswers,
    getQuestionNameById,
} from 'api/protobuf/enrollment/services';
import Config from 'config';
import {errorTypes, IDK} from 'constants/type';
import {questionConfig, questionFieldTypes} from 'constants/questions';
import {COUNTRY_CODE} from 'constants/countryCode';
import useNavigation from 'hook/useNavigation';
import useMessage from 'hook/useMessage';
import {
    manageSolutionRequest,
    ManageSolutionResult,
} from 'api/protobuf/enrollment/manageSolution';
import {
    mixpanelButtonClicked,
    mixpanelGeneralError,
    mixpanelPageLand,
    mixpanelPagePass,
    mixpanelSessionTimeOut,
    mixpanelUnableToContinue,
    mixpanelUpsellProgramEnrolled,
} from 'utilities/mixpanel-utils';
import PIWIKUtils from 'utilities/piwik-utils';
import {amplitudeFlag, AmplitudeUtils} from 'utilities/amplitude-utils';
import {
    devConsoleLog,
    getFirstNonEmptyKey,
    getRegistrationContext,
    getRegistrationStepIndex,
    getRegistrationStepLoader,
    getRegistrationStepUrls,
    setRegistrationStepLoader,
    setRegistrationStepUrls,
    setTitleFromGRPC,
    setCallBackUrl,
    removeSpecificQuestionsIfNotInForm,
    getRestApiAccessToken,
    setUpsellProgram,
    getUpsellProgram,
} from 'utilities/utils';
import NavigationButton from 'components/navigationButton';
import Section from 'components/section';
import Info from 'components/info';
import css from './Upsell.scss';

const {ILLEGAL_SCALAR_VALUE, INVALID_PHONE_NUMBER} = errorTypes;

const {
    TEXT_FIELD,
    SINGLE_SELECT_ENUM,
    MULTI_SELECT_ENUM,
    BOOLEAN_FIELD,
    INTEGER_FIELD,
    FLOAT_FIELD,
    DATE_FIELD,
    COMPOUND_FIELD,
    UNION_FIELD,
} = questionFieldTypes;
const {consentSection, upsellSections: sections} = Config;

// TODO: Move out for better organized.
/**
 * Get data for previous step.
 * This function is for previous button.
 * Pace is one step but need to continuously check until not beginning of section.
 * @param {number} sectionIndex - Index of section.
 * @param {string} sectionPathname - Pathname of section.
 * @returns {Promise<unknown>} - Promise.
 */
const getDataForPreviousStep = async (sectionIndex, sectionPathname) => {
    let navigateResponse;
    let sectionIndexForNavigation = sectionIndex;
    let sectionsForNavigation = [...sections];

    if (sectionPathname === consentSection.pathname) {
        const [signup, ...rest] = sections;

        sectionIndexForNavigation = 1;
        sectionsForNavigation = [signup, consentSection, ...rest];
    }

    /*
     * Loop under the configured sections size
     *  to cover some corner case which run into
     *  beginning of section right after another beginning of section.
     */
    for (
        let i = sectionIndexForNavigation, j = sectionIndexForNavigation;
        i > -1;
        i--
    ) {
        // eslint-disable-next-line
        navigateResponse = await navigateBack(sectionsForNavigation[j].id)
            // Comment out to take mock data.
            // TODO: Uncomment back when BE is ready.
            // .then(response => response.toObject())
            .then(response => response)
            .catch(
                error =>
                    // TODO: Test error.
                    error
            );

        if (navigateResponse.beginningOfSectionReached) {
            // Move to previous section.
            j--;
        } else {
            break;
        }
    }

    // Return the final response get from gRPC.
    return navigateResponse;
};

// TODO: Move out for better organized.
/**
 * Get data for next step.
 * This function is for next button.
 * Pace is one step but need to continuously check until not end of section.
 * @param {number} sectionIndex - Index of section.
 * @param {string} sectionPathname - Pathname of section.
 * @returns {Promise<unknown>} - Promise.
 */
const getDataForNextStep = async (sectionIndex, sectionPathname) => {
    let navigateResponse;
    let sectionIndexForNavigation = sectionIndex;
    const sectionsForNavigation = [...sections];

    if (sectionPathname === consentSection.pathname) {
        sectionIndexForNavigation = 0;
        sectionsForNavigation.unshift(consentSection);
    }
    /*
     * Loop under the configured sections size
     *  to cover some corner case which run into
     *  end of section right after another end of section.
     */
    for (
        let i = sectionIndexForNavigation, j = sectionIndexForNavigation;
        i < sectionsForNavigation.length;
        i++
    ) {
        // eslint-disable-next-line
        navigateResponse = await navigateNext(sectionsForNavigation[j].id)
            .then(response => response)
            .catch(
                error =>
                    // TODO: Test error.
                    error
            );

        if (navigateResponse.endOfSectionReached) {
            // Move to next section.
            j++;
        } else {
            break;
        }
    }

    // Return the final response get from gRPC.
    return navigateResponse;
};

/**
 * Convert formdata into a hashmap with the question id as key and answer as value
 * For compound questions, the key is a hash of sub question ids and sub question answers
 * @param {Object} formData - Hashmap of question ids to answers.
 * @returns {Object} - hashmap with the question id as key and answer as value
 */
const parseAnswersFromForm = (formData = {}) => {
    return Object.keys(formData).reduce((hash, key) => {
        const [, questionId, subQuestionId, grandQuestionId] = key.split('_-_'); // ${QUESTION_NAME}_-_${QUESTION_ID}_-_${SUB_QUESTION_ID_INT}
        const value = formData[key];
        const countryCode = COUNTRY_CODE[formData?.countryCode];

        // incorrect format; questionId start at one and can't be zero
        // also to protect again incorrect data from formData: { countryCode: US }
        if (!questionId) {
            devConsoleLog(
                `Skipping form data because of no question ID: ${key}`
            );

            return hash;
        }

        if (!grandQuestionId) {
            if (
                !subQuestionId &&
                questionId !== `${QuestionId.Values.MOBILE_PHONE_INTERNATIONAL}`
            ) {
                hash[questionId] = value;
            } else {
                // compound question
                if (!hash[questionId]) {
                    hash[questionId] = {};
                }

                // Special handle for internation phone since it return as compound question.
                if (
                    questionId ===
                    `${QuestionId.Values.MOBILE_PHONE_INTERNATIONAL}`
                ) {
                    hash[questionId][QuestionId.Values.MOBILE_PHONE_COUNTRY] =
                        countryCode;

                    // for international address, we will append the country calling code prefix for it
                    hash[questionId][
                        QuestionId.Values.PHONE_MOBILE
                    ] = `+${getCountryCallingCode(
                        formData?.countryCode
                    )} ${value}`;
                } else {
                    hash[questionId][subQuestionId] = value;
                }
            }
        } else {
            // union question
            if (!hash[questionId]) {
                hash[questionId] = {};
            }

            if (!hash[questionId][subQuestionId]) {
                hash[questionId][subQuestionId] = {};
            }

            hash[questionId][subQuestionId][grandQuestionId] = value;
        }

        return hash;
    }, {});
};

/**
 * convert answer hash and question array into Answer format for BE
 * @param {Object} answerHash - Hashmap of question ids to answers.
 * @param {Object[]} questions - Index of section.
 * @returns {Object[]} - Array of Answers.
 */
const parseAnswers = (answerHash = {}, questions = []) => {
    const answers = [];

    questions.forEach(question => {
        const questionId = question.questionId.id;
        const value = answerHash[questionId];
        const dataType = getFirstNonEmptyKey(question.dataType);

        // missing typedValueType, badly formatted data
        if (!question.dataType || Object.keys(question.dataType).length === 0) {
            throw new Error(
                `dataType not found in Question object for: ${questionId}`
            );
        }

        // optional question and not returning this answer to the backend
        if (!question.required && (value === IDK || !value)) {
            devConsoleLog(
                `Skipping answer for ${questionId} because it is optional and the user provided no answer`
            );

            return;
        }

        if (dataType === UNION_FIELD) {
            const subAnswerHash = value;
            const subQuestions = question.dataType[
                dataType
            ].questionsList.filter(
                q => `${q.questionId.id}` === Object.keys(subAnswerHash)[0]
            );

            const subValues = parseAnswers(subAnswerHash, subQuestions);

            answers.push({
                questionId: question.questionId,
                type: dataType,
                value: subValues[0],
            });
        } else if (dataType === COMPOUND_FIELD) {
            const subAnswerHash = value;
            const subQuestions = question.dataType[dataType].questionsList;
            const subValues = parseAnswers(subAnswerHash, subQuestions);

            answers.push({
                questionId: question.questionId,
                type: dataType,
                subValues,
            });
        } else {
            // Handle special case for under 13.
            if (!value && questionId === QuestionId.Values.LAST_NAME) {
                return;
            }

            let answerValue = value;

            if (dataType === MULTI_SELECT_ENUM) {
                answerValue = value ? [].concat(value) : [];
            }

            answers.push({
                questionId: question.questionId,
                type: dataType,
                value: answerValue,
            });
        }
    });

    return answers;
};

const manageSolution = async () => {
    try {
        const accessCode = getRestApiAccessToken();

        const manageSolutionResponse = await manageSolutionRequest(
            accessCode
        ).catch(error => {
            mixpanelGeneralError({
                name: 'manageSolution',
                location: 'Upsell.manageSolution.catchOfPromise',
                additionalInfo: error,
            });

            return 'manageSolution error';
        });

        const {result, failureDetail} = manageSolutionResponse;

        if (result === ManageSolutionResult.SUCCESS) {
            mixpanelUpsellProgramEnrolled();
            PIWIKUtils.trackUpsellProgramEnrolled();
        } else {
            // Todo: show different error modal based on failureDetail if needed

            mixpanelGeneralError({
                name: 'manageSolution',
                location: 'Upsell.manageSolution.failureDetail',
                additionalInfo: failureDetail,
            });

            return 'manageSolution error';
        }
    } catch (error) {
        mixpanelGeneralError({
            name: 'manageSolution',
            location: 'Upsell.manageSolution.catchOfTry',
            additionalInfo: error,
        });

        return 'manageSolution error';
    }
};

const Upsell = ({history, location}) => {
    const {t} = useTranslation(['questions', 'common', 'notification']);
    const {
        showBadRequestErrorMessage,
        showTimeoutMessage,
        showInternationalNumberNotAllowedErrorMessage,
        showZipcodeNotSupported,
    } = useMessage();

    const formRef = useRef();
    // State to flag loading or not.
    const [isLoading, setIsLoading] = useState(false);
    // State to hold the whole return from gRPC.
    const [currentUpsellStep, setCurrentUpsellStep] = useState({});
    // State to show errors for each questions from submit answers and navigate gRPC responses
    const [questionErrors, setQuestionErrors] = useState([]);
    // Get step URLs from registration history.
    const stepUrls = getRegistrationStepUrls();
    // Get previous step index from registration history.
    const previousStepIndex = getRegistrationStepIndex();
    // Get current step index from URL.
    const currentStepIndex = stepUrls.findIndex(
        url => url.indexOf(location.pathname) === 0
    );

    // Get current section pathname from URL.
    const [, , currentSectionPathname] = location.pathname.split('/');

    // Get current section index based on the pathname.
    let currentSectionIndex = sections.findIndex(
        s => s.pathname === currentSectionPathname
    );
    // state to store the navigationNextLabel
    const [navigationNextLabel, setNavigationNextLabel] = useState(
        t('buttonsLabel.next')
    );
    // state to flag navigateBackPossible
    const [isBackButtonDisabled, setIsBackButtonDisabled] = useState(false);
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;
    const sectionInfo = currentUpsellStep;

    // check to see if currentSectionPathname in sections array is found and in the stepUrls
    // otherwise set it to 0
    const pathNameFoundInStepUrls = stepUrls.some(
        url => url.indexOf(currentSectionPathname) !== -1
    );

    if (currentSectionIndex === -1 || !pathNameFoundInStepUrls) {
        currentSectionIndex = 0;
    }

    const disableNavBtn = useSelector(state => state.navigationBtnDisable);
    const dispatch = useDispatch();

    const setNavigationLabel = label => {
        const {nextLabel} = label;

        if (nextLabel) {
            setNavigationNextLabel(t(nextLabel));
        }
    };

    const handleIdle = () => {
        mixpanelSessionTimeOut(currentUpsellStep);

        if (amplitudeFlag) {
            AmplitudeUtils.trackSessionTimeOut(currentUpsellStep);
        }

        showTimeoutMessage();
    };

    /**
     * Call BE API to submit answers.
     * Construct URL and redirect to previous page if good to go back.
     * Page URL will include dynamically generated step id.
     * @returns {void}
     */
    const gotoPreviousStep = async () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        // Mixpanel navigation back click event track.
        mixpanelButtonClicked(
            currentUpsellStep,
            t('buttonsLabel.back'),
            'navigationBack'
        );

        // TODO: Display message if value of forms changed.
        // Show spinning to give visual effect.
        setIsLoading(true);
        // Set flag to signal the step is loaded by app action, not browser action.
        setRegistrationStepLoader(true);

        let navigateBackResponse;

        try {
            navigateBackResponse = await getDataForPreviousStep(
                currentSectionIndex,
                currentSectionPathname
            );
        } catch (error) {
            setIsLoading(false);
            setRegistrationStepLoader(false);
            mixpanelGeneralError({
                name: 'getDataForPreviousStep',
                location:
                    'Registration.gotoPreviousStep.getDataForPreviousStep',
                additionalInfo: error,
            });
            showBadRequestErrorMessage();

            return;
        }

        const {previousStep, navigateBackPossible, redirectUrl, title} =
            navigateBackResponse;

        if (redirectUrl) {
            setCallBackUrl(redirectUrl.value);
        }

        if (title) {
            setTitleFromGRPC(title);
        }

        setIsBackButtonDisabled(navigateBackPossible);

        if (previousStep) {
            const {sectionId, stepNumber, numberOfSteps} =
                previousStep.context || {};
            let previousSection = {};

            if (sectionId === consentSection.id) {
                previousSection = consentSection;
            } else {
                previousSection = sections.find(
                    section => section.id === sectionId
                );
            }

            if (previousSection) {
                // It is a valid section and construct the URL for previous step.
                const previousStepUrl = `${Config.upsellUrl}/${previousSection.pathname}/${numberOfSteps}/${stepNumber}`;

                if (currentStepIndex === 0) {
                    /*
                     * Handle login back in user case.
                     * Add the new step to the beginning.
                     */
                    stepUrls.unshift(previousStepUrl);
                } else if (previousStepUrl !== stepUrls[currentStepIndex - 1]) {
                    /*
                     * Step URL is different than the one in the Upsell history.
                     * Replace old one since it is not valid anymore.
                     */
                    stepUrls.splice(currentStepIndex - 1, 1, previousStepUrl);
                }

                // Update registration history to local storage or cookie.
                setRegistrationStepUrls(stepUrls);
                // Do the redirection.
                history.push(previousStepUrl);
                // Update current Upsell Step
                setCurrentUpsellStep(previousStep);
            } else {
                // TODO: Display proper message.
            }
        } else {
            // TODO: Display proper message.
        }
        setIsLoading(false);
        setRegistrationStepLoader(false);

        // Mixpanel page pass event track.
        mixpanelPagePass(currentUpsellStep);

        if (amplitudeFlag) {
            AmplitudeUtils.trackPagePass(currentUpsellStep);
        }
    };

    /**
     * Call BE API to submit answers.
     * Construct URL and redirect to next page if good to go next.
     * Page URL will include dynamically generated step id.
     * @returns {void}
     */
    const gotoNextStep = async () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        mixpanelButtonClicked(
            currentUpsellStep,
            navigationNextLabel,
            'navigationNext'
        );

        let upsellFinished = false;
        const form = formRef.current;

        if (!form.isValid()) {
            form.animateToInvalid();

            return;
        }

        // Show spinning to give visual effect.
        setIsLoading(true);
        // Set flag to signal the step is loaded by app action, not browser action.
        setRegistrationStepLoader(true);

        // If there are any questions, then get the formData and send to API
        if (currentUpsellStep.questionsToAsk?.questionsList) {
            // Get data from form and parse the results with questions into Answers
            const {...formData} = form.submit();

            // here is the fix for GROW-2825
            // we only need to filter out the empty TERMS_AND_CONDITIONS question: questionID - 86
            const questions = removeSpecificQuestionsIfNotInForm(
                formData,
                currentUpsellStep.questionsToAsk.questionsList,
                currentUpsellStep.questionsToAsk.nodeTitleId,
                QuestionId.Values.TERMS_AND_CONDITIONS,
                QuestionId.Values.SUPPORTED_REGION,
                QuestionId.Values.ZIP
            );
            let answerHash;
            let answers;

            try {
                answerHash = parseAnswersFromForm(formData);
                answers = parseAnswers(answerHash, questions);
            } catch (err) {
                setIsLoading(false);
                setRegistrationStepLoader(false);
                mixpanelGeneralError({
                    name: 'parseAnswers',
                    location: 'Upsell.goToNextStep.parseAnswers',
                    additionalInfo: err,
                });
                showBadRequestErrorMessage();

                return;
            }

            // Helper for adding the original user input into the question object
            const addUserInputToQuestion = (questionObj, userInput) => {
                const dataType = getFirstNonEmptyKey(questionObj.dataType);

                if (
                    [
                        TEXT_FIELD,
                        BOOLEAN_FIELD,
                        INTEGER_FIELD,
                        FLOAT_FIELD,
                        SINGLE_SELECT_ENUM,
                        MULTI_SELECT_ENUM,
                    ].includes(dataType) ||
                    userInput === IDK
                ) {
                    if (
                        dataType === MULTI_SELECT_ENUM &&
                        !Array.isArray(userInput)
                    ) {
                        questionObj.dataType[dataType].userInput = [userInput];
                    } else {
                        questionObj.dataType[dataType].userInput = userInput;
                    }
                } else if (dataType === DATE_FIELD) {
                    const [month, day, year] = userInput.split('/');

                    questionObj.dataType[dataType].userInput = {
                        year: Number(year),
                        month: Number(month),
                        day: Number(day),
                    };
                }
            };

            const questionIdArray = [];

            // For adding the original user input into the question object
            questions.forEach(question => {
                const qId = question.questionId.id;
                const dataType = getFirstNonEmptyKey(question.dataType);

                // Save question ID for later validation.
                questionIdArray.push(qId);

                if (answerHash[qId]) {
                    if (
                        dataType !== COMPOUND_FIELD &&
                        dataType !== UNION_FIELD
                    ) {
                        addUserInputToQuestion(question, answerHash[qId]);
                    } else if (dataType === COMPOUND_FIELD) {
                        const subAnswerHash = answerHash[qId];

                        question.dataType[COMPOUND_FIELD].questionsList.forEach(
                            subQuestion => {
                                if (subAnswerHash[subQuestion.questionId.id]) {
                                    addUserInputToQuestion(
                                        subQuestion,
                                        subAnswerHash[subQuestion.questionId.id]
                                    );
                                }
                            }
                        );
                    }
                }
            });

            try {
                const submitAnswersResponse = await submitAnswers(answers);
                const {changeSolutionPossible, invalidAnswers, badRequest} =
                    submitAnswersResponse;

                if (invalidAnswers || badRequest) {
                    // TODO: Display proper message.
                    setIsLoading(false);
                    setRegistrationStepLoader(false);

                    const errorType = getFirstNonEmptyKey(
                        invalidAnswers?.validationResultsList[0]
                            ?.errorTypesList[0]
                    );

                    // dispatch internationalNumberNotAllowed error modal on phone question when INVALID_PHONE_NUMBER
                    if (
                        errorType === INVALID_PHONE_NUMBER &&
                        questionIdArray.includes(
                            QuestionId.Values?.PHONE_MOBILE
                        )
                    ) {
                        // currently there is only one message in this invalidPhoneNumber error type
                        const clientNameFromError =
                            invalidAnswers?.validationResultsList[0]
                                ?.errorTypesList[0]?.invalidPhoneNumber
                                ?.internationalNumberNotAllowed?.clientName;

                        showInternationalNumberNotAllowedErrorMessage(
                            clientNameFromError
                        );
                    }

                    // dispatch general error modal on ILLEGAL_SCALAR_VALUE
                    // Todo: hardcode here to disable error modal on Mobile Phone Number, will remove when BE granular error ready
                    if (
                        errorType === ILLEGAL_SCALAR_VALUE &&
                        !questionIdArray.includes(
                            QuestionId.Values?.PHONE_MOBILE
                        )
                    ) {
                        showBadRequestErrorMessage();
                    }

                    if (
                        errorType === ILLEGAL_SCALAR_VALUE &&
                        questionIdArray.includes(QuestionId.Values?.ZIP)
                    ) {
                        showZipcodeNotSupported();
                    }

                    mixpanelGeneralError({
                        name: `SubmitAnswers Response: ${
                            invalidAnswers ? 'Invalid Answers' : 'Bad Request'
                        }`,
                        location: 'Upsell.goToNextStep',
                        additionalInfo: submitAnswersResponse,
                    });

                    setQuestionErrors(
                        invalidAnswers?.validationResultsList || []
                    );

                    if (badRequest) {
                        // TODO: handle badRequest.errorsList
                    }

                    return;
                } else if (changeSolutionPossible) {
                    const error = await manageSolution();

                    if (error) {
                        setIsLoading(false);
                        setRegistrationStepLoader(false);
                        mixpanelGeneralError({
                            name: 'manageSolution gRPC Error',
                            location: 'Upsell.goToNextStep.manageSolution',
                            additionalInfo: error,
                        });
                        dispatch(error);

                        return;
                    } else {
                        upsellFinished = true;
                        const currentObj = getUpsellProgram();

                        setUpsellProgram({
                            ...currentObj,
                            upsellFinished: true,
                        });
                        // send mixpanel info or add upsell done param
                    }
                }
            } catch (error) {
                setIsLoading(false);
                setRegistrationStepLoader(false);
                mixpanelGeneralError({
                    name: 'submitAnswers gRPC Error',
                    location: 'Upsell.goToNextStep.submitAnswer',
                    additionalInfo: error,
                });

                showBadRequestErrorMessage();

                return;
            }

            // Mixpanel page pass event track if no error
            mixpanelPagePass(currentUpsellStep);

            if (amplitudeFlag) {
                AmplitudeUtils.trackPagePass(currentUpsellStep);
            }
        }

        let navigateNextResponse;

        try {
            navigateNextResponse = await getDataForNextStep(
                currentSectionIndex,
                currentSectionPathname
            );
        } catch (error) {
            setIsLoading(false);
            setRegistrationStepLoader(false);
            mixpanelGeneralError({
                name: 'getDataForNextStep',
                location: 'Upsell.gotoNextStep.getDataForNextStep',
                additionalInfo: error,
            });
            showBadRequestErrorMessage();

            return;
        }

        const {
            nextStep,
            unableToContinue,
            redirectUrl,
            title,
            navigateBackPossible,
            endOfSectionReached,
            changeSolutionPossible,
        } = navigateNextResponse;

        if (redirectUrl) {
            setCallBackUrl(redirectUrl.value);
        }

        if (title) {
            setTitleFromGRPC(title);
        }

        setIsBackButtonDisabled(navigateBackPossible);

        const readyToChangeSolution =
            changeSolutionPossible ||
            endOfSectionReached?.changeSolutionPossible;

        if (readyToChangeSolution) {
            const error = await manageSolution();

            if (error) {
                setIsLoading(false);
                setRegistrationStepLoader(false);
                mixpanelGeneralError({
                    name: 'manageSolution gRPC Error',
                    location: 'Upsell.goToNextStep.manageSolution',
                    additionalInfo: error,
                });
                dispatch(error);

                return;
            } else {
                upsellFinished = true;
                const currentObj = getUpsellProgram();

                setUpsellProgram({
                    ...currentObj,
                    upsellFinished: true,
                });
                // send mixpanel info or add upsell done param
            }
        }

        if (nextStep) {
            // mixpanel log page land when there is next step
            mixpanelPageLand(nextStep);

            if (amplitudeFlag) {
                AmplitudeUtils.trackPageLand(nextStep);
            }
            const {sectionId, stepNumber, numberOfSteps} =
                nextStep.context || {};
            let nextSection = {};

            if (sectionId === consentSection.id) {
                nextSection = consentSection;
            } else {
                nextSection = sections.find(
                    section => section.id === sectionId
                );
            }

            const questionsList = nextStep.questionsToAsk?.questionsList || [];

            const validationErrorsList = questionsList.reduce(
                (list, question) => {
                    return list.concat({
                        questionId: question.questionId,
                        errorTypesList: question.validationErrorsList,
                    });
                },
                []
            );

            setQuestionErrors(validationErrorsList);

            if (nextSection) {
                // It is a valid section and construct the URL for next step.
                let nextStepUrl = `${Config.upsellUrl}/${nextSection.pathname}/${numberOfSteps}/${stepNumber}`;

                if (upsellFinished) {
                    nextStepUrl += '?upsell_finished=true';
                }

                stepUrls.splice(currentStepIndex + 1);
                stepUrls.push(nextStepUrl);

                // Update registration history to local storage or cookie.
                setRegistrationStepUrls(stepUrls);
                // Do the redirection.
                history.push(nextStepUrl);
                // Update data.
                setCurrentUpsellStep(nextStep);
            } else {
                // TODO: Display proper message.
            }
        } else if (unableToContinue) {
            // mixpanel log page land when it is not able to continue.
            mixpanelUnableToContinue(unableToContinue);

            if (amplitudeFlag) {
                AmplitudeUtils.trackUnableToContinue();
            }
            setCurrentUpsellStep(unableToContinue);
        } else {
            // TODO: Display proper message.
        }
        setIsLoading(false);
        setRegistrationStepLoader(false);
    };

    // Navigate among registration steps.
    // Used to keep track of current
    useNavigation(location.pathname, history);

    // For page, use navigateHere to get the correct question and section
    useEffect(() => {
        const loadCurrentStep = async () => {
            setIsLoading(true);
            setRegistrationStepLoader(true);

            try {
                let sectionIndex = currentSectionIndex;
                let found = false;
                let callCount = 0;

                while (!found && callCount < sections.length) {
                    const currentSection = sections[sectionIndex];
                    /* eslint-disable no-await-in-loop */
                    const navigateHereResponse = await navigateHere(
                        sections[sectionIndex].id
                    );

                    const {
                        currentStep,
                        badRequest,
                        endOfSectionReached,
                        redirectUrl,
                        title,
                        navigateBackPossible,
                    } = navigateHereResponse;

                    if (redirectUrl) {
                        setCallBackUrl(redirectUrl.value);
                    }

                    if (title) {
                        setTitleFromGRPC(title);
                    }
                    setIsBackButtonDisabled(navigateBackPossible);

                    if (badRequest) {
                        // turn off the loop condition
                        found = true;

                        throw new Error(JSON.stringify(badRequest));
                    }

                    if (currentStep) {
                        found = true;
                        setCurrentUpsellStep(currentStep);

                        // Mixpanel page land event track.
                        mixpanelPageLand(currentStep);

                        if (amplitudeFlag) {
                            AmplitudeUtils.trackPageLand(currentStep);
                        }
                        PIWIKUtils.trackPageLand();

                        // Construct the URL
                        const {numberOfSteps, stepNumber} = currentStep.context;
                        const stepUrl = `${Config.upsellUrl}/${currentSection.pathname}/${numberOfSteps}/${stepNumber}`;

                        // if the url is different, set
                        if (location.pathname !== stepUrl) {
                            history.replace(stepUrl);
                        }

                        // if the url was not found, add it to Registration Step Urls
                        if (stepUrls.indexOf(stepUrl) === -1) {
                            stepUrls.push(stepUrl);

                            // Update registration history to local storage or cookie.
                            setRegistrationStepUrls(stepUrls);
                        }
                    } else if (endOfSectionReached) {
                        // move onto next section
                        sectionIndex++;
                        sectionIndex %= sections.length;
                        callCount++;
                    }
                }
            } catch (error) {
                mixpanelGeneralError({
                    name: 'navigateHere',
                    location: 'Upsell.getCurrentStepOnLoad',
                    additionalInfo: error,
                });
                showBadRequestErrorMessage();
            } finally {
                setIsLoading(false);
                setRegistrationStepLoader(false);
            }
        };

        loadCurrentStep();
        // eslint-disable-next-line
    }, []);

    // Got data for page load by forward or backward browser action.
    // Can handle case when use skips
    useEffect(() => {
        // Get flag whether the step load by app or browser action.
        // Also used to skip when navigateHere changes the url onload
        const isLoadFromApp = getRegistrationStepLoader();

        /*
         * For below situations do not need to load data.
         * If step load by app action of next or back button.
         * If current step is not valid.
         * If current section is not valid.
         */
        if (isLoadFromApp) {
            return;
        }

        /**
         * Get section index of the step based on the step index within registration history.
         * @param {number} stepIndex - Index of step within registration history.
         * @returns {number} - Index of section.
         */
        const getSectionIndexByStepIndex = stepIndex => {
            const stepUrl = stepUrls[stepIndex] || '';
            const [, , stepPathname] = stepUrl.split('/');

            return sections.findIndex(s => s.pathname === stepPathname);
        };

        /**
         * Get data for previous or next step or steps.
         * This function is for handling browser actions.
         * @returns {Promise<*>} - Promise.
         */
        const getDataForBackAndForth = async () => {
            let navigateResponse;
            let sectionIndex = getSectionIndexByStepIndex(previousStepIndex);

            setIsLoading(true);

            if (previousStepIndex < currentStepIndex) {
                try {
                    for (
                        let i = previousStepIndex,
                            stepIndex = previousStepIndex;
                        i < currentStepIndex;
                        i++, stepIndex++
                    ) {
                        navigateResponse = await navigateNext(
                            sections[sectionIndex].id
                        );

                        const {
                            badRequest,
                            endOfSectionReached,
                            nextStep,
                            redirectUrl,
                            title,
                            navigateBackPossible,
                        } = navigateResponse;

                        if (redirectUrl) {
                            setCallBackUrl(redirectUrl.value);
                        }

                        if (title) {
                            setTitleFromGRPC(title);
                        }

                        setIsBackButtonDisabled(navigateBackPossible);

                        if (endOfSectionReached) {
                            if (sectionIndex === sections.length) {
                                break;
                            }

                            sectionIndex++; // Move to next section.
                            i--; // Adjust step index.
                        } else if (nextStep) {
                            setCurrentUpsellStep(nextStep);
                        } else if (badRequest) {
                            throw new Error(JSON.stringify(badRequest));
                        } else {
                            // unableToGoBack || unableToContinue
                            // TODO: error message for user for these cases
                        }
                    }
                } catch (error) {
                    mixpanelGeneralError({
                        name: 'navigateNext (Browser Action)',
                        location:
                            'Upsell.getDataForBackAndForth inside useEffect([location.pathname])',
                        additionalInfo: error,
                    });
                    showBadRequestErrorMessage();
                } finally {
                    setIsLoading(false);
                    setRegistrationStepLoader(false);
                }
            } else {
                try {
                    for (
                        let i = previousStepIndex,
                            stepIndex = previousStepIndex;
                        i > currentStepIndex;
                        i--, stepIndex--
                    ) {
                        navigateResponse = await navigateBack(
                            sections[sectionIndex].id
                        );

                        const {
                            beginningOfSectionReached,
                            previousStep,
                            badRequest,
                            redirectUrl,
                            title,
                            navigateBackPossible,
                        } = navigateResponse;

                        if (redirectUrl) {
                            setCallBackUrl(redirectUrl.value);
                        }

                        if (title) {
                            setTitleFromGRPC(title);
                        }

                        setIsBackButtonDisabled(navigateBackPossible);

                        if (beginningOfSectionReached) {
                            if (sectionIndex === 0) {
                                break;
                            }

                            sectionIndex--; // Move to previous section.
                            i++; // Adjust step index.
                        } else if (previousStep) {
                            setCurrentUpsellStep(previousStep);
                        } else if (badRequest) {
                            throw new Error(JSON.stringify(badRequest));
                        } else {
                            // unableToGoBack || unableToContinue
                            // TODO: error message for user for these cases
                        }
                    }
                } catch (error) {
                    mixpanelGeneralError({
                        name: 'navigateBack (Browser Action)',
                        location:
                            'Upsell.getDataForBackAndForth inside useEffect([location.pathname])',
                        additionalInfo: error,
                    });
                    showBadRequestErrorMessage();
                } finally {
                    setIsLoading(false);
                    setRegistrationStepLoader(false);
                }
            }
        };

        if (previousStepIndex !== currentStepIndex) {
            // Load data for previous or next step or steps.
            // TODO: Display message for forward action if value of forms changed.
            getDataForBackAndForth();
        }

        // TODO: Clean other dependencies.
        // eslint-disable-next-line
    }, [location.pathname]);

    const showNextButton = () => !disableNavBtn.nextBtn;
    const showBackButton = () => isBackButtonDisabled;
    const questionsList = sectionInfo?.questionsToAsk?.questionsList || [];
    const hasInfo = questionsList.some(question => {
        const {questionId} = question;
        const {id = -1} = questionId || {};
        // Get question id in string format by value.
        const questionName = getQuestionNameById(id);
        // Get question UI configuration.

        return questionConfig[questionName]?.info;
    });

    return (
        <>
            <IdleTimer
                element={document}
                onIdle={handleIdle}
                debounce={Config.idleTimerDebounce}
                timeout={Config.idleTimerTimeout}
            />
            <div className={css.card}>
                {sectionInfo && !isOneApp && <Info data={sectionInfo} />}
                <main
                    className={cn(css.questionsContainer, {
                        [css.telemedicineQuestionsContainer]: isOneApp,
                        [css.radius]: hasInfo,
                        [css.mobileRadius]: !hasInfo,
                    })}
                    role="main"
                >
                    <div className={css.questionsSubContainer}>
                        {isOneApp && !isLoading && (
                            <span className={css.backButton}>
                                {showBackButton() && (
                                    <span>
                                        <NavigationButton
                                            type="PREVIOUS"
                                            icon={<ArrowLeftDefault />}
                                            iconPosition="left"
                                            onClick={gotoPreviousStep}
                                            label={t('buttonsLabel.back')}
                                            sectionInfo={sectionInfo}
                                            setNavigationLabel={
                                                setNavigationLabel
                                            }
                                        />
                                    </span>
                                )}
                            </span>
                        )}
                        <Form ref={formRef} id="interview" className={css.form}>
                            {isLoading ? (
                                <Loader className="Loader-module-root" />
                            ) : (
                                sectionInfo && (
                                    <Section
                                        sectionInfo={sectionInfo}
                                        validationErrorsList={questionErrors}
                                        setNavigationLabel={setNavigationLabel}
                                        isOneApp={isOneApp}
                                        formRef={formRef}
                                    />
                                )
                            )}
                            {(showBackButton() || showNextButton()) && (
                                <div
                                    className={cn(
                                        css.navigationButtonsContainer,
                                        {
                                            [css.oneAppButtonContainer]:
                                                isOneApp,
                                        }
                                    )}
                                >
                                    {!isOneApp &&
                                        !isLoading &&
                                        showBackButton() && (
                                            <NavigationButton
                                                type="PREVIOUS"
                                                onClick={gotoPreviousStep}
                                                label={t('buttonsLabel.back')}
                                                sectionInfo={sectionInfo}
                                                setNavigationLabel={
                                                    setNavigationLabel
                                                }
                                            />
                                        )}
                                    {showNextButton() && !isLoading && (
                                        <NavigationButton
                                            type="NEXT"
                                            onClick={gotoNextStep}
                                            label={navigationNextLabel}
                                            sectionInfo={sectionInfo}
                                            setNavigationLabel={
                                                setNavigationLabel
                                            }
                                            active
                                        />
                                    )}
                                </div>
                            )}
                        </Form>
                    </div>
                </main>
            </div>
        </>
    );
};

Upsell.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default Upsell;
