import {useEffect} from 'react';
import Arg from '@livongo/arg';
// import MixpanelUtils from '@livongo/utils/mixpanel';
import {authenticate} from 'api/protobuf/enrollment/services';
import {
    setIsAuthenticated,
    setGrpcAccessToken,
    setGrpcAccessTokenTimeStamp,
    cleanSessionInfo,
} from 'utilities/utils';
// import {
//     mixpanelUserSSOSuccessful,
//     mixpanelUserSSOFailed,
// } from 'utilities/mixpanel-utils';

const WelcomeBack = () => {
    const arg = Arg.all();
    const isRegRecoveryFlagOn = false;

    useEffect(() => {
        const handleSSOFailed = () => {
            cleanSessionInfo(true);
            // Todo: add corresponding mixpanel event
            // mixpanelUserSSOFailed();
            window.open('/login', '_self');
        };

        const handleSSOSuccess = () => {
            // mixpanelUserSSOSuccessful();
            window.location.href = `${process.env.REG_FLOW_URL}${window.location.search}&fromWelcomeBack=true`;
        };

        if (isRegRecoveryFlagOn) {
            if (arg.access_code) {
                cleanSessionInfo(true);

                authenticate({
                    accessCode: arg.access_code,
                    isRegRecovery: true,
                })
                    .then(response => {
                        const accessToken =
                            response.authenticated?.accessToken?.token;

                        if (accessToken) {
                            setGrpcAccessToken(accessToken);
                            setGrpcAccessTokenTimeStamp(new Date().getTime());
                            setIsAuthenticated(true);
                            handleSSOSuccess();
                        } else {
                            handleSSOFailed();
                        }
                    })
                    .catch(exception => {
                        handleSSOFailed();
                    });
            } else {
                handleSSOFailed();
            }
        } else {
            handleSSOFailed();
        }
    });

    return null;
};

export default WelcomeBack;
