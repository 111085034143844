/**
 * Dynamic gRPC calls
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/enrollment/dynamic
 */
import {AutoCompleteRequest} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/dynamic/autocomplete_pb';
import {ValidationRequest} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/dynamic/validation_pb';
import {FindRegCodesByEmailRequest} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/dynamic/search_pb';
import {ProgramQualificationCheckRequest} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/dynamic/program_qualification_check_results_pb';
import {QuestionId} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/common/question_ids_pb';
import {errorMessage} from 'utilities/errors-utils';
import {
    enrollmentPromiseClient,
    getGrpcProtocolVersion,
    getGrpcAccessToken,
    getGrpcMetadata,
    answerToProtobuf,
} from './enrollment-utils';

/**
 * autoComplete
 * Autocompletion for text fields. Only works for specific questions.
 * @param {Object} question the question, defined by an id and a version
 * @param {Object} prefix the prefix entered by the user
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.dynamic.AutoCompleteResponse>}
 *  Promise
 */
export const autoComplete = (question, prefix) => {
    if (!question) {
        throw new Error(
            errorMessage('Question is not properly defined:', question)
        );
    }

    if (!question.id) {
        throw new Error(errorMessage('Missing id for question', question));
    }

    if (!question.version) {
        throw new Error(errorMessage('Missing version for question', question));
    }

    const request = new AutoCompleteRequest();
    const questionId = new QuestionId();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());
    questionId.setId(QuestionId.Values[question.id]);
    questionId.setVersion(question.version);
    request.setQuestionId(questionId);
    request.setPrefix(prefix);

    return enrollmentPromiseClient
        .autoComplete(request, getGrpcMetadata())
        .then(response => response.toObject());
};

/**
 * validateAnswer
 * Used for dynamic single-field answer validation
 *  @param {Object} answer the answer to be validated
 *   example:
 *       {
 *           questionId: {
 *               id: 37, // PASSWORD
 *               version: 1,
 *           },
 *           type: TypedField.FieldTypeCase.TEXT,
 *           value: 'P@ssw0rd',
 *       },
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.dynamic.ValidationResponse>}
 *  Promise
 */
export const validateAnswer = answer => {
    const request = new ValidationRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());
    request.setAnswer(answerToProtobuf(answer));

    return enrollmentPromiseClient
        .validateAnswer(request, getGrpcMetadata())
        .then(response => response.toObject());
};

/**
 * getProgramQualificationResults
 * Get program qualification and disqualification reasons.
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.dynamic.ProgramQualificationCheckResponse>}
 *  Promise
 */
export const getProgramQualificationResults = () => {
    const request = new ProgramQualificationCheckRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());

    return enrollmentPromiseClient
        .getProgramQualificationResults(request, getGrpcMetadata())
        .then(response => response.toObject());
};

export const findRegCodesByEmail = email => {
    const request = new FindRegCodesByEmailRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setAccessToken(getGrpcAccessToken());
    request.setEmail(email);

    return enrollmentPromiseClient
        .findRegCodesByEmail(request, getGrpcMetadata(true))
        .then(response => response.toObject());
};
