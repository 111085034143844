import {MESSAGE_ADD, MESSAGE_CLEAR} from './message-actions';

// type is of type: constants/messages/messageTypes
// id is of type: constants/messages/messageConfig.id
const defaultState = {
    type: null,
    id: null,
    text: null,
    data: null,
};

const message = (state = defaultState, {type, payload = {}}) => {
    switch (type) {
        case MESSAGE_ADD: {
            const {type: messageType = null, id, text, data} = payload;

            return {
                type: messageType,
                id,
                text,
                data,
            };
        }
        case MESSAGE_CLEAR: {
            return defaultState;
        }
        default:
            return state;
    }
};

export default message;
