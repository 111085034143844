export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

export const notificationShow = data => {
    data.id = Date.now().toString();

    return {
        type: NOTIFICATION_SHOW,
        payload: data,
    };
};

export const notificationClear = () => {
    return {
        type: NOTIFICATION_CLEAR,
    };
};
