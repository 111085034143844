import {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import Config from 'config';
import {getRegistrationContext} from 'utilities/utils';
import Address from './Address';
import AddressMilitary from './AddressMilitary';
import AddressTerritories from './AddressTerritories';
import css from './Address.scss';

const militaryStates = ['AA', 'AE', 'AP'];
const territories = ['GU', 'PR', 'PW', 'VI'];

const addressTypes = {
    STANDARD: 'STANDARD',
    MILITARY: 'MILITARY',
    TERRITORIES: 'TERRITORIES',
};

const {STANDARD, MILITARY, TERRITORIES} = addressTypes;

// TODO: Update subValue with BE return.
const AddressCombined = ({
    id,
    name,
    question,
    customValidationError,
    onChange,
    context,
    isAddressFromEditable,
    setIsAddressFromEditable,
    formRef,
}) => {
    const [addressType, setAddressType] = useState(STANDARD);
    const {t} = useTranslation('questions');
    const [questionValues, setQuestionValues] = useState({});
    const questionsList = useMemo(
        () => question?.dataType?.unionField?.questionsList || [],
        [question]
    );
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;

    // Check the which addressType (STANDARD, MILITARY, TERRITORIES) has the default value
    // So we can switch to corresponding address form
    useEffect(() => {
        questionsList.forEach(compoundField => {
            const compoundQuestionsList =
                compoundField?.dataType?.compoundField?.questionsList || [];

            compoundQuestionsList.forEach(addressQuestion => {
                if (
                    addressQuestion.questionId.id ===
                        QuestionId.Values.ADDRESS_USA_MILITARY_STATE &&
                    addressQuestion.dataType.singleSelectEnum.previousAnswerCode
                ) {
                    setAddressType(MILITARY);
                } else if (
                    addressQuestion.questionId.id ===
                        QuestionId.Values.ADDRESS_USA_TERRITORIES_STATE &&
                    addressQuestion.dataType.singleSelectEnum.previousAnswerCode
                ) {
                    setAddressType(TERRITORIES);
                } else if (
                    addressQuestion.questionId.id === QuestionId.Values.STATE &&
                    addressQuestion.dataType.singleSelectEnum.previousAnswerCode
                ) {
                    setAddressType(STANDARD);
                }
            });
        });
    }, [questionsList]);

    const handleOnChange = values => {
        // TODO: Align with BE return list.
        if (values.STATE && values.STATE !== questionValues.STATE) {
            if (territories.includes(values.STATE)) {
                setAddressType(TERRITORIES);
            } else if (militaryStates.includes(values.STATE)) {
                setAddressType(MILITARY);
            } else {
                setAddressType(STANDARD);
            }
        }

        setQuestionValues(values);

        if (onChange) {
            onChange(values);
        }
    };

    const standardAddress = questionsList.find(
        q => q.questionId.id === QuestionId.Values.ADDRESS_USA_STANDARD
    );
    const militaryAddress = questionsList.find(
        q => q.questionId.id === QuestionId.Values.ADDRESS_USA_MILITARY
    );

    const territoriesAddress = questionsList.find(
        q => q.questionId.id === QuestionId.Values.ADDRESS_USA_TERRITORIES
    );

    const includeMilitary = Boolean(militaryAddress);
    const includeUSTerritories = Boolean(territoriesAddress);

    return (
        <>
            {!isOneApp && (
                <span className={css.addressLabel}>
                    {t('ADDRESS_USA_COMBINED.label')}
                </span>
            )}
            {addressType === STANDARD && (
                <Address
                    id={id}
                    name={`${name}_-_${standardAddress.questionId.id}`}
                    question={standardAddress}
                    questionValues={questionValues}
                    includeMilitary={includeMilitary}
                    includeUSTerritories={includeUSTerritories}
                    customValidationError={customValidationError}
                    onChange={handleOnChange}
                    context={context}
                    isAddressFromEditable={isAddressFromEditable}
                    setIsAddressFromEditable={setIsAddressFromEditable}
                    formRef={formRef}
                />
            )}
            {addressType === MILITARY && (
                <AddressMilitary
                    id={id}
                    name={`${name}_-_${militaryAddress.questionId.id}`}
                    question={militaryAddress}
                    questionValues={questionValues}
                    includeMilitary={includeMilitary}
                    includeUSTerritories={includeUSTerritories}
                    customValidationError={customValidationError}
                    onChange={handleOnChange}
                    context={context}
                    isAddressFromEditable={isAddressFromEditable}
                    setIsAddressFromEditable={setIsAddressFromEditable}
                    formRef={formRef}
                />
            )}
            {addressType === TERRITORIES && (
                <AddressTerritories
                    id={id}
                    name={`${name}_-_${territoriesAddress.questionId.id}`}
                    question={territoriesAddress}
                    questionValues={questionValues}
                    includeMilitary={includeMilitary}
                    includeUSTerritories={includeUSTerritories}
                    customValidationError={customValidationError}
                    onChange={handleOnChange}
                    context={context}
                    isAddressFromEditable={isAddressFromEditable}
                    setIsAddressFromEditable={setIsAddressFromEditable}
                    formRef={formRef}
                />
            )}
        </>
    );
};

AddressCombined.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {hash} address - A hash with the question.
     */
    onChange: PropTypes.func,
    context: PropTypes.object.isRequired,
    isAddressFromEditable: PropTypes.bool,
    setIsAddressFromEditable: PropTypes.func,
    formRef: PropTypes.object,
};

export default AddressCombined;
