import findKey from 'lodash/findKey';

export {initializeEnrollment, createTeladocSdkToken} from './authentication';
export {
    navigateNext,
    navigateBack,
    navigateHere,
    progressCheck,
} from './navigation';
export {submitAnswers} from './questions';

const Values = {
    SMART_PHONE: 1,
    QUALIFY: 2,
};

// Remove for real data
export const getQuestionNameById = value => findKey(Values, id => id === value);
