import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import messageConfig from 'constants/messages';
import {cleanSessionInfo} from 'utilities/utils';
import Config from 'config';
import css from './modalStyle.scss';

const RedirectErrorMessage = ({id}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const messageValue = messageConfig[id] || {};
    const {title, content, primaryButtonText} = messageValue;

    const onClose = () => {
        cleanSessionInfo(true);
        window.open(Config.oneAppUrl, '_self');
    };

    return (
        <div className={css.modalContainer}>
            <h1>{t(title)}</h1>
            <div className={css.contentContainer}>
                <p>{t(content)}</p>
            </div>

            <div>
                <Button
                    className={css.modalButton}
                    variant="primary"
                    onClick={onClose}
                >
                    {t(primaryButtonText)}
                </Button>
            </div>
        </div>
    );
};

RedirectErrorMessage.propTypes = {
    id: PropTypes.string.isRequired,
};

export default RedirectErrorMessage;
