import {useTranslation, Trans} from 'react-i18next';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import ScreenReader from 'common/screen-reader/ScreenReader';
import Config from 'config';
import {getRegistrationContext, getSsoContext} from 'utilities/utils';
import css from './Footer.scss';

const {
    websitePrivacyPolicyUrl,
    legalDisclaimerUrl,
    noticeOfNonDiscriminationUrl,
    privacyPracticeUrl,
    tosUrl,
} = Config;

const Footer = () => {
    const {t} = useTranslation();
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;
    const isImpersonation = getSsoContext()?.isImpersonation ?? false;
    const agentNumber = getSsoContext()?.agentNumber;
    const locale = getStoredLang().toLowerCase();

    return (
        <>
            {!isOneApp && (
                <footer className={css.root}>
                    <div>&copy; Teladoc Health, Inc.</div>
                    <div>
                        <span>
                            <Trans
                                i18nKey="footer.websitePrivacyPolicy"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={`${websitePrivacyPolicyUrl}?locale=${locale}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <ScreenReader />
                                    </a>,
                                    /* eslint-enable react/jsx-key */
                                ]}
                            />
                        </span>
                        <span className={css.url}>
                            <Trans
                                i18nKey="footer.legalDisclaimer"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={`${legalDisclaimerUrl}?locale=${locale}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <ScreenReader />
                                    </a>,
                                    /* eslint-enable react/jsx-key */
                                ]}
                            />
                        </span>
                        <span>
                            <Trans
                                i18nKey="footer.noticeOfNonDiscriminationAndAccessibility"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={`${noticeOfNonDiscriminationUrl}?locale=${locale}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <ScreenReader />
                                    </a>,
                                    /* eslint-enable react/jsx-key */
                                ]}
                            />
                        </span>
                    </div>
                    <div>
                        {t('footer.version')} {process.env.VERSION}
                    </div>
                </footer>
            )}
            {isOneApp && (
                <>
                    <footer className={css.oneAppRoot}>
                        <div>
                            &copy; Teladoc Health, Inc. &nbsp; • &nbsp;
                            {t('footer.version')} {process.env.VERSION}
                        </div>
                        <div className={css.alignment}>
                            <span>
                                <Trans
                                    i18nKey="footer.privacyPractice"
                                    components={[
                                        /* eslint-disable react/jsx-key */
                                        <a
                                            href={`${privacyPracticeUrl}?locale=${locale}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={css.link}
                                        >
                                            <ScreenReader />
                                        </a>,
                                        /* eslint-enable react/jsx-key */
                                    ]}
                                />
                            </span>
                            <span className={css.url}>
                                <Trans
                                    i18nKey="footer.termsOfService"
                                    components={[
                                        /* eslint-disable react/jsx-key */
                                        <a
                                            href={`${tosUrl}?locale=${locale}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={css.link}
                                        >
                                            <ScreenReader />
                                        </a>,
                                        /* eslint-enable react/jsx-key */
                                    ]}
                                />
                            </span>
                            <span>
                                <Trans
                                    i18nKey="footer.nonDiscrimination"
                                    components={[
                                        /* eslint-disable react/jsx-key */
                                        <a
                                            href={`${noticeOfNonDiscriminationUrl}?locale=${locale}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={css.link}
                                        >
                                            <ScreenReader />
                                        </a>,
                                        /* eslint-enable react/jsx-key */
                                    ]}
                                />
                            </span>
                        </div>
                    </footer>
                    {isImpersonation && (
                        <div className={css.impersonationFooter}>
                            <span>Impersonator ID: {agentNumber}</span>
                            <span>Impersonator type: CallCenterRep</span>
                            <span>Impersonated type: Member</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default Footer;
