import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import ScreenReader from 'common/screen-reader/ScreenReader';
import Config from 'config';
import {questionConfig} from 'constants/questions';
import css from '../Question.scss';

const {GINAUrl} = Config;

const ClientConsent = ({id, name, defaultValue}) => {
    // since the content in this client consent component based on Regcode
    // we need to base on regcode and add a new level in the json mapping
    const {label, content, error, checkboxLabel} = questionConfig[id] || {};
    const {t} = useTranslation('questions');
    const tranKey = `questions:CLIENT_CONSENT.checkboxLabel`;

    return (
        <>
            <span className={css.questionLabel}>{t(label)}</span>
            <ul>
                {Object.keys(content).map((key, index) => (
                    <li key={index}>{t(content[key])}</li>
                ))}
            </ul>
            <Checkbox
                defaultChecked={defaultValue === 'true'}
                id={id}
                name={name}
                value="true"
                label={
                    <Label className={css.checkboxQuestionLabel}>
                        <Trans
                            i18nKey={tranKey}
                            components={[
                                /* eslint-disable react/jsx-key */
                                <a
                                    // Todo: there is only English version pdf for this, may need to add locale later
                                    href={GINAUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ScreenReader />
                                </a>,
                                /* eslint-enable react/jsx-key */
                            ]}
                        />
                    </Label>
                }
                i18nItemLabel={t(checkboxLabel)}
                error={<FormElementError>{t(error)}</FormElementError>}
            />
        </>
    );
};

ClientConsent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
};

export default ClientConsent;
