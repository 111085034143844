import {useTranslation} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import PropTypes from 'prop-types';
import messageConfig from 'constants/messages';
import css from './modalStyle.scss';

const SendMobileAppSms = ({id}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const messageValue = messageConfig[id] || {};
    const {title} = messageValue;
    const CLOUDINARY_IMAGE_ID = 'registration/common/qr_code_fibdez';

    return (
        <div className={css.sendMobileAppSmsModalContainer}>
            <div className={css.mobileAppSmsContainer}>
                <div className={css.titleContainer}>
                    <h1>{t(title)}</h1>
                </div>
                <Image
                    cloudinaryImageId={CLOUDINARY_IMAGE_ID}
                    width={150}
                    height={150}
                />
            </div>
        </div>
    );
};

SendMobileAppSms.propTypes = {
    id: PropTypes.string.isRequired,
};

export default SendMobileAppSms;
