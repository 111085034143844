import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import {useDispatch} from 'react-redux';
import findKey from 'lodash/findKey';
import Button from '@teladoc/pulse/ui/Button';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import IconCloseDefault from '@teladoc/pulse/icons/close-default.svg';
import Flyout from '@teladoc/pulse/ui/Flyout';
import FormGroupError from '@teladoc/pulse/ui/FormGroupError';
import Image from '@teladoc/pulse/ui/Image';
import Label from '@teladoc/pulse/ui/Label';
import TextInput from '@teladoc/pulse/ui/TextInput';
import Config from 'config';
import {programs} from 'constants/app';
import {Program as ProgramNames} from 'api/protobuf/enrollment/protoTypes';
import {
    mixpanelModalClosedEvent,
    mixpanelModalViewedEvent,
    mixpanelButtonClickedEvent,
} from 'utilities/mixpanel-utils';
import {getRegistrationContext, getStepNumber} from 'utilities/utils';
import {
    validateAnswer,
    getProgramQualificationResults,
} from 'api/protobuf/enrollment/services';
import useMessage from 'hook/useMessage';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import css from './Program.scss';

const {memberSupportPhoneNumber} = Config;
const {
    DIABETES,
    PREDIABETES,
    HYPERTENSION,
    WEIGHT_MANAGEMENT,
    BEHAVIORAL_HEALTH,
    CHRONIC_KIDNEY_DISEASE,
    HEART_FAILURE,
    DIABETES_DEVICEFLEX,
    ADV_WEIGHT_MANAGEMENT,
    ADV_PREDIABETES,
    COMPR_WEIGHT_CARE,
    COMPR_PREDIABETES_CARE,
} = programs;

const QualificationReason = () => {
    const {t} = useTranslation('questions');
    const [qualificationResults, setQualificationResults] = useState([]);

    const getProgramNameFromValue = value =>
        findKey(ProgramNames.Values, program => program === value);
    /**
     * Get formalized disqualification reasons.
     * No translation since this is for admin/agent.
     * @param {Object} reasonList
     * @return {*}
     */
    const getFormalizedDisqualificationReasons = reasonList => {
        return reasonList.reduce(
            (
                accumulatedReasons,
                currentReason,
                currentReasonIndex,
                reasonArray
            ) => {
                let reasons = '';
                let currentFormalizedReason = '';

                if (currentReason.excluded) {
                    currentFormalizedReason = `${currentReason.excluded.name}: ${currentReason.excluded.description}`;
                } else if (currentReason.neededScoreNotMet) {
                    currentFormalizedReason = `Needed score not met --- Actual score: ${
                        currentReason.neededScoreNotMet.actualScore
                    },
                                Required score: ${
                                    currentReason.neededScoreNotMet
                                        .requiredScore
                                },
                                Scores: [${currentReason.neededScoreNotMet.scoresList.reduce(
                                    (accumulatedScores, currentScore) => {
                                        let scores = '';
                                        let currentFormalizedScore = '';

                                        currentFormalizedScore = `Name: ${currentScore.name}, Score: ${currentScore.score}`;

                                        if (accumulatedScores === '') {
                                            scores = currentFormalizedScore;
                                        } else if (
                                            currentFormalizedScore === ''
                                        ) {
                                            scores = accumulatedScores;
                                        } else {
                                            scores = `${accumulatedScores}; ${currentFormalizedScore}`;
                                        }

                                        return scores;
                                    },
                                    ''
                                )}]`;
                }

                if (accumulatedReasons === '') {
                    reasons = currentFormalizedReason;
                } else if (currentFormalizedReason === '') {
                    reasons = accumulatedReasons;
                } else {
                    reasons = `${accumulatedReasons}; ${currentFormalizedReason}`;
                }

                if (
                    currentReasonIndex === reasonArray.length - 1 &&
                    reasons !== ''
                ) {
                    reasons += '.';
                }

                return reasons;
            },
            ''
        );
    };

    useEffect(() => {
        getProgramQualificationResults()
            .then(response => {
                return response.results?.programQualificationResultList ?? [];
            })
            .then(response =>
                response.filter(
                    result =>
                        result.disqualificationReasonsList &&
                        result.disqualificationReasonsList.length > 0
                )
            )
            .then(response => {
                const results = response.map(result => {
                    return `${getProgramNameFromValue(
                        result.program
                    )} - ${getFormalizedDisqualificationReasons(
                        result.disqualificationReasonsList
                    )}`;
                });

                setQualificationResults(results);
            });
    }, []);

    return (
        <div>
            <p className={css.disqualificationDescription}>
                {t('PROGRAM.salesforceDisqualifiedContext')}
            </p>
            <ul>
                {qualificationResults.map((result, index) => (
                    <li key={index}>{result}</li>
                ))}
            </ul>
        </div>
    );
};

const Program = ({
    id,
    questionId,
    isProgramPending,
    dataType,
    name,
    items,
    defaultValue,
    customValidationError,
    onChange,
    setNavigationLabel,
    isOneApp,
}) => {
    const {t} = useTranslation('questions');
    const dispatch = useDispatch();
    const {showProgramEligibility} = useMessage();
    const qualifiedProgram = [];
    const {client} = getRegistrationContext() ?? {};
    const isSalesforce = client === Config.client.salesforce;
    // Todo: use step number as the flag, will replace it once BE return more info in the context
    const storedStepNumber = getStepNumber();

    items.map(program => qualifiedProgram.push(program.value));

    if (isSalesforce) {
        items.forEach(item => {
            item.label = t(`PROGRAM.salesforceOptions.${item.value}`);
        });
    } else if (isOneApp) {
        items.forEach(item => {
            item.label = t(`PROGRAM.oneappOptions.${item.value}`);
        });
    }

    const isDiabetesDeviceFlex = qualifiedProgram.includes(DIABETES_DEVICEFLEX);
    const [disableConfirmButton, setDisableConfirmButton] = useState(false);
    const showNeedChangeButton = items.length > 1;
    const [showFlyout, setFlyout] = useState(false);
    // state to show the Diabetes card
    const [showDiabetes, setShowDiabetes] = useState(
        defaultValue.length > 0
            ? defaultValue.includes(DIABETES)
            : qualifiedProgram.includes(DIABETES)
    );
    // state to show the Hypertension card
    const [showHypertension, setShowHypertension] = useState(
        defaultValue.length > 0
            ? defaultValue.includes(HYPERTENSION)
            : qualifiedProgram.includes(HYPERTENSION)
    );
    // state to show the Weight Management card
    const [showWeightManagement, setShowWeightManagement] = useState(
        defaultValue.length > 0
            ? defaultValue.includes(WEIGHT_MANAGEMENT) ||
                  defaultValue.includes(ADV_WEIGHT_MANAGEMENT) ||
                  defaultValue.includes(COMPR_WEIGHT_CARE)
            : qualifiedProgram.includes(WEIGHT_MANAGEMENT) ||
                  qualifiedProgram.includes(ADV_WEIGHT_MANAGEMENT) ||
                  qualifiedProgram.includes(COMPR_WEIGHT_CARE)
    );
    // state to show the PreDiabetes card
    const [showPreDiabetes, setShowPreDiabetes] = useState(
        defaultValue.length > 0
            ? defaultValue.includes(PREDIABETES) ||
                  defaultValue.includes(ADV_PREDIABETES) ||
                  defaultValue.includes(COMPR_PREDIABETES_CARE)
            : qualifiedProgram.includes(PREDIABETES) ||
                  qualifiedProgram.includes(ADV_PREDIABETES) ||
                  qualifiedProgram.includes(COMPR_PREDIABETES_CARE)
    );
    // state to show the BehavioralHealth card
    const [showBehavioralHealth, setShowBehavioralHealth] = useState(
        defaultValue.length > 0
            ? defaultValue.includes(BEHAVIORAL_HEALTH)
            : qualifiedProgram.includes(BEHAVIORAL_HEALTH)
    );
    // state to show the ChronicKidneyDisease card
    const [showChronicKidneyDisease, setShowChronicKidneyDisease] = useState(
        defaultValue.length > 0
            ? defaultValue.includes(CHRONIC_KIDNEY_DISEASE)
            : qualifiedProgram.includes(CHRONIC_KIDNEY_DISEASE)
    );
    // state to show the heartFailure card
    const [showHeartFailure, setShowHeartFailure] = useState(
        defaultValue.length > 0
            ? defaultValue.includes(HEART_FAILURE)
            : qualifiedProgram.includes(HEART_FAILURE)
    );
    // state to show the diabetesDeviceFlex card
    const [showDiabetesDeviceFlex, setShowDiabetesDeviceFlex] = useState(
        defaultValue.length > 0
            ? defaultValue.includes(DIABETES_DEVICEFLEX)
            : qualifiedProgram.includes(DIABETES_DEVICEFLEX)
    );
    // programSelections is an obj and use to store the data before user hit confirm.
    const [programSelections, setProgramSelections] = useState(
        qualifiedProgram
            ? Object.assign(
                  ...qualifiedProgram.map(program => ({[program]: true}))
              )
            : {}
    );
    // programSubmissions is an array and it can only be updated after user hit confirm and pass validation.
    // checkbox pre-check or not will also base on this
    // if there is default value (previous answer), set to default value otherwise set to qualifiedProgram
    const [programSubmissions, setProgramSubmissions] = useState(
        defaultValue.length > 0 ? defaultValue : qualifiedProgram
    );

    // flag for error msg block
    const [showErrorMsg, setShowErrorMsg] = useState(null);
    const [messageType, setMessageType] = useState('');
    // when  user deselect all program, show must select program error message
    const onShowMustSelectMessage = evt => setMessageType('emptyListError');

    const onToggleFlyout = evt => {
        // Todo: close animation has some issue here
        setFlyout(show => !show);
        // when toggle off the flyout. set programSelections base on programSubmissions (back to default)
        setProgramSelections(
            Object.assign(
                ...qualifiedProgram.map(program => ({
                    [program]: programSubmissions.includes(program),
                }))
            )
        );

        mixpanelButtonClickedEvent(
            'PROGRAM',
            '',
            t('PROGRAM.toggleLabel'),
            'CTA'
        );

        if (!showFlyout) {
            mixpanelModalViewedEvent('Program Opt Out', '', 'PROGRAM');
        } else {
            mixpanelModalClosedEvent('Program Opt Out', '', 'PROGRAM');
        }
    };

    // handle onchange for all checkbox
    const onProgramChange = ({target: {value, checked}}) => {
        setProgramSelections({...programSelections, [value]: checked});

        if (onChange) {
            onChange(programSelections);
        }
    };

    const onCancelClick = () => {
        // Todo: close animation has some issue here
        setFlyout(false);
        mixpanelModalClosedEvent('Program Opt Out', '', 'PROGRAM');
        // when toggle off the flyout. set programSelections base on programSubmissions (back to default)
        setProgramSelections(
            Object.assign(
                ...qualifiedProgram.map(program => ({
                    [program]: programSubmissions.includes(program),
                }))
            )
        );
    };

    // validate SelectedAnswer when user hit confirm
    const validateSelectedAnswer = async value => {
        const validateAnswerObj = {
            questionId,
            type: dataType,
            value,
        };

        const {validationResult} = await validateAnswer(validateAnswerObj);

        // For MVP, when errorTypesList is not empty, show generic error msg and disable confirm button
        if (validationResult?.errorTypesList.length > 0) {
            // Todo: will use commented helper function, when specific error msg needed.
            // const errorMessages = handleErrorMessages(
            //     validationResult.errorTypesList,
            //     question,
            //     t
            // );

            setMessageType('genericErrorMsg');
            setShowErrorMsg(true);
            setDisableConfirmButton(true);
        } else {
            // no validation error
            setShowErrorMsg(false);
            setDisableConfirmButton(false);

            const answer = [];

            Object.keys(programSelections).forEach(key => {
                if (programSelections[key]) {
                    answer.push(key);
                }
            });

            // updated new programSubmissions data
            setProgramSubmissions(answer);

            // display and hide the card
            Object.keys(programSelections).forEach(program => {
                switch (program) {
                    case DIABETES:
                        setShowDiabetes(programSelections[program]);
                        break;
                    case HYPERTENSION:
                        setShowHypertension(programSelections[program]);
                        break;
                    case WEIGHT_MANAGEMENT:
                        setShowWeightManagement(programSelections[program]);
                        break;
                    case ADV_WEIGHT_MANAGEMENT:
                        setShowWeightManagement(programSelections[program]);
                        break;
                    case COMPR_WEIGHT_CARE:
                        setShowWeightManagement(programSelections[program]);
                        break;
                    case PREDIABETES:
                        setShowPreDiabetes(programSelections[program]);
                        break;
                    case ADV_PREDIABETES:
                        setShowPreDiabetes(programSelections[program]);
                        break;
                    case COMPR_PREDIABETES_CARE:
                        setShowPreDiabetes(programSelections[program]);
                        break;
                    case BEHAVIORAL_HEALTH:
                        setShowBehavioralHealth(programSelections[program]);
                        break;
                    case CHRONIC_KIDNEY_DISEASE:
                        setShowChronicKidneyDisease(programSelections[program]);
                        break;
                    case HEART_FAILURE:
                        setShowHeartFailure(programSelections[program]);
                        break;
                    case DIABETES_DEVICEFLEX:
                        setShowDiabetesDeviceFlex(programSelections[program]);
                        break;
                }
            });
            setFlyout(false);
        }
    };

    const onConfirmClick = evt => {
        const validateValue = [];

        Object.keys(programSelections).forEach(key => {
            if (programSelections[key]) {
                validateValue.push(key);
            }
        });
        validateSelectedAnswer(validateValue);
    };

    const renderEmptyListError = () => {
        return (
            <FormGroupError
                errors={{
                    details: (
                        <div>
                            {isOneApp
                                ? t('PROGRAM.oneAppError.p1')
                                : t('PROGRAM.error.p1')}
                            <br />
                            <Trans
                                i18nKey="questions:PROGRAM.error.p2"
                                components={[
                                    // eslint-disable-next-line
                                    <a
                                        href={`tel:${memberSupportPhoneNumber}`}
                                    />,
                                ]}
                            />
                        </div>
                    ),
                }}
            />
        );
    };

    const renderGenericError = () => {
        return (
            <FormGroupError
                errors={{
                    details: t('PROGRAM.error.p3'),
                }}
            />
        );
    };

    const renderMessage = () => {
        switch (messageType) {
            case 'emptyListError':
                return renderEmptyListError();
            case 'genericErrorMsg':
                return renderGenericError();
            default:
                return null;
        }
    };

    const renderProgramCard = (
        cloudinaryImageId,
        alt,
        width,
        height,
        subtitle,
        content,
        salesforceOnlyProgramText
    ) => {
        return isSalesforce ? (
            <li>{t(salesforceOnlyProgramText)}</li>
        ) : (
            <div className={css.card}>
                <Image
                    classNameImage={css.imageContainer}
                    cloudinaryImageId={cloudinaryImageId}
                    alt={t(alt)}
                    width={width}
                    height={height}
                />
                {isProgramPending && (
                    <div className={css.pendingSuffix}>
                        {t('Common.pending')}
                    </div>
                )}
                <div className={css.content}>
                    <h2 className={css.h2}>{t(subtitle)}</h2>
                    <p>{t(content)}</p>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (setNavigationLabel) {
            if (
                (programSubmissions.length === 1 &&
                    programSubmissions[0] === BEHAVIORAL_HEALTH) ||
                isProgramPending
            ) {
                setNavigationLabel({nextLabel: 'buttonsLabel.next'});
            } else if (isDiabetesDeviceFlex) {
                setNavigationLabel({nextLabel: 'buttonsLabel.continue'});
            } else {
                setNavigationLabel({
                    nextLabel: 'buttonsLabel.getWelcomeKit',
                });
            }
        }
    }, [
        isProgramPending,
        programSubmissions,
        setNavigationLabel,
        isDiabetesDeviceFlex,
    ]);

    useEffect(() => {
        // if all values in programSelections all false, call onShowMustSelectMessage function
        if (!Object.values(programSelections).includes(true)) {
            setDisableConfirmButton(true);
            setShowErrorMsg(true);
            onShowMustSelectMessage();
        } else {
            setDisableConfirmButton(false);
            setShowErrorMsg(false);
        }

        // disable back button for this component
        let obj = {backBtn: true, nextBtn: false};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
    }, [dispatch, programSelections]);

    return (
        <div>
            <div>{customValidationError}</div>
            {isSalesforce && (
                <p className={css.disqualificationDescription}>
                    {t('PROGRAM.salesforceContext')}
                </p>
            )}
            {storedStepNumber && (
                <p className={css.disqualificationDescription}>
                    {t('PROGRAM.contextForProgramSelection')}
                </p>
            )}
            <div className={css.programContent}>
                {(showDiabetes || showDiabetesDeviceFlex) &&
                    renderProgramCard(
                        'registration/components/questions/program/pulse/Program_DBT_cbjk89',
                        'PROGRAM.diabetes.alt',
                        1082,
                        609,
                        isOneApp || storedStepNumber
                            ? 'PROGRAM.diabetes.oneAppSubtitle'
                            : 'PROGRAM.diabetes.subtitle',
                        showDiabetesDeviceFlex
                            ? 'PROGRAM.diabetesDeviceFlex.content'
                            : 'PROGRAM.diabetes.content',
                        'PROGRAM.diabetes.salesforceSubtitle'
                    )}
                {showHypertension &&
                    renderProgramCard(
                        'registration/components/questions/program/pulse/Program_HTN_tmfqjk',
                        'PROGRAM.hypertension.alt',
                        1082,
                        609,
                        isOneApp || storedStepNumber
                            ? 'PROGRAM.hypertension.oneAppSubtitle'
                            : 'PROGRAM.hypertension.subtitle',
                        'PROGRAM.hypertension.content',
                        'PROGRAM.hypertension.salesforceSubtitle'
                    )}
                {showWeightManagement &&
                    renderProgramCard(
                        'registration/components/questions/program/pulse/Program_WM_la2mlw',
                        'PROGRAM.weightManagement.alt',
                        1082,
                        609,
                        isOneApp || storedStepNumber
                            ? 'PROGRAM.weightManagement.oneAppSubtitle'
                            : 'PROGRAM.weightManagement.subtitle',
                        'PROGRAM.weightManagement.content',
                        'PROGRAM.weightManagement.salesforceSubtitle'
                    )}
                {showPreDiabetes &&
                    renderProgramCard(
                        'registration/components/questions/program/pulse/Program_DPP_erjmib',
                        'PROGRAM.prediabetes.alt',
                        1082,
                        609,
                        isOneApp || storedStepNumber
                            ? 'PROGRAM.prediabetes.oneAppSubtitle'
                            : 'PROGRAM.prediabetes.subtitle',
                        'PROGRAM.prediabetes.content',
                        'PROGRAM.prediabetes.salesforceSubtitle'
                    )}
                {showBehavioralHealth &&
                    renderProgramCard(
                        'registration/components/questions/program/pulse/Program_BH_bhjijc',
                        'PROGRAM.behavioralHealth.alt',
                        1082,
                        609,
                        isOneApp || storedStepNumber
                            ? 'PROGRAM.behavioralHealth.oneAppSubtitle'
                            : 'PROGRAM.behavioralHealth.subtitle',
                        'PROGRAM.behavioralHealth.content',
                        'PROGRAM.behavioralHealth.salesforceSubtitle'
                    )}
                {showChronicKidneyDisease &&
                    renderProgramCard(
                        'registration/components/questions/program/pulse/Program_CKD_q98d8o',
                        'PROGRAM.chronicKidneyDisease.alt',
                        1082,
                        609,
                        isOneApp || storedStepNumber
                            ? 'PROGRAM.chronicKidneyDisease.oneAppSubtitle'
                            : 'PROGRAM.chronicKidneyDisease.subtitle',
                        'PROGRAM.chronicKidneyDisease.content',
                        'PROGRAM.chronicKidneyDisease.salesforceSubtitle'
                    )}
                {showHeartFailure &&
                    renderProgramCard(
                        'registration/components/questions/program/pulse/Program_HF_amiuny',
                        'PROGRAM.heartFailure.alt',
                        1082,
                        609,
                        isOneApp || storedStepNumber
                            ? 'PROGRAM.heartFailure.oneAppSubtitle'
                            : 'PROGRAM.heartFailure.subtitle',
                        'PROGRAM.heartFailure.content',
                        'Heart Failure'
                    )}
            </div>

            {showNeedChangeButton && !storedStepNumber && (
                <Button onClick={onToggleFlyout}>
                    {t('PROGRAM.toggleLabel')}
                </Button>
            )}

            {storedStepNumber && (
                <Button
                    onClick={() => {
                        showProgramEligibility();
                    }}
                >
                    {t('PROGRAM.learnMoreButtonText')}
                </Button>
            )}

            <Flyout
                isOpen={showFlyout}
                classNameRoot={css.modal}
                classNameContainer={css.container}
                renderClose={onCloseClick => {
                    return (
                        <Button
                            className={css.close}
                            onClick={onCloseClick}
                            aria-label={t('PROGRAM.closeAriaLabel')}
                        >
                            <IconCloseDefault />
                        </Button>
                    );
                }}
                onRequestClose={onToggleFlyout}
            >
                <div className={css.programSelectContainer}>
                    <div className={css.section}>
                        <h2 className={css.programTitle}>
                            {t(
                                isProgramPending
                                    ? 'PROGRAM.flyout.titleForPendingProgram'
                                    : 'PROGRAM.flyout.title'
                            )}
                        </h2>
                        {!isProgramPending && (
                            <>
                                <p>{t('PROGRAM.flyout.content1')}</p>
                                <p>{t('PROGRAM.flyout.content2')}</p>
                                <p className={css.programSubheader}>
                                    {t('PROGRAM.yourPrograms')}
                                </p>
                            </>
                        )}
                        <div>
                            {items.map(({value, label}) => {
                                return (
                                    <Checkbox
                                        key={value}
                                        id={value}
                                        name="selected_program"
                                        value={value}
                                        label={<Label>{label}</Label>}
                                        i18nItemLabel={label}
                                        defaultChecked={programSubmissions.includes(
                                            value
                                        )}
                                        onChange={onProgramChange}
                                    />
                                );
                            })}
                        </div>
                        {isProgramPending && (
                            <p>
                                {t('PROGRAM.flyout.content1ForPendingProgram')}
                            </p>
                        )}
                        {showErrorMsg && renderMessage()}
                        <div className={css.buttonContainer}>
                            <Button
                                className={css.buttonCancel}
                                onClick={onCancelClick}
                            >
                                {t('PROGRAM.cancelButton')}
                            </Button>
                            <Button
                                className={css.buttonConfirm}
                                variant="primary"
                                onClick={onConfirmClick}
                                disabled={disableConfirmButton}
                            >
                                {t('PROGRAM.confirmButton')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Flyout>

            {programSubmissions.map(programSubmission => {
                return (
                    <TextInput
                        // this hidden text input is only for submit form
                        id={id}
                        key={programSubmission}
                        name={name}
                        type="hidden"
                        i18nItemLabel="hidden"
                        value={programSubmission}
                    />
                );
            })}
            {isSalesforce && <QualificationReason />}
        </div>
    );
};

Program.propTypes = {
    id: PropTypes.string.isRequired,
    questionId: PropTypes.object.isRequired,
    isProgramPending: PropTypes.bool.isRequired,
    dataType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    defaultValue: PropTypes.array,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} a1cValue - Either the A1c Value or the IDK string.
     */
    onChange: PropTypes.func,
    setNavigationLabel: PropTypes.func,
    isOneApp: PropTypes.bool,
};

export default Program;
