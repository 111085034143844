import {useEffect, useReducer, useCallback} from 'react';
import {combineReducers} from 'redux';
import {autoComplete} from 'api/protobuf/enrollment/services';

const REG_CODE_MINIMUM_LENGTH = 3;
const REG_CODE_SET = 'REG_CODE_SET';
const REG_CODE_FETCH = 'REG_CODE_FETCH';
const REG_CODE_CANCEL_FETCH = 'REG_CODE_CANCEL_FETCH';
const REG_CODE_CHANGE_SUGGESTIONS = 'REG_CODE_CHANGE_SUGGESTIONS';
const REG_CODE_CLEAR_SUGGESTIONS = 'REG_CODE_CLEAR_SUGGESTIONS';
const REG_CODE_ADD_ERROR = 'REG_CODE_ADD_ERROR';
const REG_CODE_CLEAR_ERROR = 'REG_CODE_CLEAR_ERROR';
const ERROR_REG_CODE_FETCH = 'ERROR_REG_CODE_FETCH';
const ERROR_REG_CODE_FETCH_UNKNOWN = 'ERROR_REG_CODE_FETCH_UNKNOWN';

const useRegCode = (id, version) => {
    const initialState = {
        value: '',
        fetching: false,
        suggestions: [],
        error: '',
    };

    /*
     * Use this reducer / action pattern so we can easily convert it if we will move the state to redux store.
     * This fetch reducer is a common pattern for fetch data.
     * The only difference for different fetch is the error message.
     * We might be able to use it for all other data fetch
     *   by pass an additional param to identify the error message.
     */
    const value = (state = '', action) => {
        switch (action.type) {
            case REG_CODE_SET:
                return action.payload;
            default:
                return state;
        }
    };

    const fetching = (state = false, action) => {
        switch (action.type) {
            case REG_CODE_FETCH:
                return true;

            case REG_CODE_CANCEL_FETCH:
            case REG_CODE_CLEAR_SUGGESTIONS:
            case REG_CODE_CHANGE_SUGGESTIONS:
                return false;

            default:
                return state;
        }
    };

    const suggestions = (state = [], action) => {
        switch (action.type) {
            case REG_CODE_CLEAR_SUGGESTIONS:
                return [];

            case REG_CODE_CHANGE_SUGGESTIONS:
                return action.payload;

            default:
                return state;
        }
    };

    const error = (state = '', action) => {
        switch (action.type) {
            case REG_CODE_ADD_ERROR:
                return action.payload;
            case REG_CODE_CLEAR_ERROR:
            case REG_CODE_SET:
                return '';
            default:
                return state;
        }
    };

    const reducer = combineReducers({
        value,
        fetching,
        suggestions,
        error,
    });

    const [state, dispatch] = useReducer(reducer, initialState);

    // Action creators
    const setRegCode = regCode => ({
        type: REG_CODE_SET,
        payload: regCode,
    });

    const setFetching = () => ({
        type: REG_CODE_FETCH,
    });

    const cancelFetching = () => ({
        type: REG_CODE_CANCEL_FETCH,
    });

    const addError = message => ({
        type: REG_CODE_ADD_ERROR,
        payload: message,
    });

    const changeRegCode = regCodes => ({
        type: REG_CODE_CHANGE_SUGGESTIONS,
        payload: regCodes,
    });

    const clearRegCode = () => ({
        type: REG_CODE_CLEAR_SUGGESTIONS,
    });

    const setValue = useCallback(val => {
        dispatch(setRegCode(val));
    }, []);

    const clearSuggestions = useCallback(() => {
        dispatch(clearRegCode());
    }, []);

    useEffect(() => {
        const input = state.value ? state.value.trim() : '';

        if (input.length < REG_CODE_MINIMUM_LENGTH) {
            dispatch(clearRegCode());

            return;
        }

        let canceled = false;

        dispatch(setFetching());

        try {
            autoComplete({id, version}, input)
                .then(response => {
                    if (canceled) {
                        return;
                    }

                    const optionsList = response?.completions?.optionsList;

                    if (optionsList) {
                        dispatch(changeRegCode(optionsList));
                    } else {
                        dispatch(addError(ERROR_REG_CODE_FETCH));
                        dispatch(cancelFetching());
                    }
                })
                .catch(err => {
                    if (canceled) {
                        return;
                    }

                    dispatch(
                        addError(err.message || ERROR_REG_CODE_FETCH_UNKNOWN)
                    );
                    dispatch(cancelFetching());
                });
        } catch (e) {
            if (canceled) {
                return;
            }

            dispatch(addError(ERROR_REG_CODE_FETCH));
            dispatch(cancelFetching());
        }

        return () => (canceled = true);
    }, [id, version, state.value]);

    return {
        value: state.value,
        fetching: state.fetching,
        suggestions: state.suggestions,
        error: state.error,
        setValue,
        clearSuggestions,
    };
};

export default useRegCode;
