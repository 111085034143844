import Image from '@teladoc/pulse/ui/Image';
import Config from 'config';
import {getCountry} from 'utilities/utils';
import css from './Unavailable.scss';

// There is no i18n available on this page because this is a server error and translations wouldn't have loaded yet
const TITLE = 'Uh-oh! Our site had a hiccup.';
const {
    memberSupportEmail,
    memberSupportPhoneNumber,
    memberSupportFormattedPhoneNumber,
} = Config;

const Unavailable = () => {
    const countryCode = getCountry();
    let LogoComponent;

    switch (countryCode) {
        case 'US':
            LogoComponent = (
                <Image
                    classNameImage={css.logo}
                    cloudinaryImageId="registration/common/logo_zmav1x"
                    width={165}
                    height={32}
                    alt="Livongo"
                />
            );
            break;
        default:
            LogoComponent = (
                <Image
                    classNameImage={css.logo}
                    cloudinaryImageId="registration/common/Teladoc_Logo_ES.svg"
                    width={125}
                    height={40}
                    alt="Teladoc"
                />
            );
            break;
    }

    return (
        <div className={css.root}>
            {LogoComponent}
            <Image
                classNameImage={css.image}
                cloudinaryImageId="registration/common/error_tisnoe"
                width={164}
                height={117}
                alt={TITLE}
            />
            <h1 className="heading-sm">{TITLE}</h1>
            <p>
                But good news ... we&apos;re working to get it back up and
                running. In the meantime, try refreshing your browser. If it
                happens again, email us at{' '}
                <a href={`mailto:${memberSupportEmail}`}>
                    {memberSupportEmail}
                </a>{' '}
                or call{' '}
                <a href={`tel:${memberSupportPhoneNumber}`}>
                    {memberSupportFormattedPhoneNumber}
                </a>
                .
            </p>
        </div>
    );
};

export default Unavailable;
