import {createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import {composeWithDevTools} from 'redux-devtools-extension';
import SentryUtils from '@livongo/utils/sentry';
import rootReducer from './reducers';

let store;

export default function create(initialState) {
    const sentryReduxEnhancer = SentryUtils.createReduxEnhancer({
        actionTransformer: action => {
            return action;
        },
        stateTransformer: state => {
            return null;
        },
    });

    store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools(
            applyMiddleware(thunkMiddleware, promiseMiddleware),
            ...(sentryReduxEnhancer ? [sentryReduxEnhancer] : [])
        )
    );

    return store;
}

export const getStore = () => {
    return store;
};
