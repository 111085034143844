import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import Config from 'config';
import {cleanSessionInfo} from 'utilities/utils';
import css from './OneAppEnrollmentBlocked.scss';

const OneAppEnrollmentBlocked = () => {
    const {t} = useTranslation('content');
    const dispatch = useDispatch();
    const redirectToOneApp = () => {
        const url = process.env.ONEAPP_LOGIN_URL ?? Config.oneAppUrl;

        cleanSessionInfo(true);

        window.location.href = url;
    };

    /* NavigationButton must be disabled in this component */
    useEffect(() => {
        let obj = {backBtn: true, nextBtn: true};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={css.root}>
            <h1 className={css.h1}>{t('OneAppEnrollmentBlocked.title')}</h1>
            <p className={css.p}>{t('OneAppEnrollmentBlocked.paragraph')}</p>
            <Button
                className={css.oneAppButton}
                variant="primary"
                onClick={redirectToOneApp}
            >
                {t('OneAppEnrollmentBlocked.btnText')}
            </Button>
        </div>
    );
};

export default OneAppEnrollmentBlocked;
