import StorageUtils from '@livongo/utils/storage';
import isNil from 'lodash/isNil';
import Config from 'config';

export const getTdocMemberId = () => {
    return StorageUtils.get({
        key: Config.MEMBER_ID,
        type: 'cookie',
        useNative: true,
        parse: false,
    });
};

export const getTdocAuthToken = () => {
    return StorageUtils.get({
        key: Config.TDOC_AUTH_TOKEN,
        type: 'cookie',
        useNative: true,
        parse: false,
    });
};

export const getOneAppPortalUrl = () => {
    return StorageUtils.get({
        key: Config.oneAppPortal,
    });
};

export const setOneAppPortalUrl = oneAppPortalUrl => {
    StorageUtils.set({
        key: Config.oneAppPortal,
        value: oneAppPortalUrl,
    });
};

export const getOneAppLocaleCookie = () => {
    let locale = StorageUtils.get({
        key: Config.USER_LOCALE_COOKIE,
        type: 'cookie',
        useNative: true,
        parse: false,
    });
    // If there's no locale cookie, default to US English

    if (isNil(locale)) {
        locale = 'en-US';
    }

    return locale;
};
