import {useDispatch} from 'react-redux';
import {addMessage} from 'store/message/message-actions';
import messageConfig, {messageTypes} from 'constants/messages';
import {mixpanelModalViewedEvent} from 'utilities/mixpanel-utils';

const {
    ERROR_BAD_REQUEST,
    ERROR_QUESTIONS_FETCH,
    ERROR_CREATE_ACCOUNT,
    ERROR_MEMBER_SUPPORT_U13_DISQUALIFIED,
    MODAL_ACCOUNT_ALREADY_EXISTS,
    MODAL_NOT_FOUND_SPONSOR,
    MODAL_NOT_FOUND_REGCODE,
    MODAL_CLIENT_CAPPED,
    MODAL_REGISTRATION_CLOSED,
    MODAL_UNDER_AGE,
    MODAL_TIMEOUT,
    MODAL_BLOOD_TEST_EXAMPLE,
    MODAL_SUPPORTED_REGION,
    MODAL_ZIPCODE_NOT_SUPPORTED,
    MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED,
    MODAL_MOBILE_APP_SMS,
    MODAL_REDIRECT_ERROR_MESSAGE,
    MODAL_FOUND_REGCODES_FROM_EMAIL,
    MODAL_PROGRAM_ELIGIBILITY,
} = messageConfig;
const {
    ACCOUNT_ALREADY_EXISTS,
    MEMBER_SUPPORT,
    TIMEOUT,
    BLOOD_TEST_EXAMPLE,
    NOT_FOUND_SPONSOR,
    NOT_FOUND_REGCODE,
    INTERNATIONAL_NUMBER_NOT_ALLOWED,
    MOBILE_APP_SMS,
    REDIRECT_ERROR_MESSAGE,
    FOUND_REGCODES_FROM_EMAIL,
    PROGRAM_ELIGIBILITY,
} = messageTypes;

const useMessage = () => {
    const dispatch = useDispatch();

    const showBadRequestErrorMessage = () => {
        dispatch(
            addMessage({
                type: MEMBER_SUPPORT,
                id: ERROR_BAD_REQUEST.id,
            })
        );
    };

    const showQuestionsFetchErrorMessage = () => {
        dispatch(
            addMessage({
                type: MEMBER_SUPPORT,
                id: ERROR_QUESTIONS_FETCH.id,
            })
        );
    };

    const showAccountCreateErrorMessage = () => {
        dispatch(
            addMessage({
                type: MEMBER_SUPPORT,
                id: ERROR_CREATE_ACCOUNT.id,
            })
        );
    };

    const showU13DisqualifiedMemberSupportErrorMessage = () => {
        dispatch(
            addMessage({
                id: ERROR_MEMBER_SUPPORT_U13_DISQUALIFIED.id,
            })
        );
    };

    const showAccountAlreadyExistsMessage = () => {
        mixpanelModalViewedEvent(MODAL_ACCOUNT_ALREADY_EXISTS.id, '', 'EMAIL');
        dispatch(
            addMessage({
                type: ACCOUNT_ALREADY_EXISTS,
                id: MODAL_ACCOUNT_ALREADY_EXISTS.id,
            })
        );
    };

    const showNotFoundSponsorMessage = () => {
        mixpanelModalViewedEvent(MODAL_NOT_FOUND_SPONSOR.id, '', 'REG_CODE');
        dispatch(
            addMessage({
                type: NOT_FOUND_SPONSOR,
                id: MODAL_NOT_FOUND_SPONSOR.id,
            })
        );
    };

    const showFoundRegcodesFromEmail = regCodeData => {
        mixpanelModalViewedEvent(
            MODAL_FOUND_REGCODES_FROM_EMAIL.id,
            '',
            'REG_CODE'
        );
        dispatch(
            addMessage({
                type: FOUND_REGCODES_FROM_EMAIL,
                id: MODAL_FOUND_REGCODES_FROM_EMAIL.id,
                data: regCodeData,
            })
        );
    };

    const showNotFoundRegcodeMessage = () => {
        mixpanelModalViewedEvent(MODAL_NOT_FOUND_REGCODE.id, '', 'REG_CODE');
        dispatch(
            addMessage({
                type: NOT_FOUND_REGCODE,
                id: MODAL_NOT_FOUND_REGCODE.id,
            })
        );
    };

    const showClientCappedMessage = () => {
        mixpanelModalViewedEvent(MODAL_CLIENT_CAPPED.id, '', 'REG_CODE');
        dispatch(
            addMessage({
                id: MODAL_CLIENT_CAPPED.id,
            })
        );
    };

    const showRegistrationClosedMessage = () => {
        mixpanelModalViewedEvent(MODAL_REGISTRATION_CLOSED.id, '', 'REG_CODE');
        dispatch(
            addMessage({
                id: MODAL_REGISTRATION_CLOSED.id,
            })
        );
    };

    const showTimeoutMessage = () => {
        dispatch(
            addMessage({
                type: TIMEOUT,
                id: MODAL_TIMEOUT.id,
            })
        );
    };

    const showUnderAgeMessage = () => {
        mixpanelModalViewedEvent(MODAL_UNDER_AGE.id, '', 'BIRTH_DATE');
        dispatch(
            addMessage({
                id: MODAL_UNDER_AGE.id,
            })
        );
    };

    const showBloodTestExampleMessage = () => {
        mixpanelModalViewedEvent(
            MODAL_BLOOD_TEST_EXAMPLE.id,
            '',
            'BLOOD_TEST_PREDIABETES'
        );
        dispatch(
            addMessage({
                type: BLOOD_TEST_EXAMPLE,
                id: MODAL_BLOOD_TEST_EXAMPLE.id,
            })
        );
    };

    const showSupportedRegionMessage = () => {
        mixpanelModalViewedEvent(
            MODAL_SUPPORTED_REGION.id,
            '',
            'SUPPORTED_REGION'
        );
        dispatch(
            addMessage({
                id: MODAL_SUPPORTED_REGION.id,
            })
        );
    };

    const showZipcodeNotSupported = () => {
        mixpanelModalViewedEvent(MODAL_ZIPCODE_NOT_SUPPORTED.id, '', 'ZIP');
        dispatch(
            addMessage({
                id: MODAL_ZIPCODE_NOT_SUPPORTED.id,
            })
        );
    };

    const showInternationalNumberNotAllowedErrorMessage = clientName => {
        mixpanelModalViewedEvent(
            MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED.id,
            '',
            'PHONE_MOBILE'
        );
        dispatch(
            addMessage({
                type: INTERNATIONAL_NUMBER_NOT_ALLOWED,
                id: MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED.id,
                text: clientName,
            })
        );
    };

    const showMobileAppSmsMessage = contentId => {
        mixpanelModalViewedEvent('Mobile App Download', contentId, '');
        dispatch(
            addMessage({
                type: MOBILE_APP_SMS,
                id: MODAL_MOBILE_APP_SMS.id,
            })
        );
    };

    const showNotFoundRedirectUrl = () => {
        mixpanelModalViewedEvent(MODAL_REDIRECT_ERROR_MESSAGE.id, '', '');
        dispatch(
            addMessage({
                type: REDIRECT_ERROR_MESSAGE,
                id: MODAL_REDIRECT_ERROR_MESSAGE.id,
            })
        );
    };

    const showProgramEligibility = () => {
        mixpanelModalViewedEvent(MODAL_PROGRAM_ELIGIBILITY.id, '', '');
        dispatch(
            addMessage({
                type: PROGRAM_ELIGIBILITY,
                id: MODAL_PROGRAM_ELIGIBILITY.id,
            })
        );
    };

    return {
        showBadRequestErrorMessage,
        showQuestionsFetchErrorMessage,
        showAccountCreateErrorMessage,
        showAccountAlreadyExistsMessage,
        showNotFoundSponsorMessage,
        showNotFoundRegcodeMessage,
        showClientCappedMessage,
        showRegistrationClosedMessage,
        showTimeoutMessage,
        showUnderAgeMessage,
        showBloodTestExampleMessage,
        showSupportedRegionMessage,
        showZipcodeNotSupported,
        showU13DisqualifiedMemberSupportErrorMessage,
        showInternationalNumberNotAllowedErrorMessage,
        showMobileAppSmsMessage,
        showNotFoundRedirectUrl,
        showFoundRegcodesFromEmail,
        showProgramEligibility,
    };
};

export default useMessage;
