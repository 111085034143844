import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import Button from '@teladoc/pulse/ui/Button';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import cn from 'classnames';
// Todo: uncomment RegionSelect and remove popover when pulse RegionSelect component ready
// import RegionSelect from '@teladoc/pulse/ui/RegionSelect';
import Popover from '@teladoc/pulse/ui/Popover';
import IconLanguageDefault from '@teladoc/pulse/icons/language-default.svg';
import IconArrowDownDefault from '@teladoc/pulse/icons/arrow-down-default.svg';
import IconProfileDefault from '@teladoc/pulse/icons/profile-default.svg';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import Image from '@teladoc/pulse/ui/Image';
import {AmplitudeUtils} from 'utilities/amplitude-utils';
import {languageCodeForSubmit, allLocalesAndLanguages} from 'i18n';
import {submitAnswers} from 'api/protobuf/enrollment/services';
import {questionFieldTypes} from 'constants/questions';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import Config from 'config';
import {
    getIsAuthenticated,
    redirectToMemberPortal,
    getIsVisited,
    getIsEmailQuestionAsked,
    getUserSessionExpires,
    getRegistrationContext,
    getApplication,
    getCountry,
    getRestApiAccessToken,
    redirectToMemberPortalOrOneAppHomePage,
    getSsoContext,
    cleanSessionInfo,
    getCallBackUrl,
    checkIsTokenExpired,
} from 'utilities/utils';
import {
    mixpanelGeneralError,
    mixpanelClickedTeladocLogo,
} from 'utilities/mixpanel-utils';
import {MED_OPT, UPSELL} from 'constants/app';
import UserAPI from 'api/rest/user-api';
import {userUpdate} from '../../store/user/user-actions';
import css from './Header.scss';

const {loginUrl} = Config;
const {SINGLE_SELECT_ENUM} = questionFieldTypes;

const Header = ({loading, isOneApp}) => {
    const {t} = useTranslation();
    const isEmailQuestionAsked = getIsEmailQuestionAsked();
    const countryCode = getCountry();
    const isLoginPage = window.location.pathname === loginUrl;
    const isAuthenticated = getIsAuthenticated();
    const restAuthToken = getRestApiAccessToken();
    const {client} = getRegistrationContext() ?? {};
    const isSalesforce = client === Config.client.salesforce;
    const isImpersonation = getSsoContext()?.isImpersonation ?? false;
    const {selectedLang, changeLanguage} = useI18nContext();
    const handleClick = evt => {
        changeLanguage(evt.target.id);
    };

    const countryLocales = {
        US: ['en-US', 'es-US'],
        ES: ['es-ES', 'ca-ES', 'en-ES'],
    };
    const currentCountryLocales = countryLocales[countryCode];
    const user = useSelector(state => state.user);
    const isMedOpt = getApplication() === MED_OPT;
    const isUpsell = getApplication() === UPSELL;
    const dispatch = useDispatch();
    const locale = getStoredLang() || 'en-US';

    const redirectToOneAppLogin = () => {
        mixpanelClickedTeladocLogo();
        AmplitudeUtils.trackClickedTeladocLogo();
        const redirectUrl = getCallBackUrl();

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    useEffect(() => {
        const isVisited = getIsVisited() || false;
        const userSessionExpires = getUserSessionExpires() || 0;
        const isUserSessionExpired = Date.now() > userSessionExpires;

        if ((isVisited || !isUserSessionExpired) && !isMedOpt) {
            const submitLang = async () => {
                const answers = [
                    {
                        questionId: {
                            id: QuestionId.Values.LANGUAGE,
                            /*
                             * We have to hard code the version here
                             *  since this is the very beginning and
                             *  no way to get the value ahead of time.
                             */
                            version: 1,
                        },
                        type: SINGLE_SELECT_ENUM,
                        value: languageCodeForSubmit[selectedLang],
                    },
                ];

                if (!checkIsTokenExpired()) {
                    await submitAnswers(answers).catch(e => {
                        // TODO: display proper error message.
                    });
                }
            };

            submitLang();
        }
    }, [isMedOpt, selectedLang]);

    useEffect(() => {
        if (
            (user.firstName === null &&
                isMedOpt &&
                isAuthenticated &&
                restAuthToken) ||
            (user.firstName === null && isUpsell)
        ) {
            const bearer = {
                headers: {
                    Authorization: `Bearer ${restAuthToken}`,
                },
            };
            const updateUser = async () => {
                const userInfoResp = await UserAPI.getUserInfo(bearer);
                const {recipient, addressLine1, lastLine, firstName} =
                    userInfoResp;

                user.recipient = recipient || '';
                user.addressLine1 = addressLine1 || '';
                user.lastLine = lastLine || '';
                user.firstName = firstName || '';

                // Update user info to Redux state
                dispatch(userUpdate(user));
            };

            updateUser().catch(e => {
                mixpanelGeneralError({
                    name: 'updateUser',
                    location: 'Header.updateUser',
                    additionalInfo: e,
                });
            });
        }
    }, [user, isMedOpt, isUpsell, isAuthenticated, restAuthToken, dispatch]);

    const LvgoLogo = (
        <Image
            cloudinaryImageId="member-portal/common/logo_lf3lrk"
            width={146}
            height={36}
            onClick={redirectToMemberPortal}
            alt={t('header.lvgoLogo')}
            aria-label={t('header.lvgoLogo')}
            showLoader={false}
            role="button"
            tabIndex={0}
        />
    );

    return (
        <>
            {isImpersonation && (
                <div className={css.impersonation}>
                    you are impersonating registration of a member
                </div>
            )}
            <div
                className={cn(css.root, {
                    [css.oneAppRoot]: isOneApp,
                })}
                role="banner"
            >
                {!loading && (
                    <div className={css.headerLeft}>
                        <div className={css.logo}>
                            {isOneApp ? (
                                <Image
                                    cloudinaryImageId="registration/common/Teladoc_Logo_ES.svg"
                                    width={125}
                                    height={40}
                                    key={isOneApp}
                                    onClick={
                                        isMedOpt
                                            ? () =>
                                                  redirectToMemberPortalOrOneAppHomePage(
                                                      isOneApp
                                                  )
                                            : redirectToOneAppLogin
                                    }
                                    alt="Teladoc"
                                    showLoader={false}
                                />
                            ) : (
                                isMedOpt && LvgoLogo
                            )}
                        </div>
                    </div>
                )}
                {!loading && !(isOneApp && !isMedOpt) && (
                    <div className={css.headerRight}>
                        {/* <RegionSelect allowlist={['en-US', 'es-US']} align="right" /> */}
                        {!isOneApp && (
                            <Popover
                                trigger={
                                    <Button
                                        className={css.languageSelect}
                                        aria-label={t(
                                            'header.languageSelectAriaLabel'
                                        )}
                                    >
                                        <IconLanguageDefault
                                            className={
                                                css.languageSelectIconGlobe
                                            }
                                        />
                                        <span className={css.languageText}>
                                            {
                                                allLocalesAndLanguages[locale]
                                                    .currentLang
                                            }
                                        </span>
                                        <IconArrowDownDefault
                                            className={
                                                css.languageSelectIconChevronDown
                                            }
                                        />
                                    </Button>
                                }
                                content={
                                    <FocusTrap
                                        focusTrapOptions={{
                                            clickOutsideDeactivates: true,
                                        }}
                                    >
                                        <ul className={css.listRoot}>
                                            {currentCountryLocales.map(
                                                currentCountryLocale => (
                                                    <li
                                                        key={
                                                            currentCountryLocale
                                                        }
                                                    >
                                                        <Button
                                                            id={
                                                                currentCountryLocale
                                                            }
                                                            onClick={
                                                                handleClick
                                                            }
                                                        >
                                                            {
                                                                allLocalesAndLanguages[
                                                                    locale
                                                                ][
                                                                    currentCountryLocale
                                                                ]
                                                            }
                                                        </Button>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </FocusTrap>
                                }
                                hideOnDisappear
                                hideOnContentClick
                            />
                        )}
                        {!isEmailQuestionAsked &&
                            !isLoginPage &&
                            !isAuthenticated &&
                            !isSalesforce && (
                                <Button
                                    className={css.loginButton}
                                    onClick={redirectToMemberPortal}
                                >
                                    {t('header.logIn')}
                                </Button>
                            )}
                        {isAuthenticated && isMedOpt && (
                            <Button
                                onClick={() =>
                                    redirectToMemberPortalOrOneAppHomePage(
                                        isOneApp
                                    )
                                }
                            >
                                <IconProfileDefault
                                    className={css.languageSelectIconGlobe}
                                />
                                <span className={css.userFirstname}>
                                    {user?.firstName}
                                </span>
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

Header.propTypes = {
    loading: PropTypes.bool,
    isOneApp: PropTypes.bool,
};

export default Header;
