import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import Label from '@teladoc/pulse/ui/Label';
import Button from '@teladoc/pulse/ui/Button';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import {questionConfig} from 'constants/questions';
import useMessage from 'hook/useMessage';
import css from '../Question.scss';

const SupportedRegion = ({id, name, required, defaultValue}) => {
    const {title, error} = questionConfig[id] || {};
    const {t} = useTranslation('questions');
    const {showSupportedRegionMessage} = useMessage();

    const onExampleClick = evt => {
        showSupportedRegionMessage();
    };

    return (
        <Checkbox
            id={id}
            name={name}
            value="true"
            defaultChecked={defaultValue}
            label={
                <Label className={css.checkboxQuestionLabel}>
                    <Trans
                        i18nKey="questions:SUPPORTED_REGION.label"
                        components={[
                            /* eslint-disable react/jsx-key */
                            <Button
                                className={css.btnAlign}
                                onClick={onExampleClick}
                                children=""
                            /> /* eslint-enable react/jsx-key */,
                        ]}
                    />
                </Label>
            }
            i18nItemLabel={t(title)}
            error={<FormElementError>{t(error)}</FormElementError>}
            required={required}
        />
    );
};

SupportedRegion.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
};

export default SupportedRegion;
