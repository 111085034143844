import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import cn from 'classnames';
import AdvancedSelect from '@teladoc/pulse/ui/AdvancedSelect';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import TextInput from '@teladoc/pulse/ui/TextInput';
import Label from '@teladoc/pulse/ui/Label';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import StateSelect from '@teladoc/pulse/ui/StateSelect';
import ZipInput from '@teladoc/pulse/ui/ZipInput';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {questionConfig} from 'constants/questions';
import {programs as programsName} from 'constants/app';
import Config from 'config';
import {
    getFirstNonEmptyKey,
    getMelissaAddressData,
    getRegistrationContext,
    setSavedAddress,
    getSavedAddress,
} from 'utilities/utils';
import css from './Address.scss';

const {
    DIABETES,
    HYPERTENSION,
    WEIGHT_MANAGEMENT,
    PREDIABETES,
    CHRONIC_KIDNEY_DISEASE,
    HEART_FAILURE,
    ADV_WEIGHT_MANAGEMENT,
    ADV_PREDIABETES,
    COMPR_WEIGHT_CARE,
    COMPR_PREDIABETES_CARE,
} = programsName;

// render custom autoSuggestDropdown items
const AddressAutoSuggestItem = ({
    displayAddress1,
    displayAddress2,
    className,
}) => (
    <div className={className}>
        <p className={css.textMargin}>{displayAddress1}</p>
        <p>{displayAddress2}</p>
    </div>
);

AddressAutoSuggestItem.propTypes = {
    displayAddress1: PropTypes.string,
    displayAddress2: PropTypes.string,
    className: PropTypes.string,
};

// TODO: Update subValue with BE return.
const Address = ({
    id,
    name,
    question,
    customValidationError,
    onChange,
    questionValues,
    includeMilitary,
    includeUSTerritories,
    context,
    isAddressFromEditable,
    setIsAddressFromEditable,
    formRef,
}) => {
    const {t} = useTranslation('questions');
    const questions = question?.dataType?.compoundField?.questionsList || [];
    const questionIDValueHash = {};
    const questionIDDefaultValueHash = {};
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;
    const answersWithinContext = context.answersList || [];
    const programAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.PROGRAM
    );
    const lastNameAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.LAST_NAME
    );
    const firstNameAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.FIRST_NAME
    );
    const selectedPrograms =
        programAnswer?.value?.multiSelectEnum?.enumCodeList || [];
    const lastName = lastNameAnswer?.value?.text || '';
    const firstName = firstNameAnswer?.value?.text || '';
    const isDiabetes = selectedPrograms.includes(DIABETES);
    const isHypertension = selectedPrograms.includes(HYPERTENSION);
    const isWeightManagement =
        selectedPrograms.includes(WEIGHT_MANAGEMENT) ||
        selectedPrograms.includes(ADV_WEIGHT_MANAGEMENT);
    const isPreDiabetes =
        selectedPrograms.includes(PREDIABETES) ||
        selectedPrograms.includes(ADV_PREDIABETES);
    const isComprehensiveCarePrograms =
        selectedPrograms.includes(COMPR_WEIGHT_CARE) ||
        selectedPrograms.includes(COMPR_PREDIABETES_CARE);
    const isChronicKidneyDisease = selectedPrograms.includes(
        CHRONIC_KIDNEY_DISEASE
    );
    const isHeartFailure = selectedPrograms.includes(HEART_FAILURE);
    let savedAnswer = getSavedAddress();
    let isAllRequiredQuestionAnswered = true;

    questions.forEach(que => {
        const questionName = getQuestionNameById(que.questionId.id);
        const questionTypedField = getFirstNonEmptyKey(que.dataType);
        const dataType = que.dataType[questionTypedField];

        questionIDValueHash[questionName] = que;

        // can handle TEXT_FIELD, PROBABLY BOOLEAN_FIELD, INTEGER_FIELD, FLOAT_FIELD, and SINGLE_SELECT_ENUM
        if (dataType.userInput) {
            questionIDDefaultValueHash[questionName] = dataType.userInput;
        } else if (dataType.previousAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswer.value;
        } else if (dataType.defaultAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.defaultAnswer.value;
        } else if (dataType.previousAnswerCode) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswerCode.value;
        } else if (dataType.defaultAnswerCode) {
            questionIDDefaultValueHash[questionName] =
                dataType.defaultAnswerCode.value;
        } else {
            // fall into this else only if there is no any previousAnswer or defaultAnswer found
            // so if the question required to have answer, set AddressFrom to editable
            if (que.required && !savedAnswer) {
                setIsAddressFromEditable(true);
                isAllRequiredQuestionAnswered = false;
            }
        }
    });

    const [disableCancelButton, setDisableCancelButton] = useState(
        !isAllRequiredQuestionAnswered
    );

    if (savedAnswer === null) {
        savedAnswer = questionIDDefaultValueHash;
    }

    for (const key in questionValues) {
        if (questionValues[key]) {
            questionIDDefaultValueHash[key] = questionValues[key];
        }
    }

    const [answerHash, setAnswerHash] = useState(
        savedAnswer || questionIDDefaultValueHash
    );
    const [addressList, setAddressList] = useState([]);
    const [initialSelectedItems, setInitialSelectedItems] = useState(() =>
        answerHash.ADDRESS_LINE1
            ? [
                  {
                      label: answerHash.ADDRESS_LINE1 || '',
                      value: answerHash.ADDRESS_LINE1 || '',
                  },
              ]
            : []
    );

    const handleOnChange = (key, value, AnswerHash) => {
        const newAnswerHash = {
            ...answerHash,
            [key]: value,
        };

        setAnswerHash(newAnswerHash);

        if (onChange) {
            if (AnswerHash) {
                onChange(AnswerHash);
            } else {
                onChange(newAnswerHash);
            }
        }
    };

    const handleInputChange = value => {
        getMelissaAddressData(value).then(items => {
            if (typeof items !== 'undefined' && items !== null) {
                setAddressList(items?.Results);
            }
        });

        setInitialSelectedItems([]);
        const newAnswerHash = {
            ...answerHash,
            ADDRESS_LINE1: value,
        };

        setAnswerHash(newAnswerHash);
    };

    const [addressLine1Error, setAddressLine1Error] = useState(null);

    useEffect(() => {
        if (answerHash?.ADDRESS_LINE1?.length > 35) {
            setAddressLine1Error(
                <FormElementError>
                    {t(questionConfig.ADDRESS_LINE1.maxError)}
                </FormElementError>
            );
        } else {
            setAddressLine1Error(null);
        }
    }, [answerHash, t]);

    const debounceOnChange = debounce(handleInputChange, 500);
    const handleClear = () => {
        setInitialSelectedItems([]);
        const newAnswerHash = {
            ...answerHash,
            ADDRESS_LINE1: '',
        };

        setAnswerHash(newAnswerHash);
    };
    const [key, setKey] = useState(new Date().getTime());

    const handleSelect = value => {
        const {Address: autoSuggestAddress} = addressList.find(
            suggestion =>
                `${suggestion?.Address?.AddressLine1}, ${
                    suggestion?.Address?.City
                }, ${
                    suggestion?.Address?.State
                }, ${suggestion?.Address?.PostalCode.slice(0, 5)}` === value
        );

        const newAnswerHash = {
            ...answerHash,
            ADDRESS_LINE1: autoSuggestAddress.AddressLine1,
            CITY: autoSuggestAddress.City,
            STATE: autoSuggestAddress.State,
            ZIP: autoSuggestAddress.PostalCode.slice(0, 5),
        };

        handleOnChange('STATE', autoSuggestAddress.State, newAnswerHash);

        setInitialSelectedItems([
            {
                label: autoSuggestAddress.AddressLine1,
                value: autoSuggestAddress.AddressLine1,
            },
        ]);
        setAnswerHash(newAnswerHash);
        setKey(new Date().getTime());
    };

    // generate item list for advancedSelect
    const formattedSuggestions = addressList.map(address => {
        const {Address: autoSuggestAddress} = address;
        const {AddressLine1, City, State, PostalCode} = autoSuggestAddress;

        return {
            label: `${AddressLine1}, ${City}, ${State}, ${PostalCode.slice(
                0,
                5
            )}`,
            value: `${AddressLine1}, ${City}, ${State}, ${PostalCode.slice(
                0,
                5
            )}`,
        };
    });

    const rowRenderer = ({value, chunks, highlighted, item}) => {
        const [address, city, state, zipcode] = item.label.split(',');
        const displayAddress1 = address;
        const displayAddress2 = `${city}, ${state} ${zipcode}`;

        return AddressAutoSuggestItem({
            displayAddress1,
            displayAddress2,
            className: css.listItem,
        });
    };

    const onEditClicked = () => {
        setIsAddressFromEditable(true);
    };

    const onCancelClicked = () => {
        setAnswerHash(savedAnswer);
        setKey(new Date().getTime());
        setIsAddressFromEditable(false);
        setInitialSelectedItems([
            {
                label: savedAnswer.ADDRESS_LINE1 || '',
                value: savedAnswer.ADDRESS_LINE1 || '',
            },
        ]);
    };

    const onSaveClicked = () => {
        document
            .getElementById(
                includeMilitary ? 'MOBILE_PHONE_INTERNATIONAL' : 'PHONE_MOBILE'
            )
            .removeAttribute('required');

        if (formRef.current.isValid()) {
            setSavedAddress(answerHash);
            setIsAddressFromEditable(false);
            setDisableCancelButton(!savedAnswer);
            setInitialSelectedItems([
                {
                    label: answerHash.ADDRESS_LINE1,
                    value: answerHash.ADDRESS_LINE1,
                },
            ]);
        } else {
            formRef.current.animateToInvalid();
        }

        document
            .getElementById(
                includeMilitary ? 'MOBILE_PHONE_INTERNATIONAL' : 'PHONE_MOBILE'
            )
            .setAttribute('required', '');
    };

    return (
        <>
            {isOneApp && (
                <div>
                    <div className={css.oneAppHelperText}>
                        {t(
                            questionConfig.ADDRESS_USA_STANDARD.oneApp
                                .helperText
                        )}
                    </div>
                    {isDiabetes && (
                        <div className={css.oneAppImageContainer}>
                            <Image
                                cloudinaryImageId="registration/components/questions/address/BG300-oneapp_tpaqnc"
                                width={64}
                                height={64}
                                alt="BG300 device"
                                showLoader={false}
                            />
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .BG300Label
                            )}
                        </div>
                    )}
                    {isComprehensiveCarePrograms && (
                        <>
                            <div className={css.oneAppImageContainer}>
                                <Image
                                    cloudinaryImageId="registration/components/questions/address/WM1500-oneapp_jodh78"
                                    width={64}
                                    height={64}
                                    alt="WM1500 device"
                                    showLoader={false}
                                />
                                {t(
                                    questionConfig.ADDRESS_USA_STANDARD.oneApp
                                        .WM1500Label
                                )}
                            </div>
                            <div className={css.oneAppImageContainer}>
                                <Image
                                    cloudinaryImageId="registration/components/questions/address/HT945_oneapp_yxj8av"
                                    width={64}
                                    height={64}
                                    alt="HT945 device"
                                    showLoader={false}
                                />
                                {t(
                                    questionConfig.ADDRESS_USA_STANDARD.oneApp
                                        .HT900Label
                                )}
                            </div>
                        </>
                    )}
                    {(isHypertension ||
                        isChronicKidneyDisease ||
                        isHeartFailure) && (
                        <div className={css.oneAppImageContainer}>
                            <Image
                                cloudinaryImageId="registration/components/questions/address/HT900-oneapp_k8wa3p"
                                width={64}
                                height={64}
                                alt="HT900 device"
                                showLoader={false}
                            />
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .HT900Label
                            )}
                        </div>
                    )}
                    {(isWeightManagement ||
                        isPreDiabetes ||
                        isChronicKidneyDisease ||
                        isHeartFailure) && (
                        <div className={css.oneAppImageContainer}>
                            <Image
                                cloudinaryImageId="registration/components/questions/address/WM1500-oneapp_jodh78"
                                width={64}
                                height={64}
                                alt="WM1500 device"
                                showLoader={false}
                            />
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .WM1500Label
                            )}
                        </div>
                    )}
                    {!isAddressFromEditable && (
                        <div className={css.addressCardContainer}>
                            <div className={css.title}>
                                <div>
                                    {t(
                                        questionConfig.ADDRESS_USA_STANDARD
                                            .oneApp.shippingCardTitle
                                    )}
                                </div>
                                <Button onClick={onEditClicked}>
                                    {t(
                                        questionConfig.ADDRESS_USA_STANDARD
                                            .oneApp.shippingCardEditButton
                                    )}
                                </Button>
                            </div>
                            <div>
                                {firstName} {lastName}
                            </div>
                            <div>{savedAnswer.ADDRESS_LINE1}</div>
                            <div>{savedAnswer.ADDRESS_LINE2}</div>
                            <span>{savedAnswer.CITY}, </span>
                            <span>{savedAnswer.STATE} </span>
                            <span>{savedAnswer.ZIP}</span>
                        </div>
                    )}
                </div>
            )}
            <div
                className={cn(css.root, {
                    [css.oneAppRoot]: isOneApp,
                    [css.hideForm]: !isAddressFromEditable && isOneApp,
                })}
                id={id}
                key={key}
            >
                {isOneApp && (
                    <>
                        <div className={css.title}>
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .shippingFormTitle
                            )}
                        </div>
                        {includeMilitary && (
                            <div className={css.helperText}>
                                {t(
                                    questionConfig.ADDRESS_USA_STANDARD.oneApp
                                        .shippingFormHelperText
                                )}
                            </div>
                        )}
                        <div className={css.requiredLabel}>
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .requiredLabel
                            )}
                        </div>
                    </>
                )}
                {questionIDValueHash.ADDRESS_LINE1 && (
                    <div>
                        <AdvancedSelect
                            input={{
                                id: questionConfig.ADDRESS_LINE1.id,
                                name: `${name}_-_${questionIDValueHash.ADDRESS_LINE1.questionId.id}`,
                                i18nItemLabel: t(
                                    questionConfig.ADDRESS_LINE1.label
                                ),
                                error: (
                                    <FormElementError>
                                        {t(questionConfig.ADDRESS_LINE1.error)}
                                    </FormElementError>
                                ),
                                required:
                                    questionIDValueHash.ADDRESS_LINE1.required,
                                label: (
                                    <Label
                                        i18nRequiredVisualLabel={
                                            isOneApp ? '*' : ' '
                                        }
                                    >
                                        {t(questionConfig.ADDRESS_LINE1.label)}
                                    </Label>
                                ),
                            }}
                            initialSelectedItems={initialSelectedItems}
                            heightMenuItem={72}
                            onClear={handleClear}
                            rowRenderer={rowRenderer}
                            totalItemsBeforeScroll={6}
                            onItemSelect={handleSelect}
                            onValueChange={debounceOnChange}
                            items={uniqBy(formattedSuggestions, 'label')}
                            customValidationError={
                                addressLine1Error || customValidationError
                            }
                            showToggleIcon={false}
                            withValueAsDisplay
                            classNameMenu={css.menu}
                            classNameHighlight={css.highlight}
                        />
                    </div>
                )}
                {questionIDValueHash.ADDRESS_LINE2 && (
                    <TextInput
                        type={questionConfig.ADDRESS_LINE2.type}
                        id={questionConfig.ADDRESS_LINE2.id}
                        name={`${name}_-_${questionIDValueHash.ADDRESS_LINE2.questionId.id}`}
                        defaultValue={answerHash.ADDRESS_LINE2}
                        pattern={questionConfig.ADDRESS_LINE2.pattern}
                        label={
                            <Label>
                                {t(questionConfig.ADDRESS_LINE2.label)}
                                {!isOneApp &&
                                    t(
                                        questionConfig.ADDRESS_LINE2
                                            .optionalText
                                    )}
                            </Label>
                        }
                        placeholder={t(
                            questionConfig.ADDRESS_LINE2.placeholder
                        )}
                        autoComplete={questionConfig.ADDRESS_LINE2.autocomplete}
                        maxLength={35}
                        error={
                            <FormElementError>
                                {t(questionConfig.ADDRESS_LINE2.error)}
                            </FormElementError>
                        }
                        onChange={evt => {
                            handleOnChange('ADDRESS_LINE2', evt.target.value);
                        }}
                        required={questionIDValueHash.ADDRESS_LINE2.required}
                    />
                )}
                {questionIDValueHash.CITY && (
                    <TextInput
                        type={questionConfig.CITY.type}
                        id={questionConfig.CITY.id}
                        name={`${name}_-_${questionIDValueHash.CITY.questionId.id}`}
                        defaultValue={answerHash.CITY}
                        pattern={questionConfig.CITY.pattern}
                        label={
                            <Label
                                i18nRequiredVisualLabel={isOneApp ? '*' : ' '}
                            >
                                {t(questionConfig.CITY.label)}
                            </Label>
                        }
                        placeholder={t(questionConfig.CITY.placeholder)}
                        autoComplete={questionConfig.CITY.autocomplete}
                        error={
                            <FormElementError>
                                {t(questionConfig.CITY.error)}
                            </FormElementError>
                        }
                        onChange={evt => {
                            handleOnChange('CITY', evt.target.value);
                        }}
                        required={questionIDValueHash.CITY.required}
                    />
                )}
                <div className={css.stateZip}>
                    {questionIDValueHash.STATE && (
                        <StateSelect
                            classNameRoot={css.state}
                            name={`${name}_-_${questionIDValueHash.STATE.questionId.id}`}
                            id={questionConfig.STATE.id}
                            defaultValue={answerHash.STATE}
                            label={
                                <Label
                                    i18nRequiredVisualLabel={
                                        isOneApp ? '*' : ' '
                                    }
                                >
                                    {t(questionConfig.STATE.label)}
                                </Label>
                            }
                            placeholder={t(questionConfig.STATE.placeholder)}
                            autoComplete={questionConfig.STATE.autocomplete}
                            error={
                                <FormElementError>
                                    {t(questionConfig.STATE.error)}
                                </FormElementError>
                            }
                            onChange={evt => {
                                handleOnChange('STATE', evt?.value);
                            }}
                            required={questionIDValueHash.STATE.required}
                            includeMilitary={includeMilitary}
                            includeUSTerritories={includeUSTerritories}
                        />
                    )}
                    {questionIDValueHash.ZIP && (
                        <ZipInput
                            classNameRoot={css.zip}
                            id={questionConfig.ZIP.id}
                            name={`${name}_-_${questionIDValueHash.ZIP.questionId.id}`}
                            defaultValue={answerHash.ZIP}
                            autoComplete={questionConfig.ZIP.autocomplete}
                            helpNode=""
                            label={
                                <Label
                                    i18nRequiredVisualLabel={
                                        isOneApp ? '*' : ' '
                                    }
                                >
                                    {t(questionConfig.ZIP.label1)}
                                </Label>
                            }
                            error={
                                <FormElementError>
                                    {t(questionConfig.ZIP.error)}
                                </FormElementError>
                            }
                            customValidationError={customValidationError}
                            onChange={evt => {
                                handleOnChange('ZIP', evt?.target?.value);
                            }}
                            required={questionIDValueHash.ZIP.required}
                            usePlus4={false}
                        />
                    )}
                </div>
                {isOneApp && (
                    <div className={css.buttonContainer}>
                        <Button
                            className={css.cancelButton}
                            onClick={onCancelClicked}
                            disabled={disableCancelButton}
                        >
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .shippingCardCancelButton
                            )}
                        </Button>
                        <Button
                            onClick={onSaveClicked}
                            size="small"
                            variant="primary"
                        >
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .shippingCardSaveButton
                            )}
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

Address.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    questionValues: PropTypes.object,
    includeMilitary: PropTypes.bool,
    includeUSTerritories: PropTypes.bool,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {hash} address - A hash with the question.
     */
    onChange: PropTypes.func,
    context: PropTypes.object.isRequired,
    isAddressFromEditable: PropTypes.bool,
    setIsAddressFromEditable: PropTypes.func,
    formRef: PropTypes.object,
};

export default Address;
