import {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import messageConfig from 'constants/messages';
import {
    getIsRedirectFromTeams,
    redirectToMemberPortal,
    redirectToTeams,
} from 'utilities/utils';
import {clearMessage} from 'store/message/message-actions';
import {mixpanelButtonClickedEvent} from 'utilities/mixpanel-utils';
import css from './modalStyle.scss';

const AccountAlreadyExists = ({id, isOneApp}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const dispatch = useDispatch();
    const redirectFromTeams = getIsRedirectFromTeams();
    const messageValue = messageConfig[id] || {};
    const {
        title,
        oneAppTitle,
        content,
        oneAppContent,
        teamsContent,
        primaryButtonText,
        returnToTeamsText,
        cancelText,
    } = messageValue;

    const onClearMessage = useCallback(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const onCancelClick = evt => {
        mixpanelButtonClickedEvent('EMAIL', '', t(cancelText), 'CTA');
        // Todo: close animation has some issue here
        onClearMessage();
    };

    return (
        <div className={css.modalContainer}>
            <h1>{isOneApp ? t(oneAppTitle) : t(title)}</h1>
            <div className={css.contentContainer}>
                <p>
                    {isOneApp
                        ? t(oneAppContent)
                        : redirectFromTeams
                        ? t(teamsContent)
                        : t(content)}
                </p>
            </div>
            <div>
                {!isOneApp && (
                    <Button
                        className={css.modalButton}
                        variant="primary"
                        onClick={
                            redirectFromTeams
                                ? redirectToTeams
                                : redirectToMemberPortal
                        }
                    >
                        {redirectFromTeams
                            ? t(returnToTeamsText)
                            : t(primaryButtonText)}
                    </Button>
                )}
                <Button
                    className={css.modalButtonRight}
                    onClick={onCancelClick}
                >
                    {t(cancelText)}
                </Button>
            </div>
        </div>
    );
};

AccountAlreadyExists.propTypes = {
    id: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool.isRequired,
};

export default AccountAlreadyExists;
