import EnvironmentUtils from '@livongo/utils/environment';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import useMessage from 'hook/useMessage';
import {
    redirectToDownloadApps,
    redirectToMemberPortalOrOneAppHomePage,
} from 'utilities/utils';
import {getShortBrandName} from 'routes/medOpt/MedOptsUtils';
import css from './GetTheApp.scss';

const GetTheApp = ({contentId, isOneApp}) => {
    const {t} = useTranslation('content');
    const CLOUDINARY_IMAGE_ID = 'registration/common/Icons/check_mark_wvnese';
    const {showMobileAppSmsMessage} = useMessage();
    const contentTextParam = {shortBrandName: getShortBrandName(isOneApp)};

    const redirectOrShowMobileSmsFlyout = () => {
        const isMobileDevice =
            EnvironmentUtils.isMobileDevice() || EnvironmentUtils.isTablet();

        if (isMobileDevice || isOneApp) {
            redirectToDownloadApps(isOneApp);
        } else {
            showMobileAppSmsMessage(contentId);
        }
    };

    return (
        <div className={css.root}>
            <div className={css.emoji}>
                <Image
                    classNameImage={css.imageContainer}
                    cloudinaryImageId={CLOUDINARY_IMAGE_ID}
                    width={109}
                    height={109}
                />
            </div>
            <h1 className={css.h1}>
                {t('GET_THE_APP.title', contentTextParam)}
            </h1>
            <p>{t('GET_THE_APP.paragraph')}</p>
            <div className={css.actions}>
                <Button
                    className={css.secondaryAction}
                    variant="primary"
                    onClick={redirectOrShowMobileSmsFlyout}
                >
                    {t('GET_THE_APP.btnText')}
                </Button>
                <Button
                    className={css.secondaryAction}
                    variant="secondary"
                    onClick={() =>
                        redirectToMemberPortalOrOneAppHomePage(isOneApp)
                    }
                >
                    {t('GET_THE_APP.btnText2')}
                </Button>
            </div>
        </div>
    );
};

GetTheApp.propTypes = {
    contentId: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool,
};

export default GetTheApp;
