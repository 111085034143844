import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import useMessage from 'hook/useMessage';
import {getVWOVariant} from 'utilities/utils';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import css from './CongratsOnBehaviorHealth.scss';

const CongratsOnBehaviorHealth = ({contentId, isProgramPending, isOneApp}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('content');
    const vwoExperiments = getVWOVariant() ?? {};
    const isProgramSelection =
        vwoExperiments.experimentName === 'PROGRAM-SELECTION';

    const {showProgramEligibility} = useMessage();

    const BH_BANNER_CLOUDINARY_IMAGE_ID =
        'registration/components/questions/program/pulse/Program_BH_bhjijc';

    let title;
    let subTitle;

    if (isProgramSelection) {
        title = `${contentId}.programSelectionTitle`;
        subTitle = `${contentId}.programSelectionSubTitle`;
    } else if (isOneApp) {
        title = `${contentId}.oneAppTitle`;
        subTitle = `${contentId}.oneAppSubTitle`;
    } else {
        title = `${contentId}.title`;
        subTitle = `${contentId}.subTitle`;
    }

    useEffect(() => {
        let obj = isOneApp
            ? {backBtn: true, nextBtn: false}
            : {backBtn: false, nextBtn: false};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={css.root}>
            <h1 className="heading-md">{t(title)}</h1>
            <Image
                classNameImage={css.imageContainer}
                cloudinaryImageId={BH_BANNER_CLOUDINARY_IMAGE_ID}
                alt=""
                width={1082}
                height={609}
            />
            {isProgramPending && (
                <div className={css.pendingSuffix}>
                    {t('questions:Common.pending')}
                </div>
            )}
            <h2 className="heading-sm">{t(subTitle)}</h2>
            <p>
                {isOneApp
                    ? t(`${contentId}.oneappParagraph`)
                    : t(`${contentId}.paragraph`)}
            </p>
            {isProgramSelection && (
                <Button
                    className={css.btnAdj}
                    onClick={() => {
                        showProgramEligibility();
                    }}
                >
                    {t(`${contentId}.learnMoreButtonText`)}
                </Button>
            )}
        </div>
    );
};

CongratsOnBehaviorHealth.propTypes = {
    contentId: PropTypes.string.isRequired,
    isProgramPending: PropTypes.bool.isRequired,
    isOneApp: PropTypes.bool,
};

export default CongratsOnBehaviorHealth;
