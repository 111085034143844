import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import PropTypes from 'prop-types';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import SelectionCard from '@teladoc/pulse/ui/SelectionCard';
import {questionConfig} from 'constants/questions';
import {renderLabel} from 'components/question/questions-utils';
import css from './RadioCard.scss';

const RadioCard = ({
    id,
    name,
    required,
    customValidationError,
    hasNodeTitle,
    items,
    errorMessage,
    defaultValue,
}) => {
    const {t} = useTranslation('questions');
    const {
        itemListContents,
        ariaLabel,
        labelForWithNodeTitle,
        label: qLabel,
    } = questionConfig[id] || {};
    const renderQuestionLabel = hasNodeTitle ? labelForWithNodeTitle : qLabel;
    const renderClassForQuestionLabel = hasNodeTitle
        ? css.questionLabelForWithNodeTitle
        : '';

    const [key, setKey] = useState(new Date().getTime());
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    const handleOnChange = evt => {
        const currentSelectedValue = evt.target.value;

        setKey(new Date().getTime());
        setSelectedValue(currentSelectedValue);
    };

    return (
        <RadioGroup
            id={id}
            key={key}
            name={name}
            {...renderLabel(
                t(renderQuestionLabel),
                t(ariaLabel),
                renderClassForQuestionLabel
            )}
            error={<FormElementError>{errorMessage}</FormElementError>}
            required={required}
            onChange={evt => {
                handleOnChange(evt);
            }}
            customValidationError={customValidationError}
        >
            {items.map(({value, label}) => {
                return (
                    <SelectionCard
                        key={value}
                        id={`${id}_item_${value}`}
                        value={value}
                        title={<h2 className={css.title}>{label}</h2>}
                        content={
                            <ul className={css.ulContent}>
                                {Object.keys(itemListContents[value]).map(
                                    (content, index) => (
                                        <li key={index}>
                                            {t(
                                                itemListContents[value][content]
                                            )}
                                        </li>
                                    )
                                )}
                            </ul>
                        }
                        checked={value === selectedValue}
                    />
                );
            })}
        </RadioGroup>
    );
};

RadioCard.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
    customValidationError: PropTypes.element,
    hasNodeTitle: PropTypes.bool,
    items: PropTypes.array.isRequired,
    errorMessage: PropTypes.string.isRequired,
};

export default RadioCard;
