import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Config from 'config';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import ContentGeneric from './ContentGeneric';

const {memberSupportFormattedPhoneNumber} = Config;

const GenericError = ({contentId}) => {
    const dispatch = useDispatch();

    const genericErrorDetails = {
        title: {key: 'GenericError.title'},
        paragraph: {
            key: 'GenericError.paragraph',
            interpolation: {memberSupportFormattedPhoneNumber},
        },
    };

    /* NavigationButton must be disabled in this component */
    useEffect(() => {
        let obj = {backBtn: true, nextBtn: true};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, []);

    return (
        <ContentGeneric
            infoDetails={genericErrorDetails}
            contentId={contentId}
        />
    );
};

GenericError.propTypes = {
    contentId: PropTypes.string.isRequired,
};

export default GenericError;
