import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Config from 'config';
import messageConfig from 'constants/messages';
import {getUserUUID, getCallBackUrl, cleanSessionInfo} from 'utilities/utils';
import css from './modalStyle.scss';

const {
    memberSupportPhoneNumber,
    memberSupportFormattedPhoneNumber,
    oneAppMemberSupportPhoneNumber,
} = Config;

const MemberSupportNumber = ({id, isOneApp}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const messageValue = messageConfig[id] || {};

    const {title, content, oneAppTitle, oneAppContent, oneAppButtonLabel} =
        messageValue;

    const redirectToOneApp = () => {
        const redirectUrl = getCallBackUrl();

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    return (
        <div className={css.modalContainer}>
            {(title || oneAppTitle) && (
                <h1>{t(isOneApp ? oneAppTitle : title)}</h1>
            )}
            {!isOneApp ? (
                <div className={css.contentContainer}>
                    {content && <p>{t(content)}</p>}
                </div>
            ) : (
                <div className={css.contentContainer}>
                    {oneAppContent && (
                        <Trans
                            i18nKey={`messages:${oneAppContent}`}
                            components={[
                                // eslint-disable-next-line
                                <a
                                    href={`tel:${oneAppMemberSupportPhoneNumber}`}
                                />,
                            ]}
                        />
                    )}
                </div>
            )}
            {!isOneApp && (
                <div>
                    <div className={css.memberSupportText}>
                        {t('common.memberSupport')}
                    </div>
                    <a
                        className={css.memberSupportNumber}
                        href={`tel:${memberSupportPhoneNumber}`}
                    >
                        {memberSupportFormattedPhoneNumber}
                    </a>
                </div>
            )}
            <div>
                <div className={css.referenceText}>
                    {t('common.referenceNumber')}
                    <p>{getUserUUID()}</p>
                </div>
            </div>
            {oneAppButtonLabel && isOneApp && (
                <Button
                    className={css.modalGoToOneAppButton}
                    variant="primary"
                    onClick={redirectToOneApp}
                >
                    {t(oneAppButtonLabel)}
                </Button>
            )}
        </div>
    );
};

MemberSupportNumber.propTypes = {
    id: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool.isRequired,
};

export default MemberSupportNumber;
