import {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import Arg from '@livongo/arg';
import Config from 'config';
import {cleanSessionInfo, getRestApiAccessToken} from 'utilities/utils';

const {registerStartUrl} = Config;

const BhSSO = () => {
    const apiAccessToken = getRestApiAccessToken();

    useEffect(() => {
        if (apiAccessToken) {
            const arg = Arg.all();

            if (!arg.n) {
                cleanSessionInfo(true);
            }
            window.location.href = `${process.env.BH3_LOGIN_URL}?cmmRedirect=true`;
        }
    }, [apiAccessToken]);

    // Todo: May need to revisit here to see whether we should display error msg or restart
    return !apiAccessToken && <Redirect to={registerStartUrl} />;
};

export default BhSSO;
