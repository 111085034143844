import {useEffect} from 'react';
import useTransLoader from 'i18n/use-trans-loader';
import {getApplication} from 'utilities/utils';
import {MED_OPT} from 'constants/app';

/*
 * this hook is to translate the browser tab name based on route name
 * the i18b key set as documentTitle and stored in App space
 */

export default function useTitle({path, isOneApp}) {
    const {t} = useTransLoader('app');
    const isMedOpt = getApplication() === MED_OPT;
    const pathName = path ? path : '/registration';
    const brandName = isOneApp ? 'Teladoc Health' : 'Livongo Health';

    useEffect(() => {
        if (isOneApp && !isMedOpt) {
            document.title = 'Teladoc Health - CCM Registration';
        } else {
            document.title = `${brandName}  - ${t(
                `documentTitle.${pathName.substring(1)}`
            )}`;
        }

        if (isOneApp) {
            document.querySelector("link[rel*='icon']").href =
                'https://assets.livongo.com/image/upload/v1665515366/registration/common/favicon/TeledocFavIcon.png';
        }
    }, [pathName, t, isOneApp, brandName, isMedOpt]);
}
