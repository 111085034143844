export const BUILD_KEY = process.env.BUILD_KEY;
export const BUILD_ID = process.env.BUILD_ID;

export default function init({lang}) {
    const appNamespace = 'app';
    const commonNamespace = 'common';
    const contentNamespace = 'content';
    const messagesNamespace = 'messages';
    const questionsNamespace = 'questions';
    const notificationNamespace = 'notification';

    /* See default config from @livongo/fe-ui in the link below
     * https://github.com/livongo/fe-ui/blob/master/src/Context/i18n/init.js#L53
     */
    return {
        /*
         * Load messages ahead of time since they used in the flyout
         *  and, flyout has to deal with time delay.
         */
        ns: [
            appNamespace,
            commonNamespace,
            contentNamespace,
            messagesNamespace,
            questionsNamespace,
            notificationNamespace,
        ],
        defaultNS: appNamespace,
        fallbackNS: commonNamespace,
        lng: lang,
    };
}

export const languageCodeForSubmit = {
    'en-US': 'en',
    'es-US': 'es',
};

const languageCodes = {
    default: 'en-US',
    'en-us': 'en-US',
    'es-us': 'es-US',
};

export const getLanguageCode = lang => {
    let languageCode;

    if (lang) {
        languageCode = languageCodes[lang.toLowerCase()];
    }

    if (!languageCode) {
        languageCode = languageCodes.default;
    }

    return languageCode;
};

export const allLocalesAndLanguages = {
    'en-US': {
        currentLang: 'English',
        'en-US': 'English (United States)',
        'es-US': 'Español (United States)',
        'en-ES': 'English',
        'es-ES': 'Spanish',
        'ca-ES': 'Catalan',
    },
    'es-US': {
        currentLang: 'Español',
        'en-US': 'English (United States)',
        'es-US': 'Español (United States)',
        'en-ES': 'Inglés',
        'es-ES': 'Español',
        'ca-ES': 'Catalán',
    },
    'en-ES': {
        currentLang: 'English',
        'en-US': 'English',
        'es-US': 'Spanish',
        'en-ES': 'English',
        'es-ES': 'Spanish',
        'ca-ES': 'Catalan',
    },
    'es-ES': {
        currentLang: 'Español',
        'en-US': 'Inglés',
        'es-US': 'Español',
        'en-ES': 'Inglés',
        'es-ES': 'Español',
        'ca-ES': 'Catalán',
    },
    'ca-ES': {
        currentLang: 'Català',
        'en-US': 'Anglès',
        'es-US': 'Espanyol',
        'en-ES': 'Anglès',
        'es-ES': 'Espanyol',
        'ca-ES': 'Català',
    },
    'en-CA': {
        currentLang: 'English',
    },
    'fr-CA': {
        currentLang: 'Français',
    },
};
