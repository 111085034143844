import {useEffect} from 'react';

/**
 * Hook to set focus on the first focusable element of a form.
 * @param {string} formId
 * @param {object} dependence
 */
const useFocus = (formId, dependence = null) => {
    useEffect(() => {
        const form = document.querySelector(`#${formId}`);
        let firstFocusableElement;

        for (let i = 0; i < form.length; i++) {
            // Skip if hidden or button or fieldset.
            if (
                form[i].tagName !== 'FIELDSET' &&
                form[i].type !== 'hidden' &&
                form[i].innerHTML !== 'Back'
            ) {
                firstFocusableElement = form[i];

                /*
                 * For radio or checkbox group, look for the first checked one.
                 * If no one checked, default to the first one.
                 */
                if (
                    (firstFocusableElement.type === 'radio' ||
                        firstFocusableElement.type === 'checkbox') &&
                    !firstFocusableElement.checked
                ) {
                    const checkedElement = document.querySelector(
                        `input[name=${firstFocusableElement.name}][checked]`
                    );

                    if (checkedElement) {
                        firstFocusableElement = checkedElement;
                    }
                }
                break;
            }
        }

        if (firstFocusableElement) {
            firstFocusableElement.focus();
        }
    }, [formId, dependence]);
};

export default useFocus;
