import Label from '@teladoc/pulse/ui/Label';
import {
    ValidationError,
    PasswordRule,
} from 'api/protobuf/enrollment/protoTypes';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
import {errorTypes} from 'constants/type';
import {questionConfig, questionFieldTypes} from 'constants/questions';
import {mixpanelAnswerError} from 'utilities/mixpanel-utils';
import {getFirstNonEmptyKey, getObjectKeyByValue} from 'utilities/utils';
import css from './Question.scss';

const {
    // TEXT_FIELD,
    // SINGLE_SELECT_ENUM,
    // MULTI_SELECT_ENUM,
    // BOOLEAN_FIELD,
    // INTEGER_FIELD,
    FLOAT_FIELD,
    // DATE_FIELD,
    // COMPOUND_FIELD,
} = questionFieldTypes;

const {
    WRONG_TYPE,
    MISSING,
    UNDERFLOW,
    OVERFLOW,
    ILLEGAL_ENUM_VALUE,
    ILLEGAL_SCALAR_VALUE,
    INVALID_ZIP_CODE,
    INVALID_REG_CODE,
    INVALID_PASSWORD,
    INVALID_PHONE_NUMBER,
    PASSWORD_MISSING_CHAR_SETS,
    DATE_DOES_NOT_EXIST,
    INVALID_PROGRAM_SET,
    EMAIL_IN_USE,
    ANSWERED_TWICE,
    FLOW_ERROR,
    DUPLICATES,
    NONE_OPTION_ONLY,
    UNDEFINED,
    UNKNOWN_QUESTION_ID,
    UNKNOWN_ENUM_VALUE,
    UNKNOWN_NODE,
    UNKNOWN_BRANCH_POINT,
    UNKNOWN_REFERENCE,
} = errorTypes;

/** in our questionConfig, only one type of label will have value.
 * this renderLabel function is for render either Label or ariaLabel
 * return an empty label if both label and ariaLabel are null
 * @param {String | null } label - label.
 * @param {String | null} ariaLabel - ariaLabel.
 * @param {String | null} className - className.
 * @returns {Object} - Object with either a label or ariaLabel field
 */
export const renderLabel = (label, ariaLabel, className) => {
    const renderClassName = className || css.questionLabel;

    if (label) {
        return {
            label: (
                <Label className={renderClassName} i18nRequiredVisualLabel=" ">
                    {label}
                </Label>
            ),
        };
    } else if (ariaLabel) {
        return {i18nItemLabel: ariaLabel};
    }

    return {label: <Label i18nRequiredVisualLabel=" " />};
};

/**
 * Converts BE errorTypes responses to client friendly error messages.
 * It will send MixPanel error messages for errors that we should keep track of, especially ones that shouldn't happen.
 * The errorTypesList can either come from gRPC submitAnswers {submitAnswersReponse.invalidAnswers.validationResultsList[].errorTypesList}
 * or gRPC navigateHere, navigateBack, navigateNext (navigateResponse.questionsToAsk.questionsList[].validationResultsList[].errorTypesList)
 * @param {validationError[]} errorTypesList - Array of error types from gRPC ValidationResult.errorTypes type.
 * @param {Question} question - Question object that the errorTypesList belongs to.
 * @param {translation} t - Translation object.
 * @returns {String[]} - Client friendly error strings
 */
export const handleErrorMessages = (errorTypesList, question, t) => {
    const dataType = getFirstNonEmptyKey(question.dataType);

    const errorTypeKeys = errorTypesList.map(errorTypeObj =>
        getFirstNonEmptyKey(errorTypeObj)
    );
    const qId = question?.questionId?.id;
    const questionName = getQuestionNameById(qId);
    // Get general error for specific question
    const {error} = questionConfig[questionName] || {};
    const generalErrorMessage = error ? t(error) : t('error.generalProblem');

    // handle underflow and overflow error combo
    if (errorTypeKeys.includes(UNDERFLOW) && errorTypeKeys.includes(OVERFLOW)) {
        const attributeType =
            dataType === FLOAT_FIELD ? 'floatValue' : 'intValue';
        const overflowValue =
            errorTypesList[errorTypeKeys.indexOf(OVERFLOW)].overflow[
                attributeType
            ];
        const underflowValue =
            errorTypesList[errorTypeKeys.indexOf(UNDERFLOW)].underflow[
                attributeType
            ];

        return [
            t('error.overflowAndUnderflow', {
                overflow: overflowValue,
                underflow: underflowValue,
            }),
        ];
    }

    const errorMessages = errorTypesList.map(errorTypeObj => {
        const errorType = getFirstNonEmptyKey(errorTypeObj);

        switch (errorType) {
            case WRONG_TYPE: {
                const {received, expected} = errorTypeObj[errorType];

                return t('error.wrongType', {received, expected});
            }
            case MISSING:
                return t('error.missing');
            case UNDERFLOW: {
                if (questionName === 'PASSWORD') {
                    return t('PASSWORD.error');
                }
                const value =
                    dataType === FLOAT_FIELD
                        ? errorTypeObj[errorType].floatValue
                        : errorTypeObj[errorType].intValue;

                mixpanelAnswerError(qId, questionName, errorType);

                return t('error.underflow', {value});
            }
            case OVERFLOW: {
                const value =
                    dataType === FLOAT_FIELD
                        ? errorTypeObj[errorType].floatValue
                        : errorTypeObj[errorType].intValue;

                mixpanelAnswerError(qId, questionName, errorType);

                return t('error.overflow', {value});
            }
            case ILLEGAL_ENUM_VALUE: {
                const {rejectedValuesList} = errorTypeObj[errorType];

                if (
                    questionName === 'RACE' &&
                    rejectedValuesList[0] ===
                        'RACE_OTHER cannot be unanswered for RACE_SELECTION value O'
                ) {
                    return t('RACE_OTHER.error-empty');
                } else if (
                    questionName === 'GENDER_IDENTITY' &&
                    rejectedValuesList[0] ===
                        'GENDER_IDENTITY_OTHER cannot be unanswered for GENDER_IDENTITY_SELECTION value PREFER_SELF_IDENTIFY'
                ) {
                    return t('GENDER_IDENTITY_OTHER.error-empty');
                }

                mixpanelAnswerError(qId, questionName, errorType, {
                    rejectedValuesList,
                });

                return t('error.illegalEnumValue', {
                    values: rejectedValuesList,
                });
            }
            case ILLEGAL_SCALAR_VALUE:
            case INVALID_ZIP_CODE: {
                const rejectedValue = question?.dataType[dataType].userInput;

                if (questionName === 'ZIP') {
                    return t('ZIP.error-zip-invalid');
                }
                mixpanelAnswerError(qId, questionName, errorType, {
                    rejectedValue,
                });

                return generalErrorMessage;
            }
            // this error type needs to remain as it is since there are some use case that will still use it
            case PASSWORD_MISSING_CHAR_SETS: {
                const {missingCharsOfTheseTypesList} = errorTypeObj[errorType];
                const charTypes = missingCharsOfTheseTypesList.map(type =>
                    getObjectKeyByValue(
                        ValidationError.PasswordMissingCharSets.CharSet,
                        type
                    )
                );

                return (
                    t('error.passwordMissingCharSets') +
                    charTypes
                        .map(charType => {
                            return t(`error.passwordErrorMapping.${charType}`, {
                                characterType: charType,
                            });
                        })
                        .join(', ')
                );
            }
            // this is new error type requested in regcon-135
            case INVALID_PASSWORD: {
                const {violatedRulesList} = errorTypeObj[errorType];

                if (violatedRulesList.length > 1) {
                    return t('error.invalidPassword');
                } else {
                    const singleErrorName = getFirstNonEmptyKey(
                        violatedRulesList[0]
                    );

                    if (singleErrorName === 'requiredCharFromGroups') {
                        const charTypes =
                            violatedRulesList[0]?.requiredCharFromGroups?.requiredGroupsList.map(
                                type =>
                                    getObjectKeyByValue(
                                        PasswordRule.RequiredCharFromGroups
                                            .CharGroup,
                                        type
                                    )
                            );

                        return (
                            t('error.passwordMissingCharSets') +
                            charTypes
                                .map(charType => {
                                    return t(
                                        `error.passwordErrorMapping.${charType}`,
                                        {
                                            characterType: charType,
                                        }
                                    );
                                })
                                .join(', ')
                        );
                    } else {
                        return t(
                            `error.passwordErrorMapping.${singleErrorName}`
                        );
                    }
                }
            }

            case INVALID_PHONE_NUMBER: {
                const rejectDate = question?.dataType[dataType]?.userInput;

                mixpanelAnswerError(qId, questionName, errorType, {
                    rejectDate,
                });

                return t('error.invalidPhoneNumber');
            }

            case DATE_DOES_NOT_EXIST: {
                const rejectDate = question?.dataType[dataType]?.userInput;

                mixpanelAnswerError(qId, questionName, errorType, {
                    rejectDate,
                });

                return t('error.dateDoesNotExist');
            }

            case INVALID_PROGRAM_SET: {
                const {conflictingProgramsList, possibleAnchorProgramsList} =
                    errorTypeObj[errorType];
                let programMessage = '';

                // TODO: handle translation of individual programs
                conflictingProgramsList.forEach(
                    ({selectedProgram, incompatibleProgramsList}) => {
                        programMessage += t('error.invalidProgramSet', {
                            selectedProgram,
                            incompatiblePrograms:
                                incompatibleProgramsList.join(','),
                        });
                    }
                );

                if (
                    possibleAnchorProgramsList &&
                    possibleAnchorProgramsList.length
                ) {
                    programMessage += t(
                        'error.invalidProgramSetPossibleAnchorPrograms',
                        {anchorPrograms: possibleAnchorProgramsList.join(',')}
                    );
                }

                return programMessage;
            }
            case EMAIL_IN_USE: {
                mixpanelAnswerError(qId, questionName, errorType);

                return t('error.emailInUse');
            }
            case ANSWERED_TWICE:
                // Answers can generally be answered multiple times, but not within
                // the same request. This is most likely an internal FE logic error
                mixpanelAnswerError(qId, questionName, errorType);

                return t('error.generalProblem');
            case FLOW_ERROR:
                mixpanelAnswerError(qId, questionName, errorType);

                return t('error.generalProblem');
            case DUPLICATES: {
                const {locationOfOriginalList, locationOfDuplicateList} =
                    errorTypeObj[errorType];

                mixpanelAnswerError(qId, questionName, errorType, {
                    original: JSON.stringify(locationOfOriginalList),
                    duplicate: JSON.stringify(locationOfDuplicateList),
                });

                return t('error.generalProblem');
            }
            case NONE_OPTION_ONLY: {
                return t('error.noneOptionOnly');
            }
            case UNDEFINED:
                // If something passed in is undefined, empty (but not 'missing')
                // combined with form validation, this should not happen and is not a user error
                mixpanelAnswerError(qId, questionName, errorType);

                return t('error.generalProblem');
            case UNKNOWN_QUESTION_ID: {
                const {questionIdValue} = errorTypeObj[errorType];

                mixpanelAnswerError(qId, questionName, errorType, {
                    questionIdValue,
                });

                return t('error.generalProblem');
            }
            case UNKNOWN_ENUM_VALUE: {
                const {enumName, value} = errorTypeObj[errorType];

                mixpanelAnswerError(qId, questionName, errorType, {
                    enumName,
                    value,
                });

                return t('error.generalProblem');
            }
            case UNKNOWN_NODE: {
                const {nodeName} = errorTypeObj[errorType];

                mixpanelAnswerError(qId, questionName, errorType, {
                    nodeName,
                });

                return t('error.generalProblem');
            }
            case UNKNOWN_BRANCH_POINT: {
                const {branchPointName} = errorTypeObj[errorType];

                mixpanelAnswerError(qId, questionName, errorType, {
                    branchPointName,
                });

                return t('error.generalProblem');
            }
            case UNKNOWN_REFERENCE:
                // code block
                return t('error.generalProblem');
            case INVALID_REG_CODE:
                mixpanelAnswerError(qId, questionName, errorType, {
                    message: 'Invalid regcode error',
                });

                return t('error.invalidRegCode');
            default: {
                mixpanelAnswerError(qId, questionName, errorType, {
                    message: 'Unknown error',
                });

                return `Unhandled Error: ${errorType}`;
            }
        }
    });

    return errorMessages;
};

/**
 * Returns the correct matching error code translation key matching the question name and userInput
 * This is for client side form validation error state, not the error states from the BE
 * Translation keys should match the ones in questions.json
 * @param {string} questionName - String name of the question to match to questionConfig.
 * @param {string|null} userInput - Question object that the errorTypesList belongs to.
 * @returns {string} - translation key matching to question name and userInput
 */
export const getValidationErrorMessage = (questionName, userInput) => {
    let errorMessage = 'common:error.required';

    if (questionConfig[questionName] && questionConfig[questionName].error) {
        errorMessage = questionConfig[questionName].error;
    }

    const isNullorEmpty = input => {
        if (!input || (typeof input === 'string' && input.length === 0)) {
            return true;
        }

        return false;
    };

    switch (questionName) {
        case 'FIRST_NAME': {
            if (isNullorEmpty(userInput)) {
                errorMessage = 'FIRST_NAME.error-firstname-empty';
            }
            break;
        }
        case 'LAST_NAME': {
            if (isNullorEmpty(userInput)) {
                errorMessage = 'LAST_NAME.error-lastname-empty';
            }
            break;
        }
        case 'ZIP': {
            if (isNullorEmpty(userInput)) {
                errorMessage = 'ZIP.error-zip-empty';
            } else if (userInput.toString().length !== 5) {
                errorMessage = 'ZIP.error-zip-length';
            }
            break;
        }
        default:
            break;
    }

    return errorMessage;
};

export const checkIsGivenQuestion = (sectionInfo, questionName) => {
    if (sectionInfo?.questionsToAsk?.questionsList) {
        return Boolean(
            sectionInfo.questionsToAsk.questionsList.find(
                section =>
                    getQuestionNameById(section.questionId.id) === questionName
            )
        );
    }

    return false;
};
