import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Accordion from '@teladoc/pulse/ui/Accordion';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import {questionConfig} from 'constants/questions';
import css from './ClientConsent.scss';

const MountSinaiConsent = ({id, name, defaultValue}) => {
    const [userInput, setUserInput] = useState(defaultValue ?? 'NO');
    const {helperText, label, content, trigger, error} =
        questionConfig[id] || {};
    const {t} = useTranslation('questions');
    const handleChange = event => {
        if (event.target.checked) {
            setUserInput('YES');
        } else {
            setUserInput('NO');
        }
    };

    return (
        <>
            <div className={css.helperTextContainer}>{t(helperText)}</div>
            <Accordion
                className={css.root}
                key={id}
                items={[
                    {
                        isOpen: false,
                        trigger: <div>{t(trigger)}</div>,
                        content: (
                            <div className={css.accordionContent}>
                                {t(content)}
                            </div>
                        ),
                    },
                ]}
            />
            <Checkbox
                defaultChecked={defaultValue === 'YES'}
                id={id}
                name={name}
                value={userInput}
                label={<Label>{t(label)}</Label>}
                onChange={handleChange}
                i18nItemLabel={t(label)}
                error={<FormElementError>{t(error)}</FormElementError>}
            />
        </>
    );
};

MountSinaiConsent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
};

export default MountSinaiConsent;
