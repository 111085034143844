import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Config from 'config';
import messageConfig from 'constants/messages';
import {getUserUUID} from 'utilities/utils';
import css from './modalStyle.scss';

const {
    memberSupportPhoneNumber,
    memberSupportFormattedPhoneNumber,
    oneAppMemberSupportPhoneNumber,
    oneAppMemberSupportFormattedPhoneNumber,
} = Config;

const InternationalNumberNotAllowed = ({id, text, isOneApp}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const messageValue = messageConfig[id] || {};
    const {title, oneAppTitle} = messageValue;

    return (
        <div className={css.modalContainer}>
            {(title || oneAppTitle) && (
                <h1>{t(isOneApp ? oneAppTitle : title)}</h1>
            )}
            <div className={css.contentContainer}>
                <Trans
                    i18nKey={
                        text
                            ? 'messages:MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED.content'
                            : 'messages:MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED.contentWithoutClientName'
                    }
                    values={{clientName: text}}
                />
            </div>
            <div>
                <div className={css.memberSupportText}>
                    {t('common.memberSupport')}
                </div>
                {isOneApp ? (
                    <a
                        className={css.memberSupportNumber}
                        href={`tel:${oneAppMemberSupportPhoneNumber}`}
                    >
                        {oneAppMemberSupportFormattedPhoneNumber}
                    </a>
                ) : (
                    <a
                        className={css.memberSupportNumber}
                        href={`tel:${memberSupportPhoneNumber}`}
                    >
                        {memberSupportFormattedPhoneNumber}
                    </a>
                )}
            </div>
            <div>
                <div className={css.referenceText}>
                    {t('common.referenceNumber')}
                    <p>{getUserUUID()}</p>
                </div>
            </div>
        </div>
    );
};

InternationalNumberNotAllowed.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    isOneApp: PropTypes.bool.isRequired,
};

export default InternationalNumberNotAllowed;
