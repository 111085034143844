import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Accordion from '@teladoc/pulse/ui/Accordion';
import Button from '@teladoc/pulse/ui/Button';
import messageConfig from 'constants/messages';
import {clearMessage} from 'store/message/message-actions';
import {getAllOfferedPrograms} from 'utilities/utils';
import css from './modalStyle.scss';

const ProgramEligibility = ({id}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const dispatch = useDispatch();
    const allOfferedPrograms = getAllOfferedPrograms();
    const messageValue = messageConfig[id] || {};
    const {title, content, buttonCancel, buttonOK} = messageValue;
    const onClearMessage = useCallback(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const programList = allOfferedPrograms || [
        'DIABETES',
        'PREDIABETES',
        'WEIGHT_MANAGEMENT',
        'HYPERTENSION',
        'BEHAVIORAL_HEALTH',
        'CHRONIC_KIDNEY_DISEASE',
        'HEART_FAILURE',
        'AV_WEIGHT_MANAGEMENT',
        'ADV_PREDIABETES',
        'COMPR_WEIGHT_CARE',
        'COMPR_PREDIABETES_CARE',
    ];

    const itemList = [];

    programList.forEach(program => {
        itemList.push({
            isOpen: false,
            trigger: (
                <div className={css.triggerContainer}>
                    {t(messageConfig[id][program].subTitle)}
                </div>
            ),
            content: (
                <div className={css.triggerContainer}>
                    {t(messageConfig[id][program].content)}
                </div>
            ),
        });
    });

    return (
        <div className={css.modalContainer}>
            {title && <h1>{t(title)}</h1>}
            {content && <p>{t(content)}</p>}
            <Accordion
                className={css.accordionRoot}
                key={id}
                items={itemList}
            />
            <Button onClick={onClearMessage}>{t(buttonCancel)}</Button>
            <Button
                className={css.modalButtonRight}
                variant="primary"
                onClick={onClearMessage}
            >
                {t(buttonOK)}
            </Button>
        </div>
    );
};

ProgramEligibility.propTypes = {
    id: PropTypes.string.isRequired,
};

export default ProgramEligibility;
