import {NAVIGATION_BTN_SET} from './navigationBtnDisable-actions';

export default function navigationBtnDisableReducer(
    state = {backBtn: false, nextBtn: false},
    {type, payload}
) {
    switch (type) {
        case NAVIGATION_BTN_SET: {
            return payload;
        }

        default:
            return state;
    }
}
