import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import DOBInput from '@teladoc/pulse/ui/DOBInput';
import Label from '@teladoc/pulse/ui/Label';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import FormGroupError from '@teladoc/pulse/ui/FormGroupError';
import ScreenReader from 'common/screen-reader/ScreenReader';
import {errorTypes} from 'constants/type';
import {questionConfig} from 'constants/questions';
import {redirectToMemberPortal, cleanSessionInfo} from 'utilities/utils';
import css from './BirthDate.scss';

const {
    INCORRECT_BIRTH_DATE,
    ALREADY_ENROLLED,
    EMAIL_IN_USE,
    CONFIRM_DOB_ATTEMPTS_EXHAUSTED,
} = errorTypes;

const BirthDate = ({
    id,
    name,
    required,
    defaultYear,
    defaultMonth,
    defaultDay,
    u13Checked,
    hasNodeTitle,
    customValidationErrorKeyList,
    hasTrackingID,
}) => {
    const {t} = useTranslation('questions');
    const {
        label,
        childLabelForWithNodeTitle,
        error,
        helperText,
        helperTextForTrackingID,
        requiredLabel,
    } = questionConfig[id] || {};
    const renderClassForQuestionLabel = hasNodeTitle
        ? css.questionLabelForWithNodeTitle
        : css.questionLabel;

    const defaultValue =
        defaultYear && defaultMonth && defaultDay
            ? `${defaultMonth}/${defaultDay}/${defaultYear}`
            : null;

    const [hasError, setHasError] = useState(null);
    const handleChange = () => {
        setHasError(null);
    };

    const onStartOverClick = evt => {
        cleanSessionInfo(true);

        window.location.href = process.env.REG_FLOW_URL;
    };

    useEffect(() => {
        if (customValidationErrorKeyList.includes(INCORRECT_BIRTH_DATE)) {
            setHasError(INCORRECT_BIRTH_DATE);
        } else if (
            customValidationErrorKeyList.includes(ALREADY_ENROLLED) ||
            customValidationErrorKeyList.includes(EMAIL_IN_USE)
        ) {
            // ALREADY_ENROLLED and EMAIL_IN_USE share the same error msg
            setHasError(ALREADY_ENROLLED);
        } else if (
            customValidationErrorKeyList.includes(
                CONFIRM_DOB_ATTEMPTS_EXHAUSTED
            )
        ) {
            // if CONFIRM_DOB_ATTEMPTS_EXHAUSTED, automatically redirect to plain reg
            onStartOverClick();
        }
    }, [customValidationErrorKeyList]);

    return (
        <>
            {id === 'CONFIRM_BIRTH_DATE' &&
                hasError === INCORRECT_BIRTH_DATE && (
                    <FormGroupError
                        className={css.radiusAdj}
                        title={t(
                            'questions:CONFIRM_BIRTH_DATE.errorNotMatch.p1'
                        )}
                        errors={{
                            details: (
                                <Trans
                                    i18nKey="questions:CONFIRM_BIRTH_DATE.errorNotMatch.p2"
                                    components={[
                                        /* eslint-disable react/jsx-key */
                                        <Button
                                            className={css.startOverButton}
                                            onClick={onStartOverClick}
                                        >
                                            <ScreenReader />
                                        </Button>,
                                        /* eslint-disable react/jsx-key */
                                    ]}
                                />
                            ),
                        }}
                    />
                )}
            {id === 'CONFIRM_BIRTH_DATE' && hasError === ALREADY_ENROLLED && (
                <FormGroupError
                    className={css.radiusAdj}
                    title={t(
                        'questions:CONFIRM_BIRTH_DATE.errorAlreadyExist.p1'
                    )}
                    errors={{
                        details: (
                            <Button onClick={redirectToMemberPortal}>
                                {t(
                                    'questions:CONFIRM_BIRTH_DATE.errorAlreadyExist.p2'
                                )}
                            </Button>
                        ),
                    }}
                />
            )}
            {id === 'CONFIRM_BIRTH_DATE' && (
                <div className={css.helperText}>
                    {t(hasTrackingID ? helperTextForTrackingID : helperText)}
                </div>
            )}
            {id === 'CONFIRM_BIRTH_DATE' && (
                <div className={css.requiredLabel}>{t(requiredLabel)}</div>
            )}
            <DOBInput
                id={id}
                name={name}
                defaultValue={defaultValue}
                label={
                    <Label
                        className={renderClassForQuestionLabel}
                        i18nRequiredVisualLabel={
                            id === 'CONFIRM_BIRTH_DATE' ? '' : ' '
                        }
                    >
                        {t(u13Checked ? childLabelForWithNodeTitle : label)}
                    </Label>
                }
                error={<FormElementError>{t(error)}</FormElementError>}
                required={required}
                onChange={handleChange}
                yearValidate
            />
        </>
    );
};

BirthDate.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultYear: PropTypes.number,
    defaultMonth: PropTypes.number,
    defaultDay: PropTypes.number,
    u13Checked: PropTypes.bool.isRequired,
    hasNodeTitle: PropTypes.bool,
    customValidationErrorKeyList: PropTypes.array,
    hasTrackingID: PropTypes.string,
};

export default BirthDate;
