import 'styles/index.scss';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import Cookies from 'universal-cookie';
import isNil from 'lodash/isNil';
import Arg from '@livongo/arg';
import APIUtils from '@livongo/utils/api';
import MixpanelUtils from '@livongo/utils/mixpanel';
import SentryUtils, {DEFAULT_OPTIONS} from '@livongo/utilities/system/sentry';
import NewRelicUtils from '@livongo/utilities/system/newrelic';
import PulseApp, {STATUSES} from '@teladoc/pulse/ui/App';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import i18n, {BUILD_KEY, BUILD_ID, getLanguageCode} from 'i18n';
import createStore from 'store';
import App from 'App';
import Unavailable from 'routes/unavailable';
import UserAPI from 'api/rest/user-api';
import PIWIKUtils from 'utilities/piwik-utils';
import {AmplitudeUtils} from 'utilities/amplitude-utils';

const cookies = new Cookies();
const savedLanguageFromCookies = cookies.get('user_preferred_language');
const languageFromUrl = Arg('locale')
    ? getLanguageCode(Arg('locale'))
    : getStoredLang();
const defaultLocale = 'en-US';
const LOCALE_REGEX = /^[a-zA-Z]{2}-[a-zA-Z]{2}$/;
let lang = savedLanguageFromCookies || languageFromUrl;

if (isNil(lang) || !LOCALE_REGEX.test(lang)) {
    lang = defaultLocale;
}

try {
    lang = lang?.split('-');
    lang?.splice(1, 1, lang[1].toUpperCase());
    lang = lang.join('-');
} catch (e) {
    lang = defaultLocale;
}

// Update New Relic key values for this repository
const nrObject = {
    ACCOUNT_ID: process.env.ACCOUNT_ID,
    TRUST_KEY: process.env.TRUST_KEY,
    AGENT_ID: process.env.AGENT_ID,
    LICENSE_KEY: process.env.LICENSE_KEY,
    APPLICATION_ID: process.env.APPLICATION_ID,
};

// Initialize New Relic
NewRelicUtils.init({document, nrObject});

APIUtils.init({
    baseUrl: process.env.API_URL,
    additionalHeaders: {
        'Content-Type': 'application/json',
    },
});

// Reset access token for rest API.
UserAPI.updateAuthorization();

// PIWIK Initialization scripts
if (process.env.APP_ENV === 'production' || cookies.get('piwik-enabled')) {
    PIWIKUtils.init(window, document, process.env.PIWIK_SITE_ID);
    PIWIKUtils.loadScript(process.env.PIWIK_SITE_ID);
}

AmplitudeUtils.load();

MixpanelUtils.init(process.env.MIXPANEL_TOKEN);

SentryUtils.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.BUILD_TAG,
    environment: process.env.APP_ENV,
    whitelistUrls: [/(signup\.ccm\.teladoc\.com)|(signup\.livongo\.com)/],
    ignoreErrors: [
        ...DEFAULT_OPTIONS.ignoreErrors,
        // the source of this issue is external to this project and it wasn't identified
        "undefined is not an object (evaluating 'window.webkit.messageHandlers*postMessage')",
        // axe-core issue
        `t.getAttribute is not a function. (In 't.getAttribute("role")', 't.getAttribute' is undefined)`,
        // Microsoft Outlook SafeLink crawler issue
        'Non-Error promise rejection captured',
    ],
});

// promises will be called in the background, paralel with rendering App component.
// has been moved to individual functions based on the route
const promises = [
    new Promise(resolve => {
        (() => {
            resolve();
        })();
    }),
];

/* i18n declared in the AppUi
 * i18n and react-18n is installed by @livongo/fe-ui
 * Learn more about AppUi props: https://github.com/livongo/fe-ui/blob/master/UPGRADING.md#appui-component
 * Learn more about i18n on AppUi: https://github.com/livongo/fe-ui/blob/master/UPGRADING.md#if-the-project-currently-has-react-i18next
 */

render(
    <PulseApp
        promises={promises}
        build={{
            key: BUILD_KEY,
            value: BUILD_ID,
        }}
        i18n={i18n({lang})}
        render={({status}) => {
            switch (status) {
                case STATUSES.ERROR:
                    return <Unavailable />;
                case STATUSES.INITIALIZED:
                    return (
                        <Provider store={createStore()}>
                            <App />
                        </Provider>
                    );
            }
        }}
    />,
    document.getElementById('root')
);
