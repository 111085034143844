import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import ScreenReader from 'common/screen-reader/ScreenReader';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {questionConfig} from 'constants/questions';
import {mixpanelButtonClicked} from 'utilities/mixpanel-utils';
import css from './u13Experience.scss';

const U13Experience = ({data}) => {
    const {t} = useTranslation('questions');
    const [hasU13Question, setHasU13Question] = useState(false);
    const [registerU13, setRegisterU13] = useState(false);

    const handleU13Click = () => {
        if (registerU13) {
            mixpanelButtonClicked(
                data,
                t(questionConfig.REGISTERING_UNDER_13.notU13ActionLink),
                'Child Registration Opt Out'
            );
        } else {
            mixpanelButtonClicked(
                data,
                t(questionConfig.REGISTERING_UNDER_13.u13ActionLink),
                'Child Registration'
            );
        }

        setRegisterU13(!registerU13);

        const u13Input = document.getElementById(
            questionConfig.REGISTERING_UNDER_13.id
        );

        if (u13Input) {
            u13Input.click();
        }

        return false;
    };

    useEffect(() => {
        const questions = data.questionsToAsk?.questionsList || [];
        const u13QuestionExisting = questions.some(question => {
            if (
                question.questionId?.id ===
                    QuestionId.Values.REGISTERING_UNDER_13 &&
                question?.dataType?.boolField?.previousAnswer?.value === true
            ) {
                setRegisterU13(false);
            }

            return (
                question.questionId?.id ===
                QuestionId.Values.REGISTERING_UNDER_13
            );
        });

        setHasU13Question(u13QuestionExisting);
    }, [data]);

    if (hasU13Question) {
        return (
            <div className={css.root}>
                <Trans
                    i18nKey={
                        registerU13
                            ? `questions:${questionConfig.REGISTERING_UNDER_13.notU13ActionLink}`
                            : `questions:${questionConfig.REGISTERING_UNDER_13.u13ActionLink}`
                    }
                    components={[
                        /* eslint-disable react/jsx-key */
                        <Button className={css.button} onClick={handleU13Click}>
                            <ScreenReader />
                        </Button>,
                        /* eslint-enable react/jsx-key */
                    ]}
                />
            </div>
        );
    } else {
        return null;
    }
};

U13Experience.propTypes = {
    data: PropTypes.object.isRequired,
};

export default U13Experience;
