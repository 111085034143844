import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import Button from '@teladoc/pulse/ui/Button';
import Config from 'config';
import {mixpanelPdfViewedEvent} from 'utilities/mixpanel-utils';
import css from './ContentGeneric.scss';

const ContentGeneric = ({infoDetails, contentId, isOneApp}) => {
    const {title, paragraph, paragraph2, paragraph3, btnPDF, oneAppTitle} =
        infoDetails;
    const {t} = useTranslation('content');
    const locale = getStoredLang().toLowerCase();
    const onPdfClick = () => {
        window.open(
            `${Config.healthyEating}?locale=${locale}`,
            '_blank',
            'noopener noreferrer'
        );
        mixpanelPdfViewedEvent('Healthy Eating Guide', '', contentId);
    };

    return (
        <div>
            {title && (
                <h1 className={css.h1}>
                    {isOneApp && oneAppTitle
                        ? t(oneAppTitle.key, oneAppTitle.interpolation || {})
                        : t(title.key, title.interpolation || {})}
                </h1>
            )}

            {paragraph && (
                <p className={css.p}>
                    {t(paragraph.key, paragraph.interpolation || {})}
                </p>
            )}
            {paragraph2 && (
                <p className={css.p}>
                    {t(paragraph2.key, paragraph2.interpolation || {})}
                </p>
            )}
            {paragraph3 && (
                <p>{t(paragraph3.key, paragraph3.interpolation || {})}</p>
            )}

            {btnPDF && (
                <Button
                    className={css.btnPDF}
                    variant="primary"
                    onClick={onPdfClick}
                >
                    {t(btnPDF.key, btnPDF.interpolation || {})}
                </Button>
            )}
        </div>
    );
};

ContentGeneric.propTypes = {
    infoDetails: PropTypes.object.isRequired,
    contentId: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool,
};

export default ContentGeneric;
