import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import {questionConfig} from 'constants/questions';
import {getSsoContext} from 'utilities/utils';
import css from './Sms.scss';

const Sms = ({id, name, defaultValue}) => {
    const {t} = useTranslation('questions');
    const {label: questionLabel, error} = questionConfig[id] || {};
    const isImpersonation = getSsoContext()?.isImpersonation ?? false;

    return (
        <div>
            {isImpersonation && (
                <div className={css.i}>
                    “We’d also love to keep you in the loop by texting you
                    program updates! Our Notice of Privacy Practices is linked
                    at the bottom of teladoc.com. You can review it online at
                    any time. Can we send you text messages?”
                    <p className={css.fontAdj}>
                        [Await verbal ‘yes, ‘ok’ response] “Thank you.”
                    </p>
                    <br /> <br />
                    <span className={css.fontAdj}>[If the member says no]</span>
                    “Okay, not a problem. You can always opt in later through
                    the communication preferences in your online account.”
                    <br />
                </div>
            )}
            <Checkbox
                classNameRoot={css.marginAdj}
                defaultChecked={defaultValue === 'true'}
                id={id}
                name={name}
                value="true"
                label={<Label>{t(questionLabel)}</Label>}
                i18nItemLabel={t(questionLabel)}
                error={<FormElementError>{t(error)}</FormElementError>}
            />
        </div>
    );
};

Sms.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
};

export default Sms;
