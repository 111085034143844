import {messageTypes} from 'constants/messages';

export const MESSAGE_ADD = 'MESSAGE_ADD';
export const MESSAGE_CLEAR = 'MESSAGE_CLEAR';

const {GENERAL} = messageTypes;

/**
 * Present a message based on messageId from 'constants/messages
 * The type determines with subComponent of message to use and id determines which copy to use inside 'constants/messages'
 * @param {obj} message - message object containing a type and id
 * @returns {obj} - Action
 */
export const addMessage = ({type = GENERAL, id, text, data}) => {
    return {
        type: MESSAGE_ADD,
        payload: {
            type,
            id,
            text,
            data,
        },
    };
};

export const clearMessage = () => {
    return {
        type: MESSAGE_CLEAR,
    };
};
