import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import round from 'lodash/round';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import TextInput from '@teladoc/pulse/ui/TextInput';
import {questionConfig} from 'constants/questions';
import {renderLabel} from '../questions-utils';
import css from './Weight.scss';

const Weight = ({
    id,
    name,
    required,
    defaultValue,
    customValidationError,
    hasNodeTitle,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    const {error, label, labelForWithNodeTitle, ariaLabel, placeholder} =
        questionConfig[id] || {};
    const textInputId = `txt_${id}`;
    // This is the display value with the 'lbs' sign shown
    const [weightValue, setWeightValue] = useState('');
    // This is the int value that will be passed when a form submit happens
    const [weightHiddenValue, setWeightHiddenValue] = useState('');
    const [placeHolder, setPlaceHolder] = useState(placeholder);

    const onTextInputFocus = evt => {
        setPlaceHolder('0 lbs');
    };
    const onTextInputBlur = () => {
        setPlaceHolder(placeholder);
    };
    const handleTextOnChange = evt => {
        const {value} = evt.target;
        let newValue = value.replace(/[^0-9]/g, '');

        setWeightHiddenValue(newValue);

        if (onChange) {
            onChange(newValue);
        }

        // add a lbs sign
        if (newValue.length !== 0) {
            newValue = `${newValue} lbs`;
        }
        setWeightValue(newValue);

        // This is for setting the carat
        // put this in front of the percent sign %
        // setTimeout is done after the render
        setTimeout(() => {
            const elem = document.getElementById(textInputId);

            if (elem !== null && elem.selectionStart && newValue.length > 0) {
                const position = newValue.length - 4; // in front of the lbs

                elem.focus();
                elem.setSelectionRange(position, position);
            }
        }, 0);
    };

    useEffect(() => {
        const trimmedDefaultValue = defaultValue
            ? round(defaultValue, 1)
            : defaultValue;

        const formattedDefaultValue = trimmedDefaultValue
            ? trimmedDefaultValue.toString().includes(' lbs')
                ? trimmedDefaultValue
                : `${trimmedDefaultValue} lbs`
            : '';

        setWeightValue(formattedDefaultValue);
        setWeightHiddenValue(formattedDefaultValue);
    }, [defaultValue]);

    const renderQuestionLabel = hasNodeTitle ? labelForWithNodeTitle : label;
    const renderClassForQuestionLabel = hasNodeTitle
        ? css.questionLabelForWithNodeTitle
        : '';

    return (
        <>
            <TextInput
                {...renderLabel(
                    t(renderQuestionLabel),
                    t(ariaLabel),
                    renderClassForQuestionLabel
                )}
                id={textInputId}
                placeholder={t(placeHolder)}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={customValidationError}
                onChange={handleTextOnChange}
                onFocus={onTextInputFocus}
                onBlur={onTextInputBlur}
                value={weightValue}
                required={required}
            />
            <TextInput
                // this hidden text input is only for submit form
                id={id}
                name={name}
                type="hidden"
                i18nItemLabel="hidden"
                value={weightHiddenValue}
            />
        </>
    );
};

Weight.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.number,
    customValidationError: PropTypes.element,
    hasNodeTitle: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Weight;
