import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {MED_OPT} from 'constants/app';
import Config from 'config';
import {getApplication} from 'utilities/utils';
import css from './ProgressBar.scss';

const {consentSection} = Config;

const ProgressBar = ({location: {pathname}, sections, className, isOneApp}) => {
    const {t} = useTranslation();
    const percentage = useRef(0);
    const [, , sectionName, totalSteps, currentStep] = pathname.split('/');
    const totalSections = sections.length;
    const isMedOpt = getApplication() === MED_OPT;
    const sectionIndex = sections.findIndex(
        section => section.pathname === sectionName
    );

    const [progress, setProgress] = useState(percentage);
    const sectionsOrder = ['sign-up', 'coverage', 'about-you', 'welcome'];
    let progressStatus = 'incomplete';

    useEffect(() => {
        if (sectionName !== consentSection.pathname) {
            setProgress(percentage.current);
        }
    }, [sectionName, percentage, currentStep, totalSteps, isOneApp]);

    if (sectionName && totalSteps && currentStep) {
        percentage.current = Math.round(
            ((currentStep / totalSteps) * 100) / totalSections +
                (100 / totalSections) * sectionIndex
        );
    }

    return (
        <div className={cn(css.root, className)}>
            <div className={css.progressBarSection}>
                {isMedOpt
                    ? // show a single label / Back button label for ProgressBar #GROW-2863
                      sectionIndex >= 0 && (
                          <p
                              aria-label={t(
                                  `progressBarAriaLabel.${sections[sectionIndex]?.title}`,
                                  {
                                      progressStatus,
                                  }
                              )}
                              className={cn(css.name, css.active)}
                          >
                              {t(`section.${sections[sectionIndex]?.title}`)}
                          </p>
                      )
                    : // standard navigation label for ProgressBar
                      sections.map(({id, title, pathname: path}) => {
                          let activeSection = pathname.includes(path);

                          if (
                              id === consentSection.id &&
                              pathname.includes(consentSection.pathname)
                          ) {
                              activeSection = true;
                          }

                          if (activeSection) {
                              progressStatus = 'current';
                          } else if (
                              sectionsOrder.indexOf(sectionName) >
                              sectionsOrder.indexOf(path)
                          ) {
                              progressStatus = 'complete';
                          } else {
                              progressStatus = 'incomplete';
                          }

                          return (
                              <div
                                  key={id}
                                  aria-label={t(
                                      `progressBarAriaLabel.${title}`,
                                      {
                                          progressStatus,
                                      }
                                  )}
                                  role="img"
                                  className={cn(css.name, {
                                      [css.active]: activeSection,
                                  })}
                              >
                                  {t(`section.${title}`)}
                              </div>
                          );
                      })}
                {!isMedOpt && (
                    <div className={css.mobilePercentage} aria-hidden="true">
                        {sectionsOrder.indexOf(sectionName) + 1}/{totalSections}
                    </div>
                )}
            </div>
            <div
                className={cn(css.progressBar, {
                    [css.progressBarNoBar]: isMedOpt,
                })}
            >
                <div
                    key={isOneApp}
                    role="progressbar"
                    aria-hidden="true"
                    className={cn(css.progress, {
                        [css.progressOneApp]: isOneApp,
                    })}
                    style={{width: `${progress}%`}}
                />
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    location: PropTypes.object.isRequired,
    sections: PropTypes.array.isRequired,
    /** Additional class(es) to add to the component. */
    className: PropTypes.string,
    isOneApp: PropTypes.bool,
};
export default ProgressBar;
