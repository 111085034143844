import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import round from 'lodash/round';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import TextInput from '@teladoc/pulse/ui/TextInput';
import {IDK} from 'constants/type';
import {questionConfig} from 'constants/questions';
import {renderLabel} from 'components/question/questions-utils';
import css from './A1cValue.scss';

const A1cValue = ({
    id,
    name,
    required,
    defaultValue,
    defaultDisabled = false,
    customValidationError,
    hasNodeTitle,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    // Get question UI configuration.
    const {
        label,
        labelForWithNodeTitle,
        helpNode,
        ariaLabel,
        checkboxLabel,
        autocomplete,
        placeholder,
        error,
    } = questionConfig[id] || {};
    const textInputId = `txt_${id}`;
    // This is the display value with the percent sign shown
    const [a1cValue, setA1CValue] = useState('');
    const [disableA1CInput, setDisableA1CInput] = useState(defaultDisabled);
    // This is the float value that will be passed when a form submit happens
    const [a1cValueHiddenValue, setA1cValueHiddenValue] = useState('');
    const [placeHolder, setPlaceHolder] = useState(placeholder);
    const onA1cChange = evt => {
        setDisableA1CInput(evt.target.checked);
        setA1cValueHiddenValue(evt.target.checked ? IDK : a1cValue);

        if (onChange) {
            onChange(evt.target.checked ? IDK : a1cValue);
        }
    };
    const handleTextOnChange = evt => {
        const {value} = evt.target;
        let newValue = value.replace(/[^0-9.]/g, '');

        setA1cValueHiddenValue(newValue);

        if (onChange) {
            onChange(newValue);
        }

        // add a percent sign
        if (newValue.length !== 0) {
            newValue = `${newValue}%`;
        }
        setA1CValue(newValue);

        // This is for setting the carat
        // put this in front of the percent sign %
        // setTimeout is done after the render
        setTimeout(() => {
            const elem = document.getElementById(textInputId);

            if (elem !== null && elem.selectionStart && newValue.length > 0) {
                const position = newValue.length - 1; // in front of the %

                elem.focus();
                elem.setSelectionRange(position, position);
            }
        }, 0);
    };
    const onTextInputFocus = evt => {
        setPlaceHolder('0.0%');
    };
    const onTextInputBlur = evt => {
        setPlaceHolder(placeholder);
    };

    useEffect(() => {
        const trimmedDefaultValue = defaultValue
            ? round(defaultValue, 1)
            : defaultValue;

        const formattedDefaultValue = trimmedDefaultValue
            ? trimmedDefaultValue.toString().includes('%')
                ? trimmedDefaultValue
                : `${trimmedDefaultValue}%`
            : '';

        setA1CValue(formattedDefaultValue);
        setA1cValueHiddenValue(formattedDefaultValue);
    }, [defaultValue]);

    const renderQuestionLabel = hasNodeTitle ? labelForWithNodeTitle : label;
    const renderClassForQuestionLabel = hasNodeTitle
        ? css.questionLabelForWithNodeTitle
        : '';

    return (
        <div>
            <TextInput
                {...renderLabel(
                    t(renderQuestionLabel),
                    t(ariaLabel),
                    renderClassForQuestionLabel
                )}
                id={textInputId}
                placeholder={t(placeHolder)}
                autoComplete={autocomplete}
                min={0}
                max={1}
                step={0.1}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={customValidationError}
                onChange={handleTextOnChange}
                onFocus={onTextInputFocus}
                onBlur={onTextInputBlur}
                disabled={disableA1CInput}
                required={required || !disableA1CInput}
                value={a1cValue}
                helpNode={t(helpNode)}
            />
            <Checkbox
                id={`chb_${id}`}
                value={IDK}
                i18nItemLabel={t(checkboxLabel)}
                label={<Label>{t(checkboxLabel)}</Label>}
                error={<FormElementError>{t(error)}</FormElementError>}
                onChange={onA1cChange}
                defaultChecked={defaultDisabled}
            />
            <TextInput
                // this hidden text input is only for submit form
                id={id}
                name={name}
                type="hidden"
                i18nItemLabel="hidden"
                value={a1cValueHiddenValue}
            />
        </div>
    );
};

A1cValue.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.number,
    defaultDisabled: PropTypes.bool,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    hasNodeTitle: PropTypes.bool,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} a1cValue - Either the A1c Value or the IDK string.
     */
    onChange: PropTypes.func,
};

export default A1cValue;
