export const contentIds = {
    AccountAlreadyExists: 'AccountAlreadyExists',
    Policies: 'Policies',
    DidNotAcceptTOS: 'DidNotAcceptTOS',
    NoSponsorFound: 'NoSponsorFound',
    NotInSupportedRegion: 'NotInSupportedRegion',
    EligibilityConfirmed: 'EligibilityConfirmed',
    EligibilityNotConfirmed: 'EligibilityNotConfirmed',
    Disqualified: 'Disqualified',
    DisqualificationDiabetesDiagnosisNeeded:
        'DisqualificationDiabetesDiagnosisNeeded',
    DisqualificationHypertensionDiagnosisNeeded:
        'DisqualificationHypertensionDiagnosisNeeded',
    DisqualificationUnderAge: 'DisqualificationUnderAge',
    DisqualificationPregnancy: 'DisqualificationPregnancy',
    DisqualificationSmartPhone: 'DisqualificationSmartPhone',
    DisqualificationLowBMI: 'DisqualificationLowBMI',
    DisqualificationGeneric: 'DisqualificationGeneric',
    Preferences: 'Preferences',
    Confirmation: 'Confirmation',
    ConfirmationNoRecruitableMatch: 'ConfirmationNoRecruitableMatch',
    CustomConfirmationNoRecruitableMatch:
        'CustomConfirmationNoRecruitableMatch',
    CoverageIntro: 'CoverageIntro',
    EmptyProgramSelection: 'EmptyProgramSelection',
    CongratsOnBehaviorHealth: 'CongratsOnBehaviorHealth',
    StartedPrivoConsent: 'StartedPrivoConsent',
    AwaitingPrivoConsent: 'AwaitingPrivoConsent',
    PrivoConsentNotGiven: 'PrivoConsentNotGiven',
    ThankYouForConsent: 'ThankYouForConsent',
    U18Disqualified: 'U18Disqualified',
    U13Disqualified: 'U13Disqualified',
    EnrollmentCapReached: 'EnrollmentCapReached',
    SsoWarmWelcome: 'SsoWarmWelcome',
    DidNotAcceptClientConsent: 'DidNotAcceptClientConsent',
    OneAppIntroduction: 'OneAppIntroduction',
    BHBundleEnrollmentInfo: 'BHBundleEnrollmentInfo',
};

const INVALID = 'INVALID';
const CONTROL = 'CONTROL';
const VARIATION_1 = 'VARIATION_1';

export {INVALID, CONTROL, VARIATION_1};

export const contentConfig = {
    SsoWarmWelcome: {
        info: {
            title: 'FIRST_NAME.info.title',
            content: 'FIRST_NAME.info.content',
        },
    },
};
