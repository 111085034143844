import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import {
    redirectToMemberPortalOrOneAppHomePage,
    redirectToCoach,
} from 'utilities/utils';
import css from './Ineligible.scss';

const Ineligible = ({contentId, isOneApp}) => {
    const {t} = useTranslation('content');

    return (
        <div className={css.root}>
            <h1 className={css.h1}>{t('INELIGIBLE.title')}</h1>
            <p>{t('INELIGIBLE.paragraph')}</p>
            <div className={css.actions}>
                <Button
                    className={css.secondaryAction}
                    variant="primary"
                    onClick={() => redirectToCoach(isOneApp)}
                >
                    {t('INELIGIBLE.btnText')}
                </Button>
                <Button
                    className={css.secondaryAction}
                    variant="secondary"
                    onClick={() =>
                        redirectToMemberPortalOrOneAppHomePage(isOneApp)
                    }
                >
                    {t('INELIGIBLE.btnText2')}
                </Button>
            </div>
        </div>
    );
};

Ineligible.propTypes = {
    contentId: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool,
};

export default Ineligible;
