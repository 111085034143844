import PropTypes from 'prop-types';
import {useState} from 'react';
import Loader from '@teladoc/pulse/ui/Loader';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {
    getRestApiAccessCode,
    setIsVisited,
    getUpsellProgram,
} from 'utilities/utils';
import {eligibilityStatus} from 'constants/questions';
import {
    HERE_IS_HOW,
    GET_THE_APP,
    INELIGIBLE,
    VALUE_POINTS,
} from 'constants/medOpt/contentIds';
import AccountAlreadyExists from './subComponents/AccountAlreadyExists';
import AwaitingPrivoConsent from './subComponents/AwaitingPrivoConsent';
import Coverage from './subComponents/Coverage';
import CongratsOnBehaviorHealth from './subComponents/CongratsOnBehaviorHealth';
import Confirmation from './subComponents/Confirmation';
import ContentGeneric from './subComponents/ContentGeneric';
import ListCard from './subComponents/ListCard';
import Ineligible from './subComponents/Ineligible';
import Disqualification from './subComponents/Disqualification';
import DidNotAcceptClientConsent from './subComponents/DidNotAcceptClientConsent';
import GenericError from './subComponents/GenericError';
import SsoWarmWelcome from './subComponents/SsoWarmWelcome';
import OneAppConfirmation from './subComponents/OneAppConfirmation';
import OneAppIntroduction from './subComponents/OneAppIntroduction';
import BHBundleEnrollmentInfo from './subComponents/BHBundleEnrollmentInfo';
import InformationalScreen from './subComponents/InformationalScreen';
import GetTheApp from './subComponents/GetTheApp';
import UpsellConfirmation from './subComponents/UpsellConfirmation';

const Content = ({contentId, sectionInfo, setNavigationLabel, isOneApp}) => {
    const answersWithinContext = sectionInfo.context?.answersList || [];
    const eligibilityAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.ELIGIBILITY_STATUS
    );
    const isProgramPending =
        eligibilityAnswer?.value?.singleSelectEnumCode ===
        eligibilityStatus.INELIGIBLE;
    const clientNameAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.CLIENT_NAME
    );
    const userFirstNameAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.FIRST_NAME
    );
    const clientName = clientNameAnswer?.value?.text || '';
    const userFirstName = userFirstNameAnswer?.value?.text || '';
    const [isLoading, setLoading] = useState(false);

    const contentDetails = {
        title: {key: `${contentId}.title`},
        paragraph: {
            key: `${contentId}.paragraph`,
        },
    };

    switch (contentId) {
        case 'Confirmation':
        case 'ConfirmationNoRecruitableMatch': {
            const restApiAccessCode = getRestApiAccessCode();

            if (isOneApp) {
                return (
                    <>
                        {isLoading && <Loader className="loader" />}
                        {!isLoading && (
                            <OneAppConfirmation
                                contentId={contentId}
                                sectionInfo={sectionInfo}
                                isloading={isLoading}
                                setLoading={setLoading}
                            />
                        )}
                    </>
                );
            } else {
                if (restApiAccessCode) {
                    setIsVisited(false);

                    return (
                        <Confirmation
                            contentId={contentId}
                            sectionInfo={sectionInfo}
                        />
                    );
                } else {
                    return (
                        <>
                            {isLoading && <Loader className="loader" />}
                            {!isLoading && (
                                <GenericError contentId="AccountNotCreated" />
                            )}
                        </>
                    );
                }
            }
        }
        case 'ManageSolutionConfirmation': {
            const {upsellFinished} = getUpsellProgram() || false;

            if (upsellFinished) {
                return (
                    <UpsellConfirmation
                        contentId={contentId}
                        sectionInfo={sectionInfo}
                    />
                );
            } else {
                return <GenericError contentId="UpsellNotFinished" />;
            }
        }

        case 'CoverageIntro':
        case 'EligibilityNotConfirmed':
        case 'EligibilityConfirmed':
            return (
                <Coverage
                    contentId={contentId}
                    clientName={clientName}
                    isOneApp={isOneApp}
                />
            );

        case 'CongratsOnBehaviorHealth':
            return (
                <CongratsOnBehaviorHealth
                    contentId={contentId}
                    isProgramPending={isProgramPending}
                    isOneApp={isOneApp}
                />
            );

        case 'AccountAlreadyExists':
            return (
                <AccountAlreadyExists
                    contentId={contentId}
                    isOneApp={isOneApp}
                />
            );

        case 'AwaitingPrivoConsent':
        case 'StartedPrivoConsent':
        case 'PrivoConsentNotGiven':
            return (
                <AwaitingPrivoConsent
                    contentId={contentId}
                    setNavigationLabel={setNavigationLabel}
                />
            );

        case 'ThankYouForConsent':
            return (
                <ContentGeneric
                    infoDetails={contentDetails}
                    contentId={contentId}
                />
            );

        case 'DidNotAcceptClientConsent':
            return (
                <DidNotAcceptClientConsent
                    sectionInfo={sectionInfo}
                    contentId={contentId}
                />
            );

        case 'CustomConfirmationNoRecruitableMatch':
        case 'EnrollmentCapReached':
        case 'DisqualificationDiabetesDiagnosisNeeded':
        case 'DisqualificationHypertensionDiagnosisNeeded':
        case 'DisqualificationUnderAge':
        case 'U13Disqualified':
        case 'U18Disqualified':
        case 'DisqualificationPregnancy':
        case 'DisqualificationSmartPhone':
        case 'DisqualificationLowBMI':
        case 'DisqualificationGeneric':
        case 'DisqualificationWeight':
        case 'Disqualified':
        case 'OneAppEnrollmentBlocked':
            return (
                <Disqualification
                    contentId={contentId}
                    clientName={clientName}
                    isOneApp={isOneApp}
                />
            );

        case 'SsoWarmWelcome':
            return (
                <SsoWarmWelcome
                    contentId={contentId}
                    userFirstName={userFirstName}
                />
            );

        case 'OneAppIntroduction':
            return <OneAppIntroduction contentId={contentId} />;

        case 'BHBundleEnrollmentInfo':
            return <BHBundleEnrollmentInfo contentId={contentId} />;

        case 'InformationalScreen':
            return <InformationalScreen contentId={contentId} />;

        // MedOPT contents page
        case HERE_IS_HOW:
        case VALUE_POINTS:
            return <ListCard contentId={contentId} isOneApp={isOneApp} />;
        case GET_THE_APP:
            return <GetTheApp contentId={contentId} isOneApp={isOneApp} />;
        case INELIGIBLE:
            return <Ineligible contentId={contentId} isOneApp={isOneApp} />;
    }

    // if the contentId isn't correct, showing Generic Error
    return <GenericError contentId={contentId} />;
};

Content.propTypes = {
    contentId: PropTypes.string.isRequired,
    sectionInfo: PropTypes.object.isRequired,
    setNavigationLabel: PropTypes.func,
    isOneApp: PropTypes.bool,
};

export default Content;
