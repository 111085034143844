/**
 * This file gathers all gRPC services used so that we can set the GRPCWEB_DEVTOOLS in one spot.
 * For now, we are using only the enrollment gPRC service
 */
import {EnrollmentPromiseClient} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/enrollment_api_grpc_web_pb';
import {ManageSolutionPromiseClient} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/manage_solution_api_grpc_web_pb';

export const enrollmentPromiseClient = new EnrollmentPromiseClient(
    process.env.SERVICE_URL
);

export const manageSolutionPromiseClient = new ManageSolutionPromiseClient(
    process.env.SERVICE_URL
);

const developmentEnvs = ['local', 'integration', 'preprod'];

if (developmentEnvs.indexOf(process.env.APP_ENV) > -1) {
    // eslint-disable-next-line
    const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

    // enable debugging grpc calls
    enableDevTools([enrollmentPromiseClient, manageSolutionPromiseClient]);
}
