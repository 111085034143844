/**
 * Navigation gRPC calls
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/med_opt/med_opt_onboarding.proto
 */
import {
    NavigateNextRequest,
    NavigateBackRequest,
    NavigateHereRequest,
    ProgressCheckRequest,
} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/med_opt_onboarding_pb';
// COMMENT IN, when test with Mock Data
// import {
//     getNavigateHereMockPromise,
//     getNavigateNextMockPromise,
//     getNavigateBackMockPromise,
// } from 'constants/medOptMockData';
import {
    medOptOnboardingPromiseClient,
    getGrpcProtocolVersion,
    getGrpcMetadata,
} from './utils';

// let mockDataStep = 0;

/**
 * navigateNext
 * Next questions to be asked
 * @param {int} sectionId the sectionId of the section we want question(s) for
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.navigation.NavigateNextResponse>}
 *  The next group of questions which should be answered, or an indicator that there aren't any more questions in this
 *     section which have not been answered.
 */
export const navigateNext = sectionId => {
    const request = new NavigateNextRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setSectionId(sectionId);
    request.setParent(`user/liv/me/medopt`);

    // Comment out to take mock data.
    return medOptOnboardingPromiseClient
        .navigateNext(request, getGrpcMetadata())
        .then(response => response.toObject());

    // Comment in for mock data
    // mockDataStep++;

    // const ret = getNavigateNextMockPromise(mockDataStep)
    // const str = JSON.stringify(ret);

    // return ret;
};

/**
 * navigateBack
 * Previous questions which were asked
 * @param {int} sectionId the sectionId of the section we want question(s) for
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.navigation.NavigateBackResponse>}
 *  In the case where it is possible to go back, a `RegistrationStep` is returned which should be equivalent to the
 *     previous step - with the addition of previously provided answers.
 */
export const navigateBack = sectionId => {
    const request = new NavigateBackRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setSectionId(sectionId);
    request.setParent(`user/liv/me/medopt`);

    // Comment out to take mock data.
    return medOptOnboardingPromiseClient
        .navigateBack(request, getGrpcMetadata())
        .then(response => response.toObject());

    // Comment in for mock data
    // if (mockDataStep > 0) {
    //     mockDataStep--;
    // }

    // return getNavigateBackMockPromise(mockDataStep);
};

/**
 * navigateHere
 * Requests the current questions to be asked
 * @param {int} sectionId the sectionId of the section we want question(s) for
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.navigation.NavigateHereResponse>}
 *  Promise
 */
export const navigateHere = sectionId => {
    const request = new NavigateHereRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());
    request.setSectionId(sectionId);
    request.setParent(`user/liv/me/medopt`);

    // Comment out to take mock data.
    return medOptOnboardingPromiseClient
        .navigateNext(request, getGrpcMetadata())
        .then(response => response.toObject());

    // Comment in for mock data
    // const ret = getNavigateHereMockPromise(mockDataStep)
    // const str = JSON.stringify(ret);
    // return ret;
};

/**
 * progressCheck
 * Overall Progress Check
 * This is useful for when the FrontEnd thinks they should be done, but the
 * `NavigateNextResponse.end_of_section_reached.account_creation_possible` flag is unexpectedly `false`
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.navigation.ProgressCheckResponse>}
 *  Promise
 */
export const progressCheck = () => {
    const request = new ProgressCheckRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());

    return medOptOnboardingPromiseClient.progressCheck(
        request,
        getGrpcMetadata()
    );
};
