import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import TextInput from '@teladoc/pulse/ui/TextInput';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import Radio from '@teladoc/pulse/ui/Radio';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import Select from '@teladoc/pulse/ui/Select';
import Label from '@teladoc/pulse/ui/Label';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import ZipInput from '@teladoc/pulse/ui/ZipInput';
import {QuestionId as MedOptQuestionId} from 'api/protobuf/medOpt/prototypes';
import {getQuestionNameById as medOptGetQuestionNameById} from 'api/protobuf/medOpt/services';
import {
    QuestionId as RegistrationQuestionId,
    SectionId as RegistrationSectionId,
} from 'api/protobuf/enrollment/protoTypes';
import {getQuestionNameById as registrationGetQuestionNameById} from 'api/protobuf/enrollment/services';
import {MED_OPT, programs} from 'constants/app';
import {
    questionConfig,
    questionFieldTypes,
    eligibilityStatus,
} from 'constants/questions';
import {questionUiType, IDK} from 'constants/type';
import {
    getApplication,
    getFirstNonEmptyKey,
    getRegCode,
    getDynamicValidationParams,
    getStepNumber,
} from 'utilities/utils';
import {contentConfig} from 'constants/contentIds';
import {getBrandName} from 'routes/medOpt/MedOptsUtils';
import GeneralInfoByQuestionId from '../info/GeneralInfoByQuestionId';
import A1cDate from './subComponents/A1cDate';
import A1cValue from './subComponents/A1cValue';
import AcknowledgedChildPrivacyPolicy from './subComponents/AcknowledgedChildPrivacyPolicy';
import Address from './subComponents/Address';
import AddressCombined from './subComponents/AddressCombined';
import BirthDate from './subComponents/BirthDate';
import BloodTest from './subComponents/BloodTest';
import CheckboxCard from './subComponents/CheckboxCard';
import ClientConsent from './subComponents/ClientConsent';
import ConfirmEligibilityFileRegCode from './subComponents/ConfirmEligibilityFileRegCode';
import Password from './subComponents/Password';
import Program from './subComponents/Program';
import PhoneInputField from './subComponents/PhoneInputField';
import PhoneInputInternational from './subComponents/PhoneInputInternational';
import LabConsent from './subComponents/LabConsent';
import MultiSelect from './subComponents/MultiSelect';
import RadioCard from './subComponents/RadioCard';
import RegCode from './subComponents/RegCode';
import Sms from './subComponents/Sms';
import SupportedRegion from './subComponents/SupportedRegion';
import Height from './subComponents/Height';
import HealthIssues from './subComponents/HealthIssues';
import Weight from './subComponents/Weight';
import TargetPrograms from './subComponents/TargetPrograms';
import TermsAndConditions from './subComponents/TermsAndConditions';
import MountSinaiConsent from './subComponents/MountSinaiConsent';
import DignityHealthConsent from './subComponents/DignityHealthConsent';
import UmassConsent from './subComponents/UmassConsent';
import Local99Consent from './subComponents/Local99Consent';
import Location from './subComponents/Location';
import RaceV2 from './subComponents/RaceV2';
import GenderIdentityV2 from './subComponents/GenderIdentityV2';
import {
    renderLabel,
    handleErrorMessages,
    getValidationErrorMessage,
} from './questions-utils';
import css from './Question.scss';

const {
    TEXT_INPUT,
    RADIO,
    CHECKBOX,
    SELECT,
    MULTI_SELECT,
    MULTI_SELECT_REQUIRED,
    COMPOUND,
    PASSWORD,
    ADDRESS,
    A1C_DATE,
    A1C_VALUE,
    BIRTH_DATE,
    SMS_OPT_IN,
    TERMS_AND_CONDITIONS,
    REG_CODE,
    PROGRAM,
    PHONE_INPUT,
    BLOOD_TEST,
    HEIGHT,
    WEIGHT,
    SUPPORTED_REGION,
    LAB_CONSENT,
    ADDRESS_COMBINED,
    PHONE_INPUT_INTERNATIONAL,
    UNION,
    ZIP,
    HEALTH_ISSUES,
    ACKNOWLEDGED_CHILD_PRIVACY_POLICY,
    CLIENT_CONSENT,
    MOUNTSINAI_DIABETES_ALLIANCE,
    DIGNITY_HEALTH_TOU,
    UMASS_BG_DATA,
    LOCAL99_TOU,
    LOCATION,
    CONFIRM_ELIGIBILITY_FILE_REG_CODE,
    TARGET_PROGRAMS,
    RADIO_CARD,
    CHECKBOX_CARD,
    RACE_V2,
    GENDER_IDENTITY_V2,
} = questionUiType;

const {
    TEXT_FIELD,
    SINGLE_SELECT_ENUM,
    MULTI_SELECT_ENUM,
    BOOLEAN_FIELD,
    INTEGER_FIELD,
    FLOAT_FIELD,
    DATE_FIELD,
    COMPOUND_FIELD,
    UNION_FIELD,
} = questionFieldTypes;

const {DIABETES_DEVICEFLEX} = programs;

/**
 * Get the default values from the question object based on dataType
 * userInput > previousAnswer > defaultAnswer
 * @param {Question} question - Question Object with datatype.
 * @returns {text|date|string[]} - default value depending on dataType
 */
const getDefaultValueFromQuestion = question => {
    // Get the correct MedOpt or Registration gRPC QuestionId or getQuestionNameById
    const QuestionId =
        getApplication() === MED_OPT
            ? MedOptQuestionId
            : RegistrationQuestionId;
    const dataType = getFirstNonEmptyKey(question.dataType);
    const dataTypeObj = question.dataType[dataType];
    let defaultValue;

    if (dataTypeObj.userInput) {
        return dataTypeObj.userInput;
    }

    // TEXT_FIELD, INTEGER_FIELD, FLOAT_FIELD
    if ([TEXT_FIELD, INTEGER_FIELD, FLOAT_FIELD].includes(dataType)) {
        if (dataTypeObj.previousAnswer) {
            defaultValue = dataTypeObj.previousAnswer.value;
        } else if (dataTypeObj.defaultAnswer) {
            defaultValue = dataTypeObj.defaultAnswer.value;
        }
        // RegCode
        else if (question?.questionId?.id === QuestionId.Values.REG_CODE) {
            defaultValue = getRegCode();
        }
    }
    // BOOLEAN_FIELD
    else if (dataType === BOOLEAN_FIELD) {
        if (
            dataTypeObj?.previousAnswer?.value === true ||
            dataTypeObj?.previousAnswer?.value === 'true' ||
            dataTypeObj?.defaultAnswer?.value === true ||
            dataTypeObj?.defaultAnswer?.value === 'true'
        ) {
            defaultValue = 'true';
        } else if (
            dataTypeObj?.previousAnswer?.value === false ||
            dataTypeObj?.previousAnswer?.value === 'false' ||
            dataTypeObj?.defaultAnswer?.value === false ||
            dataTypeObj?.defaultAnswer?.value === 'false'
        ) {
            defaultValue = 'false';
        }
    }
    // DATE_FIELD
    else if (dataType === DATE_FIELD) {
        defaultValue =
            dataTypeObj.userInput ||
            dataTypeObj.previousAnswer ||
            dataTypeObj.defaultAnswer ||
            null;
    }
    // SINGLE_SELECT_ENUM
    else if (dataType === SINGLE_SELECT_ENUM) {
        if (dataTypeObj.previousAnswerCode) {
            defaultValue = dataTypeObj.previousAnswerCode.value;
        } else if (dataTypeObj.defaultAnswerCode) {
            defaultValue = dataTypeObj.defaultAnswerCode.value;
        }
    }
    // MULTI_SELECT_ENUM
    else if (dataType === MULTI_SELECT_ENUM) {
        if (
            dataTypeObj.previousAnswerCodesList &&
            dataTypeObj.previousAnswerCodesList.length > 0
        ) {
            defaultValue = dataTypeObj.previousAnswerCodesList;
        } else if (dataTypeObj.defaultAnswerCodesList) {
            defaultValue = dataTypeObj.defaultAnswerCodesList;
        }
    }
    // COMPOUND_FIELD not needed

    return defaultValue;
};

/**
 * Get the available options from either the configuration file or possible values from the question object
 * t may be null which means don't translate
 * BOOL_FIELD are 'true' or 'false'
 * SINGLE_SELECT_ENUMS and MULTI_SELECT_ENUMS read from answerCodesList in the dataType object.
 * @param {Object[]|null} defaultOptions - default configuration from the configuration file
 * @param {Question} question - Question Object with datatype.
 * @param {translate|null} t - Translation object, may be null.
 * @param {i18n} i18n - Other Translation object to check for translations.
 * @param isOneApp {false|true} isOneApp - is OneApp flag.
 * @returns {Object[]} - Array of objects consisting of value and label.
 */
const getOptions = (defaultOptions, question, t, i18n, isOneApp) => {
    // Get the correct MedOpt or Registration gRPC QuestionId or getQuestionNameById
    const getQuestionNameById =
        getApplication() === MED_OPT
            ? medOptGetQuestionNameById
            : registrationGetQuestionNameById;

    const dataType = getFirstNonEmptyKey(question.dataType);
    const questionName = getQuestionNameById(question.questionId.id);
    let options = [];

    if (Array.isArray(defaultOptions)) {
        options = options.map(({value, label}) => ({
            value,
            label: t ? t(label) : label,
        }));
    } else if (dataType === BOOLEAN_FIELD) {
        if (
            questionName === 'SMS_OPT_IN' ||
            questionName === 'CONFIRM_ELIGIBILITY_FILE_REG_CODE'
        ) {
            options = [
                {
                    value: 'true',
                    label: t(`questions:${questionName}.options.Yes`),
                },
                {
                    value: 'false',
                    label: t(`questions:${questionName}.options.No`),
                },
            ];
        } else {
            options = [
                {value: 'true', label: t('options.YES')},
                {value: 'false', label: t('options.NO')},
            ];
        }
    } else if ([SINGLE_SELECT_ENUM, MULTI_SELECT_ENUM].includes(dataType)) {
        const answerCodes = question.dataType[dataType]?.answerCodesList || [];

        options = answerCodes.map(code => {
            let label = code;

            if (t) {
                if (i18n.exists(`questions:${questionName}.options.${code}`)) {
                    if (
                        isOneApp &&
                        questionName === 'CONSENT_LABS_DATA_ACCESS'
                    ) {
                        label = t(`${questionName}.optionsOneApp.${code}`);
                    } else {
                        label = t(`${questionName}.options.${code}`);
                    }
                } else if (i18n.exists(`common:options.${code}`)) {
                    label = t(`common:options.${code}`);
                } else {
                    // TODO: alert to missing translation
                    // doing the translation to debug
                    if (
                        questionName !== 'MOBILE_PHONE_COUNTRY' &&
                        questionName !== 'INSURER_NAME'
                    ) {
                        // TODO: alert to missing translation
                        // doing the translation to debug
                        t(`${questionName}.options.${code}`);
                    }
                }
            }

            return {
                value: code,
                label,
            };
        });

        if (questionName === 'DIABETES_MEDS') {
            options.splice(1, 0, {
                value: 'ORAL_MEDICATIONS_1',
                label: 'Non-insulin shots',
            });
        }
    }

    return options;
};

const Question = ({
    question,
    answersList,
    validationErrorsList = [],
    // TODO: Append parent question id to name for compound question.
    isCompoundQuestion = false,
    hasNodeTitle,
    isMobileQuestionWithAddress,
    setNavigationLabel,
    onChange,
    u13Checked,
    sectionInfo,
    formRef,
    isOneApp,
}) => {
    const {t, i18n} = useTranslation('questions');
    // customValidationError is the object that wrapped by <FormElementError>
    const [customValidationError, setCustomValidationError] = useState(null);
    // customValidationErrorKeyList is the error key list from BE
    const [customValidationErrorKeyList, setCustomValidationErrorKeyList] =
        useState([]);
    const [userInput, setUserInput] = useState(null);
    const [isAddressFromEditable, setIsAddressFromEditable] = useState(false);

    useEffect(() => {
        if (validationErrorsList.length) {
            const errorMessages = handleErrorMessages(
                validationErrorsList,
                question,
                t
            );

            const errorMessageNameList = validationErrorsList.map(
                errorTypeObj => getFirstNonEmptyKey(errorTypeObj)
            );

            setCustomValidationError(
                <FormElementError>{errorMessages}</FormElementError>
            );
            setCustomValidationErrorKeyList(errorMessageNameList);
        } else {
            setCustomValidationError(null);
        }
    }, [validationErrorsList, question, t]);

    // Get the correct MedOpt or Registration gRPC QuestionId or getQuestionNameById
    const QuestionId =
        getApplication() === MED_OPT
            ? MedOptQuestionId
            : RegistrationQuestionId;
    const getQuestionNameById =
        getApplication() === MED_OPT
            ? medOptGetQuestionNameById
            : registrationGetQuestionNameById;
    // const {questionId, required, validationErrorsList} = question;
    const {questionId, required} = question;
    const {id = -1, version = -1} = questionId || {};
    // Get question name in string format by question id.
    let questionName = getQuestionNameById(id);
    const regCodeAnswer = answersList.find(
        answer => answer.questionId?.id === QuestionId.Values.REG_CODE
    );
    const regCode = regCodeAnswer?.value?.text;
    const selectedProgramsAnswer = answersList.find(
        answer => answer.questionId?.id === QuestionId.Values.PROGRAM
    );
    const selectedPrograms =
        selectedProgramsAnswer?.value?.multiSelectEnum?.enumCodeList || [];
    const isDiabetesDeviceFlexOnly =
        selectedPrograms.includes(DIABETES_DEVICEFLEX) &&
        selectedPrograms.length === 1;

    /*
     * For regcodes which need customization, override question name so question config pulls
     *  specific label, validation pattern and error message.
     */
    if (questionId.id === QuestionId.Values.POLICY_HOLDER_MEMBER_ID) {
        switch (regCode) {
            case 'FEPBLUE':
                questionName = 'POLICY_HOLDER_MEMBER_ID_FEPBLUE';
                break;
        }
    }

    // Race and genderIdentity question version is the flag to check which version is live on ProD
    if (questionId.id === QuestionId.Values.RACE) {
        if (questionId.version === 2) {
            questionName = 'RACE_V2';
        }
    } else if (questionId.id === QuestionId.Values.GENDER_IDENTITY) {
        if (questionId.version === 2) {
            questionName = 'GENDER_IDENTITY_V2';
        }
    }

    // Get question UI configuration.
    const {
        uiType: defaultUiType, // may be undefined
        type,
        pattern: patternFromConfig,
        autocomplete,
        options: defaultOptions,
        title,
        titleForMultipleQuestion,
        subTitle,
        label: questionLabel,
        labelForWithNodeTitle,
        childLabelForWithNodeTitle, // may be undefined
        labelForMultipleQuestion,
        ariaLabel,
        placeholder,
        placeholderForWithNodeTitle,
        placeholderForMultipleQuestion,
        mask,
        translateOptions = true,
        error,
    } = questionConfig[questionName] || {};

    const name = `${questionName}_-_${id}`;
    const dataType = getFirstNonEmptyKey(question.dataType);
    // Get the user input, previous answer, or default answer for the question object if it exists
    const defaultValue = getDefaultValueFromQuestion(question);
    // Get the available options from either the configuration file or possible values from the question object
    const options = getOptions(
        defaultOptions,
        question,
        translateOptions ? t : null,
        i18n,
        isOneApp
    );

    // Get the correct translation key for the matching question name and user Input
    let errorMessage = t(getValidationErrorMessage(questionName, userInput));

    /* prevent user from creating white space while typing email input.
    However, it doesn't prevent white space on copy-paste, but modifiedValue
    handles this short coming through trim*/
    const handlePreventSpace = evt => {
        if (evt.code === 'Space' && questionName === 'EMAIL') {
            evt.preventDefault();
        }
    };

    const handleChange = newValue => {
        if (customValidationError !== null) {
            setCustomValidationError(null);
        }

        setUserInput(newValue);

        if (onChange) {
            onChange(id, newValue);
        }
    };

    let renderTitle = title;
    let renderQuestionLabel = questionLabel;
    let renderClassForQuestionLabel = '';
    let renderPlaceholder = placeholder;
    let questionLabelTextParams = {};
    let contentTextParams = {};
    const isSsoWarmWelcome = sectionInfo?.contentId === 'SsoWarmWelcome';
    const info = isSsoWarmWelcome
        ? contentConfig?.SsoWarmWelcome?.info
        : questionConfig[questionName]?.info;
    let {content} = info || {};
    const sectionId = sectionInfo.context.sectionId;

    if (isOneApp && id === QuestionId.Values.CONSENT_LABS_DATA_ACCESS) {
        renderTitle = `${renderTitle}OneApp`;
        content = `${content}OneApp`;
    }

    if (isOneApp && id === QuestionId.Values.GOT_SMART_PHONE) {
        content = `${content}OneApp`;
    }

    if (isOneApp && id === QuestionId.Values.EMAIL) {
        renderTitle = `${renderTitle}OneApp`;
    }

    // Pass in params for i18n interpolation
    if (id === QuestionId.Values.SMART_PHONE) {
        questionLabelTextParams = {
            brandName: getBrandName(isOneApp),
        };
        contentTextParams = questionLabelTextParams;
    }

    /*
     * Special logic for mobile phone to display different title and label
     * if it got asked with address question, show label and no title.
     */
    if (
        (id === QuestionId.Values.PHONE_MOBILE ||
            id === QuestionId.Values.MOBILE_PHONE_INTERNATIONAL) &&
        isMobileQuestionWithAddress
    ) {
        renderTitle = titleForMultipleQuestion;
        renderQuestionLabel = labelForMultipleQuestion;
        renderPlaceholder = placeholderForMultipleQuestion;
    }

    // render address question title based on if DBTDF or if oneApp
    if (
        id === QuestionId.Values.ADDRESS_USA_COMBINED ||
        id === QuestionId.Values.ADDRESS_USA_STANDARD
    ) {
        if (isDiabetesDeviceFlexOnly) {
            renderTitle = `${title}ForDiabetesDF`;
        } else if (isOneApp) {
            renderTitle = `${title}OneApp`;
        }
    }

    const experimentContext = answersList.find(
        answer =>
            answer.questionId?.id ===
            QuestionId.Values.SHOW_EXPERIMENTAL_WORDING
    );

    const isExperimentContext = experimentContext?.value.pb_boolean === true;

    if (isExperimentContext) {
        renderTitle = `${title}Experimental`;
    }

    // Get program real time eligibility flag
    const eligibilityAnswer = answersList.find(
        answer => answer.questionId?.id === QuestionId.Values.ELIGIBILITY_STATUS
    );
    const isProgramPending =
        eligibilityAnswer?.value?.singleSelectEnumCode ===
        eligibilityStatus.INELIGIBLE;

    // Todo: use step number as the program selection flag
    const isProgramSelection = getStepNumber();

    /*
     * Special logic for program to display different title
     *  1: programs are pending for real time eligibility.
     *  2: program selection
     */

    if (id === QuestionId.Values.PROGRAM && isProgramSelection) {
        renderTitle += 'ForProgramSelection';
    } else if (id === QuestionId.Values.PROGRAM && isProgramPending) {
        renderTitle += 'ForPendingProgram';
    }

    // Get trackingID from context as flag to differentiate reg recovery and email tracking
    const trackingIDAnswer = answersList.find(
        answer => answer.questionId?.id === QuestionId.Values.TRACKING_UUID
    );

    const hasTrackingID = trackingIDAnswer?.value?.text;

    if (id === QuestionId.Values.CONFIRM_BIRTH_DATE && hasTrackingID) {
        renderTitle += 'ForTrackingID';
    }

    /**
     * Used for group questions.
     */
    if (hasNodeTitle) {
        if (u13Checked && childLabelForWithNodeTitle) {
            renderQuestionLabel = childLabelForWithNodeTitle;
        } else {
            renderQuestionLabel = labelForWithNodeTitle;
        }
        renderPlaceholder = placeholderForWithNodeTitle;
        renderClassForQuestionLabel = css.questionLabelForWithNodeTitle;
    }

    // get the pattern from the config, but it might be overridden by the pattern existing in the gRPC call if any
    let pattern = patternFromConfig;
    let uiType = defaultUiType;

    // The UI Type depends on the datatype returned from BE question,
    // for instance a textField matches to a TextInput or a singleSelectEnum to radio buttons.
    // This can be overridden by the uiType in the questionConfig.
    if (!uiType) {
        if (dataType === SINGLE_SELECT_ENUM) {
            // defaults to radio instead of select because it is more common, override in config
            uiType = RADIO;
        } else if (dataType === MULTI_SELECT_ENUM) {
            uiType = MULTI_SELECT;
        } else if (dataType === BOOLEAN_FIELD) {
            uiType = RADIO;
        } else if (dataType === COMPOUND_FIELD) {
            uiType = COMPOUND;
        } else if (dataType === UNION_FIELD) {
            uiType = UNION;
        } else {
            // TEXT_FIELD, INTEGER_FIELD, FLOAT_FIELD, DATE_FIELD
            uiType = TEXT_INPUT;
        }
    }

    /**
     * Set the validation attributes depending on what the BE provides.
     */
    const setValidationParams = () => {
        // restrict the dynamic validation to TEXT_INPUT to minimize side effects
        if (uiType === TEXT_INPUT) {
            // Restricting to POLICY_HOLDER_MEMBER_ID for now. Might be extended in the future to REG_CODE, ZIP, NAME, and others questions
            if (id === QuestionId.Values.POLICY_HOLDER_MEMBER_ID) {
                // extract the needed validation data from the gRPC pattern tokens
                const patternTokens =
                    question?.dataType?.textField?.fieldPattern?.tokensList;
                // prepare the translations keys needed by getDynamicValidationParams to build the label dynamically
                const translationKeys = {
                    range: 'common:validation.range',
                    minimum: 'common:validation.minimum',
                    maximum: 'common:validation.maximum',
                    valueWithCharacter: 'common:validation.valueWithCharacter',
                    prefix: renderQuestionLabel,
                    chunk1Prefix: 'common:validation.chunk1Prefix',
                    chunk2Prefix: 'common:validation.chunk2Prefix',
                    chunkLast: 'common:validation.chunkLast',
                    error: 'common:error.formatWithExample',
                };
                // get the label, pattern and example which are built dynamically from what the BE gives us
                const {
                    label: labelFromBE,
                    pattern: patternFromBE,
                    placeholder: placeholderFromBE,
                    errorMsg: errorMsgFromBE,
                } = getDynamicValidationParams(
                    patternTokens,
                    t,
                    translationKeys
                );

                if (labelFromBE) renderQuestionLabel = labelFromBE;
                else renderQuestionLabel = t(renderQuestionLabel);

                if (patternFromBE) pattern = patternFromBE;

                if (placeholderFromBE) renderPlaceholder = placeholderFromBE;
                else renderPlaceholder = t(renderPlaceholder);

                if (errorMsgFromBE) errorMessage = errorMsgFromBE;
            } else {
                renderQuestionLabel = t(renderQuestionLabel);
                renderPlaceholder = t(renderPlaceholder);
            }
        }
    };

    setValidationParams();

    let styleHideU13 = {};

    if (id === QuestionId.Values.REGISTERING_UNDER_13) {
        styleHideU13 = {display: 'none'};
    }

    return (
        <div style={styleHideU13}>
            {!hasNodeTitle && <h1 className={css.h1}>{t(renderTitle)}</h1>}
            {isOneApp &&
                (sectionId === RegistrationSectionId.Values.ABOUT_YOU ||
                    sectionId === RegistrationSectionId.Values.WELCOME) &&
                (() => {
                    switch (id) {
                        case QuestionId.Values.EVER_DIAGNOSED_WITH_DIABETES:
                            return (
                                <div key={questionName}>
                                    <GeneralInfoByQuestionId
                                        info={info}
                                        id={id}
                                    />
                                </div>
                            );

                        case QuestionId.Values.BLOOD_TEST_PREDIABETES:
                            return (
                                <GeneralInfoByQuestionId info={info} id={id} />
                            );

                        // default info for oneapp will only have one simple content
                        default:
                            return (
                                content &&
                                t(content) && (
                                    <p className={css.paragraphAlign}>
                                        {t(content, contentTextParams)}
                                    </p>
                                )
                            );
                    }
                })()}

            {(() => {
                switch (uiType) {
                    case TEXT_INPUT:
                        return (
                            <TextInput
                                {...renderLabel(
                                    renderQuestionLabel,
                                    t(ariaLabel),
                                    renderClassForQuestionLabel
                                )}
                                id={questionName}
                                name={name}
                                type={type}
                                defaultValue={defaultValue}
                                placeholder={renderPlaceholder}
                                pattern={pattern}
                                autoComplete={autocomplete}
                                mask={mask}
                                error={
                                    <FormElementError>
                                        {errorMessage}
                                    </FormElementError>
                                }
                                customValidationError={customValidationError}
                                onKeyPress={evt => {
                                    handlePreventSpace(evt);
                                }}
                                onChange={evt => {
                                    handleChange(evt.target.value);
                                }}
                                required={required}
                            />
                        );
                    case RADIO:
                        return (
                            <RadioGroup
                                id={questionName}
                                name={name}
                                label={
                                    <Label
                                        className={
                                            hasNodeTitle
                                                ? css.questionLabelForWithNodeTitle
                                                : css.questionLabel
                                        }
                                        i18nRequiredVisualLabel=" "
                                    >
                                        {t(
                                            renderQuestionLabel,
                                            questionLabelTextParams
                                        )}
                                    </Label>
                                }
                                error={
                                    <FormElementError>
                                        {errorMessage}
                                    </FormElementError>
                                }
                                required={required}
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    handleChange(evt.target.value);
                                }}
                            >
                                {options.map(({value, label}) => {
                                    return (
                                        <Radio
                                            key={value}
                                            id={`${questionName}_item_${value}`}
                                            value={value}
                                            label={<Label>{label}</Label>}
                                            defaultChecked={
                                                value === defaultValue
                                            }
                                        />
                                    );
                                })}
                            </RadioGroup>
                        );
                    case RADIO_CARD:
                        return (
                            <RadioCard
                                id={questionName}
                                name={name}
                                items={options}
                                required={required}
                                defaultValue={defaultValue}
                                hasNodeTitle={hasNodeTitle}
                                customValidationError={customValidationError}
                                errorMessage={errorMessage}
                            />
                        );
                    case CHECKBOX:
                        return (
                            <Checkbox
                                id={questionName}
                                name={name}
                                value="true"
                                label={<Label>{t(renderQuestionLabel)}</Label>}
                                defaultChecked={defaultValue === 'true'}
                                i18nItemLabel={t(title)}
                                error={
                                    <FormElementError>
                                        {errorMessage}
                                    </FormElementError>
                                }
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    handleChange(evt.target.checked);
                                }}
                            />
                        );
                    case CHECKBOX_CARD:
                        return (
                            <CheckboxCard
                                id={questionName}
                                name={name}
                                items={options}
                                required={required}
                                defaultValues={defaultValue}
                                hasNodeTitle={hasNodeTitle}
                                customValidationError={customValidationError}
                                errorMessage={errorMessage}
                                isOneApp={isOneApp}
                            />
                        );
                    case SELECT:
                        return (
                            <Select
                                id={questionName}
                                name={name}
                                defaultValue={defaultValue}
                                label={<Label>{t(renderQuestionLabel)}</Label>}
                                placeholder={t(renderPlaceholder)}
                                items={options}
                                error={
                                    <FormElementError>
                                        {errorMessage}
                                    </FormElementError>
                                }
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    handleChange(evt.value);
                                }}
                                required={required}
                            />
                        );
                    case MULTI_SELECT:
                        return (
                            <MultiSelect
                                {...renderLabel(
                                    t(renderQuestionLabel),
                                    null,
                                    renderClassForQuestionLabel
                                )}
                                id={questionName}
                                name={name}
                                items={options}
                                defaultValues={defaultValue}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    case MULTI_SELECT_REQUIRED:
                        return (
                            <MultiSelect
                                {...renderLabel(
                                    t(renderQuestionLabel),
                                    null,
                                    renderClassForQuestionLabel
                                )}
                                id={questionName}
                                name={name}
                                required={required}
                                items={options}
                                defaultValues={defaultValue}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    case PASSWORD:
                        return (
                            <Password
                                id={questionName}
                                name={name}
                                required={required}
                                defaultValue={defaultValue}
                                customValidationError={customValidationError}
                                hasNodeTitle={hasNodeTitle}
                                onChange={evt => {
                                    handleChange(evt.target.value);
                                }}
                                validationErrorsList={validationErrorsList}
                            />
                        );
                    case ADDRESS:
                        return (
                            <Address
                                id={questionName}
                                name={name}
                                required={required}
                                question={question}
                                customValidationError={customValidationError}
                                onChange={addressHash => {
                                    handleChange(addressHash);
                                }}
                                includeMilitary={false}
                                includeUSTerritories={false}
                                context={sectionInfo.context || {}}
                                isAddressFromEditable={isAddressFromEditable}
                                setIsAddressFromEditable={
                                    setIsAddressFromEditable
                                }
                                formRef={formRef}
                            />
                        );
                    case A1C_DATE:
                        return (
                            <A1cDate
                                id={questionName}
                                name={name}
                                required={required}
                                defaultValue={
                                    defaultValue !== IDK ? defaultValue : null
                                }
                                defaultDisabled={defaultValue === IDK}
                                hasNodeTitle={hasNodeTitle}
                                onChange={handleChange}
                            />
                        );
                    case A1C_VALUE:
                        return (
                            <A1cValue
                                id={questionName}
                                name={name}
                                required={required}
                                defaultValue={
                                    defaultValue !== IDK ? defaultValue : null
                                }
                                defaultDisabled={defaultValue === IDK}
                                customValidationError={customValidationError}
                                hasNodeTitle={hasNodeTitle}
                                onChange={handleChange}
                            />
                        );
                    case BIRTH_DATE:
                        return (
                            <BirthDate
                                id={questionName}
                                name={name}
                                required={required}
                                defaultYear={defaultValue?.year}
                                defaultMonth={defaultValue?.month}
                                defaultDay={defaultValue?.day}
                                customValidationError={customValidationError}
                                customValidationErrorKeyList={
                                    customValidationErrorKeyList
                                }
                                hasNodeTitle={hasNodeTitle}
                                onChange={handleChange}
                                u13Checked={u13Checked}
                                hasTrackingID={hasTrackingID}
                            />
                        );
                    case TERMS_AND_CONDITIONS:
                        return (
                            <TermsAndConditions
                                id={questionName}
                                name={name}
                                required={required}
                            />
                        );
                    case REG_CODE:
                        return (
                            <RegCode
                                id={questionName}
                                version={version}
                                name={name}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                                required={required}
                                defaultValue={defaultValue}
                                isExperimentContext={isExperimentContext}
                                isOneApp={isOneApp}
                            />
                        );
                    case PROGRAM:
                        return (
                            <Program
                                id={questionName}
                                questionId={questionId}
                                isProgramPending={isProgramPending}
                                dataType={dataType}
                                name={name}
                                items={options}
                                defaultValue={defaultValue}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                                question={question}
                                setNavigationLabel={setNavigationLabel}
                                isOneApp={isOneApp}
                                context={sectionInfo.context || {}}
                            />
                        );
                    case COMPOUND: {
                        // TODO: Clean up and add data protection.
                        const subQuestions =
                            question.dataType[COMPOUND_FIELD]?.questionsList ||
                            [];

                        return (
                            <>
                                <span>{t(subTitle)}</span>
                                {subQuestions.map((subQuestion, index) => (
                                    <div key={index}>
                                        <Question
                                            question={subQuestion}
                                            isCompoundQuestion
                                        />
                                    </div>
                                ))}
                            </>
                        );
                    }
                    case SMS_OPT_IN: {
                        return (
                            <Sms
                                id={questionName}
                                name={name}
                                defaultValue={defaultValue}
                            />
                        );
                    }
                    case PHONE_INPUT: {
                        return (
                            <PhoneInputField
                                id={questionName}
                                name={name}
                                label={renderQuestionLabel}
                                ariaLabel={ariaLabel}
                                placeholder={renderPlaceholder}
                                autocomplete={autocomplete}
                                required={required}
                                defaultValue={defaultValue}
                                error={error}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    }
                    case HEIGHT: {
                        return (
                            <Height
                                id={questionName}
                                name={name}
                                required={required}
                                defaultValue={defaultValue}
                                onChange={handleChange}
                                customValidationError={customValidationError}
                                hasNodeTitle={hasNodeTitle}
                            />
                        );
                    }
                    case WEIGHT: {
                        return (
                            <Weight
                                id={questionName}
                                name={name}
                                required={required}
                                defaultValue={defaultValue}
                                onChange={handleChange}
                                customValidationError={customValidationError}
                                hasNodeTitle={hasNodeTitle}
                            />
                        );
                    }
                    case BLOOD_TEST: {
                        return (
                            <BloodTest
                                id={questionName}
                                name={name}
                                required={required}
                                items={options}
                                defaultValue={defaultValue}
                                customValidationError={customValidationError}
                                hasNodeTitle={hasNodeTitle}
                            />
                        );
                    }
                    case LAB_CONSENT: {
                        return (
                            <LabConsent
                                id={questionName}
                                name={name}
                                required={required}
                                items={options}
                                defaultValue={defaultValue}
                                customValidationError={customValidationError}
                                hasNodeTitle={hasNodeTitle}
                                isOneApp={isOneApp}
                            />
                        );
                    }
                    case SUPPORTED_REGION:
                        return (
                            <SupportedRegion
                                id={questionName}
                                name={name}
                                required={required}
                                defaultValue={defaultValue}
                            />
                        );
                    case ADDRESS_COMBINED:
                        return (
                            <AddressCombined
                                id={questionName}
                                name={name}
                                question={question}
                                customValidationError={customValidationError}
                                onChange={addressHash => {
                                    handleChange(addressHash);
                                }}
                                context={sectionInfo.context || {}}
                                isAddressFromEditable={isAddressFromEditable}
                                setIsAddressFromEditable={
                                    setIsAddressFromEditable
                                }
                                formRef={formRef}
                            />
                        );
                    case PHONE_INPUT_INTERNATIONAL:
                        return (
                            <PhoneInputInternational
                                id={questionName}
                                name={name}
                                label={renderQuestionLabel}
                                ariaLabel={ariaLabel}
                                placeholder={renderPlaceholder}
                                autocomplete={autocomplete}
                                question={question}
                                required={required}
                                defaultValue={defaultValue}
                                error={error}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    case ZIP:
                        return (
                            <ZipInput
                                {...renderLabel(
                                    t(renderQuestionLabel),
                                    t(ariaLabel),
                                    renderClassForQuestionLabel
                                )}
                                id={questionName}
                                name={name}
                                helpNode=""
                                defaultValue={defaultValue}
                                placeholder={t(renderPlaceholder)}
                                autoComplete={autocomplete}
                                error={
                                    <FormElementError>
                                        {errorMessage}
                                    </FormElementError>
                                }
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    handleChange(evt.target.value);
                                }}
                                required={required}
                                usePlus4={false}
                            />
                        );
                    case HEALTH_ISSUES:
                        return (
                            <HealthIssues
                                id={questionName}
                                name={name}
                                question={question}
                                customValidationError={customValidationError}
                                required={required}
                            />
                        );
                    case ACKNOWLEDGED_CHILD_PRIVACY_POLICY:
                        return (
                            <AcknowledgedChildPrivacyPolicy
                                id={questionName}
                                name={name}
                                required={required}
                            />
                        );
                    case CLIENT_CONSENT:
                        return (
                            <ClientConsent
                                id={questionName}
                                name={name}
                                defaultValue={defaultValue}
                            />
                        );
                    case MOUNTSINAI_DIABETES_ALLIANCE:
                        return (
                            <MountSinaiConsent
                                id={questionName}
                                name={name}
                                defaultValue={defaultValue}
                            />
                        );
                    case DIGNITY_HEALTH_TOU:
                        return (
                            <DignityHealthConsent
                                id={questionName}
                                name={name}
                                defaultValue={defaultValue}
                                required={required}
                            />
                        );
                    case UMASS_BG_DATA:
                        return (
                            <UmassConsent
                                id={questionName}
                                name={name}
                                defaultValue={defaultValue}
                            />
                        );
                    case LOCAL99_TOU:
                        return (
                            <Local99Consent
                                id={questionName}
                                name={name}
                                defaultValue={defaultValue}
                                required={required}
                            />
                        );
                    case LOCATION:
                        return (
                            <Location
                                id={questionName}
                                name={name}
                                items={options}
                                defaultValue={defaultValue}
                                required={required}
                            />
                        );
                    case CONFIRM_ELIGIBILITY_FILE_REG_CODE:
                        return (
                            <ConfirmEligibilityFileRegCode
                                id={questionName}
                                name={name}
                                required={required}
                                items={options}
                                defaultValue={defaultValue}
                                customValidationError={customValidationError}
                                hasNodeTitle={hasNodeTitle}
                                answersList={answersList}
                            />
                        );
                    case TARGET_PROGRAMS:
                        return (
                            <TargetPrograms
                                id={questionName}
                                questionId={questionId}
                                dataType={dataType}
                                name={name}
                                items={options}
                                defaultValue={defaultValue}
                                customValidationError={customValidationError}
                                question={question}
                                isOneApp={isOneApp}
                                context={sectionInfo.context || {}}
                                answersList={answersList}
                            />
                        );
                    case RACE_V2:
                        return (
                            <RaceV2
                                id={questionName}
                                name={name}
                                question={question}
                                error={customValidationError}
                            />
                        );
                    case GENDER_IDENTITY_V2:
                        return (
                            <GenderIdentityV2
                                id={questionName}
                                name={name}
                                question={question}
                                error={customValidationError}
                            />
                        );
                    default:
                        // TODO: Display proper message.
                        return null;
                }
            })()}
        </div>
    );
};

Question.propTypes = {
    question: PropTypes.object.isRequired,
    answersList: PropTypes.array,
    validationErrorsList: PropTypes.arrayOf(PropTypes.object),
    isCompoundQuestion: PropTypes.bool,
    hasNodeTitle: PropTypes.bool,
    isMobileQuestionWithAddress: PropTypes.bool,
    setNavigationLabel: PropTypes.func,
    onChange: PropTypes.func,
    u13Checked: PropTypes.bool,
    sectionInfo: PropTypes.object,
    formRef: PropTypes.object,
    isOneApp: PropTypes.bool,
};

Question.defaultProps = {
    // errorList: [],
};

export default Question;
