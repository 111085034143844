import Config from 'config';

const {registerStartUrl, healthyEating} = Config;

// translations located in i18n/locales/{country code}/messages
const messageConfig = {
    ERROR_BAD_REQUEST: {
        id: 'ERROR_BAD_REQUEST',
        title: 'ERROR_BAD_REQUEST.title',
        content: 'ERROR_BAD_REQUEST.content',
        oneAppTitle: 'ERROR_BAD_REQUEST.oneAppTitle',
        oneAppContent: 'ERROR_BAD_REQUEST.oneAppContent',
        oneAppButtonLabel: 'ERROR_BAD_REQUEST.oneAppButtonLabel',
    },
    ERROR_QUESTIONS_FETCH: {
        id: 'ERROR_QUESTIONS_FETCH',
        title: 'ERROR_QUESTIONS_FETCH.title',
        content: 'ERROR_QUESTIONS_FETCH.content',
        oneAppTitle: 'ERROR_QUESTIONS_FETCH.oneAppTitle',
        oneAppContent: 'ERROR_QUESTIONS_FETCH.oneAppContent',
        oneAppButtonLabel: 'ERROR_QUESTIONS_FETCH.oneAppButtonLabel',
    },
    ERROR_CREATE_ACCOUNT: {
        id: 'ERROR_CREATE_ACCOUNT',
        title: 'ERROR_CREATE_ACCOUNT.title',
        content: 'ERROR_CREATE_ACCOUNT.content',
        oneAppTitle: 'ERROR_CREATE_ACCOUNT.oneAppTitle',
        oneAppContent: 'ERROR_CREATE_ACCOUNT.oneAppContent',
        oneAppButtonLabel: 'ERROR_CREATE_ACCOUNT.oneAppButtonLabel',
    },
    ERROR_MEMBER_SUPPORT_U13_DISQUALIFIED: {
        id: 'ERROR_MEMBER_SUPPORT_U13_DISQUALIFIED',
        title: 'ERROR_MEMBER_SUPPORT_U13_DISQUALIFIED.title',
        content: 'ERROR_MEMBER_SUPPORT_U13_DISQUALIFIED.content',
        oneAppTitle: 'ERROR_MEMBER_SUPPORT_U13_DISQUALIFIED.oneAppTitle',
        oneAppContent: 'ERROR_MEMBER_SUPPORT_U13_DISQUALIFIED.oneAppContent',
        oneAppButtonLabel:
            'ERROR_MEMBER_SUPPORT_U13_DISQUALIFIED.oneAppButtonLabel',
    },
    MODAL_ACCOUNT_ALREADY_EXISTS: {
        id: 'MODAL_ACCOUNT_ALREADY_EXISTS',
        title: 'MODAL_ACCOUNT_ALREADY_EXISTS.title',
        oneAppTitle: 'MODAL_ACCOUNT_ALREADY_EXISTS.oneAppTitle',
        content: 'MODAL_ACCOUNT_ALREADY_EXISTS.content',
        oneAppContent: 'MODAL_ACCOUNT_ALREADY_EXISTS.oneAppContent',
        teamsContent: 'MODAL_ACCOUNT_ALREADY_EXISTS.teamsContent',
        primaryButtonText: 'MODAL_ACCOUNT_ALREADY_EXISTS.login',
        returnToTeamsText: 'MODAL_ACCOUNT_ALREADY_EXISTS.returnToTeams',
        cancelText: 'MODAL_ACCOUNT_ALREADY_EXISTS.buttonLabel',
    },
    MODAL_NOT_FOUND_SPONSOR: {
        id: 'MODAL_NOT_FOUND_SPONSOR',
        title: 'MODAL_NOT_FOUND_SPONSOR.title',
        title2: 'MODAL_NOT_FOUND_SPONSOR.title2',
        content: 'MODAL_NOT_FOUND_SPONSOR.content',
        oneAppContent: 'MODAL_NOT_FOUND_SPONSOR.oneAppContent',
        subTitle1: 'MODAL_NOT_FOUND_SPONSOR.subTitle1',
        subTitle2: 'MODAL_NOT_FOUND_SPONSOR.subTitle2',
        subTitle3: 'MODAL_NOT_FOUND_SPONSOR.subTitle3',
        callUs: 'MODAL_NOT_FOUND_SPONSOR.callUs',
        oneAppCallUs: 'MODAL_NOT_FOUND_SPONSOR.oneAppCallUs',
        oneAppButtonLabel: 'MODAL_NOT_FOUND_SPONSOR.oneAppButtonLabel',
        imageLetter: 'MODAL_NOT_FOUND_SPONSOR.imageLetter',
        imageEmail: 'MODAL_NOT_FOUND_SPONSOR.imageEmail',
        email: 'MODAL_NOT_FOUND_SPONSOR.email',
        btnText1: 'MODAL_NOT_FOUND_SPONSOR.btnText1',
    },
    MODAL_NOT_FOUND_REGCODE: {
        id: 'MODAL_NOT_FOUND_REGCODE',
        title: 'MODAL_NOT_FOUND_REGCODE.title',
        subTitle: 'MODAL_NOT_FOUND_REGCODE.subTitle',
        content: 'MODAL_NOT_FOUND_REGCODE.content',
        callUs: 'MODAL_NOT_FOUND_REGCODE.callUs',
        oneAppCallUs: 'MODAL_NOT_FOUND_REGCODE.oneAppCallUs',
        oneAppButtonLabel: 'MODAL_NOT_FOUND_REGCODE.oneAppButtonLabel',
    },
    MODAL_CLIENT_CAPPED: {
        id: 'MODAL_CLIENT_CAPPED',
        title: 'MODAL_CLIENT_CAPPED.title',
        regFlowContent: [
            'MODAL_CLIENT_CAPPED.regFlowContent.content1',
            'MODAL_CLIENT_CAPPED.regFlowContent.content2',
            'MODAL_CLIENT_CAPPED.regFlowContent.content3',
        ],
        oneAppFlowContent: [
            'MODAL_CLIENT_CAPPED.oneAppFlowContent.content1',
            'MODAL_CLIENT_CAPPED.oneAppFlowContent.content2',
        ],
        primaryButtonText: 'MODAL_CLIENT_CAPPED.buttonLabel',
        primaryButtonLink: healthyEating,
        oneAppButtonLabel: 'MODAL_CLIENT_CAPPED.oneAppButtonLabel',
    },
    MODAL_REGISTRATION_CLOSED: {
        id: 'MODAL_REGISTRATION_CLOSED',
        title: 'MODAL_REGISTRATION_CLOSED.title',
        regFlowContent: [
            'MODAL_REGISTRATION_CLOSED.regFlowContent.content1',
            'MODAL_REGISTRATION_CLOSED.regFlowContent.content2',
            'MODAL_REGISTRATION_CLOSED.regFlowContent.content3',
        ],
        oneAppFlowContent: [
            'MODAL_REGISTRATION_CLOSED.oneAppFlowContent.content1',
            'MODAL_REGISTRATION_CLOSED.oneAppFlowContent.content2',
        ],
        primaryButtonText: 'MODAL_REGISTRATION_CLOSED.buttonLabel',
        primaryButtonLink: healthyEating,
        oneAppButtonLabel: 'MODAL_REGISTRATION_CLOSED.oneAppButtonLabel',
    },
    MODAL_TIMEOUT: {
        id: 'MODAL_TIMEOUT',
        title: 'MODAL_TIMEOUT.title',
        content: 'MODAL_TIMEOUT.content',
        oneappContent: 'MODAL_TIMEOUT.oneappContent',
        primaryButtonText: 'MODAL_TIMEOUT.buttonLabel',
        primaryButtonLink: registerStartUrl,
    },
    MODAL_UNDER_AGE: {
        id: 'MODAL_UNDER_AGE',
        title: 'MODAL_UNDER_AGE.title',
        oneAppTitle: 'MODAL_UNDER_AGE.oneAppTitle',
        regFlowContent: [
            'MODAL_UNDER_AGE.regFlowContent.content1',
            'MODAL_UNDER_AGE.regFlowContent.content2',
        ],
        oneAppFlowContent: ['MODAL_UNDER_AGE.oneAppFlowContent.content1'],
        primaryButtonText: 'MODAL_UNDER_AGE.buttonLabel',
        primaryButtonLink: healthyEating,
        oneAppButtonLabel: 'MODAL_UNDER_AGE.oneAppButtonLabel',
    },
    MODAL_BLOOD_TEST_EXAMPLE: {
        id: 'MODAL_BLOOD_TEST_EXAMPLE',
        title: 'MODAL_BLOOD_TEST_EXAMPLE.title',
        heading1: 'MODAL_BLOOD_TEST_EXAMPLE.heading1',
        content1: 'MODAL_BLOOD_TEST_EXAMPLE.content1',
        heading2: 'MODAL_BLOOD_TEST_EXAMPLE.heading2',
        content2: 'MODAL_BLOOD_TEST_EXAMPLE.content2',
        heading3: 'MODAL_BLOOD_TEST_EXAMPLE.heading3',
        content3: 'MODAL_BLOOD_TEST_EXAMPLE.content3',
    },
    MODAL_SUPPORTED_REGION: {
        id: 'MODAL_SUPPORTED_REGION',
        title: 'MODAL_SUPPORTED_REGION.title',
        content: 'MODAL_SUPPORTED_REGION.content',
        oneAppTitle: 'MODAL_SUPPORTED_REGION.oneAppTitle',
        oneAppContent: 'MODAL_SUPPORTED_REGION.oneAppContent',
    },
    MODAL_ZIPCODE_NOT_SUPPORTED: {
        id: 'MODAL_ZIPCODE_NOT_SUPPORTED',
        title: 'MODAL_ZIPCODE_NOT_SUPPORTED.title',
        content: 'MODAL_ZIPCODE_NOT_SUPPORTED.content',
        oneAppContent: 'MODAL_ZIPCODE_NOT_SUPPORTED.oneAppContent',
    },
    MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED: {
        id: 'MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED',
        title: 'MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED.title',
        oneAppTitle: 'MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED.oneAppTitle',
        content: 'MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED.content',
        oneAppContent: 'MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED.oneAppContent',
        contentWithoutClientName:
            'MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED.contentWithoutClientName',
    },
    MODAL_MOBILE_APP_SMS: {
        id: 'MODAL_MOBILE_APP_SMS',
        title: 'MODAL_MOBILE_APP_SMS.title',
    },
    MODAL_REDIRECT_ERROR_MESSAGE: {
        id: 'MODAL_REDIRECT_ERROR_MESSAGE',
        title: 'MODAL_REDIRECT_ERROR_MESSAGE.title',
        content: 'MODAL_REDIRECT_ERROR_MESSAGE.content',
        primaryButtonText: 'MODAL_REDIRECT_ERROR_MESSAGE.buttonLabel',
    },
    MODAL_FOUND_REGCODES_FROM_EMAIL: {
        id: 'MODAL_FOUND_REGCODES_FROM_EMAIL',
        title: 'MODAL_FOUND_REGCODES_FROM_EMAIL.title',
        content: 'MODAL_FOUND_REGCODES_FROM_EMAIL.content',
        useCodeButtonText: 'MODAL_FOUND_REGCODES_FROM_EMAIL.useCodeButtonText',
        backButtonText: 'MODAL_FOUND_REGCODES_FROM_EMAIL.backButtonText',
        content2: 'MODAL_FOUND_REGCODES_FROM_EMAIL.content2',
        buttonLabel: 'MODAL_FOUND_REGCODES_FROM_EMAIL.buttonLabel',
    },
    MODAL_PROGRAM_ELIGIBILITY: {
        id: 'MODAL_PROGRAM_ELIGIBILITY',
        title: 'MODAL_PROGRAM_ELIGIBILITY.title',
        content: 'MODAL_PROGRAM_ELIGIBILITY.content',
        buttonCancel: 'MODAL_PROGRAM_ELIGIBILITY.buttonCancel',
        buttonOK: 'MODAL_PROGRAM_ELIGIBILITY.buttonOK',
        DIABETES: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.DIABETES.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.DIABETES.content',
        },
        PREDIABETES: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.PREDIABETES.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.PREDIABETES.content',
        },
        ADV_PREDIABETES: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.PREDIABETES.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.PREDIABETES.content',
        },
        COMPR_PREDIABETES_CARE: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.PREDIABETES.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.PREDIABETES.content',
        },
        WEIGHT_MANAGEMENT: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.WEIGHT_MANAGEMENT.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.WEIGHT_MANAGEMENT.content',
        },
        ADV_WEIGHT_MANAGEMENT: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.WEIGHT_MANAGEMENT.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.WEIGHT_MANAGEMENT.content',
        },
        COMPR_WEIGHT_CARE: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.WEIGHT_MANAGEMENT.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.WEIGHT_MANAGEMENT.content',
        },
        HYPERTENSION: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.HYPERTENSION.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.HYPERTENSION.content',
        },
        BEHAVIORAL_HEALTH: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.BEHAVIORAL_HEALTH.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.BEHAVIORAL_HEALTH.content',
        },
        CHRONIC_KIDNEY_DISEASE: {
            subTitle:
                'MODAL_PROGRAM_ELIGIBILITY.CHRONIC_KIDNEY_DISEASE.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.CHRONIC_KIDNEY_DISEASE.content',
        },
        HEART_FAILURE: {
            subTitle: 'MODAL_PROGRAM_ELIGIBILITY.HEART_FAILURE.subTitle',
            content: 'MODAL_PROGRAM_ELIGIBILITY.HEART_FAILURE.content',
        },
    },
};

export const messageTypes = {
    ACCOUNT_ALREADY_EXISTS: 'ACCOUNT_ALREADY_EXISTS',
    GENERAL: 'GENERAL', // General, for most use cases, allows for title, content, primary button, and secondary button
    LABEL: 'LABEL',
    MEMBER_SUPPORT: 'MEMBER_SUPPORT',
    TIMEOUT: 'TIMEOUT',
    BLOOD_TEST_EXAMPLE: 'BLOOD_TEST_EXAMPLE',
    SUPPORTED_REGION: 'SUPPORTED_REGION',
    NOT_FOUND_SPONSOR: 'NOT_FOUND_SPONSOR',
    NOT_FOUND_REGCODE: 'NOT_FOUND_REGCODE',
    INTERNATIONAL_NUMBER_NOT_ALLOWED: 'INTERNATIONAL_NUMBER_NOT_ALLOWED',
    MOBILE_APP_SMS: 'MOBILE_APP_SMS',
    REDIRECT_ERROR_MESSAGE: 'REDIRECT_ERROR_MESSAGE',
    FOUND_REGCODES_FROM_EMAIL: 'FOUND_REGCODES_FROM_EMAIL',
    PROGRAM_ELIGIBILITY: 'PROGRAM_ELIGIBILITY',
};

export default messageConfig;
