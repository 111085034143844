/**
 * Authentication gRPC calls
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/enrollment/authentication
 */
import {
    InitializeEnrollmentRequest,
    AuthenticateRequest,
    ClientRegistrationCountryRequest,
    AuthenticationContext,
} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/authentication/authentication_pb';
import {
    enrollmentPromiseClient,
    getGrpcProtocolVersion,
    getGrpcMetadata,
    getGrpcRegistrationContext,
} from './enrollment-utils';

/**
 * initializeEnrollment
 * Used to start the enrollment process.
 * @returns {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.authentication.InitializeEnrollmentResponse>}
 *  The response will be the same as a successful attempt to authenticate (modulo wrappers), with the difference that
 *     the returned token is the _only_ way to access this pending account until an email and password have been
 *     submitted using the returned token.
 */
export const initializeEnrollment = user => {
    const request = new InitializeEnrollmentRequest();

    request.setRegistrationContext(getGrpcRegistrationContext(user));
    request.setProtocolVersion(getGrpcProtocolVersion());

    return enrollmentPromiseClient
        .initializeEnrollment(request, getGrpcMetadata())
        .then(response => response.toObject());
};

/**
 * authenticate
 * Used to authenticate a user who's returning to resume a partially-complete enrollment attempt.
 * @param {string} email user email
 * @param {string} password user password
 * @param {string} accessCode accessCode from url
 * @param {boolean} isRegRecovery only reg recovery needs authenticationContext
 * @param {boolean} isAuthAndNav SSO will call authenticateAndNavigate instead of authentication
 * @returns {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.authentication.AuthenticateResponse>}
 *  If the credentials supplied match, a token will be returned that can be used to continue the enrollment process.
 */
export const authenticate = ({
    email,
    password,
    accessCode,
    isRegRecovery = false,
    isAuthAndNav = false,
}) => {
    const request = new AuthenticateRequest();

    request.setProtocolVersion(getGrpcProtocolVersion());

    const basicAuth = new AuthenticateRequest.BasicAuth();
    const oneTimePassword = new AuthenticateRequest.OneTimePassword();
    const authenticationContext = new AuthenticationContext();

    if (accessCode) {
        if (isRegRecovery) {
            // Reg recovery
            oneTimePassword.setOtp(accessCode);
            oneTimePassword.setAuthenticationContext(
                authenticationContext.setContext(
                    AuthenticationContext.Values.REG_RECOVERY
                )
            );
            request.setOtp(oneTimePassword);
        } else {
            // Third party single sign on
            oneTimePassword.setOtp(accessCode);
            request.setOtp(oneTimePassword);
        }
    } else {
        basicAuth.setEmail(email);
        basicAuth.setPassword(password);
        request.setBasicAuth(basicAuth);
    }

    return isAuthAndNav
        ? enrollmentPromiseClient
              .authenticateAndNavigate(request, getGrpcMetadata())
              .then(response => response.toObject())
        : enrollmentPromiseClient
              .authenticate(request, getGrpcMetadata())
              .then(response => response.toObject());
};

/**
 * getRegistrationCountryForClientCode
 * Get client code registration country.
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.enrollment.authentication.ClientRegistrationCountryResponse>}
 *  Promise
 */
export const getRegistrationCountryForClientCode = clientCode => {
    const request = new ClientRegistrationCountryRequest();

    request.setClientCode(clientCode);

    return enrollmentPromiseClient
        .getRegistrationCountryForClientCode(request, getGrpcMetadata())
        .then(response => response.toObject());
};
