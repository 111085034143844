import {useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Flyout from '@teladoc/pulse/ui/Flyout';
import IconCloseDefault from '@teladoc/pulse/icons/close-default.svg';
import {messageTypes} from 'constants/messages';
import {clearMessage} from 'store/message/message-actions';
import {
    mixpanelModalClosedEvent,
    mixpanelSessionContinuedBeforeTimeOut,
} from 'utilities/mixpanel-utils';
import {getRegistrationContext} from 'utilities/utils';
import {GET_THE_APP} from 'constants/medOpt/contentIds';
import Config from 'config';
import AccountAlreadyExists from './subComponents/AccountAlreadyExists';
import Timeout from './subComponents/Timeout';
import GeneralMessage from './subComponents/GeneralMessage';
import LabelMessage from './subComponents/LabelMessage';
import MemberSupportNumber from './subComponents/MemberSupportNumber';
import BloodTestExample from './subComponents/BloodTestExample';
import NotFoundSponsor from './subComponents/NotFoundSponsor';
import NotFoundRegcode from './subComponents/NotFoundRegcode';
import FoundRegcodesFromEmail from './subComponents/FoundRegcodesFromEmail';
import InternationalNumberNotAllowed from './subComponents/InternationalNumberNotAllowed';
import SendMobileAppSms from './subComponents/SendMobileAppSms';
import RedirectErrorMessage from './subComponents/RedirectErrorMessage';
import ProgramEligibility from './subComponents/ProgramEligibility';
import css from './Message.scss';

const {
    GENERAL,
    LABEL,
    MEMBER_SUPPORT,
    TIMEOUT,
    ACCOUNT_ALREADY_EXISTS,
    BLOOD_TEST_EXAMPLE,
    NOT_FOUND_SPONSOR,
    NOT_FOUND_REGCODE,
    INTERNATIONAL_NUMBER_NOT_ALLOWED,
    MOBILE_APP_SMS,
    REDIRECT_ERROR_MESSAGE,
    FOUND_REGCODES_FROM_EMAIL,
    PROGRAM_ELIGIBILITY,
} = messageTypes;

const Message = ({appearFrom}) => {
    const {t} = useTranslation('messages');
    const dispatch = useDispatch();
    const {message} = useSelector(state => state);
    const {type, id, text, data} = message;
    let isOpen = Boolean(id || text);
    const regCodeRef = useRef(0);

    if (id === 'MODAL_FOUND_REGCODES_FROM_EMAIL' && !data.isOpen) {
        isOpen = false;
    }

    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;

    const onClearMessage = useCallback(() => {
        let questionId = '';
        let modalDescription = '';
        let contentId = '';

        switch (id) {
            case 'MODAL_ACCOUNT_ALREADY_EXISTS':
                questionId = 'EMAIL';
                modalDescription = 'Account Already Exists';
                break;
            case 'MODAL_NOT_FOUND_SPONSOR':
                questionId = 'REG_CODE';
                modalDescription = 'Need Registration Code Help';
                break;
            case 'MODAL_FOUND_REGCODES_FROM_EMAIL':
                questionId = 'REG_CODE';
                modalDescription = 'Regcodes found based on email';
                break;
            case 'MODAL_NOT_FOUND_REGCODE':
                questionId = 'REG_CODE';
                modalDescription = 'submitted Regcode not found';
                break;
            case 'MODAL_CLIENT_CAPPED':
                questionId = 'REG_CODE';
                modalDescription = 'Client Capped';
                break;
            case 'MODAL_REGISTRATION_CLOSED':
                questionId = 'REG_CODE';
                modalDescription = 'Registration Closed';
                break;
            case 'MODAL_UNDER_AGE':
                questionId = 'BIRTH_DATE';
                modalDescription = 'Under Age';
                break;
            case 'MODAL_BLOOD_TEST_EXAMPLE':
                questionId = 'BLOOD_TEST_PREDIABETES';
                modalDescription = 'Blood Test Example';
                break;
            case 'MODAL_SUPPORTED_REGION':
                questionId = 'SUPPORTED_REGION';
                modalDescription = 'Supported Region';
                break;
            case 'MODAL_ZIPCODE_NOT_SUPPORTED':
                questionId = 'ZIP';
                modalDescription = 'Zip Code not found';
                break;
            case 'MODAL_INTERNATIONAL_NUMBER_NOT_ALLOWED':
                questionId = 'INTERNATIONAL_NUMBER_NOT_ALLOWED';
                modalDescription = 'Invalid Phone Number';
                break;
            case 'MODAL_MOBILE_APP_SMS':
                questionId = '';
                modalDescription = 'Mobile App Download';
                contentId = GET_THE_APP;
                break;
            case 'MODAL_REDIRECT_ERROR_MESSAGE':
                questionId = '';
                modalDescription = 'Redirect URL not found';
                break;
            case 'MODAL_PROGRAM_ELIGIBILITY':
                questionId = '';
                modalDescription = 'Program Eligibility';
        }

        mixpanelModalClosedEvent(modalDescription, contentId || '', questionId);

        if (id === 'MODAL_TIMEOUT') {
            mixpanelSessionContinuedBeforeTimeOut();
        }

        dispatch(clearMessage());
    }, [dispatch, id]);

    const regCodeTally = () => {
        regCodeRef.current += 1;
    };

    const subComponent = () => {
        switch (type) {
            case ACCOUNT_ALREADY_EXISTS:
                return <AccountAlreadyExists id={id} isOneApp={isOneApp} />;
            case GENERAL:
                return <GeneralMessage id={id} isOneApp={isOneApp} />;
            case LABEL:
                return <LabelMessage text={text} />;
            case MEMBER_SUPPORT:
                return <MemberSupportNumber id={id} isOneApp={isOneApp} />;
            case TIMEOUT:
                return <Timeout id={id} onHidden={onClearMessage} />;
            case BLOOD_TEST_EXAMPLE:
                return <BloodTestExample id={id} />;
            case NOT_FOUND_SPONSOR:
                return (
                    <NotFoundSponsor
                        id={id}
                        isOneApp={isOneApp}
                        onHidden={onClearMessage}
                        regCodeTally={regCodeTally}
                        regCodeRef={regCodeRef}
                    />
                );
            case NOT_FOUND_REGCODE:
                return <NotFoundRegcode id={id} isOneApp={isOneApp} />;
            case FOUND_REGCODES_FROM_EMAIL:
                return <FoundRegcodesFromEmail id={id} data={data} />;
            case INTERNATIONAL_NUMBER_NOT_ALLOWED:
                return (
                    <InternationalNumberNotAllowed
                        id={id}
                        text={text}
                        isOneApp={isOneApp}
                    />
                );
            case MOBILE_APP_SMS:
                return <SendMobileAppSms id={id} />;
            case REDIRECT_ERROR_MESSAGE:
                return <RedirectErrorMessage id={id} />;
            case PROGRAM_ELIGIBILITY:
                return <ProgramEligibility id={id} />;

            default:
                return <GeneralMessage id={id} isOneApp={isOneApp} />;
        }
    };

    return (
        <div aria-live="assertive">
            <Flyout
                isOpen={isOpen}
                classNameRoot={css.modal}
                classNameContainer={css.container}
                appearFrom={appearFrom}
                renderClose={onCloseClick => {
                    return (
                        <Button
                            className={css.close}
                            onClick={onCloseClick}
                            aria-label={t('common.close')}
                        >
                            <IconCloseDefault />
                        </Button>
                    );
                }}
                onRequestClose={onClearMessage}
            >
                {subComponent()}
            </Flyout>
        </div>
    );
};

Message.propTypes = {
    appearFrom: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

Message.defaultProps = {
    appearFrom: 'right',
};

export default Message;
