import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import Radio from '@teladoc/pulse/ui/Radio';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {questionConfig} from 'constants/questions';
import css from './ConfirmEligibilityFileRegCode.scss';

const Item = ({displayName, code}) => (
    <div>
        <p className={css.coverageName}>{displayName}</p>
        <p className={css.coverageCode}>{code}</p>
    </div>
);

Item.propTypes = {
    code: PropTypes.string,
    displayName: PropTypes.string,
};
const ConfirmEligibilityFileRegCode = ({
    id,
    name,
    required,
    items,
    defaultValue,
    customValidationError,
    hasNodeTitle,
    answersList,
}) => {
    const {t} = useTranslation('questions');

    const regCode = answersList.find(
        answer => answer.questionId?.id === QuestionId.Values.REG_CODE
    );
    const clientName = answersList.find(
        answer => answer.questionId?.id === QuestionId.Values.CLIENT_NAME
    );

    const coverageName = {
        code: regCode?.value?.text,
        displayName: clientName?.value?.text,
    };
    const {
        label: questionLabel,
        labelForWithNodeTitle,
        error,
    } = questionConfig[id] || {};

    const renderQuestionLabel = hasNodeTitle
        ? labelForWithNodeTitle
        : questionLabel;

    return (
        <div>
            <Label className={css.label} i18nRequiredVisualLabel=" ">
                {t(renderQuestionLabel)}
            </Label>
            <div className={css.item}>
                <Item {...coverageName} />
            </div>
            <RadioGroup
                id={id}
                name={name}
                label={<Label />}
                required={required}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={customValidationError}
            >
                {items.map(({value, label}) => {
                    return (
                        <Radio
                            key={value}
                            id={`item-${value}`}
                            value={value}
                            label={<Label>{label}</Label>}
                            defaultChecked={value === defaultValue}
                        />
                    );
                })}
            </RadioGroup>
        </div>
    );
};

ConfirmEligibilityFileRegCode.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
    customValidationError: PropTypes.element,
    hasNodeTitle: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    answersList: PropTypes.array,
};

export default ConfirmEligibilityFileRegCode;
